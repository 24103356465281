import React, { useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import config from '../config'
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function FormDC() {
  const [startLottery, setStartLottery] = React.useState();
  const [hotelData, setHotelData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const useStyles = makeStyles((theme) => ({

    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '18ch',
      },
    },
    card: {
      root: {
        minWidth: 50,
      },
      bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
      title: {
        fontSize: 14,
      },
      pos: {
        marginBottom: 12,
      }
    },
  }));

  const handleSubmit = (event) => {
    event.preventDefault();
    var url;
    hotelData.map((value) => url = `${config.protelExpressUri}/api/hotels/formdc/${value._id}?_id=${value._id}`)
    const dcData = {
      startLottery,
    };
    axios
      .put(url, dcData)
      .then(res => {
        window.location.reload();
        console.log(res)
        handleOpenSuccess()
      })
      .catch(err => {
        console.log(err);
        console.log(err.response)
        handleOpenError()
      })
  }

  const { id } = useParams();

  useEffect(() => {
    var dataGet = JSON.stringify({ "hotelID": `${id}` });

    var config0 = {
      method: 'post',
      url: `${config.protelExpressUri}/api//find/hotels`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataGet
    };
    async function getData() {
      const result = await axios(config0)
      setHotelData(result.data)
    }
    getData()
  }, [id])

  const handleCloseSuccess = (òevent, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //snackbar success
  const handleOpenSuccess = () => {
    setOpen(true);
  };

  const handleOpenError = () => {
    setOpenError(true);
  };

  const classes = useStyles();

  return (<div style={{ height: '100vh', backgroundColor: '#F8F8F8' }}>
    <div style={{ height: '64px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
    <div style={{ backgroundColor: "#1aa3ff", height: '60px', display: 'flex', alignItems: 'center', marginLeft: 250 }} >
      <Typography variant="h5" style={{
        color: "white",
        fontSize: 20,
        marginLeft: 40
      }} gutterBottom>Inserisci Dati Documento Commerciale</Typography>
    </div>
    <div onSubmit={handleSubmit} style={{
      marginLeft: 290
      , display: 'flex'
    }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSuccess}>
        <div><Alert severity="success">SUCCESS!</Alert></div>
      </Snackbar>

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
        <div><Alert severity="error">Errore</Alert></div>
      </Snackbar>
      <Card style={{ minWidth: 250, marginTop: 11, marginRight: 11, borderRadius: 5 }} elevation={0}>
        <CardContent>
          {hotelData.map((value, i) =>
            <form key={i} className={classes.root} onSubmit={handleSubmit}>
              <TextField id="standard-basic" label="Inizio Lotteria" InputLabelProps={{ shrink: true }} defaultValue={value.startLottery} type="date"
                format={'yyyy/MM/dd'} onChange={e => setStartLottery(e.target.value)} />
              <CardActions>
                <Button variant="contained" onClick={handleSubmit} style={{ backgroundColor: '#1aa3ff', color: "white" }}>Inserisci</Button>
              </CardActions>
            </form>
          )}
        </CardContent>
      </Card>
    </div ></div>
  );
}

export default FormDC;
