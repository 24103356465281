import React, { useEffect, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SendIcon from '@mui/icons-material/Send';
import { Link as RouterLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import axios from 'axios'
import config from '../config';
import AppsIcon from '@mui/icons-material/Apps';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from "react-router-dom";
import { AuthenticationContext } from '../contexts'

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  nestedPartner: { paddingLeft: theme.spacing.unit * 1 },
  nestedCompany: { paddingLeft: theme.spacing.unit * 3 },
  nestedHotel: { paddingLeft: theme.spacing.unit * 5 },
}));

export default function MenuAppBar() {

  const { currentUser } = useContext(AuthenticationContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clearAutocomplete, setClearAutocomplete] = React.useState(false);
  //lista hotel per popolare autocomplete
  const [hotels, setHotels] = React.useState([]);
  
  const navigate = useNavigate();

  useEffect(() => {
    const getHotels = async () => {
      try {
        const userRaccordo = await axios.get(`${config.protelExpressUri}/api/raccordo`)
        //ordina per nome company
        let sorted = []
        for (let i = 0; i < userRaccordo.data.length; i++) {
          sorted.push(userRaccordo.data[i])
        }
        sorted.map((value) => value.partner.companies.sort((a, b) => a.companyName.localeCompare(b.companyName))
        )  
  
        //costruisce array di hotel aggiungendo ad ognuno la sua company
        const hotels = []
        const companies = [];
        sorted.forEach(p => {
          if (currentUser.roles.includes("ADMIN") || currentUser.partner === p._id) {
            companies.push(...p.partner.companies)
          }
        })
        companies.forEach(company => { //per ogni company in companies 
          const companyHotels = company.hotels.map(hotel => {
            return {
              ...hotel,
              company: company.companyName
            }
          })
          hotels.push(...companyHotels)
        })
        setHotels(hotels)
      } catch (err) {
        console.error(err.message);
      }
    };

    getHotels();
  },[currentUser.partner, currentUser.roles])

  const handleChange = (event, value) => {
    if(value) {
      navigate(`/HomeDetailed/${value.hotelID}`)
      setClearAutocomplete(!clearAutocomplete)
    }

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={classes.root} >
      <AppBar position="fixed" style={{ background: 'white' }} >
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
          </Typography>

           <Autocomplete
            key={clearAutocomplete}
            id="grouped-demo"
            options={hotels}
            groupBy={(option) => option.company}
            getOptionLabel={(option) => option.hotelName}
            style={{ minWidth: 300 }}
            size="small"
            renderInput={(params) => <TextField {...params} label="Serenissima Informatica" variant="outlined" />}
            blurOnSelect
            onChange={handleChange}
          />

          <div style={{ minWidth: 30 }}>
          </div>
          <Divider orientation="vertical" flexItem />
          <div style={{ minWidth: 20 }}>
          </div>
          <div>
            <AppsIcon onClick={handleClick} style={{ color: 'black' }} />
            <Menu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <MenuItem style={{ backgroundColor: 'white' }}>
                <ListItem button component={RouterLink} to="/" >
                  <ListItemIcon>
                    <SendIcon fontSize="small" style={{ color: 'black' }} />
                  </ListItemIcon>
                  <ListItemText primary="Ritorna alla Home" style={{ color: 'black' }} />
                </ListItem>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div >
  );
}
