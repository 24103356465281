import React, { useContext, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AppsIcon from '@mui/icons-material/Apps';
import { Link as RouterLink } from "react-router-dom";
import Menu from '@mui/material/Menu';
import ListItemText from '@mui/material/ListItemText';
import ListItem from "@mui/material/ListItem";
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { AuthenticationContext } from '../contexts';
import axios from 'axios'
import config from '../config'
import ApartmentIcon from '@mui/icons-material/Apartment';

const useStyles = makeStyles((theme) => ({
  root: {
    //flexGrow: 5,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  nestedPartner: { paddingLeft: theme.spacing.unit * 1 },
  nestedCompany: { paddingLeft: theme.spacing.unit * 3 },
  nestedHotel: { paddingLeft: theme.spacing.unit * 5 },
}));

export default function MenuAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [companyData, setCompanyData] = React.useState({});
  const classes = useStyles();
  const { currentUser } = useContext(AuthenticationContext);

  const handleClose = () => {
    setAnchorEl(null);

  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    var dataC = JSON.stringify({ "companyId": currentUser.company, "hotelID": currentUser.hotel });
    var configPr = {
      method: 'put',
      url: `${config.protelExpressUri}/api/raccordo/findData`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataC
    };
    async function getDataC() {
      const result = await axios(configPr)
      setCompanyData(result.data)
    }
    getDataC()
  }, [currentUser.company, currentUser.hotel])

  return (
    <div className={classes.root} >
      <AppBar position="fixed" style={{ background: 'white'/*'#F8F8F8'*/ }} elevation={1}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
          </Typography>
          <Divider orientation="vertical" flexItem />
          <div style={{ minWidth: 20 }}>
          </div>
          <div>
            <AppsIcon onClick={handleClick} style={{ color: 'black' }} />
            <Menu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}>

              <MenuItem style={{ backgroundColor: 'white' }}>
                <ListItem>
                  <ListItemText primary={companyData.companys ? companyData.companys.companyName : ''} style={{ color: 'black' }} />
                </ListItem>
              </MenuItem>
              {companyData.companys &&
                companyData.companys.hotels.map((value) =>
                  <MenuItem key={value.hotelID} style={{ backgroundColor: 'white', marginLeft: 20 }}>
                    <ListItem button component={RouterLink} to={`/Home/${value.hotelID}`} >
                      <ListItemText primary={value.hotelName} style={{ color: 'black' }} ><ApartmentIcon style={{ fontSize: '20px', color: 'black' }} /></ListItemText>
                    </ListItem>
                  </MenuItem>
                )}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div >
  );
}
