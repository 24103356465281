import React, { useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  CardContent,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  TextField,
  Snackbar,
  Box,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { read, writeFileXLSX } from "xlsx";
import config from "../config";
import { AuthenticationContext } from "../contexts";
import TitleBar from "./TitleBar";

// funzioni per ordinare le colonne della tabella
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
// colonne della tabella
const headCells = [
  {
    id: "regNo",
    label: "Guest Registration No.",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "category",
    label: "Category",
  },
  {
    id: "IDNo",
    label: "Identification No.",
  },
  {
    id: "nationality",
    label: "Nationality",
  },
  {
    id: "bookingMethod",
    label: "Booking Method",
  },
  {
    id: "arrivalDate",
    label: "Check-in Date",
  },
  {
    id: "arrivalTime",
    label: "Check-in Time",
  },
  {
    id: "departureDate",
    label: "Check-out Date",
  },
  {
    id: "departureTime",
    label: "Check-out Time",
  },
  {
    id: "days",
    label: "No. of Taxable Days",
  },
];

function GreenTax() {
  const [isLoading, setIsLoading] = React.useState(false);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
  const [dialogError, setDialogError] = React.useState({
    title: "",
    open: false,
    errors: [],
  });

  // lista ospiti
  const [guests, setGuests] = React.useState([]);
  // data checkin più recente degli ospiti salvati in db
  const [latestCheckIn, setLatestCheckIn] = React.useState("");

  const { currentUser } = useContext(AuthenticationContext);

  const { id } = useParams();

  // funzione per cercare l'ospite con checkin più recente in db
  const checkLatestCheckIn = React.useCallback(() => {
    setIsLoading(true);
    axios
      .post(`${config.protelExpressUri}/api/latestGreenTaxCheckIn`, {
        hotelID: id,
      })
      .then((res) => {
        if (res.data.length > 0) {
          setLatestCheckIn(res.data[0].checkInDate);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [id]);

  React.useEffect(() => {
    checkLatestCheckIn();
  }, [checkLatestCheckIn]);

  // richiesta prenotazioni
  const handleSearch = async (e, period, from, to, readOnly = false) => {
    e.preventDefault();
    setIsLoading(true);
    setGuests([]);
    let fromDate = from;
    let toDate = to;
    if (period) {
      const [year, month] = period.split("-");
      fromDate = `${year}-${month}-01`;
      toDate = `${year}-${month}-${new Date(year, month, 0).getDate()}`;
    }
    axios
      .get(
        `${
          config.requestsUri
        }/greentax?hotelID=${id}&from=${fromDate}&to=${toDate}&readOnly=${
          readOnly ? "true" : ""
        }`
      )
      .then((res) => {
        const { guests, warnings } = res.data.data;
        // TODO: rimuovere ordinamento quando sarà gestito nel backend
        setGuests(
          guests.slice().sort((a, b) => Number(a.regNo) - Number(b.regNo))
        );
        if (warnings.length > 0) {
          setDialogError({ open: true, title: "Warnings", errors: warnings });
        }
        checkLatestCheckIn();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error?.message) {
          setDialogError({
            title: "Error",
            open: true,
            //FIXME: hardcoded
            errors: [
              error?.response?.data === "Nessuna prenotazione"
                ? "No reservations found"
                : error.message,
            ],
          });
        }
        setIsLoading(false);
      });
  };
  // salva ospiti in db
  const handleSave = () => {
    setIsLoading(true);
    const guestsToSave = guests.map(({ regNo, reservationID, guestID }) => {
      return {
        resNo: reservationID,
        profileID: guestID,
        number: regNo,
      };
    });
    axios
      .post(`${config.protelExpressUri}/api/saveGuestsGreenTax`, {
        hotelID: id,
        guests: guestsToSave,
      })
      .then(() => {
        setIsLoading(false);
        setOpenSnackbar(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  // cancella ospiti dal db
  const handleDelete = (period) => {
    setIsLoading(true);
    axios
      .post(`${config.protelExpressUri}/api/deleteGreenTaxGuests`, {
        hotelID: id,
        from: `${period}-01`,
      })
      .then(() => {
        setIsLoading(false);
        setOpenSnackbar(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  // esporta file excel
  const handleExport = (period) => {
    const head =
      "Guest Registration No.;Name of Guest;Category;Identification No.;Nationality;Booking Method;Check-in Date;Check-in Time;Check-out Date;Check-out Time;No. of Days";
    const rows = guests.map(
      ({
        regNo,
        name,
        category,
        IDNo,
        nationality,
        bookingMethod,
        arrivalDate,
        arrivalTime,
        departureDate,
        departureTime,
        days,
      }) => {
        return [
          regNo,
          name,
          category,
          IDNo,
          nationality,
          bookingMethod,
          arrivalDate.replaceAll("-", "/"),
          arrivalTime,
          departureDate.replaceAll("-", "/"),
          departureTime,
          days,
        ].join(";");
      }
    );
    const csv = [head, ...rows].join("\r\n");
    const wb = read(csv, { type: "string", cellDates: true });
    writeFileXLSX(wb, `GreenTax_${period}.xlsx`);
  };

  const isSaveAndExportDisabled = () => {
    return isLoading || guests.length === 0;
  };

  const handleUpdateGuest = (e, guest) => {
    setGuests((guests) => {
      const newGuests = [...guests];
      const newGuest = newGuests.find(
        ({ guestID, reservationID }) =>
          guestID === guest.guestID && reservationID === guest.reservationID
      );
      newGuest.regNo = e.target.value;
      return newGuests;
    });
  };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <TitleBar title="Green Tax" loading={isLoading} />
      <div style={{ margin: "10px 10px 0px 290px" }}>
        <ActionsCard
          handleSearch={handleSearch}
          handleSave={handleSave}
          handleDelete={handleDelete}
          handleExport={handleExport}
          isSaveAndExportDisabled={isSaveAndExportDisabled}
          isLoading={isLoading}
          latestCheckIn={latestCheckIn}
          currentUser={currentUser}
        />

        {guests && guests.length > 0 && (
          <GuestsTable
            guests={guests}
            updateGuest={handleUpdateGuest}
            currentUser={currentUser}
          />
        )}

        <Snackbar
          open={openSnackbar}
          autoHideDuration="6000"
          onClose={() => setOpenSnackbar(false)}
        >
          <div>
            <Alert severity="success">Success</Alert>
          </div>
        </Snackbar>
        <Snackbar
          open={openSnackbarError}
          autoHideDuration="6000"
          onClose={() => {
            setOpenSnackbarError(false);
          }}
        >
          <div>
            <Alert severity="error">Error</Alert>
          </div>
        </Snackbar>
        <Dialog
          open={dialogError.open}
          onClose={() => setDialogError({ open: false, errors: [], title: "" })}
        >
          <DialogTitle style={{ paddingBottom: 0 }}>
            {dialogError.title}
          </DialogTitle>
          <DialogContent>
            {dialogError.errors.map((error, i) => (
              <p key={i}>{error}</p>
            ))}
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const GuestsTable = ({ guests, updateGuest, currentUser }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={(event) => handleSort(event, headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {guests
            .slice()
            .sort(getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((guest) => (
              <TableRow key={`${guest.reservationID}-${guest.guestID}`}>
                <TableCell>
                  {currentUser.roles.includes("ADMIN") ? (
                    <TextField
                      onChange={(e) => {
                        updateGuest(e, guest);
                      }}
                      value={guest.regNo}
                    />
                  ) : (
                    guest.regNo
                  )}
                </TableCell>
                <TableCell>{guest.name}</TableCell>
                <TableCell>{guest.category}</TableCell>
                <TableCell>{guest.IDNo}</TableCell>
                <TableCell>{guest.nationality}</TableCell>
                <TableCell>{guest.bookingMethod}</TableCell>
                <TableCell>{guest.arrivalDate}</TableCell>
                <TableCell>{guest.arrivalTime}</TableCell>
                <TableCell>{guest.departureDate}</TableCell>
                <TableCell>{guest.departureTime}</TableCell>
                <TableCell>{guest.days}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={guests.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

const ActionsCard = ({
  handleSearch,
  handleSave,
  handleDelete,
  handleExport,
  isSaveAndExportDisabled,
  isLoading,
  latestCheckIn,
  currentUser,
}) => {
  const [period, setPeriod] = React.useState("");
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);

  const isSearchDisabled = () => {
    return isLoading || !period;
  };

  const isCheckDisabled = () => {
    return isLoading || !fromDate || !toDate;
  };

  // la generazione del report è attiva solo per i mesi passati
  const isGenerateDisabled = () => {
    const nowPeriod = new Date().toISOString().substring(0, 7);
    return period >= nowPeriod;
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        flexWrap: "wrap",
      }}
    >
      <Card style={{ marginBottom: 10 }}>
        <CardContent>
          <Typography gutterBottom color="primary">
            Generate Report (assign registration numbers)
          </Typography>
          <form
            style={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <TextField
              label="Period"
              value={period}
              type="month"
              onChange={(e) => setPeriod(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />

            <Button
              variant="contained"
              disabled={isSearchDisabled() || isGenerateDisabled()}
              onClick={(e) => {
                if (latestCheckIn) {
                  const [periodYear, periodMonth] = period.split("-");
                  if (
                    new Date(latestCheckIn.split("T")[0]) >
                    new Date(periodYear, periodMonth - 2, 1)
                  )
                    handleSearch(e, period);
                  else {
                    e.preventDefault();
                    setOpenAlertDialog(true);
                  }
                } else {
                  e.preventDefault();
                  setOpenAlertDialog(true);
                }
              }}
              type="submit"
            >
              generate
            </Button>
            {currentUser.roles.includes("ADMIN") && (
              <Button
                variant="contained"
                color="error"
                disabled={isSaveAndExportDisabled()}
                onClick={handleSave}
              >
                SAVE REG. NOS.
              </Button>
            )}
            {(currentUser.roles.includes("ADMIN") ||
              currentUser.roles.includes("SUPPORT")) && (
              <Button
                variant="contained"
                color="error"
                disabled={isSearchDisabled()}
                onClick={() => handleDelete(period)}
              >
                DELETE
              </Button>
            )}
            <Button
              variant="contained"
              disabled={isSaveAndExportDisabled()}
              onClick={() => handleExport(period)}
            >
              EXPORT FILE
            </Button>
          </form>
        </CardContent>
      </Card>
      <Card style={{ marginBottom: 10 }}>
        <CardContent>
          <Typography gutterBottom color="primary">
            Check guests
          </Typography>
          <form
            style={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <TextField
              label="From Date"
              value={fromDate}
              type="date"
              format={"yyyy/MM/dd"}
              onChange={(e) => setFromDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                max: toDate || "3000-01-01",
              }}
            />
            <TextField
              label="To Date"
              value={toDate}
              type="date"
              format={"yyyy/MM/dd"}
              onChange={(e) => setToDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: fromDate,
                max: "3000-01-01",
              }}
            />

            <Button
              variant="contained"
              disabled={isCheckDisabled()}
              onClick={(e) => {
                handleSearch(e, undefined, fromDate, toDate, true);
              }}
              type="submit"
            >
              CHECK
            </Button>
          </form>
        </CardContent>
      </Card>
      <Dialog
        open={openAlertDialog}
        onClose={() => setOpenAlertDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Was the previous period completed?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {latestCheckIn &&
              `The last check in with a registration number assigned was on ${
                latestCheckIn.split("T")[0]
              }.`}{" "}
            <br />
            Are you sure you want to assign the registration numbers for the
            period {period}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlertDialog(false)}>NO</Button>
          <Button
            onClick={(e) => {
              setOpenAlertDialog(false);
              handleSearch(e, period);
            }}
            autoFocus
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GreenTax;
