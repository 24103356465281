import React, { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import FileDownload from "js-file-download";
import MuiAlert from '@mui/material/Alert';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  CircularProgress,
  TextField,
  Snackbar,
} from "@mui/material";
import config from "../config";

function ExportCityTax() {
  const [properties, setProperties] = React.useState([]);
  const [selectedProperty, setSelectedProperty] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );
  /* const [toDate, setToDate] = React.useState(
    new Date().toISOString().split("T")[0]
  ); */
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
  const [dialogError, setDialogError] = React.useState({
    title: "Errori",
    open: false,
    errors: [],
  });
  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(`${config.protelExpressUri}/api/findsProperty`, { hotelID: id })
      .then((res) => {
        let setup = res.data;
        let propertyCodes = [""];
        if (setup) {
          propertyCodes = setup.propertyConfiguration.map(
            (p) => p.propertyCode
          );
        }
        setProperties(["", ...propertyCodes]);
        setSelectedProperty("");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [id]);

  const handleExport = async () => {
    setIsLoading(true);
    axios
      .get(
        `${config.requestsUri}/ctexp?hotelID=${id}&from=${fromDate}&to=${fromDate}&propertyCode=${selectedProperty}`
      )
      .then((res) => {
        const { file } = res.data.data;
        FileDownload(file, `citytax_${fromDate}.txt`);
        setIsLoading(false);
      })
      .catch((err) => {
        const data = err?.response?.data;
        const errori = data?.errori;
        if (errori) {
          setDialogError({ title: "Errori", open: true, errors: errori });
        }
        else if (data) {
          setDialogError({ title: "Errore", open: true, errors: [data] });
        }
        setIsLoading(false);
      });
  };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#1aa3ff",
          height: "60px",
          display: "flex",
          alignItems: "center",
          marginLeft: 250,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "white",
            fontSize: 20,
            marginLeft: 40,
          }}
          gutterBottom
        >
          Esporta File Tassa di Soggiorno
        </Typography>
      </div>

      <div style={{ margin: "10px 10px 0px 290px" }}>
        <Card style={{ marginBottom: 10 }}>
          <CardContent style={{ display: "flex" }}>
            <CardActions>
              <FormControl>
                <InputLabel id="property-label">Property</InputLabel>
                <Select
                  labelId="property-label"
                  value={selectedProperty}
                  name="property"
                  onChange={(e) => {
                    setSelectedProperty(e.target.value);
                  }}
                  style={{ width: 150 }}
                >
                  {properties.map((prp) =>
                    prp === "" ? (
                      <MenuItem key={prp} value="">
                        TUTTE
                      </MenuItem>
                    ) : (
                      <MenuItem key={prp} value={prp}>
                        {prp}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <TextField
                id="standard-basic"
                label="Data Check Out"
                value={fromDate}
                type="date"
                format={"yyyy/MM/dd"}
                onChange={(e) => setFromDate(e.target.value)}
              />
              
              {/* <TextField
                id="standard-basic"
                label="A Data"
                value={toDate}
                type="date"
                format={"yyyy/MM/dd"}
                onChange={(e) => setToDate(e.target.value)}
              /> */}

              <Button
                variant="contained"
                onClick={handleExport}
                style={{
                  backgroundColor: "rgb(3, 155, 229)",
                  color: "white",
                  marginLeft: 8,
                }}
                elevation={0}
                disabled={isLoading}
              >
                ESPORTA
              </Button>

              {isLoading && <CircularProgress />}
            </CardActions>
          </CardContent>
        </Card>
        <Snackbar
          open={openSnackbar}
          autoHideDuration="6000"
          onClose={() => setOpenSnackbar(false)}
        >
          <div><Alert severity="success">Operazione effettuata</Alert></div>
        </Snackbar>
        <Snackbar
          open={openSnackbarError}
          autoHideDuration="6000"
          onClose={() => {
            setOpenSnackbarError(false);
          }}
        >
          <div><Alert severity="error">Operazione fallita</Alert></div>
        </Snackbar>
        <Dialog
          open={dialogError.open}
          onClose={() =>
            setDialogError({ open: false, errors: [], title: "Errori" })
          }
        >
          <DialogTitle style={{ paddingBottom: 0 }}>
            {dialogError.title}
          </DialogTitle>
          <DialogContent>
            {dialogError.errors.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default ExportCityTax;
