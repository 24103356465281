import React from 'react';
import "./css/styles.css";
import Typography from '@mui/material/Typography';

function HomeDetailed() {
  return (
    <div className="foo">
      <div style={{ height: '64px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5 }} elevation={0}></div>
      <div style={{ backgroundColor: "#1aa3ff", height: '60px', display: 'flex', alignItems: 'center', marginLeft: 250 }} >
        <Typography variant="h5" style={{
          color: "white",
          fontSize: 20,
          marginLeft: 40
        }} gutterBottom>Configurazione Hotel</Typography>
      </div>

    </div >
  );
}

export default HomeDetailed;
