import React, { useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import config from '../../config'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import NativeSelect from '@mui/material/NativeSelect';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete'

import { useForm } from '../../utils/hooks'

function FormCompany() {

  const [raccordo, setRaccordo] = React.useState([]);
  const [partnerId, setPartnerId] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [val, setVal] = React.useState();
  const [reload, setReload] = React.useState();

  //form modifica company
  const [companyUpdate, setCompanyUpdate] = useForm({
    "_id": "",
    "companyName": "",
    "ragioneSociale": "",
    "pIva": "",
    "indirizzo": "",
    "citta": "",
    "stato": "",
    "cap": "",
    "paese": "",
    "hotels": []
  })

  //form inserisci company
  const [newCompanyFields, setNewCompanyFields] = useForm({
    "companyName": "",
    "ragioneSociale": "",
    "pIva": "",
    "indirizzo": "",
    "citta": "",
    "stato": "",
    "cap": "",
    "paese": "",
    "hotels": []
  })

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '14ch',
      },
    },
  }));

  const handleUpdCompany = (value) => {
    setCompanyUpdate(null, value);
    setOpenDialog(true);
  }
  //dialog
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleCloseDelete = () => {
    setOpenDialogDelete(false);
  };

  const handleDelete = () => {

    var dataD = JSON.stringify({
      "companyId": val._id
    });

    var configD = {
      method: 'delete',
      url: `${config.protelExpressUri}/api/delete/raccordoCompany`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataD
    };

    axios(configD)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setOpenDialogDelete(false);
        handleOpenSuccess();
        setReload(!reload)
      })
      .catch(function (error) {
        console.log(error);
      });

  };

  const handleOpenDelete = (value) => {
    setOpenDialogDelete(true);
    setVal(value)
  }
  //

  const handleSubmitUpd = () => {

    var dataP = JSON.stringify(companyUpdate);

    var config1 = {
      method: 'put',
      url: `${config.protelExpressUri}/api/raccordoUpdCompany`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataP
    };

    axios(config1)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setOpenDialog();
        handleOpenSuccess();
        setReload(!reload); 
      })
      .catch(function (error) {
        console.log(error);

      });
  }

  //Post data
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!newCompanyFields.companyName || !partnerId) {
      handleOpenError();
      return
    }

    //Submit raccordo
    var data = JSON.stringify(newCompanyFields);

    var config0 = {
      method: 'put',
      url: `${config.protelExpressUri}/api/raccordo/company/${partnerId}?_id= + ${partnerId}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config0)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        handleOpenSuccess()
        setReload(!reload)
      })
      .catch(function (error) {
        console.log(error);
        handleOpenError()
      });
  }

  const getRaccordo = async () => {
    try {
      const userRaccordo = await axios.get(`${config.protelExpressUri}/api/raccordo`)
      setRaccordo(userRaccordo.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getRaccordo()
  }, [reload])

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //snackbar success
  const handleOpenSuccess = () => {
    setOpen(true);
  };

  const handleOpenError = () => {
    setOpenError(true);
  };

  const classes = useStyles();

  return (
    <div style={{ height: '100vh', backgroundColor: '#F8F8F8' }}>
      <div style={{ height: '64px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
      <div style={{ backgroundColor: "#1aa3ff", height: '60px', display: 'flex', alignItems: 'center', marginLeft: 270 }} >
        <Typography variant="h5" style={{
          color: "white",
          fontSize: 20,
          marginLeft: 40
        }} gutterBottom>Gestione Company</Typography>
      </div>
      <div onSubmit={handleSubmit} style={{
        marginLeft: 290,
        display: 'flex'
      }}>

        <Dialog open={openDialogDelete} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Cancellare Company: {val ? val.companyName : ''}?</DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDelete} style={{ color: '#1aa3ff' }}>
              Annulla
            </Button>
            <Button onClick={handleDelete} style={{ color: '#1aa3ff' }}>
              Cancella
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Modifica Company: {companyUpdate.companyName}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="companyName"
              label="Nome Company"
              fullWidth
              value={companyUpdate.companyName}
              onChange={setCompanyUpdate}
              error={!companyUpdate.companyName}
            />
            <TextField
              autoFocus
              margin="dense"
              name="ragioneSociale"
              label="Ragione Sociale"
              fullWidth
              value={companyUpdate.ragioneSociale}
              onChange={setCompanyUpdate}
            />
            <TextField
              autoFocus
              margin="dense"
              name="pIva"
              label="Partita Iva"
              fullWidth
              value={companyUpdate.pIva}
              onChange={setCompanyUpdate}
            />
            <TextField
              autoFocus
              margin="dense"
              name="indirizzo"
              label="Indirizzo"
              fullWidth
              value={companyUpdate.indirizzo}
              onChange={setCompanyUpdate}
            />
            <TextField
              autoFocus
              margin="dense"
              name="citta"
              label="Città"
              type="email"
              fullWidth
              value={companyUpdate.citta}
              onChange={setCompanyUpdate}
            />
            <TextField
              autoFocus
              margin="dense"
              name="stato"
              label="Stato"
              fullWidth
              value={companyUpdate.stato}
              onChange={setCompanyUpdate}
            />
            <TextField
              autoFocus
              margin="dense"
              name="cap"
              label="Cap"
              fullWidth
              value={companyUpdate.cap}
              onChange={setCompanyUpdate}
            />
            <TextField
              autoFocus
              margin="dense"
              name="paese"
              label="Paese"
              fullWidth
              value={companyUpdate.paese}
              onChange={setCompanyUpdate}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} style={{ color: '#1aa3ff' }}>
              Annulla
            </Button>
            <Button onClick={handleSubmitUpd} style={{ color: '#1aa3ff' }}>
              Conferma
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSuccess}>
          <div><Alert severity="success">SUCCESS!</Alert></div>
        </Snackbar>

        <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
          <div><Alert severity="error">Errore</Alert></div>
        </Snackbar>
        <Card style={{ /*marginLeft: 290,*/ minWidth: 250, marginTop: 11, marginRight: 11, borderRadius: 5 }} elevation={0}>
          <CardContent>
            <Typography style={{ fontSize: '16px', color: '#1aa3ff' }} variant="h5" gutterBottom>Inserisci Company</Typography>
            <form className={classes.root} onSubmit={handleSubmit}>
              <TextField id="standard-basic" name="companyName" label="Nome" value={newCompanyFields.companyName} onChange={setNewCompanyFields} />
              <TextField id="standard-basic" name="ragioneSociale" label="Ragione Sociale" value={newCompanyFields.ragioneSociale} onChange={setNewCompanyFields} />
              <TextField id="standard-basic" name="pIva" label="Partita Iva" value={newCompanyFields.pIva} onChange={setNewCompanyFields} />
              <TextField id="standard-basic" name="indirizzo" label="Indirizzo" value={newCompanyFields.indirizzo} onChange={setNewCompanyFields} />
              <TextField id="standard-basic" name="citta" label="Citta" value={newCompanyFields.citta} onChange={setNewCompanyFields} />
              <TextField id="standard-basic" name="stato" label="Stato" value={newCompanyFields.stato} onChange={setNewCompanyFields} />
              <TextField id="standard-basic" name="cap" label="Cap" value={newCompanyFields.cap} onChange={setNewCompanyFields} />
              <TextField id="standard-basic" name="paese" label="Paese" value={newCompanyFields.paese} onChange={setNewCompanyFields} />
              <FormControl variant="outlined" className={classes.formControl} margin='dense'>
                <InputLabel htmlFor="outlined-age-native-simple">Partner</InputLabel>
                <NativeSelect
                  native
                  label="Partner"
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                  onChange={e => setPartnerId(e.target.value)}>
                  <option></option>
                  {raccordo.map((value) =>
                    <option key={value._id} value={value._id}>{value.partner.partnerName}</option>
                  )}
                </NativeSelect>
              </FormControl>
              <CardActions>
                <Button variant="contained" onClick={handleSubmit} style={{ backgroundColor: '#1aa3ff', color: "white" }}>Inserisci</Button>
              </CardActions>
            </form>
          </CardContent>
        </Card>
      </div><br></br><div style={{ marginLeft: 290, marginTop: 11 }}>
        <Card style={{ /*marginLeft: 290,*/ minWidth: 250, marginTop: 11, marginRight: 11, borderRadius: 5 }} elevation={0}>
          <CardContent>
            <Typography style={{ fontSize: '16px', color: '#1aa3ff' }} variant="h5" gutterBottom>Lista Companies</Typography>
            <TableContainer >
              <Table className={classes.table} size="small" aria-label="a dense table" >
                <TableHead>
                  <TableRow>
                    <TableCell variant="h5" width="10%">Nome</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Ragione Sociale</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Partita Iva</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Indirizzo</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Citta</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Stato</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Cap</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Paese</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Partner</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Operazioni</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {raccordo !== [] && raccordo !== [""] && raccordo &&
                    raccordo.map((row) => 
                        row.partner.companies.map((value) =>
                          <TableRow key={value._id}>
                            <TableCell /*component="th" scope="row"*/>{value.companyName}</TableCell>
                            <TableCell align="left">{value.ragioneSociale}</TableCell>
                            <TableCell align="left">{value.pIva}</TableCell>
                            <TableCell align="left">{value.indirizzo}</TableCell>
                            <TableCell align="left">{value.citta}</TableCell>
                            <TableCell align="left">{value.stato}</TableCell>
                            <TableCell align="left">{value.cap}</TableCell>
                            <TableCell align="left">{value.paese}</TableCell>
                            <TableCell align="left">{row.partner.partnerName}</TableCell>
                            <TableCell align="left"><EditIcon style={{ fontSize: '15px', fill: "black" }} button onClick={() => handleUpdCompany(value)} /><DeleteIcon style={{ fontSize: '15px', fill: "black" }} button onClick={() => handleOpenDelete(value)} /></TableCell>
                          </TableRow>)
                      )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>

      </div>
    </div>
  );
}

export default FormCompany;
