import React, { useState, useEffect } from "react";
import axios from "axios";
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from "react-router-dom";
import config from "../config";
import {
  Typography,
  Checkbox,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Snackbar,
  LinearProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

function FormPrinter() {
  // campi nuova stampante
  const [codice, setCodice] = React.useState("");
  const [nome, setNome] = React.useState("");
  const [ip, setIp] = React.useState("");
  const [seriale, setSeriale] = React.useState("");
  const [newUrl, setNewUrl] = React.useState("");
  const [fiscalCodes, setFiscalCodes] = React.useState("");
  const [utente, setUtente] = React.useState("");
  const [password, setPassword] = React.useState("");
  // campi RT
  const [versione, setVersione] = React.useState("");
  const [startFrom, setStartFrom] = React.useState("");
  const [protelEOD, setProtelEOD] = React.useState(true);
  const [sharedCounters, setSharedCounters] = React.useState(true);
  const [goods, setGoods] = React.useState("");

  const [hotelData, setHotelData] = React.useState();
  const [selectedPrinter, setSelectedPrinter] = useState();
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(0);
  const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
    container: {
      marginLeft: 290,
      marginRight: 20,
      display: "flex",
      flexWrap: "wrap",
      gap: "1rem",
      "& > .MuiCard-root": {
        flexGrow: 1,
      },
    },
  }));

  const { id } = useParams();

  const handleUpdatePrinter = () => {
    if (!fiscalCodesValidation(selectedPrinter?.fiscalCodes.join(";") ?? "")) {
      return;
    }
    setLoading(true);
    axios
      .put(`${config.protelExpressUri}/api/hotels/updatePrinter`, {
        hotelID: id,
        printer: { ...selectedPrinter },
      })
      .then(() => {
        handleOpenSuccess();
        setLoading(false);
        setRefresh((refresh) => refresh + 1);
      })
      .catch((err) => {
        console.log(err);
        handleOpenError();
        setLoading(false);
      });
  };

  const handleNewPrinter = () => {
    if (!fiscalCodesValidation(fiscalCodes)) {
      return;
    }
    setLoading(true);
    const printer = {
      code: codice,
      name: nome,
      ip,
      serial: seriale,
      url: newUrl.split(";"),
      fiscalCodes: fiscalCodes ? fiscalCodes.split(";") : [],
      auth: {
        user: utente,
        password,
      },
    };

    axios
      .put(`${config.protelExpressUri}/api/hotels/newPrinter`, {
        hotelID: id,
        printer,
      })
      .then(() => {
        handleOpenSuccess();
        setLoading(false);
        setRefresh((refresh) => refresh + 1);
      })
      .catch((err) => {
        console.log(err);
        handleOpenError();
        setLoading(false);
      });
  };

  const handleDeletePrinter = () => {
    setLoading(true);
    axios
      .post(`${config.protelExpressUri}/api/hotels/deletePrinter`, {
        hotelID: id,
        code: selectedPrinter.code,
      })
      .then(() => {
        handleOpenSuccess();
        setLoading(false);
        setRefresh((refresh) => refresh + 1);
      })
      .catch((err) => {
        console.log(err);
        handleOpenError();
        setLoading(false);
      });
  };

  const handleUpdateRT = () => {
    setLoading(true);
    const RT = {
      version: {
        number: versione,
        startFrom,
      },
      protelEOD,
      sharedCounters,
      goods: goods.split(";"),
    };

    axios
      .put(`${config.protelExpressUri}/api/hotels/updateRT`, {
        hotelID: id,
        RT,
      })
      .then(() => {
        handleOpenSuccess();
        setLoading(false);
        setRefresh((refresh) => refresh + 1);
      })
      .catch((err) => {
        console.log(err);
        handleOpenError();
        setLoading(false);
      });
  };

  useEffect(() => {
    if (refresh > 0) {
      setSelectedPrinter("");
    }

    var dataGet = JSON.stringify({ hotelID: `${id}` });

    var config0 = {
      method: "post",
      url: `${config.protelExpressUri}/api/findHotels`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataGet,
    };
    async function getData() {
      const result = await axios(config0);
      const { data } = result;

      setHotelData(data);
      setVersione(data?.RT?.version?.number);
      setStartFrom(data?.RT?.version?.startFrom);
      setGoods(data?.RT?.goods.join(";"));
      setProtelEOD(data?.RT?.protelEOD);
      setSharedCounters(data?.RT?.sharedCounters);
    }
    getData();
  }, [id, refresh]);

  const classes = useStyles();

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //snackbar success
  const handleOpenSuccess = () => {
    setOpen(true);
  };

  const handleOpenError = () => {
    setOpenError(true);
  };

  const fiscalCodesValidation = (fc) => {
    if (!fc) {
      return true;
    }

    const codes = fc.split(";");

    if (codes.length !== 3) {
      return false;
    }

    const orderedCodes = codes.sort().join(";");
    const acceptedValues = ["DC1;DCA1;DCR1", "DC2;DCA2;DCR2", "DC3;DCA3;DCR3"];

    if (acceptedValues.includes(orderedCodes)) {
      return true;
    }

    return false;
  };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 5,
        }}
        elevation={0}
      ></div>
      <div
        style={{
          backgroundColor: "#1aa3ff",
          height: "60px",
          display: "flex",
          alignItems: "center",
          marginLeft: 250,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "white",
            fontSize: 20,
            marginLeft: 40,
          }}
          gutterBottom
        >
          Gestione Stampanti
        </Typography>
      </div>
      <LinearProgress style={{ visibility: loading ? "visible" : "hidden" }} />
      <div className={classes.container}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        >
          <div>
            <Alert severity="success">SUCCESS!</Alert>
          </div>
        </Snackbar>
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleCloseError}
        >
          <div>
            <Alert severity="error">Errore</Alert>
          </div>
        </Snackbar>
        <Card
          style={{
            marginTop: 11,
          }}
          elevation={0}
        >
          <CardContent>
            <Typography
              style={{ fontSize: "16px", color: "#1aa3ff" }}
              variant="h5"
              gutterBottom
            >
              Nuova Stampante
            </Typography>
            <form className={classes.root}>
              <TextField
                label="Codice"
                onChange={(e) => setCodice(e.target.value)}
              />
              <TextField
                label="Nome"
                onChange={(e) => setNome(e.target.value)}
              />
              <TextField label="IP" onChange={(e) => setIp(e.target.value)} />
              <TextField
                label="Seriale"
                onChange={(e) => setSeriale(e.target.value)}
              />
              <TextField
                label="URL (separati da ; )"
                onChange={(e) => setNewUrl(e.target.value)}
              />
              <TextField
                label="Codici Fiscali (separati da ; )"
                onChange={(e) => setFiscalCodes(e.target.value)}
                error={!fiscalCodesValidation(fiscalCodes)}
              />
              <TextField
                label="Utente"
                onChange={(e) => setUtente(e.target.value)}
              />
              <TextField
                label="Password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <CardActions>
                <Button
                  variant="contained"
                  onClick={handleNewPrinter}
                  style={{ backgroundColor: "#1aa3ff", color: "white" }}
                  disabled={loading}
                >
                  Inserisci
                </Button>
              </CardActions>
            </form>
          </CardContent>
        </Card>
        <Card style={{ marginTop: 11 }} elevation={0}>
          <CardContent>
            <Typography
              style={{ fontSize: "16px", color: "#1aa3ff" }}
              variant="h5"
              gutterBottom
            >
              Modifica Stampante
            </Typography>
            <br></br>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ minWidth: 190 }}
              margin="dense"
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Lista Stampanti
              </InputLabel>
              <Select
                label="Lista Stampanti"
                onChange={(e) => setSelectedPrinter(e.target.value)}
              >
                {hotelData?.printers.map((value, i) => (
                  <MenuItem key={i} value={value}>
                    {value.name}-{value.serial}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedPrinter && (
              <form className={classes.root}>
                <TextField
                  label="Codice"
                  value={selectedPrinter?.code ?? ""}
                  onChange={(e) =>
                    setSelectedPrinter({
                      ...selectedPrinter,
                      code: e.target.value,
                    })
                  }
                  disabled
                />
                <TextField
                  label="Nome"
                  value={selectedPrinter?.name ?? ""}
                  onChange={(e) =>
                    setSelectedPrinter({
                      ...selectedPrinter,
                      name: e.target.value,
                    })
                  }
                />
                <TextField
                  label="IP"
                  value={selectedPrinter?.ip ?? ""}
                  onChange={(e) =>
                    setSelectedPrinter({
                      ...selectedPrinter,
                      ip: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Seriale"
                  value={selectedPrinter?.serial ?? ""}
                  onChange={(e) =>
                    setSelectedPrinter({
                      ...selectedPrinter,
                      serial: e.target.value,
                    })
                  }
                />
                <TextField
                  label="URL (separati da ; )"
                  value={selectedPrinter?.url.join(";") ?? ""}
                  onChange={(e) => {
                    setSelectedPrinter({
                      ...selectedPrinter,
                      url: e.target.value ? e.target.value.split(";") : [],
                    });
                  }}
                />
                <TextField
                  label="Codici Fiscali (separati da ; )"
                  value={selectedPrinter?.fiscalCodes.join(";") ?? ""}
                  onChange={(e) => {
                    setSelectedPrinter({
                      ...selectedPrinter,
                      fiscalCodes: e.target.value
                        ? e.target.value.split(";")
                        : [],
                    });
                  }}
                  error={
                    !fiscalCodesValidation(
                      selectedPrinter?.fiscalCodes.join(";") ?? ""
                    )
                  }
                />
                <TextField
                  label="Utente"
                  value={selectedPrinter?.auth?.user ?? ""}
                  onChange={(e) =>
                    setSelectedPrinter({
                      ...selectedPrinter,
                      auth: {
                        ...selectedPrinter.auth,
                        user: e.target.value,
                      },
                    })
                  }
                />
                <TextField
                  label="Password"
                  type="password"
                  value={selectedPrinter?.auth?.password ?? ""}
                  onChange={(e) =>
                    setSelectedPrinter({
                      ...selectedPrinter,
                      auth: {
                        ...selectedPrinter.auth,
                        password: e.target.value,
                      },
                    })
                  }
                />
                <CardActions>
                  <Button
                    variant="contained"
                    onClick={handleUpdatePrinter}
                    style={{ backgroundColor: "#1aa3ff", color: "white" }}
                    disabled={loading}
                  >
                    Modifica
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleDeletePrinter}
                    style={{ backgroundColor: "#1aa3ff", color: "white" }}
                    disabled={loading}
                  >
                    Elimina
                  </Button>
                </CardActions>
              </form>
            )}
          </CardContent>
        </Card>
        <Card style={{ marginTop: 11 }} elevation={0}>
          <CardContent>
            <Typography
              style={{ fontSize: "16px", color: "#1aa3ff" }}
              variant="h5"
              gutterBottom
            >
              RT
            </Typography>
            <form className={classes.root}>
              <TextField
                label="Versione"
                value={versione ?? ""}
                onChange={(e) => setVersione(e.target.value)}
              />
              <TextField
                label="Data inizio versione"
                InputLabelProps={{ shrink: true }}
                value={startFrom ?? ""}
                type="date"
                format={"yyyy/MM/dd"}
                onChange={(e) => setStartFrom(e.target.value)}
              />

              <TextField
                label="Cot Beni (separati da ; )"
                value={goods ?? ""}
                onChange={(e) => setGoods(e.target.value)}
              />

              <div>
                <Checkbox
                  checked={protelEOD}
                  onChange={(e) => {
                    setProtelEOD(e.target.checked);
                  }}
                />
                Protel EOD
              </div>
              <div>
                <Checkbox
                  checked={sharedCounters}
                  onChange={(e) => {
                    setSharedCounters(e.target.checked);
                  }}
                />
                Contatori Condivisi
              </div>
              <CardActions>
                <Button
                  variant="contained"
                  onClick={handleUpdateRT}
                  style={{ backgroundColor: "#1aa3ff", color: "white" }}
                  disabled={loading}
                >
                  Inserisci
                </Button>
              </CardActions>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default FormPrinter;
