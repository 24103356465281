import React, { useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import config from '../config'
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'

function FormSetup() {
  const [codiceProperty, setCodiceProperty] = React.useState();
  const [propertyData, setPropertyData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [toDeleteProperty, setToDeleteProperty] = React.useState(false)
  const [toUpdProperty, setToUpdProperty] = React.useState(false)
  const [newProp, setNewProp] = React.useState();

  const useStyles = makeStyles((theme) => ({
    form: {
      root: {
        '& > *': {
          margin: theme.spacing(3),
          width: '18ch',
        },
      }
    },
    card: {
      root: {
        minWidth: 500,
      },
      bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
      title: {
        fontSize: 14,
      },
      pos: {
        marginBottom: 12,
      }
    },
    table: {
      minWidth: 100,
    },
  }));

  const handleUpdateProperty = () => {
    var data1 = JSON.stringify({
      "hotelID": id,
      "propertyCode": toUpdProperty,
      "newProperty": newProp
    });

    var configDE = {
      method: 'put',
      url: `${config.protelExpressUri}/api/updateProperty`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data1
    };

    axios(configDE)
      .then(function (response) {
        window.location.reload();
        setOpenDialog(false)
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleDeleteProperty = () => {
    var data = JSON.stringify({
      "hotelID": id,
      "propertyCode": toDeleteProperty
    });

    var configD = {
      method: 'delete',
      url: `${config.protelExpressUri}/api/deleteProperty`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(configD)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        window.location.reload();
        setOpenDialogDelete(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleDelete = (value) => {
    setToDeleteProperty(value)
    setOpenDialogDelete(true);
  }

  const handleSubmitProperty = (event) => {
    event.preventDefault();

    var data = JSON.stringify({
      "hotelID": id,
      "propertyConfiguration": [
        {
          "propertyCode": codiceProperty,
          "roomTypes": [],
          "roomsStatus": []
        }
      ]
    });

    var configP = {
      method: 'put',
      url: `${config.protelExpressUri}/api/insertProperty`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(configP)
      .then(function (response) {
        handleOpenSuccess()
        window.location.reload();
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
        handleOpenError()
      });
  }

  const { id } = useParams();

  useEffect(() => {


    var dataP = JSON.stringify({ "hotelID": id });

    var configPr = {
      method: 'post',
      url: `${config.protelExpressUri}/api//findsProperty`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataP
    };
    async function getDataP() {
      const result = await axios(configPr)
      setPropertyData(propertyData => [...propertyData, result.data]);
    }
    getDataP()
  }, [id])

  const handleClose = () => {
    setOpenDialog(false);
  }

  const handleCloseDelete = () => {
    setOpenDialogDelete(false);
  }

  const handleClickOpenDialog = (input) => {
    setOpenDialog(true);
    setToUpdProperty(input)
  };

  const handleCloseSuccess = (òevent, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //snackbar success
  const handleOpenSuccess = () => {
    setOpen(true);
  };

  const handleOpenError = () => {
    setOpenError(true);
  };

  const classes = useStyles();

  return (
    <div style={{ height: '100vh', backgroundColor: '#F8F8F8' }}>
      <div style={{ height: '64px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
      <div style={{ backgroundColor: "#1aa3ff", height: '60px', display: 'flex', alignItems: 'center', marginLeft: 250 }} >
        <Typography variant="h5" style={{
          color: "white",
          fontSize: 20,
          marginLeft: 40
        }} gutterBottom>Gestione Property</Typography>
      </div>
      <div style={{ display: 'flex' }}>

        <Dialog open={openDialogDelete} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Cancellare Property: {toDeleteProperty}?</DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDelete} style={{ color: '#1aa3ff' }}>
              Annulla
            </Button>
            <Button onClick={handleDeleteProperty} style={{ color: '#1aa3ff' }}>
              Cancella
            </Button>
          </DialogActions>
        </Dialog>


        <Dialog open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Modifica property: {toUpdProperty}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nome"
              type="Nome"
              fullWidth
              defaultValue={toUpdProperty}
              onChange={e => setNewProp(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} style={{ color: '#1aa3ff' }}>
              Annulla
            </Button>
            <Button onClick={handleUpdateProperty} style={{ color: '#1aa3ff' }}>
              Conferma
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSuccess}>
          <div><Alert severity="success">SUCCESS!</Alert></div>
        </Snackbar>
        <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
          <div><Alert severity="error">Errore</Alert></div>
        </Snackbar>
        <Card className={classes.card.root} style={{ marginLeft: 290, minWidth: 250, marginTop: 11, marginRight: 11, borderRadius: 5 }} elevation={0} >
          <CardContent>
            <form className={classes.form.root}>
              <Typography style={{ fontSize: '16px', color: '#1aa3ff' }} variant="h5" gutterBottom>Inserisci Property</Typography>
              <TextField id="standard-basic" label="Nome Property" value={codiceProperty}
                onChange={e => setCodiceProperty(e.target.value)} /><br></br>
            </form>
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={handleSubmitProperty} style={{ backgroundColor: '#1aa3ff', color: "white" }}>Inserisci</Button>
          </CardActions>
        </Card>
        <div style={{ minWidth: 50 }}></div>
      </div>

      <div style={{ marginLeft: 290, marginTop: 11, marginRight: 50 }}>
        <Card style={{ borderRadius: 5 }}>
          <CardContent>
            <Typography style={{ fontSize: '16px', color: '#1aa3ff' }} variant="h5" gutterBottom>Lista Property</Typography>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell variant="h5" >Codice Property</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Operazioni</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    propertyData[0] &&
                    propertyData[0].propertyConfiguration.map((value) => <TableRow key={{}}><TableCell component="th" scope="row">{value.propertyCode}</TableCell>
                      <TableCell align="left"><EditIcon style={{ fontSize: '15px', fill: "black" }} button onClick={() => handleClickOpenDialog(value.propertyCode)} /><DeleteIcon style={{ fontSize: '15px', fill: "black" }} button onClick={() => handleDelete(value.propertyCode)} /></TableCell>
                    </TableRow>
                    )
                  }
                </TableBody >
              </Table >
            </TableContainer >
          </CardContent >
        </Card >
      </div >
    </div >
  );
}

export default FormSetup;
