import React, { useEffect, useState } from "react";

import axios from "axios";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import config from "../../config";
import NativeSelect from "@mui/material/NativeSelect";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useParams } from "react-router-dom";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import FileDownload from "js-file-download";
import GetAppIcon from "@mui/icons-material/GetApp";
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@mui/material/Dialog";
import TitleBar from "../TitleBar";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {Checkbox, DialogContent, InputLabel, toggleButtonClasses} from "@mui/material";
import { IconButton, Icon } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import Input from "@mui/material";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import SendIcon from '@mui/icons-material/Send';
import ConstructionIcon from '@mui/icons-material/Construction';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MinimizeIcon from '@mui/icons-material/Minimize';
import SaveIcon from '@mui/icons-material/Save';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function FormIne() {
  const [daData, setDaData] = React.useState("");
  const [aData, setAData] = React.useState("");
  const [selectedDay, setSelectedDay] = React.useState('');
  const [apiResponse, setApiResponse] = useState(null);
  const [datiIne, setDatiIne] = React.useState([]);
  
  const [propertyData, setPropertyData] = React.useState([]);
  const [codiceProperty, setCodiceProperty] = React.useState("");

  const [showLoad, setShowLoad] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState(
    "introduzca los parámetros en la sección anterior"
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalLink, setModalLink] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openEditar, setOpenEditar] = React.useState(false);
  const [datoincorrectos, setDatoIncorrectos] = React.useState(false);
  const [datoexcluidos, setDatoExcluidos] = React.useState(false);
  const [nDia, setNdia] = React.useState("");
  const [idpais, setIdpais] = React.useState("");
  const [idisla, setIdisla] = React.useState("");
  const [selectedFile, setSelectedFile] = useState('');
  const [openD, setOpenD] = React.useState(false);
  const [openAvviso, setOpenAvviso] = React.useState(false);
  const [openAvvisoS, setOpenAvvisoS] = React.useState(false);
  const [selectedScreen, setSelectedScreen] = React.useState(' ');
  const [openModifiche, setOpenModifiche] = React.useState(false);
  const [isTableMinimized, setTableMinimized] = useState(false);
  const [datiRisposta, setDatiRisposta] = useState(null);
  const [modifiedData, setModifiedData] = useState(null);
  const [renderizzaTabella, impostaRenderizzaTabella] = useState(false);
  const [codigoControl, setCodigoControl] = React.useState(" ");
  const [numeroOrden, setNumeroOrden] = React.useState(" ");
  const [correoElettronico, setCorreoElettronico] = React.useState(" ")
  const [oldDownloadLink, setOldDownloadLink] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  
  
  const { id } = useParams();


  //data odierna
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  var dataOdierna = yyyy + mm + dd;

  // Assicurati che i dati siano stati precedentemente convertiti in oggetti JavaScript
  let errori = [];
  let dati = [];

  if(datiRisposta?.data){
    errori = JSON.parse(JSON.stringify(datiRisposta?.data[1]));
    dati = JSON.parse(JSON.stringify(datiRisposta?.data[0]));
  }

  let listaAlojiamento = [];
  if ( modifiedData ) {
    listaAlojiamento =
        modifiedData?.alojamiento?.residencia.map((residenza) => (
            residenza.movimiento.map((movimento) => {
              return { ...movimento, idPais: residenza.idPais, idProvinciaIsla: residenza.ID_PROVINCIA_ISLA }
            })
        )).flat().sort((a, b) => a.nDia - b.nDia)
  }
  else {
    listaAlojiamento =
        dati?.alojamiento?.residencia.map((residenza) => (
            residenza.movimiento.map((movimento) => {
              return { ...movimento, idPais: residenza.idPais, idProvinciaIsla: residenza.ID_PROVINCIA_ISLA }
            })
        )).flat().sort((a, b) => a.nDia - b.nDia)
  }

  const filtraAlloggio = () => {
    let filteredAlojamiento = listaAlojiamento.filter((movimento) => {
      return (
        (nDia === '' || movimento.nDia.includes(nDia)) &&
        (idpais === '' || movimento.idPais.includes(idpais)) &&
        (idisla === '' || movimento.idProvinciaIsla.includes(idisla))
      )});

      return filteredAlojamiento;
  }

  // console.log(datiRisposta)
  // console.log(`modifica Data ${JSON.stringify(modifiedData)}`);
  

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  
  const TextInput = (props) => {
    const { small, ...textFieldProps } = props;
    return (
      <TextField
        style={{ margin: "1rem", ...(small && { width: "50px" }) }}
        {...textFieldProps}
      />
    );
  };

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    form: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "baseline",
      gap: "1rem",
      margin: "1rem",
    },

    button: {
      backgroundColor: 'rgb(3, 155, 229)',
      color: 'white',
      '&:disabled': {
        backgroundColor: 'gray',
      },
    },

  }));

  const fetchData = async () => {  //get da dove vogliamo i dati 
    try {
      const response = await axios.get('https://6c528956-7e3c-4c22-a18d-95e91b8f572f.mock.pstmn.io'); //.get("http://172.22.163.26Z:8080/setupExample.json") //https://6c528956-7e3c-4c22-a18d-95e91b8f572f.mock.pstmn.io

      if (response.status === 200) {
        setApiResponse(response.data);   //questo 
        // console.log(response.data)
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleLeggiData = async () => {  //in teoria dovrebbe salvarmi tutti i dati della richiesta genera effettuta, poi ovviamente dovrai cambiarti il nome delle variabili con i dati che vuoi 
    setIsLoading(true);
 
    try {
      const response = await axios.get(`${config.requestsUri}/SYSDATA?hotelID=${id}`);
      const data = response.data.data.IO_SystemDataRS.SystemData;      //cambiare con i nomi che vogliamo ottenere
      let rawData;
      const newData = rawData.map((item) => ({   //mappa su ogni array e setta i valori
        code: item.$.Code,
        name: item.Name._,
        type: "",
        active: true
      }))
      setDatiIne(newData);
      // console.log ("siamo qua");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching distribution data: '. error);
      }
    }

  const handleDayChange = (e) => {
    // Aggiungi qui la logica per gestire il cambio di giorno
    setSelectedDay(e.target.value);
  };

  //modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalTitle("Errori nella generazione");
    setModalLink("");
  };

  useEffect(() => {
    // Esegui eventuali logiche qui, se necessario

    // Imposta renderizzaTabella su true per avviare la renderizzazione della tabella
    impostaRenderizzaTabella(true);
  }, []); // L'array di dipendenze vuoto assicura che l'effetto venga eseguito solo una volta


  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLoad(true);

    var configIne = {
      method: "get",
      url: `${config.requestsUri}/ine/?hotelID=${id}&from=${daData}&to=${aData}&propertyCode=${codiceProperty}&days=${selectedDay}`,
      headers: {},
    };

    axios(configIne)
      .then(CircularIndeterminate())
      .then(function (response) {
        const datiRisposta = response.data;
        handleOpenSuccess();
        setCurrentStep(2);
        setShowLoad(false);
        // Aggiungi la tua dichiarazione condizionale qui
        if (codiceProperty === "Hotel") {
          // Operazioni da eseguire se propertyCode è "hotel"
          // console.log("propertyCode è hotel");
          setModalMessage(datiRisposta);
          setDatiRisposta(datiRisposta);
          setSelectedScreen('Alojamiento');
          // Aggiungi qui le operazioni specifiche per "hotel"
        } else if (codiceProperty === "Apartamentos") {
          // Operazioni da eseguire se propertyCode è "apartamientos"
          // console.log("propertyCode è apartamientos");
          setModalMessage(datiRisposta);
          setDatiRisposta(datiRisposta);
          setSelectedScreen('Alojamiento');
          // Aggiungi qui le operazioni specifiche per "apartamientos"
        }
      })
      .catch(function (error) {
        setShowLoad(false);
        handleOpenModal();
        error.response && error.response.data !== ""
          ? setModalMessage(error.response.data)
          : setModalMessage(error.message);
      });
  };

  // Analizza ogni stringa nell'array e trasformala in un oggetto JavaScript

  const handleDownload = () => {
    // Ottieni il nome del file desiderato dall'utente
    const customFileName = window.prompt('Ingrese el nombre del archivo deseado (sin extensión):');
    
    if (customFileName) {
        const jsonString = JSON.stringify(dati, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json;charset=utf-8' });
        const blobURL = URL.createObjectURL(blob);
      
        // Se l'utente ha inserito un nome del file personalizzato, utilizzalo; altrimenti, utilizza la data corrente
        const fileName = customFileName.trim() !== '' ? `${customFileName}.json` : generateDefaultFileName();
      
        // Salva l'URL del Blob e il nome del file in localStorage
        localStorage.setItem('downloadLink', JSON.stringify({ url: blobURL, fileName }));
      
        const downloadLink = document.createElement('a');
        downloadLink.href = blobURL;
        downloadLink.download = fileName;
      
        document.body.appendChild(downloadLink);
        downloadLink.click();
        
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(blobURL);
    }
};

// Funzione di supporto per generare un nome di file predefinito basato sulla data corrente
const generateDefaultFileName = () => {
    const today = new Date();
    return `Ine_${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}.json`;
};
  
  // Esegui questa parte del codice al caricamento della pagina per recuperare e visualizzare il link del vecchio download
  useEffect(() => {
    const storedDownloadLink = localStorage.getItem('downloadLink');
  
    if (storedDownloadLink) {
      setTimeout(() => {
      const { url, fileName } = JSON.parse(storedDownloadLink);
  
      // Visualizza il link del vecchio download
      setOldDownloadLink({ url, fileName });
    }, 0);
    }
  }, []);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //Spinner
  const showLoading = () => {
    setShowLoad(!showLoad);
  };

  //snackbar success
  const handleOpenSuccess = () => {
    setOpen(true);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  //end success

    function CircularIndeterminate() {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleSendIne = (sendFile) => {
    setOpenDialog(false);
    setOpenEditar(false);

    const jsonSend = {"dati":JSON.stringify(dati)};

    let optionAxios = {
      method: "post",
      url: `${config.requestsUri}/qsvc?service=SENDINE&codigoControl=${codigoControl.trim()}&numeroOrder=${numeroOrden.trim()}&correoElectronico=${correoElettronico.trim()}&sendFile=${sendFile}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: jsonSend
    }

    axios(optionAxios)
        .then(showLoading())
        .then(function (response) {
          // const datiRisposta = response.data;
          setShowLoad(false);
          setCurrentStep(3);
          handleOpenSuccess();
        })
        .catch(function (error) {
          setShowLoad(false);
          handleOpenModal();
          error.response && error.response.data !== ""
              ? setModalMessage(error.response.data)
              : setModalMessage(error.message);
          // console.log("Errore");
        });
  };

  const handleClickOpenEditar = () => {
    setOpenEditar(true);
  };

  const handleDelete = () => {
    setOpenDialog(false);
    setOpenEditar(false);
  }

  const handleChange = (event) => {
    setDatoIncorrectos(event.target.value);
    
  };

  const handleChangeDatos = (event) => {
    setDatoExcluidos(event.target.value);
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Mostra il nome del file nel TextInput
    setSelectedFile(file);
  };

  const handleOpenDialog = () => {
    setOpenD(true);
  }

  const handleOpenAvviso = () => {
    setOpenAvviso(true);
  }
  const handleCloseAvviso = () => {
    setOpenAvviso(false);
  }

  const handleOpenAvvisoS= () => {
    setOpenAvvisoS(true);
  }

  const handleCloseAvvisoS = () => {
    setOpenAvvisoS(false);
  }

  const handleOpenModifiche = () => {
    setOpenModifiche(true);
    setModifiedData({...dati})
  }

  const handleCloseModifiche = () => {
    setOpenModifiche(false);
    setOpenEditar(false);
    setOpenAvviso(false);
  }

  const handleCloseDialog = () => {
    setOpenD(false);
  }

  const handleScreenChange = (screen) => {
    setSelectedScreen(screen);
    // Puoi aggiungere ulteriori azioni in base alla schermata selezionata
  };

  const handleMinimizeToggle = () => {
    setTableMinimized(!isTableMinimized);
    
  };

  const tableStyle = {
    padding: isTableMinimized ? '0' : '20px',
    transition: 'padding 0.4s',  // Aggiungi una transizione per un effetto più fluido
    display: isTableMinimized ? 'none' : 'block',  // Nascondi la tabella quando è minimizzata
  };

  const handleAlojamientoContentChange = (nDia, idPais, idProvinciaIsla, field, value) => {
    const newData = { ...modifiedData };
    let pos = 0;
    const day = nDia - 1;
    if(idProvinciaIsla) pos = newData.alojamiento.residencia.findIndex(x => x.ID_PROVINCIA_ISLA === idProvinciaIsla);
    else pos = newData.alojamiento.residencia.findIndex(x => x.idPais === idPais);
    newData.alojamiento.residencia[pos].movimiento[day][field] = Number(value);
    setModifiedData(newData);
  };

  const handlePreciosChange = (field, value) => {
    const newData = {...modifiedData};
    newData.precios[field] = Number(value);
    setModifiedData(newData);
  };


  const handleAppartamentoEstudiosChange = (field, value) => {

    const newData = {...modifiedData}; 
    newData.precios.estudios[field] = Number(value);
    setModifiedData(newData);

  };

  const handleAppartamento2e4Change = (field, value) => {

    const newData = {...modifiedData};
    newData.precios.apartamentos2or4Pax[field] = Number(value);
    setModifiedData(newData);

  };

  const handleAppartamento4e6Change = (field, value) => {

    const newData = {...modifiedData};
    newData.precios.apartamentos4or6Pax[field] = Number(value);
    setModifiedData(newData);

  };


  const handleAppartamentoOtrosChange = (field, value) => {

    const newData = {...modifiedData};
    newData.precios.otros[field] = Number(value);
    setModifiedData(newData);

  };



  const handleHabitacionesChange = (index, field, value) => {
    const newData = { ...modifiedData };
    newData.habitaciones.habitacionesMovimiento[index][field] = Number(value);
    setModifiedData(newData);
  };

  const handleAppartamentoChange = (index, field, value) => {
    const newData = { ...modifiedData };
    newData.ocupacion.movimiento[index][field] = Number(value);
    setModifiedData(newData);
  };

  const handleSave = () => {
    // console.log(`modifica Data ${JSON.stringify(modifiedData)}`);
    if (modifiedData) {
      // Esegui la logica di salvataggio qui, ad esempio chiamando un'API o facendo una richiesta al server
      // Passa il nuovo stato modificato a una funzione di salvataggio
      saveModifiedData(modifiedData);
      // Dopo il salvataggio, reimposta lo stato modificato a null
      // setModifiedData(null);
      setOpenModifiche(false);
      setOpenEditar(false);
      setOpenAvvisoS(!openAvvisoS)
      setOpenAvviso(false);
    }
  };

  const saveModifiedData = (newData) => {
    // Esegui la logica di salvataggio qui
    // Ad esempio, puoi chiamare un'API per salvare i dati modificati sul server
    // Poi, aggiorna lo stato datiRisposta con i nuovi dati appena salvati SALMAU
    const dataSave = {};
    dataSave.data = []
    dataSave.data.push(newData);
    dataSave.data.push(errori);
    setDatiRisposta({...dataSave});
  };

  const headerCellStyle = {
    width: '5px',
    height: '1px',
    fontSize: '15px',
    backgroundColor: '#f2f2f2',
    border: '1px solid #dddddd',
    textAlign: 'center',
    padding: '5px',
  };
  
  const dataCellStyle = {
    fontSize: '15px',
    width: '3px',
    border: '1px solid #dddddd',
    textAlign: 'center',
    padding: '5px',
  };

  const handleUpload = () => {
    // Puoi utilizzare axios o qualsiasi altra libreria HTTP per inviare il file al server
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      axios.post(`${config.protelExpressUri}/api/FileIne/${id}`, formData)
        .then(response => {
          // Gestisci la risposta del server se necessario
          // console.log(response.status);

        })
        .catch(error => {
          // Gestisci gli errori
          console.error(error);
        });
    } else {
      console.error('Nessun file selezionato.');
    }
  };

  const downloadError = () => {
    let erroreModal = modalMessage.toString();
    var erroreFormattato = erroreModal.split(" ,").join("\n");
    FileDownload(erroreFormattato, `IneError_${dataOdierna}.txt`);  //qua va cambiato in ine percche credo sia come crea il download del file 
  };

  const handleStepChange = (newStep) => {
    setCurrentStep(newStep);
  };

  useEffect(() => {
    var dataP = JSON.stringify({ hotelID: id });

    var configPr = {
      method: "post",
      url: `${config.protelExpressUri}/api/findsProperty`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataP,
    };

    async function getDataP() {
      const result = await axios(configPr);
      setPropertyData((propertyData) => [...propertyData, result.data]);
    }
    getDataP();
  }, [id]);

  const classes = useStyles();

  return (
    <>
      <div style={{ height: "28vh", backgroundColor: "#F8F8F8" }}>
        <div
          style={{
            height: "64px",
          }}
        ></div>
      <TitleBar
        title={
          <span style={{ fontWeight: 'bold', textAlign: 'center', margin: -10, padding: -10,  }}>
            Encuesta Ocupación en Alojamientos Turìsticos
          </span>
        }
        loading={showLoad}
      />
        <div style={{ marginLeft: 290, display: "flex" }}>
          <Dialog
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModal}
            onClose={handleCloseModal}
            closeAfterTransition
          >
            <Fade in={openModal}>
              <div className={classes.paper}>
                <div style={{ display: "flex" }}>
                  <GetAppIcon
                    button
                    onClick={downloadError}
                    style={{}}
                  ></GetAppIcon>
                  <CloseIcon
                    button
                    onClick={handleCloseModal}
                    style={{
                      flex: "0 0 50%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  ></CloseIcon>
                </div>
                <h4 id="transition-modal-title">{modalTitle}</h4>
                {Array.isArray(modalMessage) ? (
                  modalMessage.map((value, index) => (
                    <Typography key={index} id="transition-modal-description">
                      {value}
                    </Typography>
                  ))
                ) : (
                  <Typography>{modalMessage}</Typography>
                )}
                {modalLink ? (
                  <a href={modalLink} target="_blank" rel="noreferrer">
                    {modalLink}
                  </a>
                ) : null}
              </div>
            </Fade>
          </Dialog>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseSuccess}
          >
            <div>
              <Alert severity="success">SUCCESS!</Alert>
            </div>
          </Snackbar>
          <Card
            style={{ minWidth: 250, marginTop: 11, borderRadius: 5 }}
            elevation={0}
          >
            <CardContent>
              <form className={classes.form}>
                <TextField
                  helperText={
                    <Typography variant="title" display="block">
                      Partir de la fecha
                    </Typography>
                  }
                  value={daData}
                  type="date"
                  format={"yyyy/MM/dd"}
                  onChange={(e) => setDaData(e.target.value)} //quello che scrivo nel campo
                />
                <TextField
                  helperText={
                    <Typography variant="caption" display="block">
                      Hasta la fecha
                    </Typography>
                  }
                  value={aData}
                  type="date"
                  format={"yyyy/MM/dd"}
                  onChange={(e) => setAData(e.target.value)}
                />
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: 190 }}
                  margin="dense"
                >
                  <NativeSelect
                    native
                    label="Codice Property"
                    onChange={(e) => setCodiceProperty(e.target.value)}
                  >
                    <option></option>
                    {propertyData.map((value) => {
                      if (value.propertyConfiguration)
                        return value.propertyConfiguration.map((value) =>
                          value.propertyCode ? (
                            <option value={value.propertyCode}>
                              {value.propertyCode}
                            </option>
                          ) : null
                        );
                    })}
                  </NativeSelect>
                  <FormHelperText>Codigo de propiedad</FormHelperText>
                </FormControl>

                {/* SALMAU Non serve aggiungere i giorni apperti perche vengono ricavati dal backend
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: 190 }}
                  margin="dense"
                >

                  <Select
                      labelId="select-label"
                      id="select"
                      defaultValue={selectedDay}
                      label="Pick a dias abierto mas referencias"
                      onChange={handleDayChange}
                  >
                  {[...Array(32).keys()].map((number) => (
                 <MenuItem key={number} value={number}>
                 {number}
                  </MenuItem>
                 ))}
                </Select>
                <FormHelperText>Dias abierto mas referencias</FormHelperText>
                </FormControl> */}

                <CardActions>
                <Button
                  component="label"
                  variant="contained"
                   startIcon={<DownloadIcon />}
                  className={classes.button}
                  onClick={handleSubmit}
                  disabled={ !daData || !aData || !codiceProperty /*|| !selectedDay*/}
                  loading={showLoad}
                >
                  extracción de datos
                </Button>

                <Button
                  component="label"
                  variant="contained"

                  className={classes.button}
                  onClick={handleOpenDialog}
                  disabled= {!datiRisposta}

                >
                  Leyes Report
                </Button>

                
                 <Button
                    component="label"
                    variant="contained"
                    className={classes.button}
                    onClick={handleDownload}
                    disabled={!datiRisposta}
                    
                 >
                  última descarga
                 </Button>

   

                <Dialog
                 open={openD}
                 onClose={handleCloseDialog}
                 fullScreen
                 maxWidth="md"
                 fullWidth
                >

                  <AppBar position="static"  style={{ backgroundColor: 'rgb(3, 155, 229)'}}>
                  <Toolbar>
                    <Button color="inherit" onClick={() => handleScreenChange('Alojamiento')}>
                     Alojamiento
                    </Button>
                 <Button color="inherit" onClick={() => handleScreenChange('Habitaciones')}>
                    Habitaciones
                  </Button>
                  <Button color="inherit" onClick={() => handleScreenChange('Precios')}>
                   Precios
                  </Button>
                    {errori.length > 0 &&
                        <Button id="errorButton" color="inherit" onClick={() => handleScreenChange('Error')}>
                          Error
                        </Button> }

                <Button color="inherit" onClick={() => handleDownload()}>
                   download
                </Button>
                <Button color="inherit" onClick={handleCloseDialog} style={{ marginLeft: 'auto' }}>
                <CloseIcon></CloseIcon>
                </Button>
                </Toolbar>
                  </AppBar>

                  <DialogContent>
                      {selectedScreen === 'Alojamiento' && codiceProperty === 'Hotel' &&(
                       <Typography variant="h4"> <div>

      <table style={{ width: '100%'}}>
                <thead>
                  <tr>
                    <th style={headerCellStyle}>nDia</th>
                    <th style={headerCellStyle}>Entrada</th>
                    <th style={headerCellStyle}>Salida</th>
                    <th style={headerCellStyle}>Reservas</th>
                    <th style={headerCellStyle}>id pais</th>
                    <th style={headerCellStyle}>id provincia</th>
                  </tr>
                </thead>
        <tbody>
           {
                  listaAlojiamento.map((movimento, index) => (
                    <tr key={index}>
                      <td style={dataCellStyle}>{movimento.nDia}</td>
                      <td style={dataCellStyle}>{movimento.entradas}</td>
                      <td style={dataCellStyle}>{movimento.salidas}</td>
                      <td style={dataCellStyle}>{movimento.pernoctaciones}</td>
                      <td style={{...dataCellStyle, backgroundColor: movimento.idPais === null || movimento.idPais === undefined ? movimento.idPais="ESP" : 'white'}}>{movimento.idPais}</td>
                      <td style={dataCellStyle}>{movimento.idProvinciaIsla}</td>
                    </tr>
                  ))
          }
        </tbody>
      </table>
    </div></Typography>)}

    {selectedScreen === 'Alojamiento' && codiceProperty === 'Apartamentos'  && (
                       <Typography variant="h4"> <div>

      <table style={{ width: '100%'}}>
        <thead>
          <tr>
            <th style={headerCellStyle}>nDia</th>
            <th style={headerCellStyle}>Entrada</th>
            <th style={headerCellStyle}>Salida</th>
            <th style={headerCellStyle}>Reservas</th>
            <th style={headerCellStyle}>id pais</th>
            <th style={headerCellStyle}>id provincia</th>
          </tr>
        </thead>
        <tbody>
           {
             listaAlojiamento.map((movimento, index) => (
               <tr key={index}>
                 <td style={dataCellStyle}>{movimento.nDia}</td>
                 <td style={dataCellStyle}>{movimento.entradas}</td>
                 <td style={dataCellStyle}>{movimento.salidas}</td>
                 <td style={dataCellStyle}>{movimento.pernoctaciones}</td>
                 <td style={{...dataCellStyle, backgroundColor: movimento.idPais === null || movimento.idPais === undefined ? movimento.idPais="ESP" : 'white'}}>{movimento.idPais}</td>
                 <td style={dataCellStyle}>{movimento.idProvinciaIsla}</td>
               </tr>
             ))
          }
        </tbody>
      </table>
    </div></Typography>)}

    {selectedScreen === 'Habitaciones' && codiceProperty ==='Hotel' && (
    <Typography variant="h4"> <div>
      <table style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th style={headerCellStyle}>Habitaciones n.dia</th>
                    <th style={headerCellStyle}>Plaza Supletorias</th>
                    <th style={headerCellStyle}>Habitaciones double uso double </th>
                    <th style={headerCellStyle}>Habitaciones doubles uso individual</th>
                    <th style={headerCellStyle}>Habitaciones Otras</th>
                    
                  </tr>
                </thead>
      <tbody>
        
        {dati.habitaciones.habitacionesMovimiento.map((abitazione, index) => (
                    <tr key={index}>
                      <td style={dataCellStyle}>{abitazione.habitaciones_N_Dia}</td>
                      <td style={dataCellStyle}>{abitazione.plazas_Supletorias}</td>
                      <td style={dataCellStyle}>{abitazione.habitaciones_Dobles_Uso_Doble}</td>
                      <td style={dataCellStyle}>{abitazione.habitaciones_Dobles_Uso_Individual}</td>
                      <td style={dataCellStyle}>{abitazione.habitaciones_Otras}</td>
                      
                    </tr>
                  ))
        }
      </tbody>
    </table>

    </div></Typography>)}

    {selectedScreen === 'Habitaciones' && codiceProperty ==='Apartamentos' && (
    <Typography variant="h4"> <div>
      <table style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th style={headerCellStyle}>Ocupación n.dia</th>
                    <th style={headerCellStyle}>Apartamentos Ocupados Estudio</th>
                    <th style={headerCellStyle}>Apartamentos Ocupados 2-4pax </th>
                    <th style={headerCellStyle}>Apartamentos Ocupados 4-6pax</th>
                    <th style={headerCellStyle}>Apartamentos Ocupados Otros</th>
                    <th style={headerCellStyle}>Plazas Supletorias</th>
                  </tr>
                </thead>

      <tbody>
        {dati.ocupacion.movimiento.map((abitazione, index) => (
                    <tr key={index}>
                      <td style={dataCellStyle}>{abitazione.n_Dia_Ap}</td>
                      <td style={dataCellStyle}>{abitazione.apartamentos_Ocupados_2or4Pax}</td>
                      <td style={dataCellStyle}>{abitazione.apartamentos_Ocupados_4or6Pax}</td>
                      <td style={dataCellStyle}>{abitazione.apartamentos_Ocupados_Estudio}</td>
                      <td style={dataCellStyle}>{abitazione.apartamentos_Ocupados_Otros}</td>
                      <td style={dataCellStyle}>{abitazione.plazas_Supletorias}</td>
                    </tr>
                  ))
        }
      </tbody>
    </table>
    </div></Typography>)}

      {selectedScreen === 'Precios' && codiceProperty === 'Hotel' && (
      <Typography variant="h4"> <div>
      <table style={{width: '100%'}}>
         <thead>
           <tr>
                    <th style={headerCellStyle}>Days</th>
                    <th style={headerCellStyle}>RevPAR Mensual</th>
                    <th style={headerCellStyle}>ADR Mensual</th>
                    <th style={headerCellStyle}>ADR Touroperador Tradicional</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Touroperador Tradicional</th>
                    <th style={headerCellStyle}>ADR Touroperador Online</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Touroperador Online</th>
                    <th style={headerCellStyle}>ADR Empresas</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Empresas</th>
                    <th style={headerCellStyle}>ADR Agencia de Viaje Tradicional</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Agencia Tradicional</th>
                    <th style={headerCellStyle}>ADR Agencia de Viaje Online</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Agencia Online</th>
                    <th style={headerCellStyle}>ADR Particulares</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Particulares</th>
                    <th style={headerCellStyle}>ADR Grupos</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Grupos</th>
                    <th style={headerCellStyle}>ADR Internet</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Internet</th>
                    <th style={headerCellStyle}>ADR Otros</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Otros</th>
                  </tr>
                </thead>

          <tbody>
                      <td style={dataCellStyle}>{dati.cabecera.dias_Abierto_Mes_Referencia}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.revpar_Mensual).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.adr_Mensual).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.adr_Touroperador_Tradicional).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.pctn_Habitaciones_Ocupadas_Touroperador_Tradicional).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.adr_Touroperador_Online).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.pctn_Habitaciones_Ocupadas_Touroperador_Online).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.adr_Empresas).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.pctn_Habitaciones_Ocupadas_Empresas).toFixed(2)}</td>         
                      <td style={dataCellStyle}>{parseFloat(dati.precios.adr_Agencia_De_Viaje_Tradicional).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.pctn_Habitaciones_Ocupadas_Agencia_Tradicional).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.adr_Agencia_De_Viaje_Online).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.pctn_Habitaciones_Ocupadas_Agencia_Online).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.adr_Particulares).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.pctn_Habitaciones_Ocupadas_Particulares).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.adr_Grupos).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.pctn_Habitaciones_Ocupadas_Grupos).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.adr_Internet).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.pctn_Habitaciones_Ocupadas_Internet).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.adr_Otros).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.pctn_Habitaciones_Ocupadas_Otros).toFixed(2)}</td>
      </tbody>
    </table>
    </div></Typography>)}

    {selectedScreen === 'Precios' && codiceProperty === 'Apartamentos' && (
      <Typography variant="h4"> <div>
      <table style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th style={headerCellStyle}>Days</th>
                    <th style={headerCellStyle}>Estudios Tarifa Normal</th>
                    <th style={headerCellStyle}>% Estudios Tarifa Normal</th>
                    <th style={headerCellStyle}>Estudios Tarifa fin de semana</th>
                    <th style={headerCellStyle}>% Estudios Tarifa fin de semana</th>
                    <th style={headerCellStyle}>Estudios Tarifa Touroperador</th>
                    <th style={headerCellStyle}>% Estudios Tarifa Touroperador</th>
                    <th style={headerCellStyle}>Estudios Tarifa Otras</th>
                    <th style={headerCellStyle}>% Estudios Tarifa Otras</th>

                    <th style={headerCellStyle}>App 2-4p Tarifa Normal</th>
                    <th style={headerCellStyle}>% App 2-4p Tarifa Normal</th>
                    <th style={headerCellStyle}>App 2-4p Tarifa fin de semana</th>
                    <th style={headerCellStyle}>% App 2-4p Tarifa fin de semana</th>
                    <th style={headerCellStyle}>App 2-4p Tarifa Touroperador</th>
                    <th style={headerCellStyle}>% App 2-4p Tarifa Touroperador</th>
                    <th style={headerCellStyle}>App 2-4p Tarifa Otras</th>
                    <th style={headerCellStyle}>% App 2-4p Tarifa Otras</th>

                    <th style={headerCellStyle}>App 4-6p Tarifa Normal</th>
                    <th style={headerCellStyle}>% App 4-6p Tarifa Normal</th>
                    <th style={headerCellStyle}>App 4-6p Tarifa fin de semana</th>
                    <th style={headerCellStyle}>% App 4-6p Tarifa fin de semana</th>
                    <th style={headerCellStyle}>App 4-6p Tarifa Touroperador</th>
                    <th style={headerCellStyle}>% App 4-6p Tarifa Touroperador</th>
                    <th style={headerCellStyle}>App 4-6p Tarifa Otras</th>
                    <th style={headerCellStyle}>% App 4-6p Tarifa Otras</th>

                    <th style={headerCellStyle}>Otros Tarifa Normal</th>
                    <th style={headerCellStyle}>% Otros Tarifa Normal</th>
                    <th style={headerCellStyle}>Otros Tarifa fin de semana</th>
                    <th style={headerCellStyle}>% Otros Tarifa fin de semana</th>
                    <th style={headerCellStyle}>Otros Tarifa Touroperador</th>
                    <th style={headerCellStyle}>% Otros Tarifa Touroperador</th>
                    <th style={headerCellStyle}>Otros Tarifa Otras</th>
                    <th style={headerCellStyle}>% Otros Tarifa Otras</th>
                  </tr>
                </thead>

      <tbody>
                      <td style={dataCellStyle}>{dati.cabecera.dias_Abierto_Mes_Referencia}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.estudios.tarifa_Normal).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.estudios.pctn_Tarifa_Normal).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.estudios.tarifa_Fin_De_Semana).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.estudios.pctn_Tarifa_Fin_De_Semana).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.estudios.tarifa_Touroperador).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.estudios.pctn_Tarifa_Touroperador).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.estudios.tarifa_Otras).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.estudios.pctn_Tarifa_Otras).toFixed(2)}</td>

                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos2or4Pax.tarifa_Normal).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos2or4Pax.pctn_Tarifa_Normal).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos2or4Pax.tarifa_Fin_De_Semana).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos2or4Pax.pctn_Tarifa_Fin_De_Semana).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos2or4Pax.tarifa_Touroperador).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos2or4Pax.pctn_Tarifa_Touroperador).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos2or4Pax.tarifa_Otras).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos2or4Pax.pctn_Tarifa_Otras).toFixed(2)}</td>

                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos4or6Pax.tarifa_Normal).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos4or6Pax.pctn_Tarifa_Normal).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos4or6Pax.tarifa_Fin_De_Semana).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos4or6Pax.pctn_Tarifa_Fin_De_Semana).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos4or6Pax.tarifa_Touroperador).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos4or6Pax.pctn_Tarifa_Touroperador).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos4or6Pax.tarifa_Otras).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.apartamentos4or6Pax.pctn_Tarifa_Otras).toFixed(2)}</td>


                      <td style={dataCellStyle}>{parseFloat(dati.precios.otros.tarifa_Normal).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.otros.pctn_Tarifa_Normal).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.otros.tarifa_Fin_De_Semana).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.otros.pctn_Tarifa_Fin_De_Semana).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.otros.tarifa_Touroperador).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.otros.pctn_Tarifa_Touroperador).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.otros.tarifa_Otras).toFixed(2)}</td>
                      <td style={dataCellStyle}>{parseFloat(dati.precios.otros.pctn_Tarifa_Otras).toFixed(2)}</td>

      </tbody>
    </table>

    </div></Typography>)}

                    {selectedScreen === 'Error' && (
                        <Typography variant="h4"> <div>
                          <table style={{width: '100%'}}>
                            <thead>
                            <tr>
                              <th style={headerCellStyle}>n.Error</th>
                              <th style={headerCellStyle}>Description</th>
                            </tr>
                            </thead>

                            <tbody>
                            {errori.map((error, index) => (
                                <tr key={index}>
                                  <td style={dataCellStyle}>{index += 1}</td>
                                  <td style={dataCellStyle}>{error}</td>
                                </tr>
                            ))
                            }
                            </tbody>
                          </table>
                        </div></Typography>)}

                  </DialogContent>
               </Dialog>
                </CardActions>

                {/* <CardActions>
                  <Button
                    variant="outlined"
                    onClick={handleSubmit}
                  >
                    Estado de extracción de datos
                  </Button>
                </CardActions>

                <CardActions>
                  <Button
                    variant="outlined"
                    onClick={handleButtonCall}
                  >
                    Prueba de Conexiòn
                  </Button>
                </CardActions> */}
              </form>
            </CardContent>
          </Card>
        </div>
      </div>

      <div style={{ height: "23vh", backgroundColor: "#F8F8F8" }}>
        <div
          style={{
            height: "60px",
          }}
        >
        </div>
      <TitleBar
        title={
          <span style={{ fontWeight: 'bold', textAlign: 'center', }}>
            Controles/Cambios
          </span>
        }
      />
        <div style={{ marginLeft: 290, display: "flex" }}>
          <Card
            style={{ minWidth: 100, marginTop: 23, borderRadius: 5 }}
            elevation={0}
          >
            <CardContent>
              <>
              <div style={{ display: 'flex', alignItems: 'center' }}>

              <Button component="label" variant="contained" startIcon={<LibraryBooksIcon/>}  className={classes.button}
               onClick={() => setOpenDialog(true)}
               disabled= {!datiRisposta}
              >
                Comprobar
              </Button>

              <Dialog
              open={openDialog}
              width="lg"
              onClose = {() => setOpenDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                ¿Quieres iniciar la verificación de datos nuevamente?
                </DialogContentText>
                 </DialogContent>
                 <DialogActions>
                 <Button></Button>
                 <Button onClick={handleDelete} autoFocus>
                  rechazar
                 </Button>
                 <Button onClick={() => handleSendIne(false)} autoFocus>
                  El confirma
                 </Button>
                </DialogActions>
              </Dialog>

              <Button component="label" variant="contained" startIcon={<EditIcon/>}  className={classes.button}
               style={{ marginLeft:'15px' }}
               onClick={handleClickOpenEditar}
               disabled= {!datiRisposta}
              >
                Editar
              </Button>

              <Dialog
              open={openEditar}
              onClose = {() => setOpenEditar(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              >

            <Typography
            variant="h6"
            style={{
            color: "white",
            width: "100%",
            marginLeft: "100px",
            fontSize: 20,
            textAlign: "center",
            fontWeight: "bold",
            backgroundColor: "#1aa3ff",
            height: "50px",
             display: "flex",
              alignItems: "center",
              justifyContent: "center", 
              margin: 0,
            padding: 0,
            }}
           >
              Filtro Alojamientos
            </Typography>
             <DialogContent>
                <Grid container spacing={1}>
                {/* <Grid item xs={4}>
                <Box sx={{ maxWidth: 120 }}>
                <FormControl fullWidth>
                 <InputLabel id="demo-simple-select-label">Datos incorrectos</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                 id="demo-simple-select"
                  value={datoincorrectos}
                label="datoincorrectos"
                  onChange={handleChange}
                  >
                    <MenuItem value={10}>Todos</MenuItem>
                    <MenuItem value={20}>Incorrecto</MenuItem>
                    <MenuItem value={30}>Correcto</MenuItem>
                  </Select>
                  </FormControl>
                  </Box>
                  </Grid> */}

                  {/* <Grid item xs={4} style={{ marginLeft: '-30px' }}>
                  <Box sx={{ maxWidth: 120 }}>
                  <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Datos excluidos</InputLabel>
                  <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={datoexcluidos}
                  label="datoexcluidos"
                  onChange={handleChangeDatos}
                   >
                  <MenuItem value={10}>Todos</MenuItem>
                  <MenuItem value={20}>Excluyendo</MenuItem>
                  <MenuItem value={30}>No Excluido</MenuItem>
                   </Select>
                   </FormControl>
                  </Box>
                  </Grid> */}
                  
                  <ListItemButton sx={{ width: '100px' , pl: 2 , marginTop: '10px' }}>
                   <TextField
                  label="N dia"
                  name="Ndia"
                  value={nDia}
                  onChange = {(e) => setNdia(e.target.value.toUpperCase())}
                   />
                  </ListItemButton>

                  <ListItemButton sx={{ width: '150px' , pl: 2 , marginTop: '10px' }}>
                   <TextField
                  label="Id Pais"
                  name="IdPais"
                  value={idpais}
                  onChange = {(e) => setIdpais(e.target.value.toUpperCase())}
                />
                  </ListItemButton>

                  <ListItemButton sx={{ width: '140px' , pl: 2 , marginTop: '10px'}}>
                   <TextField
                  label="Id Provincia isla"
                  name="IdProvinciaIsla"
                  value={idisla}
                  onChange={(e) => setIdisla(e.target.value.toUpperCase())}
                />
                  </ListItemButton>

                

                  
                  </Grid>

                 </DialogContent>
                 <DialogActions>
                 <Button></Button>
                 <Button onClick={handleDelete} autoFocus>
                  rechazar
                 </Button>
                 <Button
                   onClick={handleOpenModifiche}
                  >
                  El confirma
                 </Button>

                 <Dialog
                 open={openModifiche}
                 onClose={handleCloseModifiche}
                 fullScreen
                 maxWidth="md"
                 fullWidth
                >

                 <AppBar position="static" style={{ backgroundColor: 'rgb(3, 155, 229)'}} >
                  <Toolbar>
                    <Button color="inherit" onClick={() => handleScreenChange('Alojamiento')}>
                     Alojamiento
                    </Button>
                 <Button color="inherit" onClick={() => handleScreenChange('Habitaciones')}>
                    Habitaciones
                  </Button>
                  <Button color="inherit" onClick={() => handleScreenChange('Precios')}>
                   Precios
                   </Button>
                   <Button color="inherit" onClick={handleOpenAvvisoS} style={{ marginLeft: '990px' }}>
                <SaveIcon></SaveIcon >
                </Button>

                <Dialog
                 open={openAvvisoS}
                 width="lg"
                 onClose = {() => setOpenAvvisoS(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                ¿Quieres guardar los datos?
                </DialogContentText>
                 </DialogContent>
                 <DialogActions>
                 <Button></Button>
                 <Button onClick={handleCloseAvvisoS} autoFocus>
                 rechazar
                 </Button>
                 <Button onClick={handleSave}>
                 el confirma
                 </Button>
                </DialogActions>
              </Dialog>

                <Button color="inherit" onClick={handleOpenAvviso} style={{ marginLeft: 'auto' }}>
                <CloseIcon></CloseIcon >
                </Button>

                <Dialog
                 open={openAvviso}
                 width="lg"
                 onClose = {() => setOpenAvviso(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                Los cambios se perderán¿Aún quieres continuar?
                </DialogContentText>
                 </DialogContent>
                 <DialogActions>
                 <Button></Button>
                 <Button onClick={handleCloseAvviso} autoFocus >
                  rechazar
                 </Button>
                 <Button onClick={handleCloseModifiche} autoFocus>
                  el confirma
                 </Button>
                </DialogActions>
              </Dialog>
                </Toolbar>
                  </AppBar>

                  <DialogContent>
                      {selectedScreen === 'Alojamiento' && codiceProperty ==='Hotel' &&    (
                        
                      <Typography variant="h4"> <div>
                      <table style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th style={headerCellStyle}>nDia</th>
                    <th style={headerCellStyle}>Entrada</th>
                    <th style={headerCellStyle}>Salida</th>
                    <th style={headerCellStyle}>Reservas</th>
                    <th style={headerCellStyle}>id pais</th>
                    <th style={headerCellStyle}>id provincia</th>
                  </tr>
                </thead>

      <tbody>
        {renderizzaTabella && listaAlojiamento
            .filter(
              (movimento) =>
                (nDia === '' || movimento.nDia.toString().trim() === nDia.padStart(2, '0').trim()) &&
                (idpais === '' || movimento.idPais === idpais) &&
                (idisla === '' || movimento.idProvinciaIsla === idisla)
            )
            .map((movimento,index) => (
            <tr key={index}>
            <td
              style={dataCellStyle}
              contentEditable
              onBlur={(e) => handleAlojamientoContentChange(movimento.nDia, movimento.idPais, movimento.idProvinciaIsla, 'nDia', Number(e.target.innerText))}
              suppressContentEditableWarning={true}
            >
              {movimento.nDia}
            </td>
            <td style={{...dataCellStyle, backgroundColor: movimento.entradas === null || movimento.entradas === undefined || movimento.entradas < 0 ? 'red' : 'white'}}
              contentEditable
              onBlur={(e) => handleAlojamientoContentChange(movimento.nDia, movimento.idPais, movimento.idProvinciaIsla, 'entradas', Number(e.target.innerText))}
              suppressContentEditableWarning={true}
            >
              {movimento.entradas}
            </td>
            <td style={{...dataCellStyle, backgroundColor: movimento.salidas === null || movimento.salidas === undefined || movimento.salidas < 0 ? 'red' : 'white'}}
              contentEditable
              onBlur={(e) => handleAlojamientoContentChange(movimento.nDia, movimento.idPais, movimento.idProvinciaIsla, 'salidas', Number(e.target.innerText))}
              suppressContentEditableWarning={true}
            >
              {movimento.salidas}
            </td>
            <td style={{...dataCellStyle, backgroundColor: movimento.pernoctaciones === null || movimento.pernoctaciones === undefined || movimento.pernoctaciones < 0 ? 'red' : 'white'}}
              contentEditable
              onBlur={(e) => handleAlojamientoContentChange(movimento.nDia, movimento.idPais, movimento.idProvinciaIsla,  'pernoctaciones', Number(e.target.innerText))}
              suppressContentEditableWarning={true}
            >
              {movimento.pernoctaciones}
            </td>
            <td style={{...dataCellStyle, backgroundColor: movimento.idPais === null || movimento.idPais === undefined ? movimento.idPais="ESP" : 'white'}}
              contentEditable
              onBlur={(e) => handleAlojamientoContentChange(movimento.nDia, movimento.idPais, movimento.idProvinciaIsla, 'idPais', e.target.innerText)}
              suppressContentEditableWarning={true}
            >
              {movimento.idPais}
            </td>
            <td style={{...dataCellStyle, backgroundColor: (movimento.idProvinciaIsla === null || movimento.idProvinciaIsla === undefined) && !movimento.idPais ? 'red' : 'white'}}
              contentEditable
              onBlur={(e) => handleAlojamientoContentChange(movimento.nDia, movimento.idPais, movimento.idProvinciaIsla, 'ID_PROVINCIA_ISLA', e.target.innerText)}
              suppressContentEditableWarning={true}
            >
              {movimento.idProvinciaIsla}
            </td>
                    </tr>
                  )
        )}
      </tbody>
    </table>
    </div></Typography>)}

    {selectedScreen === 'Alojamiento' && codiceProperty ==='Apartamentos' &&    (
                      <Typography variant="h4"> <div>

                      <table style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th style={headerCellStyle}>nDia</th>
                    <th style={headerCellStyle}>Entrada</th>
                    <th style={headerCellStyle}>Salida</th>
                    <th style={headerCellStyle}>Reservas</th>
                    <th style={headerCellStyle}>id pais</th>
                    <th style={headerCellStyle}>id provincia</th>
                  </tr>
                </thead>
      <tbody>
      {renderizzaTabella && listaAlojiamento
            .filter(
              (movimento) =>
                (nDia === '' || movimento.nDia.toString().trim() === nDia.padStart(2, '0').trim()) &&
                (idpais === '' || movimento.idPais === idpais) &&
                (idisla === '' || movimento.idProvinciaIsla === idisla)
            )
            .map((movimento,index) => (
            <tr key={index}>
            <td
              style={dataCellStyle}
              contentEditable
              onBlur={(e) => handleAlojamientoContentChange(movimento.nDia, movimento.idPais, movimento.idProvinciaIsla, 'nDia', Number(e.target.innerText))}
              suppressContentEditableWarning={true}
            >
              {movimento.nDia}
            </td>
            <td style={{...dataCellStyle, backgroundColor: movimento.entradas === null || movimento.entradas === undefined || movimento.entradas < 0 ? 'red' : 'white'}}
              contentEditable
              onBlur={(e) => handleAlojamientoContentChange(movimento.nDia, movimento.idPais, movimento.idProvinciaIsla, 'entradas', Number(e.target.innerText))}
              suppressContentEditableWarning={true}
            >
              {movimento.entradas}
            </td>
            <td style={{...dataCellStyle, backgroundColor: movimento.salidas === null || movimento.salidas === undefined || movimento.salidas < 0 ? 'red' : 'white'}}
              contentEditable
              onBlur={(e) => handleAlojamientoContentChange(movimento.nDia, movimento.idPais, movimento.idProvinciaIsla, 'salidas', Number(e.target.innerText))}
              suppressContentEditableWarning={true}
            >
              {movimento.salidas}
            </td>
            <td style={{...dataCellStyle, backgroundColor: movimento.pernoctaciones === null || movimento.pernoctaciones === undefined || movimento.pernoctaciones < 0 ? 'red' : 'white'}}
              contentEditable
              onBlur={(e) => handleAlojamientoContentChange(movimento.nDia, movimento.idPais, movimento.idProvinciaIsla,  'pernoctaciones', Number(e.target.innerText))}
              suppressContentEditableWarning={true}
            >
              {movimento.pernoctaciones}
            </td>
            <td style={{...dataCellStyle, backgroundColor: movimento.idPais === null || movimento.idPais === undefined ? movimento.idPais="ESP" : 'white'}}
              contentEditable
              onBlur={(e) => handleAlojamientoContentChange(movimento.nDia, movimento.idPais, movimento.idProvinciaIsla, 'idPais', e.target.innerText)}
              suppressContentEditableWarning={true}
            >
              {movimento.idPais}
            </td>
            <td style={{...dataCellStyle, backgroundColor: (movimento.idProvinciaIsla === null || movimento.idProvinciaIsla === undefined) && !movimento.idPais ? 'red' : 'white'}}
              contentEditable
              onBlur={(e) => handleAlojamientoContentChange(movimento.nDia, movimento.idPais, movimento.idProvinciaIsla, 'ID_PROVINCIA_ISLA', e.target.innerText)}
              suppressContentEditableWarning={true}
            >
              {movimento.idProvinciaIsla}
            </td>
                    </tr>
                  )
        )}
      </tbody>
    </table>
    </div></Typography>)}

      {selectedScreen === 'Habitaciones' && codiceProperty ==='Hotel' && (

       <Typography variant="h4"> <div>
      <table style={{ width: '100%'}}>
                <thead>
                  <tr>
                    <th style={headerCellStyle}>Habitaciones n.dia</th>
                    <th style={headerCellStyle}>Plaza Supletorias</th>
                    <th style={headerCellStyle}>Habitaciones double uso double </th>
                    <th style={headerCellStyle}>Habitaciones doubles uso individual</th>
                    <th style={headerCellStyle}>Habitaciones Otras</th>
                  </tr>
                </thead>

      <tbody>
        {modifiedData?.habitaciones.habitacionesMovimiento.map((abitazione, index) => (
                    <tr key={index}>
                      <td style={{...dataCellStyle,  backgroundColor: abitazione.habitaciones_N_Dia === null || abitazione.habitaciones_N_Dia === undefined || abitazione.habitaciones_N_Dia < 0 ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleHabitacionesChange(index, 'habitaciones_N_Dia', e.target.innerText)}
                      suppressContentEditableWarning={true}
                      >{abitazione.habitaciones_N_Dia}
                      </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: abitazione.plazas_Supletorias === null || abitazione.plazas_Supletorias === undefined || abitazione.plazas_Supletorias < 0 ? 'red' : 'white' }}
                      contentEditable
                      onBlur={(e) => handleHabitacionesChange(index, 'plazas_Supletorias', e.target.innerText)}
                      suppressContentEditableWarning={true}
                      >{abitazione.plazas_Supletorias}
                      </td>

                      <td
                       style={{...dataCellStyle, backgroundColor: abitazione.habitaciones_Dobles_Uso_Doble === null || abitazione.habitaciones_Dobles_Uso_Doble === undefined || abitazione.habitaciones_Dobles_Uso_Doble < 0 ? 'red' : 'white' }}
                       contentEditable
                        onBlur={(e) => handleHabitacionesChange(index, 'habitaciones_Dobles_Uso_Doble', e.target.innerText)}
                        suppressContentEditableWarning={true}
                       >{abitazione.habitaciones_Dobles_Uso_Doble}
                       </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: abitazione.habitaciones_Dobles_Uso_Individual === null || abitazione.habitaciones_Dobles_Uso_Individual === undefined || abitazione.habitaciones_Dobles_Uso_Individual < 0 ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleHabitacionesChange(index, 'habitaciones_Dobles_Uso_Individual', e.target.innerText)}
                      suppressContentEditableWarning={true}
                      >{abitazione.habitaciones_Dobles_Uso_Individual}
                      </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: abitazione.habitaciones_Otras === null || abitazione.habitaciones_Otras === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleHabitacionesChange(index, 'habitaciones_Otras', e.target.innerText)}
                      suppressContentEditableWarning={true}
                      >{abitazione.habitaciones_Otras}
                      </td>
                    </tr>
                  ))
        }
      </tbody>
    </table>

    </div>

    </Typography>)}

     {selectedScreen === 'Habitaciones' && codiceProperty ==='Apartamentos' && (
       <Typography variant="h4"> <div>
      <table style={{ width: '100%'}}>
                <thead>
                  <tr>
                    <th style={headerCellStyle}>Ocupación n.dia</th>
                    <th style={headerCellStyle}>Apartamentos Ocupados Estudio</th>
                    <th style={headerCellStyle}>Apartamentos Ocupados 2-4pax </th>
                    <th style={headerCellStyle}>Apartamentos Ocupados 4-6pax</th>
                    <th style={headerCellStyle}>Apartamentos Ocupados Otros</th>
                    <th style={headerCellStyle}>Plazas Supletorias</th>
                  </tr>
                </thead>

      <tbody>
        {modifiedData.ocupacion.movimiento.map((abitazione, index) => (
                    <tr key={index}>
                      <td style={{...dataCellStyle,  backgroundColor: abitazione.n_Dia_Ap === null || abitazione.n_Dia_Ap === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoChange(index, 'n_Dia_Ap', e.target.innerText)}
                      suppressContentEditableWarning={true}
                      >{abitazione.n_Dia_Ap}
                      </td>
                      <td
                      style={{...dataCellStyle, backgroundColor: abitazione.apartamentos_Ocupados_Estudio === null || abitazione.apartamentos_Ocupados_Estudio === undefined || abitazione.apartamentos_Ocupados_Estudio < 0 ? 'red' : 'white' }}
                      contentEditable
                      onBlur={(e) => handleAppartamentoChange(index, 'apartamentos_OcupadosEstudio', e.target.innerText)}
                      suppressContentEditableWarning={true}
                      >{abitazione.apartamentos_Ocupados_Estudio}
                      </td>

                      <td
                       style={{...dataCellStyle, backgroundColor: abitazione.apartamentos_Ocupados_2or4Pax === null || abitazione.apartamentos_Ocupados_2or4Pax === undefined || abitazione.apartamentos_Ocupados_2or4Pax < 0 ? 'red' : 'white' }}
                       contentEditable
                        onBlur={(e) => handleAppartamentoChange(index, 'apartamentos_Ocupados2or4Pax', e.target.innerText)}
                        suppressContentEditableWarning={true}
                       >{abitazione.apartamentos_Ocupados_2or4Pax}
                       </td>
                      <td
                      style={{...dataCellStyle, backgroundColor: abitazione.apartamentos_Ocupados_4or6Pax === null || abitazione.apartamentos_Ocupados_4or6Pax === undefined || abitazione.apartamentos_Ocupados_4or6Pax < 0 ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoChange(index, 'apartamentosOcupados4or6Pax', e.target.innerText)}
                      suppressContentEditableWarning={true}
                      >{abitazione.apartamentos_Ocupados_4or6Pax}
                      </td>
                      <td
                      style={{...dataCellStyle, backgroundColor: abitazione.apartamentos_Ocupados_Otros === null || abitazione.apartamentos_Ocupados_Otros === undefined || abitazione.apartamentos_Ocupados_Otros < 0 ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoChange(index, 'apartamentosOcupadosOtros', e.target.innerText)}
                      suppressContentEditableWarning={true}
                      >{abitazione.apartamentos_Ocupados_Otros}
                      </td>
                      <td
                      style={{...dataCellStyle, backgroundColor: abitazione.plazas_Supletorias === null || abitazione.plazas_Supletorias === undefined || abitazione.plazas_Supletorias < 0 ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoChange(index, 'plazas_Supletorias', e.target.innerText)}
                      suppressContentEditableWarning={true}
                      >{abitazione.plazas_Supletorias}
                      </td>
                    </tr>
                  ))
        }
      </tbody>
    </table>
    </div>

    </Typography>)}
      {selectedScreen === 'Precios' && codiceProperty === 'Hotel' && (
      <Typography variant="h4"> <div>
      <table style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th style={headerCellStyle}>Days</th>
                    <th style={headerCellStyle}>RevPAR Mensual</th>
                    <th style={headerCellStyle}>ADR Mensual</th>
                    <th style={headerCellStyle}>ADR Touroperador Tradicional</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Touroperador Tradicional</th>
                    <th style={headerCellStyle}>ADR Touroperador Online</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Touroperador Online</th>
                    <th style={headerCellStyle}>ADR Empresas</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Empresas</th>
                    <th style={headerCellStyle}>ADR Agencia de Viaje Tradicional</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Agencia Tradicional</th>
                    <th style={headerCellStyle}>ADR Agencia de Viaje Online</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Agencia Online</th>
                    <th style={headerCellStyle}>ADR Particulares</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Particulares</th>
                    <th style={headerCellStyle}>ADR Grupos</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Grupos</th>
                    <th style={headerCellStyle}>ADR Internet</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Internet</th>
                    <th style={headerCellStyle}>ADR Otros</th>
                    <th style={headerCellStyle}>% Habitaciones Ocupadas Otros</th>
                  </tr>
                </thead>
      <tbody>
                      <td style={dataCellStyle}>{modifiedData?.cabecera.dias_Abierto_Mes_Referencia}</td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.revpar_Mensual === null || modifiedData?.precios.revpar_Mensual === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('revpar_Mensual', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >{parseFloat(modifiedData?.precios.revpar_Mensual).toFixed(2)}
                      </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.adr_Mensual === null || modifiedData?.precios.adr_Mensual === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('adr_Mensual', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.adr_Mensual).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.adr_Touroperador_Tradicional === null || modifiedData?.precios.adr_Touroperador_Tradicional === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('adr_Touroperador_Tradicional', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.adr_Touroperador_Tradicional).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.pctn_Habitaciones_Ocupadas_Touroperador_Tradicional === null || modifiedData?.precios.pctn_Habitaciones_Ocupadas_Touroperador_Tradicional === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('pctn_Habitaciones_Ocupadas_Touroperador_Tradicional', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.pctn_Habitaciones_Ocupadas_Touroperador_Tradicional).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.adr_Touroperador_Online === null || modifiedData?.precios.adr_Touroperador_Online === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('adr_Touroperador_Online', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.adr_Touroperador_Online).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.pctn_Habitaciones_Ocupadas_Touroperador_Online === null || modifiedData?.precios.pctn_Habitaciones_Ocupadas_Touroperador_Online === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('pctn_Habitaciones_Ocupadas_Touroperador_Online', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.pctn_Habitaciones_Ocupadas_Touroperador_Online).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.adr_Empresas === null || modifiedData?.precios.adr_Empresas === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('adr_Empresas', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.adr_Empresas).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.pctn_Habitaciones_Ocupadas_Empresas === null || modifiedData?.precios.pctn_Habitaciones_Ocupadas_Empresas === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('pctn_Habitaciones_Ocupadas_Empresas', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.pctn_Habitaciones_Ocupadas_Empresas).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.adr_Agencia_De_Viaje_Tradicional === null || modifiedData?.precios.adr_Agencia_De_Viaje_Tradicional === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('adr_Agencia_De_Viaje_Tradicional', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.adr_Agencia_De_Viaje_Tradicional).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.pctn_Habitaciones_Ocupadas_Agencia_Tradicional === null || modifiedData?.precios.pctn_Habitaciones_Ocupadas_Agencia_Tradicional === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('pctn_Habitaciones_Ocupadas_Agencia_Tradicional', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.pctn_Habitaciones_Ocupadas_Agencia_Tradicional).toFixed(2)}
                        </td>

                      <td
                       style={{...dataCellStyle, backgroundColor: modifiedData?.precios.adr_Agencia_De_Viaje_Online === null || modifiedData?.precios.adr_Agencia_De_Viaje_Online === undefined ? 'red' : 'white'}}
                       contentEditable
                       onBlur={(e) => handlePreciosChange('adr_Agencia_De_Viaje_Online', parseFloat(e.target.innerText).toFixed(2))}
                       suppressContentEditableWarning={true}
                       >
                        {parseFloat(modifiedData?.precios.adr_Agencia_De_Viaje_Online).toFixed(2)}
                      </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.pctn_Habitaciones_Ocupadas_Agencia_Online === null || modifiedData?.precios.pctn_Habitaciones_Ocupadas_Agencia_Online === undefined ? 'red' : 'white' }}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('pctn_Habitaciones_Ocupadas_Agencia_Online', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.pctn_Habitaciones_Ocupadas_Agencia_Online).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.adr_Particulares === null || modifiedData?.precios.adr_Particulares === undefined ? 'red' : 'white' }}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('adr_Particulares', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.adr_Particulares).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.pctn_Habitaciones_Ocupadas_Particulares === null || modifiedData?.precios.pctn_Habitaciones_Ocupadas_Particulares === undefined ? 'red' : 'white' }}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('pctn_Habitaciones_Ocupadas_Particulares', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.pctn_Habitaciones_Ocupadas_Particulares).toFixed(2)}
                        </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.adr_Grupos === null || modifiedData?.precios.adr_Grupos === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('adr_Grupos', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.adr_Grupos).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.pctn_Habitaciones_Ocupadas_Grupos === null || modifiedData?.precios.pctn_Habitaciones_Ocupadas_Grupos === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('pctn_Habitaciones_Ocupadas_Grupos', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.pctn_Habitaciones_Ocupadas_Grupos).toFixed(2)}
                        </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.adr_Internet === null || modifiedData?.precios.adr_Internet === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('adr_Internet', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.adr_Internet).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.pctn_Habitaciones_Ocupadas_Internet === null || modifiedData?.precios.pctn_Habitaciones_Ocupadas_Internet === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('pctn_Habitaciones_Ocupadas_Internet', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.pctn_Habitaciones_Ocupadas_Internet).toFixed(2)}
                        </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.adr_Otros === null || modifiedData?.precios.adr_Otros === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('adr_Otros', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.adr_Otros).toFixed(2)}
                        </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.pctn_Habitaciones_Ocupadas_Otros === null || modifiedData?.precios.pctn_Habitaciones_Ocupadas_Otros === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handlePreciosChange('pctn_Habitaciones_Ocupadas_Otros', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.pctn_Habitaciones_Ocupadas_Otros).toFixed(2)}
                        </td>

      </tbody>
    </table>
 </div>
</Typography>)}

{selectedScreen === 'Precios' && codiceProperty === 'Apartamentos' && (
      <Typography variant="h4"> <div>
      <table style={{width: '100%'}}>
                <thead>
                  <tr>

                  <th style={headerCellStyle}>Days</th>
                    <th style={headerCellStyle}>Estudios Tarifa Normal</th>
                    <th style={headerCellStyle}>% Estudios Tarifa Normal</th>
                    <th style={headerCellStyle}>Estudios Tarifa fin de semana</th>
                    <th style={headerCellStyle}>% Estudios Tarifa fin de semana</th>
                    <th style={headerCellStyle}>Estudios Tarifa Touroperador</th>
                    <th style={headerCellStyle}>% Estudios Tarifa Touroperador</th>
                    <th style={headerCellStyle}>Estudios Tarifa Otras</th>
                    <th style={headerCellStyle}>% Estudios Tarifa Otras</th>

                    <th style={headerCellStyle}>App 2-4p Tarifa Normal</th>
                    <th style={headerCellStyle}>% App 2-4p Tarifa Normal</th>
                    <th style={headerCellStyle}>App 2-4p Tarifa fin de semana</th>
                    <th style={headerCellStyle}>% App 2-4p Tarifa fin de semana</th>
                    <th style={headerCellStyle}>App 2-4p Tarifa Touroperador</th>
                    <th style={headerCellStyle}>% App 2-4p Tarifa Touroperador</th>
                    <th style={headerCellStyle}>App 2-4p Tarifa Otras</th>
                    <th style={headerCellStyle}>% App 2-4p Tarifa Otras</th>

                    <th style={headerCellStyle}>App 4-6p Tarifa Normal</th>
                    <th style={headerCellStyle}>% App 4-6p Tarifa Normal</th>
                    <th style={headerCellStyle}>App 4-6p Tarifa fin de semana</th>
                    <th style={headerCellStyle}>% App 4-6p Tarifa fin de semana</th>
                    <th style={headerCellStyle}>App 4-6p Tarifa Touroperador</th>
                    <th style={headerCellStyle}>% App 4-6p Tarifa Touroperador</th>
                    <th style={headerCellStyle}>App 4-6p Tarifa Otras</th>
                    <th style={headerCellStyle}>% App 4-6p Tarifa Otras</th>

                    <th style={headerCellStyle}>Otros Tarifa Normal</th>
                    <th style={headerCellStyle}>% Otros Tarifa Normal</th>
                    <th style={headerCellStyle}>Otros Tarifa fin de semana</th>
                    <th style={headerCellStyle}>% Otros Tarifa fin de semana</th>
                    <th style={headerCellStyle}>Otros Tarifa Touroperador</th>
                    <th style={headerCellStyle}>% Otros Tarifa Touroperador</th>
                    <th style={headerCellStyle}>Otros Tarifa Otras</th>
                    <th style={headerCellStyle}>% Otros Tarifa Otras</th>

                  </tr>
                </thead>

      <tbody>

                      <td style={dataCellStyle}>{modifiedData.cabecera.dias_Abierto_Mes_Referencia}</td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.estudios.tarifa_Normal === null || modifiedData?.precios.estudios.tarifa_Normal === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoEstudiosChange('tarifa_Normal', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >{parseFloat(modifiedData?.precios.estudios.tarifa_Normal).toFixed(2)}
                      </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.estudios.pctn_Tarifa_Normal === null || modifiedData?.precios.estudios.pctn_Tarifa_Normal === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoEstudiosChange('pctn_Tarifa_Normal',  parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.estudios.pctn_Tarifa_Normal).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.estudios.tarifa_Fin_De_Semana === null || modifiedData?.precios.estudios.tarifa_Fin_De_Semana === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoEstudiosChange('tarifa_Fin_De_Semana', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.estudios.tarifa_Fin_De_Semana).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.estudios.pctn_Tarifa_Fin_De_Semana === null || modifiedData?.precios.estudios.pctn_Tarifa_Fin_De_Semana === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoEstudiosChange('pctn_Tarifa_Fin_De_Semana', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.estudios.pctn_Tarifa_Fin_De_Semana).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.estudios.tarifa_Touroperador === null || modifiedData?.precios.estudios.tarifa_Touroperador === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoEstudiosChange('tarifa_Touroperador', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.estudios.tarifa_Touroperador).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.estudios.pctn_Tarifa_Touroperador === null || modifiedData?.precios.estudios.pctn_Tarifa_Touroperador === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoEstudiosChange('pctn_Tarifa_Touroperador', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.estudios.pctn_Tarifa_Touroperador).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.estudios.tarifa_Otras === null || modifiedData?.precios.estudios.tarifa_Otras === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoEstudiosChange('tarifa_Otras', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.estudios.tarifa_Otras).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.estudios.pctn_Tarifa_Otras === null || modifiedData?.precios.estudios.pctn_Tarifa_Otras === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoEstudiosChange('pctn_Tarifa_Otras', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.estudios.pctn_Tarifa_Otras).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos2or4Pax.tarifa_Normal === null || modifiedData?.precios.apartamentos2or4Pax.tarifa_Normal === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamento2e4Change('tarifa_Normal', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.apartamentos2or4Pax.tarifa_Normal).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos2or4Pax.pctn_Tarifa_Normal === null || modifiedData?.precios.apartamentos2or4Pax.pctn_Tarifa_Normal === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamento2e4Change('pctn_Tarifa_Normal', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.apartamentos2or4Pax.pctn_Tarifa_Normal).toFixed(2)}
                        </td>

                      <td
                       style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos2or4Pax.tarifa_Fin_De_Semana === null || modifiedData?.precios.apartamentos2or4Pax.tarifa_Fin_De_Semana === undefined ? 'red' : 'white'}}
                       contentEditable
                       onBlur={(e) => handleAppartamento2e4Change('tarifa_Fin_De_Semana', parseFloat(e.target.innerText).toFixed(2))}
                       suppressContentEditableWarning={true}
                       >
                        {parseFloat(modifiedData?.precios.apartamentos2or4Pax.tarifa_Fin_De_Semana).toFixed(2)}
                      </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos2or4Pax.pctn_Tarifa_Fin_De_Semana === null || modifiedData?.precios.apartamentos2or4Pax.pctn_Tarifa_Fin_De_Semana === undefined ? 'red' : 'white' }}
                      contentEditable
                      onBlur={(e) => handleAppartamento2e4Change('pctn_Tarifa_Fin_De_Semana', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.apartamentos2or4Pax.pctn_Tarifa_Fin_De_Semana).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos2or4Pax.tarifa_Touroperador === null || modifiedData?.precios.apartamentos2or4Pax.tarifa_Touroperador === undefined ? 'red' : 'white' }}
                      contentEditable
                      onBlur={(e) => handleAppartamento2e4Change('tarifa_Touroperador', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.apartamentos2or4Pax.tarifa_Touroperador).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos2or4Pax.pctn_Tarifa_Touroperador === null || modifiedData?.precios.apartamentos2or4Pax.pctn_Tarifa_Touroperador === undefined ? 'red' : 'white' }}
                      contentEditable
                      onBlur={(e) => handleAppartamento2e4Change('pctn_Tarifa_Touroperador', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.apartamentos2or4Pax.pctn_Tarifa_Touroperador).toFixed(2)}
                        </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos2or4Pax.tarifa_Otras === null || modifiedData?.precios.apartamentos2or4Pax.tarifa_Otras === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamento2e4Change('tarifa_Otras', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.apartamentos2or4Pax.tarifa_Otras).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos2or4Pax.pctn_Tarifa_Otras === null || modifiedData?.precios.apartamentos2or4Pax.pctn_Tarifa_Otras === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamento2e4Change('pctn_Tarifa_Otras', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.apartamentos2or4Pax.pctn_Tarifa_Otras).toFixed(2)}
                        </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos4or6Pax.tarifa_Normal === null || modifiedData?.precios.apartamentos4or6Pax.tarifa_Normal === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamento4e6Change('tarifa_Normal', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.apartamentos4or6Pax.tarifa_Normal).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos4or6Pax.pctn_Tarifa_Normal === null || modifiedData?.precios.apartamentos4or6Pax.pctn_Tarifa_Normal === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamento4e6Change('pctn_Tarifa_Normal', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.apartamentos4or6Pax.pctn_Tarifa_Normal).toFixed(2)}
                        </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos4or6Pax.tarifa_Fin_De_Semana === null || modifiedData?.precios.apartamentos4or6Pax.tarifa_Fin_De_Semana === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamento4e6Change('tarifa_Fin_De_Semana', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.apartamentos4or6Pax.tarifa_Fin_De_Semana).toFixed(2)}
                        </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos4or6Pax.pctn_Tarifa_Fin_De_Semana === null || modifiedData?.precios.apartamentos4or6Pax.pctn_Tarifa_Fin_De_Semana === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamento4e6Change('pctn_Tarifa_Fin_De_Semana', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.apartamentos4or6Pax.pctn_Tarifa_Fin_De_Semana).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos4or6Pax.tarifa_Touroperador === null || modifiedData?.precios.apartamentos4or6Pax.tarifa_Touroperador === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamento4e6Change('tarifa_Touroperador', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.apartamentos4or6Pax.tarifa_Touroperador).toFixed(2)}
                        </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos4or6Pax.pctn_Tarifa_Touroperador === null || modifiedData?.precios.apartamentos4or6Pax.pctn_Tarifa_Touroperador === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamento4e6Change('pctn_Tarifa_Touroperador', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.apartamentos4or6Pax.pctn_Tarifa_Touroperador).toFixed(2)}
                        </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos4or6Pax.tarifa_Otras === null || modifiedData?.precios.apartamentos4or6Pax.tarifa_Otras === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamento4e6Change('tarifa_Otras', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.apartamentos4or6Pax.tarifa_Otras).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.apartamentos4or6Pax.pctn_Tarifa_Otras === null || modifiedData?.precios.apartamentos4or6Pax.pctn_Tarifa_Otras === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamento4e6Change('pctn_Tarifa_Otras', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.apartamentos4or6Pax.pctn_Tarifa_Otras).toFixed(2)}
                        </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.otros.tarifa_Normal === null || modifiedData?.precios.otros.tarifa_Normal === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoOtrosChange('tarifa_Normal', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.otros.tarifa_Normal).toFixed(2)}
                        </td>

                      <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.otros.pctn_Tarifa_Normal === null || modifiedData?.precios.otros.pctn_Tarifa_Normal === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoOtrosChange('pctn_Tarifa_Normal', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.otros.pctn_Tarifa_Normal).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.otros.tarifa_Fin_De_Semana === null || modifiedData?.precios.otros.tarifa_Fin_De_Semana === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoOtrosChange('tarifa_Fin_De_Semana', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.otros.tarifa_Fin_De_Semana).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.otros.pctn_Tarifa_Fin_De_Semana === null || modifiedData?.precios.otros.pctn_Tarifa_Fin_De_Semana === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoOtrosChange('pctn_Tarifa_Fin_De_Semana', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.otros.pctn_Tarifa_Fin_De_Semana).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.otros.tarifa_Touroperador === null || modifiedData?.precios.otros.tarifa_Touroperador === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoOtrosChange('tarifa_Touroperador', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.otros.tarifa_Touroperador).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.otros.pctn_Tarifa_Touroperador === null || modifiedData?.precios.otros.pctn_Tarifa_Touroperador === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoOtrosChange('pctn_Tarifa_Touroperador', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.otros.pctn_Tarifa_Touroperador).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.otros.tarifa_Otras === null || modifiedData?.precios.otros.tarifa_Otras === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoOtrosChange('tarifa_Otras', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.otros.tarifa_Otras).toFixed(2)}
                        </td>

                        <td
                      style={{...dataCellStyle, backgroundColor: modifiedData?.precios.otros.pctn_Tarifa_Otras === null || modifiedData?.precios.otros.pctn_Tarifa_Otras === undefined ? 'red' : 'white'}}
                      contentEditable
                      onBlur={(e) => handleAppartamentoOtrosChange('pctn_Tarifa_Otras', parseFloat(e.target.innerText).toFixed(2))}
                      suppressContentEditableWarning={true}
                      >
                        {parseFloat(modifiedData?.precios.otros.pctn_Tarifa_Otras).toFixed(2)}
                        </td>

      </tbody>
    </table>
 </div>
</Typography>)}

                  </DialogContent>

      <div style={{ backgroundColor: '#f0f0f0' , width: '100%'}}>
  <AppBar position="relative" style={{ backgroundColor: 'rgb(3, 155, 229)' }}>
    <Toolbar>
      <Button color="inherit">
        Tabla de Soporte
      </Button>

      <Button color="inherit" onClick={handleMinimizeToggle} style={{ marginLeft: 'auto' }}>
        <MinimizeIcon></MinimizeIcon>
      </Button>
    </Toolbar>
  </AppBar>

  {modifiedData && (
  <>
    <div style={{ display: 'flex' }}>
      <table style={{...tableStyle, width: '33%', borderCollapse: 'collapse', marginTop: '3px', marginLeft: '15px', overflow: 'auto', maxHeight: '330px'}}>
        <thead>
        <tr>
          <th style={headerCellStyle}>N.Dia</th>
          <th style={headerCellStyle}>Entradas</th>
          <th style={headerCellStyle}>Salidas</th>
          <th style={headerCellStyle}>Pernoctaciones</th>
        </tr>
        </thead>
        <tbody>
        {
          modifiedData.alojamiento.residencia[0].movimiento.map((movimiento, index) => {
            let totalEntradas = 0;
            let totalSalidas = 0;
            let totalPernoctaciones = 0;

            modifiedData.alojamiento.residencia.map((residenza) => {
              totalEntradas += Number(residenza.movimiento[index].entradas);
              totalSalidas += Number(residenza.movimiento[index].salidas);
              totalPernoctaciones += Number(residenza.movimiento[index].pernoctaciones);
            })
            return (
                <tr key={index}>
                  <td style={dataCellStyle}>{index + 1}</td>
                  <td style={dataCellStyle}>{Number(totalEntradas)}</td>
                  <td style={dataCellStyle}>{Number(totalSalidas)}</td>
                  <td style={dataCellStyle}>{Number(totalPernoctaciones)}</td>
                </tr>
            );
          })}
        </tbody>
      </table>

      <ul style={{
        ...tableStyle,
        width: '100%',
        marginTop: '16px',
        listStyle: 'none',
        padding: 0,
        overflow: 'auto',
        maxHeight: '330px',
        marginRight: '15px'
      }}>
        <table style={{width: '100%'}}>
          <thead>
          <tr>
            <th style={{...headerCellStyle, width: '10%' }}>n.Error</th>
            <th style={{...headerCellStyle, width: '90%'}}>Description</th>
          </tr>
          </thead>

          <tbody>
          {errori.map((error, index) => (
              <tr key={index}>
                <td style={dataCellStyle}>{index += 1}</td>
                <td style={dataCellStyle}>{error}</td>
              </tr>
          ))
          }
          </tbody>
        </table>
      </ul>
    </div>

  </>
  )}
</div>
                 </Dialog>
                 </DialogActions>
              </Dialog>
                <input type="file" onChange={handleFileChange} style={{ display: 'none' }} />
      <Button
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        style={{ marginLeft: '15px' }}
        // style={{ backgroundColor: 'rgb(3, 155, 229)', marginLeft: '15px' }}
        disabled={true}
      >
        Elija el archivo
        <input type="file" onChange={handleFileChange} style={{ display: 'none' }} />
      </Button>

      <Button
        variant="contained"
        color="primary"
        startIcon={<CloudUploadIcon />}
        style={{ marginLeft: '15px' }}
        // style={{ backgroundColor: 'rgb(3, 155, 229)', marginLeft: '15px' }}
        onClick={handleUpload}
        disabled={true}
      >
        Subir archivos
      </Button>

      {selectedFile && (
        <div>
          <p>Archivo elegido: {selectedFile.name}</p>
          {/* Add file processing logic here if needed */}
        </div>
      )}
              </div>
               </>
            </CardContent>
          </Card>
        </div>
      </div>

      <div style={{ height: "45vh", backgroundColor: "#F8F8F8" }}>
        <div
          style={{
            height: "64px",
          }}
        ></div>

      <TitleBar
        title={
          <span style={{ fontWeight: 'bold', textAlign: 'center', }}>
            Encuesta
          </span>
        }
      />

        <div style={{ width: 'auto' , marginLeft: 290, display: "flex" }}>
          <Card
            style={{ width: 'auto', marginTop: 11, borderRadius: 5 }}
            elevation={0}
          >
            <CardContent>
              <>
              <div style={{ width: 'auto', display: 'flex', gap: '15px' }}>
                <ListItemButton sx={{ width: '20%', pl: 2  }}>
                  <TextField
                      label="Codigo control"
                      value={codigoControl}
                      disabled={!datiRisposta}
                      onChange={(e) => setCodigoControl(e.target.value)}

                      id={datiRisposta !== null ? "outlined-error-helper-text" : "er campo"}

                      style={{ width: '100%' }}
                  />
                </ListItemButton>

                <ListItemButton sx={{ width: '30%' , pl: 2 }}> 
                  <TextField
                      label="Numero Orden"
                      value={numeroOrden}
                      disabled={!datiRisposta}
                      onChange={(e) => setNumeroOrden(e.target.value)}

                      id={datiRisposta !== null ? "outlined-error-helper-text" : "no"}

                      style={{ width: 'auto' }}

                  />
                </ListItemButton>

                <ListItemButton sx={{ width: '50%' , pl: 2 , marginLeft: '-20px' }}>
                  <TextField
                      label="Correo Electronico"
                      value={correoElettronico}
                      disabled={!datiRisposta}
                      onChange={(e) => setCorreoElettronico(e.target.value)}
                      

                      id={datiRisposta !== null ? "outlined-error-helper-text" : "puede"}

                      style={{ width: '100%', display: 'flex' }}
                  />
                </ListItemButton>
                </div>

                <div>
              <div>
              <Button component="label" variant="contained" startIcon={<SendIcon />}  className={classes.button}
               style={{ width: 'auto' , minWidth: '230px', marginTop: '10px' }}
               disabled={!(correoElettronico.trim() !== '') || !(codigoControl.trim() !== '') || !(numeroOrden.trim() !== '')}
               onClick={() => handleSendIne(true)}
              >
                Costruir y Enviar
                
              </Button>
              </div>
              </div>
               </>
            </CardContent>
          </Card>

          <Stepper sx={{ marginLeft: "40px", width: '40%' }} activeStep={currentStep - 1}>
      <Step
        onClick={() => handleStepChange(1)}
        style={{
          backgroundColor: currentStep === 1 ? 'rgb(3, 155, 229)' : '',
          color: currentStep === 1 ? 'white' : '',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        Extraccion de datos
        {currentStep === 1 && <ArrowUpwardIcon style={{ marginTop: '4px' }} />}
      </Step>
      <Step
        onClick={() => handleStepChange(2)}
        style={{
          backgroundColor: currentStep === 2 ? 'rgb(3, 155, 229)' : '',
          color: currentStep === 2 ? 'white' : '',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        Comprobar
        {currentStep === 2 && <ArrowUpwardIcon style={{ marginTop: '4px' }} />}
      </Step>
      <Step
        onClick={() => handleStepChange(3)}
        style={{
          backgroundColor: currentStep === 3 ? 'rgb(3, 155, 229)' : '',
          color: currentStep === 3 ? 'white' : '',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        Costruir y Enviar
        {currentStep === 3 && <ArrowUpwardIcon style={{ marginTop: '4px' }} />}
      </Step>
    </Stepper>

        </div>
      </div>
    </>
  );
}

export default FormIne;