import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ReactBigCalendar from "./Calendar/Calendar";

function BlockRoom() {
  return (
    <>
      <div style={{ height: "100vh", backgroundColor: "#F8F8F8" }}>
        <div
          style={{
            height: "64px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <div
          style={{
            backgroundColor: "rgb(3, 155, 229)",
            height: "60px",
            display: "flex",
            alignItems: "center",
            marginLeft: 270,
          }}
        >
          <Typography
            variant="h5"
            style={{
              color: "white",
              fontSize: 20,
              marginLeft: 40,
            }}
            gutterBottom
          >
            Chiusura
          </Typography>
        </div>
        <div
          style={{
            marginLeft: 290,
            display: "flex",
          }}
        >
          <Card
            style={{ minWidth: 250, marginTop: 11, borderRadius: 5 }}
            elevation={0}
          >
            <CardContent>
              <ReactBigCalendar />
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default BlockRoom;
