import React from "react";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

export default function TitleBar({ title = "", loading = false }) {
  return (
    <div
      style={{
        backgroundColor: "rgb(3, 155, 229)",
        height: "60px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginLeft: 270,
      }}
    >
      <div />
      <Typography
        variant="h5"
        style={{
          color: "white",
          fontSize: 20,
          marginLeft: 40,
          marginBottom: 0,
        }}
        gutterBottom
      >
        {title}
      </Typography>
      <LinearProgress style={{ visibility: loading ? "visible" : "hidden" }} />
    </div>
  );
}
