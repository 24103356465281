import React, { useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MuiAlert from '@mui/material/Alert';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardActions,
  CardContent,
  Box,
  Button,
  Typography,
  LinearProgress,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  TextField,
  Checkbox,
  Snackbar,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import config from "../config";
import { AuthenticationContext } from "../contexts";

function CityTax() {
  const [properties, setProperties] = React.useState([]);
  const [selectedProperty, setSelectedProperty] = React.useState("");
  const [reservationList, setReservationList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState(
    new Date().toISOString().split("T")[0]
  );
  const [isTodayChargeEnabled, setIsTodayChargeEnabled] = React.useState(false);
  const [isChargeEnabled, setIsChargeEnabled] = React.useState(false);
  const [open, setOpen] = React.useState([]);
  const [openButton, setOpenButton] = React.useState("ESPANDI");
  const [waitingConfirmation, setWaitingConfirmation] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
  const [dialogError, setDialogError] = React.useState({
    title: "Errori",
    open: false,
    errors: [],
  });
  const [init, setInit] = React.useState(false);
  const { id } = useParams();
  const { currentUser } = useContext(AuthenticationContext);

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(`${config.protelExpressUri}/api/findsProperty`, { hotelID: id })
      .then((res) => {
        let setup = res.data;
        let propertyCodes = [""];
        if (setup) {
          propertyCodes = setup.propertyConfiguration.map(
            (p) => p.propertyCode
          );
        }
        setProperties(["", ...propertyCodes]);
        setSelectedProperty("");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [id]);

  const fetchReservationList = async () => {
    setIsLoading(true);
    setOpenButton("ESPANDI");
    setReservationList([]);
    axios
      .get(
        `${
          config.requestsUri
        }/citytax?hotelID=${id}&from=${data}&to=${data}&propertyCode=${
          selectedProperty ?? ""
        }`
      )
      .then((res) => {
        const rl = res.data.data;
        const errori = rl.reduce((errs, res) => errs.concat(res.errori), []);
        if (errori.length > 0) {
          setDialogError({
            title: "Errori:",
            open: true,
            errors: errori,
          });
        } else {
          // aggiunge campo selected per ogni prenotazione
          rl.forEach((res) => (res.selected = false));
          setReservationList(rl);
          setOpen(new Array(rl.length).fill(false));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const selectedReservations = reservationList.filter(
      (reservation) => reservation.selected
    );
    setIsTodayChargeEnabled(
      selectedReservations.filter((reservation) =>
        reservation.ospiti.find((ospite) => ospite.nottiAddebitabiliAdOggi > 0)
      ).length
    );
    setIsChargeEnabled(selectedReservations.length);
  }, [reservationList]);

  // tutto = true se addebito intera prenotazione
  const handleAddebita = (tutto = false) => {
    let reservations = reservationList.filter((res) => res.selected);
    reservations = reservations.map((res) => {
      let imponibili = [];
      res.ospiti.forEach((ospite) => {
        if (!ospite.esente && ospite.nottiDaAddebitare) {
          const imponibile = {
            profileID: ospite.guestID,
            chargedNights: ospite.nottiAddebitate,
            nights: tutto
              ? ospite.nottiDaAddebitare
              : ospite.nottiAddebitabiliAdOggi,
            totValue: tutto
              ? ospite.totaleAddebitabile
              : ospite.totaleAddebitabileAdOggi,
          };

          if (imponibile.nights) {
            imponibili.push(imponibile);
          }
        }
      });
      return {
        rooms: res.roomIDs,
        firstName: res.ospiti[0].name,
        lastName: res.ospiti[0].surname,
        resNo: res.reservationID,
        profiles: imponibili,
      };
    });

    reservations = reservations.filter((res) => res.profiles.length > 0);

    if (reservations.length === 0) {
      setOpenSnackbar(true);
      return;
    }

    const reqObj = {
      correlationID: uuidv4(),
      hotelID: id,
      reservations,
    };
    setWaitingConfirmation(true);
    axios
      .post(
        `${config.fiscalizeITServer}/citytax/charge${init ? "?init=true" : ""}`,
        reqObj
      )
      .then((res) => {
        setWaitingConfirmation(false);

        const failedReservations = res.data.reservations.filter(
          (reservation) => !reservation.success
        );
        if (failedReservations.length === 0) {
          setOpenSnackbar(true);
          /* ricarica lista prenotazioni */
          fetchReservationList();
        } else {
          fetchReservationList();
          //setOpenSnackbarError(true);
          setDialogError({
            title: "Qualche addebito è fallito:",
            open: true,
            errors: failedReservations.map((res) => res.message),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setOpenSnackbarError(true);
        setWaitingConfirmation(false);
      });
  };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#1aa3ff",
          height: "60px",
          display: "flex",
          alignItems: "center",
          marginLeft: 250,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "white",
            fontSize: 20,
            marginLeft: 40,
          }}
          gutterBottom
        >
          Tassa di Soggiorno
        </Typography>
      </div>

      <div style={{ margin: "10px 10px 0px 290px" }}>
        <Card style={{ marginBottom: 10 }}>
          <CardContent style={{ display: "flex" }}>
            <CardActions>
              <FormControl>
                <InputLabel id="property-label">Property</InputLabel>
                <Select
                  labelId="property-label"
                  value={selectedProperty}
                  name="property"
                  onChange={(e) => {
                    setSelectedProperty(e.target.value);
                  }}
                  style={{ width: 150 }}
                >
                  {properties.map((prp) =>
                    prp === "" ? (
                      <MenuItem key={prp} value="">
                        TUTTE
                      </MenuItem>
                    ) : (
                      <MenuItem key={prp} value={prp}>
                        {prp}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <TextField
                id="standard-basic"
                label="Data Protel Air"
                value={data}
                type="date"
                format={"yyyy/MM/dd"}
                onChange={(e) => setData(e.target.value)}
                // disabled
              />

              <Button
                variant="contained"
                onClick={fetchReservationList}
                style={{
                  backgroundColor: "rgb(3, 155, 229)",
                  color: "white",
                  marginLeft: 8,
                }}
                elevation={0}
                disabled={isLoading || waitingConfirmation}
              >
                CERCA
              </Button>
              <Button
                variant="contained"
                onClick={() => handleAddebita()}
                style={{
                  backgroundColor:
                    isTodayChargeEnabled && !waitingConfirmation
                      ? "rgb(3, 155, 229)"
                      : "#e0e0e0",
                  color:
                    isTodayChargeEnabled && !waitingConfirmation
                      ? "white"
                      : "#b6b6b6",
                }}
                elevation={0}
                disabled={!isTodayChargeEnabled || waitingConfirmation}
              >
                ADDEBITA FINO AD OGGI
              </Button>
              <Button
                variant="contained"
                onClick={() => handleAddebita(true)}
                style={{
                  backgroundColor:
                    isChargeEnabled && !waitingConfirmation
                      ? "rgb(3, 155, 229)"
                      : "#e0e0e0",
                  color:
                    isChargeEnabled && !waitingConfirmation
                      ? "white"
                      : "#b6b6b6",
                }}
                elevation={0}
                disabled={!isChargeEnabled || waitingConfirmation}
              >
                ADDEBITA TUTTO
              </Button>
              {(currentUser.roles.includes("ADMIN") ||
                currentUser.roles.includes("SUPPORT")) && (
                <>
                  <Checkbox
                    onChange={(e) => {
                      setInit(e.target.checked);
                    }}
                  />
                  <p style={{ marginLeft: 0 }}>Inizializzazione</p>
                </>
              )}
              {waitingConfirmation && <CircularProgress />}
            </CardActions>
          </CardContent>
        </Card>
        <TableContainer component={Paper}>
          {isLoading && <LinearProgress />}
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  {reservationList.length > 0 && (
                    <Checkbox
                      onChange={(e) =>
                        setReservationList(
                          reservationList.map((reservation) => {
                            // non selezionare se gli addebiti della prenotazione sono completati
                            if (reservation.completata || !reservation.inCasa)
                              return reservation;
                            return {
                              ...reservation,
                              selected: e.target.checked,
                            };
                          })
                        )
                      }
                    />
                  )}
                </TableCell>
                <TableCell padding="checkbox">
                  <Button
                    onClick={() => {
                      if (openButton === "ESPANDI") {
                        setOpen(new Array(open.length).fill(true));
                        setOpenButton("RIDUCI");
                      } else {
                        setOpen(new Array(open.length).fill(false));
                        setOpenButton("ESPANDI");
                      }
                    }}
                    elevation={0}
                    disabled={reservationList.length === 0 || isLoading}
                    size="small"
                  >
                    {openButton}
                  </Button>
                </TableCell>
                <TableCell>ID</TableCell>
                <TableCell align="left">Ospite</TableCell>
                <TableCell align="left">Camera</TableCell>
                <TableCell align="left">Stato</TableCell>
                <TableCell align="left">Imponibili</TableCell>
                <TableCell align="left">Esenti</TableCell>
                <TableCell align="left">Arrivo</TableCell>
                <TableCell align="left">Partenza</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservationList.map((row, index) => (
                <Row
                  key={row.reservationID}
                  row={row}
                  handleSelection={() =>
                    setReservationList(
                      reservationList.map((reservation) => {
                        if (reservation.reservationID === row.reservationID) {
                          return {
                            ...reservation,
                            selected: !reservation.selected,
                          };
                        } else return reservation;
                      })
                    )
                  }
                  handleOpen={() => {
                    const openUpd = [...open];
                    openUpd[index] = !open[index];
                    setOpen(openUpd);
                  }}
                  open={open[index]}
                  disabled={row.completata}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Snackbar
          open={openSnackbar}
          autoHideDuration="6000"
          onClose={() => setOpenSnackbar(false)}
        >
          <div><Alert severity="success">Operazione effettuata</Alert></div>
        </Snackbar>
        <Snackbar
          open={openSnackbarError}
          autoHideDuration="6000"
          onClose={() => {
            setOpenSnackbarError(false);
          }}
        >
          <div><Alert severity="error">Operazione fallita</Alert></div>
        </Snackbar>
        <Dialog
          open={dialogError.open}
          onClose={() =>
            setDialogError({ open: false, errors: [], title: "Errori" })
          }
        >
          <DialogTitle style={{ paddingBottom: 0 }}>
            {dialogError.title}
          </DialogTitle>
          <DialogContent>
            {dialogError.errors.map((error, i) => (
              <p key={i}>{error}</p>
            ))}
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Row = ({ row, handleSelection, handleOpen, open, disabled }) => {
  //const [open, setOpen] = React.useState(false);
  const dataArrivo = new Date(row.arrivo).toLocaleDateString(undefined, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const dataPartenza = new Date(row.partenza).toLocaleDateString(undefined, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return (
    <>
      <TableRow style={{ opacity: disabled ? 0.5 : 1 }}>
        <TableCell padding="checkbox">
          {!disabled && row.inCasa && (
            <Checkbox checked={row.selected} onChange={handleSelection} />
          )}
        </TableCell>
        <TableCell padding="checkbox" align="center">
          <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.reservationID}
        </TableCell>
        <TableCell align="left">
          {row.ospiti[0]?.surname} {row.ospiti[0]?.name}
        </TableCell>
        <TableCell align="left">{row.roomIDs.join('/')}</TableCell>
        <TableCell align="left">{row.inCasa ? "In Casa" : "Partito"}</TableCell>
        <TableCell align="left">
          {row.ospiti.length - row.numeroEsenti}
        </TableCell>
        <TableCell align="left">{row.numeroEsenti}</TableCell>
        <TableCell align="left">{dataArrivo}</TableCell>
        <TableCell align="left">{dataPartenza}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#d7d7d7",
          }}
          colSpan={10}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            style={{ opacity: disabled ? 0.65 : 1 }}
          >
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Ospite</TableCell>
                    <TableCell>Età</TableCell>
                    <TableCell>Residenza</TableCell>
                    <TableCell>Esenzione</TableCell>
                    <TableCell>Riduzione</TableCell>
                    <TableCell>Notti</TableCell>
                    <TableCell>Notti Addebitate</TableCell>
                    <TableCell>Notti Da Addebitare</TableCell>
                    <TableCell>Totale (€)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.ospiti.map((ospite) => (
                    <TableRow
                      key={ospite.guestID}
                      style={{ opacity: ospite.nottiDaAddebitare ? 1 : 0.65 }}
                    >
                      <TableCell component="th" scope="row">
                        {ospite.surname} {ospite.name} (#{ospite.guestID})
                      </TableCell>
                      <TableCell>{ospite.age}</TableCell>
                      <TableCell>{ospite.cityName}</TableCell>
                      <TableCell>{ospite.motivoEsenzione}</TableCell>
                      <TableCell>{ospite.motivoRiduzione}</TableCell>
                      <TableCell>{ospite.notti}</TableCell>
                      <TableCell>{ospite.nottiAddebitate}</TableCell>
                      <TableCell>{ospite.nottiDaAddebitare}</TableCell>
                      <TableCell>{ospite.totale}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow key={`${row.reservationID} - tot`}>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      <b>{row.totale}</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CityTax;
