import React, { useContext, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import makeStyles from '@mui/styles/makeStyles';
import config from '../../config'
import { AuthenticationContext } from '../../contexts';
import { Link as RouterLink } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import LocationCityIcon from '@mui/icons-material/LocationCity';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function HotelSelection() {
  const [hotelData, setHotelData] = React.useState({});
  const { currentUser } = useContext(AuthenticationContext);
  const { logout } = useContext(AuthenticationContext);
  const company = currentUser.company;
  const hotel = currentUser.hotel;

  const handleLogOut = () => {
    const { redirectUri, logoutUri } = config.authentication;
    logout();
    window.location = `${logoutUri}/?redirectTo=${redirectUri}`;
  };

  useEffect(() => {
    var data = JSON.stringify({ "companyId": company, "hotelID": hotel });
    var config1 = {
      method: 'post',
      url: `${config.protelExpressUri}/api/find/raccordo`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };
    axios(config1)
      .then(function (response) {
        setHotelData(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [company, hotel])

  const classes = useStyles();

  return (
    <div style={{ height: '100vh', backgroundColor: '#737373' }}>
      <div style={{ border: '1px solid rgba(0, 0, 0, 0.05)', position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)', minWidth: 400
      }}>
        <Card style={{ minHeight: 240, backgroundColor: '#f2f2f2' }} boxShadow={3}>
          <div style={{
            backgroundColor: 'white', minHeight: 60, alignItems: 'center', color: 'rgba(0, 0, 0, 0.87)', display: 'flex', fontFamily: 'Muli, "Helvetica Neue", Arial, sans-serif', fontSize: '18px', letterSpacing: '-0.1px', lineHeight: '23.4px', paddingLeft: '24px',
          }} >
            Please select an Hotel
          </div>
          <div style={{ justifyContent: "center" }} >
            <div borderRadius style={{ backgroundColor: 'white', marginTop: 4, marginRight: 4, marginLeft: 4, minHeight: 105, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FormControl style={{ minWidth: 260, marginRight: 25 }} className={classes.formControl}>
                <InputLabel id="demo-simple-select-filled-label">Seleziona Hotel</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  onChange={{}}>
                  <MenuItem value="">
                    <em></em>
                  </MenuItem>
                  <div>
                    <MenuItem component={RouterLink}
                      to={`/Home/${hotelData.hotelID}`} className={classes.nestedHotel} style={{ fontSize: '14px', color: 'gray' }}>{hotelData.hotelName}
                      <LocationCityIcon />
                    </MenuItem>
                  </div>
                </Select>
              </FormControl>
            </div>
            <CardActions>
            </CardActions>
          </div>
          <Button onClick={handleLogOut} variant="contained" style={{ marginLeft: 290, boxShadow: 'none', fontSize: '12.5px' }}>
            LOGOUT
          </Button>
        </Card>
      </div>
    </div >
  );
}

export default HotelSelection;
