import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
//eslint-disable-next-line
import moment_timezone from "moment-timezone"
import axios from 'axios';
import { useParams } from "react-router-dom";
import config from '../../../config'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import "react-big-calendar/lib/css/react-big-calendar.css";

moment.locale("en-GB");
moment.tz.setDefault("UTC")
const localizer = momentLocalizer(moment);

export default function ReactBigCalendar() {
    const [eventsData, setEventsData] = useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const hotel = useParams();

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDeleteDialog = ({ start, end }) => {
        setSelectedEvent({ start, end });
        setOpenDialog(true);
    };

    var newArray = Array.prototype.concat.apply([], eventsData);

    const handleDeleteEvent = () => {
        var configD = {
            method: 'delete',
            url: `${config.protelExpressUri}/api/deleteClosingDays`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                "hotelID": hotel.id,
                "start": selectedEvent.start,
                "end": selectedEvent.end
            }
        };

        axios(configD)
            .then(function (response) {
                console.log(response.data);
                handleCloseDialog()
                window.location.reload();
            })
            .catch(function (error) {
                console.log(error)
                handleCloseDialog()
            });
    }

    const handleSelect = (slotInfo) => {

        console.log(JSON.stringify(slotInfo, null, 2))
        const slots = slotInfo.slots;
        // get start and end date strings from slots
        const start = slots[0];
        let end = moment(slots[slots.length - 1]).add(1, 'days').toDate();

        const title = 'Chiusura';

        if (title)
            setEventsData([
                ...newArray,
                {
                    start,
                    end,
                    title
                }
            ]);

        var dataC = JSON.stringify({
            "hotelID": hotel.id,
            "propertyConfiguration": [],
            "setup": [],
            "closingDays": [
                {
                    "start": start,
                    "end": end,
                    "title": "Chiusura"
                }
            ]
        });

        var configC = {
            method: 'put',
            url: `${config.protelExpressUri}/api/insertClosingDays`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: dataC
        };

        axios(configC)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                window.location.reload()
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    useEffect(() => {
        var dataP = JSON.stringify({ "hotelID": hotel.id });

        var configPr = {
            method: 'post',
            url: `${config.protelExpressUri}/api/getChiusure`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: dataP
        };
        async function getDataP() {
            const result = await axios(configPr)
            setEventsData(eventsData => [...eventsData, result.data]);
        }
        getDataP()
    }, [hotel.id])

    return (
        <div className="App">
            <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Cancellare la chiusura?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDialog} style={{ color: '#1aa3ff' }}>
                        Annulla
                    </Button>
                    <Button onClick={handleDeleteEvent} style={{ color: '#1aa3ff' }}>
                        Cancella
                    </Button>
                </DialogActions>
            </Dialog>

            <Calendar
                views={["month"]}
                selectable
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="month"
                events={newArray}
                style={{ height: "70vh", width: "150vh", maxWidth: "100%" }}
                onSelectEvent={handleOpenDeleteDialog}
                onSelectSlot={handleSelect}
            />
        </div>
    );
}
