import React, { useState, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link as RouterLink } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import config from '../../config'
import { AuthenticationContext } from '../../contexts';
import Divider from '@mui/material/Divider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Typography from '@mui/material/Typography';
import logo from '../img/logo.png'
import DehazeIcon from '@mui/icons-material/Dehaze';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DomainIcon from '@mui/icons-material/Domain';
import LocationCityIcon from '@mui/icons-material/LocationCity';

const drawerWidth = 270;

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  title: { flexGrow: 1 },
  list: { width: 270 },
  nested: { paddingLeft: theme.spacing.unit * 9, },
  nestedCompany: { paddingLeft: theme.spacing.unit * 6 },
  nestedHotel: { paddingLeft: theme.spacing.unit * 20 },
  drawer: {
    height: 1,
    width: drawerWidth,
    flexShrink: 0,
    elevation: 24
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundImage: `linear-gradient(#cfd9df,#e2ebf0)`,
    color: '#F8F8F8',
    elevation: 24
  },
  drawerContainer: {
    overflow: 'auto',
    elevation: 24
  },

  paper: {
    height: 'calc(100% - 0px)',
    top: 0,
    background: "#F8F8F8",
    elevation: 9
  },
  listItemText: {
    fontSize: '10px',
  },
}));

const SideBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentUser } = useContext(AuthenticationContext);
  const { logout } = useContext(AuthenticationContext);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleLogOut = () => {
    const { redirectUri, logoutUri } = config.authentication;
    logout();
    window.location = `${logoutUri}/?redirectTo=${redirectUri}`;
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Drawer
        anchor="left"
        variant="permanent"
        classes={{ paper: classes.paper }}
        style={{ background: '#EBEBEB', zIndex: 1251 }}
        position="fixed"
        PaperProps={{ elevation: 3 }}
      >
        <div className={classes.list}>
          <List className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}>
            <ListItem style={{ height: '56px', }}>
              <img src={logo} style={{
                height: '50px',
                width: '110px'
              }} />

              <DehazeIcon style={{ marginLeft: 100 }} />
            </ListItem>
            <Divider />

            <ListItem
              button  //button
              component={RouterLink}
              to="/FormUtenti"
              style={{ marginRight: 50 }}
            >
              <ListItemIcon>
                <EmojiPeopleIcon style={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={<Typography type="body2" style={{ fontSize: 15 }}>Gestione Utenti</Typography>}
              />

            </ListItem>
            
            {currentUser.roles.includes('ADMIN') && 
              <>
                <ListItem
                  button  //button
                  component={RouterLink}
                  to="/FormPartner"
                  style={{ marginRight: 50 }}
                >
                  <ListItemIcon>
                    <DomainIcon style={{ fontSize: '20px' }} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={<Typography type="body2" style={{ fontSize: 15 }}>Partner</Typography>}
                  />
                </ListItem>

                <ListItem
                  button  //button
                  component={RouterLink}
                  to="/FormCompany"
                  style={{ marginRight: 50 }}
                >
                  <ListItemIcon>
                    <ApartmentIcon style={{ fontSize: '20px' }} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={<Typography type="body2" style={{ fontSize: 15 }}>Company</Typography>}
                  />
                </ListItem>

                <ListItem
                  button //button
                  component={RouterLink}
                  to="/FormHotel"
                  style={{ marginRight: 50 }}
                >
                  <ListItemIcon>
                    <LocationCityIcon style={{ fontSize: '20px' }} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={<Typography type="body2" style={{ fontSize: 15 }}>Hotel</Typography>}
                  />
                </ListItem>
                
                <ListItem
                  button //button
                  component={RouterLink}
                  to="/HotelStatus"
                  style={{ marginRight: 50 }}
                >
                  <ListItemIcon>
                    <LocationCityIcon style={{ fontSize: '20px' }} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={<Typography type="body2" style={{ fontSize: 15 }}>Stato Hotel</Typography>}
                  />
                </ListItem>
              </>
            }
          </List>
        </div>


        <List style={{ marginTop: `auto` }} PaperProps={{ elevation: 20 }}>
          <Divider />
          <ListItem button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={<Typography type="body2" style={{ fontSize: 15 }}>{currentUser.displayName}</Typography>}></ListItemText><KeyboardArrowDownIcon />
          </ListItem>
          <Menu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem style={{ backgroundColor: 'white' }}>
              <ListItem button onClick={handleLogOut}>
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" style={{ color: 'rgba(0, 0, 0, 0.87)' }} />
                </ListItemIcon>
                <ListItemText primary="Logout" style={{ color: 'rgba(0, 0, 0, 0.87)' }} />
              </ListItem>
            </MenuItem>
          </Menu>
        </List>
      </Drawer >
    </div >
  );
};

export default SideBar;
