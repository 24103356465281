import React, { useEffect, useContext } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  Card,
  CardActions,
  CardContent,
  NativeSelect,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import MuiAlert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'
import makeStyles from '@mui/styles/makeStyles';

import { AuthenticationContext } from '../../contexts'
import config from '../../config'
import { useForm } from '../../utils/hooks';

function FormUser() {

  const [raccordo, setRaccordo] = React.useState([]);
  const [users, setUsers] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [row, setRow] = React.useState({});
  const [rowId, setRowId] = React.useState({});
  

  const [userUpdateForm, setUserUpdateForm] = useForm({})

  const [newUserForm, setNewUserForm] = useForm({})

  const { currentUser } = useContext(AuthenticationContext);

  const isPasswordValid = (password) => {
    const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.-])(?=.{8,})/
    return passwordRegEx.test(password)
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '17ch',
      },
    },
    form: {
      position: 'absolute',
      left: '50%',
      top: '25%',
      transform: 'translate(-50%, -50%)'
    }
  }));

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleCloseDelete = () => {
    setOpenDialogDelete(false);
  };

  const handleDelete = () => {

    var dataD = JSON.stringify({
      "_id": rowId
    });

    var configD = {
      method: 'delete',
      url: `${config.protelUserUri}/users/delete`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataD
    };

    axios(configD)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setOpenDialogDelete(false);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleOpenDelete = (row) => {
    setOpenDialogDelete(true);
    setRow(row)
    setRowId(row)
  }

  const handleSubmit = () => {
    
    if (
      !newUserForm.name ||
      !newUserForm.surname ||
      !newUserForm.displayName ||
      !newUserForm.email ||
      !newUserForm.password ||
      !newUserForm.passwordConfirmation ||
      newUserForm.password !== newUserForm.passwordConfirmation ||
      !isPasswordValid(newUserForm.password)
    ) {
      handleOpenError();
      return;
    }

    var data1 = JSON.stringify(newUserForm);

    var config1 = {
      method: 'post',
      url: `${config.protelUserUri}/users`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data1
    };

    axios(config1)
      .then(function (response) {
        console.log(response.data);
        handleOpenSuccess()
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        handleOpenError()
      });
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //snackbar success
  const handleOpenSuccess = () => {
    setOpen(true);
  };

  const handleOpenError = () => {
    setOpenError(true);
  };

  const handleUpdUser = (row, ids) => {
    setRow(row)
    setRowId(ids)
    setUserUpdateForm(null, row/*{...row, "role": row.roles[0]}*/)
    console.log(row)
    setOpenDialog(true)
  }

  const getRaccordo = async () => {
    try {
      const userRaccordo = await axios.get(`${config.protelExpressUri}/api/raccordo`)
      setRaccordo(userRaccordo.data);
      //console.log(JSON.stringify(userRaccordo.data))
    } catch (err) {
      console.error(err.message);
    }
  };
  //alert(JSON.stringify(raccordo))
  const getUsers = async () => {
    try {
      const users = await axios.get(`${config.protelUserUri}/users/findUsers`)
      setUsers(users.data);

      //console.log(JSON.stringify(userRaccordo.data))
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  const handleSubmitUpdUser = () => {
    if (!userUpdateForm.name || !userUpdateForm.surname || !userUpdateForm.displayName || !userUpdateForm.email) {
      handleOpenError()
      return;
    }

    var data1 = JSON.stringify(userUpdateForm);
    //alert(data1)
    var config1 = {
      method: 'put',
      url: `${config.protelUserUri}/users/updateUser`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data1
    };

    axios(config1)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setOpenDialog(false);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        handleOpenError()
      });

  }

  useEffect(() => {
    getRaccordo()
    getUsers()
  }, [])

  const classes = useStyles();

  return (
    <div style={{ height: '100vh', backgroundColor: '#F8F8F8' }}>
      <div style={{ height: '64px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      </div>
      <div style={{ backgroundColor: "#1aa3ff", height: '60px', display: 'flex', /*justifyContent: 'center',*/ alignItems: 'center', marginLeft: 270 }}>
        <Typography variant="body2" style={{
          color: "white", //position: 'absolute',
          //left: '20%',
          fontSize: 20,
          marginLeft: 40
        }} gutterBottom>Gestione Utenti</Typography>
      </div>
      <div onSubmit={handleSubmit} style={{
        marginLeft: 290,
        display: 'flex'
      }}>

        <Dialog open={openDialogDelete} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Cancellare utente: {row.name} {row.surname}?</DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDelete} style={{ color: '#1aa3ff' }}>
              Annulla
            </Button>
            <Button onClick={handleDelete} style={{ color: '#1aa3ff' }}>
              Cancella
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Modifica utente: {row.name} {row.surname}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Nome"
              fullWidth
              name="name"
              value={userUpdateForm.name}
              error={!userUpdateForm.name}
              onChange={setUserUpdateForm}
              required
            />
            <TextField
              autoFocus
              margin="dense"
              label="Cognome"
              fullWidth
              name="surname"
              value={userUpdateForm.surname}
              error={!userUpdateForm.surname}
              onChange={setUserUpdateForm}
              required
            />
            <TextField
              autoFocus
              margin="dense"
              label="Nome Completo"
              fullWidth
              name="displayName"
              value={userUpdateForm.displayName}
              error={!userUpdateForm.displayName}
              onChange={setUserUpdateForm}
              required
            />
            <TextField
              autoFocus
              margin="dense"
              label="Email"
              fullWidth
              name="email"
              value={userUpdateForm.email}
              error={!userUpdateForm.email}
              onChange={setUserUpdateForm}
              required
            />
            <FormControl fullWidth margin="dense">
                <InputLabel id="ruoli-label">Ruoli</InputLabel>
                <Select
                  labelId="ruoli-label"
                  multiple
                  value={userUpdateForm.roles ?? []}
                  name="roles"
                  onChange={(e) => setUserUpdateForm(e, undefined)}
                  style={{ minWidth: 150 }}
                >
                  <MenuItem key="ADMIN" value="ADMIN" disabled={!currentUser.roles.includes('ADMIN')}>
                    ADMIN
                  </MenuItem>
                  <MenuItem key="SUPPORT" value="SUPPORT" disabled={!currentUser.roles.includes('ADMIN') && userUpdateForm.role === 'ADMIN'}>
                    SUPPORT
                  </MenuItem>
                  <MenuItem key="CUSTOMER" value="CUSTOMER" disabled={!currentUser.roles.includes('ADMIN') && userUpdateForm.role === 'ADMIN'}>
                    CUSTOMER
                  </MenuItem>
                  <MenuItem key="CUSTOMER_ADMIN" value="CUSTOMER_ADMIN" disabled={!currentUser.roles.includes('ADMIN') && userUpdateForm.role === 'ADMIN'}>
                    CUSTOMER_ADMIN
                  </MenuItem>
                </Select>
            </FormControl>

            <FormControl fullWidth margin='dense'>
              <InputLabel htmlFor="outlined-age-native-simple">Partner</InputLabel>
              <NativeSelect
                name="partner"
                value={userUpdateForm.partner}
                onChange={setUserUpdateForm}
              >
                <option>
                </option>
                {raccordo.map((value) => <option value={value._id} key={value._id}>{value.partner.partnerName}</option>)}
              </NativeSelect>
            </FormControl>
            <FormControl fullWidth margin='dense'>
              <InputLabel htmlFor="outlined-age-native-simple">Company</InputLabel>
              <NativeSelect
                name="company"
                value={userUpdateForm.company}
                onChange={setUserUpdateForm}
              >
                <option>
                </option>
                {raccordo.map((value) => {
                  return value.partner.companies.map((value) => <option value={value._id} key={value._id}>{value.companyName}</option>
                  )
                }
                )}
              </NativeSelect>
            </FormControl>
            <FormControl fullWidth margin='dense'>
              <InputLabel htmlFor="outlined-age-native-simple">Hotel</InputLabel>
              <NativeSelect
                name="hotel"
                onChange={setUserUpdateForm}
                value={userUpdateForm.hotel}>
                <option>
                </option>
                {raccordo.map((value) => {
                  return value.partner.companies.map((value) => { return value.hotels.map((value, index) => <option key={index} value={value.hotelID}>{value.hotelName}</option>) })
                })}
              </NativeSelect>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} style={{ color: '#1aa3ff' }}>
              Annulla
            </Button>
            <Button onClick={handleSubmitUpdUser} style={{ color: '#1aa3ff' }}>
              Conferma
            </Button>
          </DialogActions>
        </Dialog>


        <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSuccess}>
          <div><Alert severity="success">SUCCESSO!</Alert></div>
        </Snackbar>

        <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
          <div><Alert severity="error">Ci sono Errori, controlla i dati</Alert></div>
        </Snackbar>

        <Card style={{ /*marginLeft: 290,*/ minWidth: 250, marginTop: 11, marginRight: 11, borderRadius: 5 }} elevation={0}>
          <CardContent>
            <Typography style={{ fontSize: '16px', color: '#1aa3ff' }} variant="h5" gutterBottom>Inserisci nuovo utente</Typography>
            <form className={classes.root} onSubmit={handleSubmit} >
              <TextField id="standard-basic" label="Nome" name="name" value={newUserForm.name} onChange={setNewUserForm} />
              <TextField id="standard-basic" label="Cognome" name="surname" value={newUserForm.surname} onChange={setNewUserForm} />
              <TextField id="standard-basic" label="Nome Completo" name="displayName" value={newUserForm.displayName} onChange={setNewUserForm} />
              <TextField id="standard-basic" label="Email" name="email" value={newUserForm.email} onChange={setNewUserForm} />
              <TextField
                label="Password"
                type="password"
                name="password"
                value={newUserForm.password}
                onChange={setNewUserForm}
                error={newUserForm.password && !isPasswordValid(newUserForm.password)}
              />
              <TextField id="standard-basic" label="Conferma password" type="password" name="passwordConfirmation" value={newUserForm.passwordConfirmation} onChange={setNewUserForm} />
              <FormControl>
                <InputLabel id="ruoli-label">Ruoli</InputLabel>
                <Select
                  labelId="ruoli-label"
                  multiple
                  value={newUserForm.role ?? []}
                  name="role"
                  onChange={(e) => setNewUserForm(e, undefined)}
                  style={{ width: 150 }}
                >
                  <MenuItem key="ADMIN" value="ADMIN" disabled={!currentUser.roles.includes('ADMIN')}>
                    ADMIN
                  </MenuItem>
                  <MenuItem key="SUPPORT" value="SUPPORT">
                    SUPPORT
                  </MenuItem>
                  <MenuItem key="CUSTOMER" value="CUSTOMER">
                    CUSTOMER
                  </MenuItem>
                  <MenuItem key="CUSTOMER_ADMIN" value="CUSTOMER_ADMIN">
                    CUSTOMER_ADMIN
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl} margin='dense'>
                <InputLabel id="partner-label">Partner</InputLabel>
                <NativeSelect
                  labelId="partner-label"
                  name="partner"
                  value={newUserForm.partner}
                  onChange={setNewUserForm}>
                  <option></option>
                  {raccordo.map((value) =>
                    <option value={value._id} key={value._id}>{value.partner.partnerName}</option>
                  )}
                </NativeSelect>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl} margin='dense'>
                <InputLabel htmlFor="outlined-age-native-simple">Company</InputLabel>
                <NativeSelect
                  name="company"
                  value={newUserForm.company}
                  onChange={setNewUserForm}>
                  <option>
                  </option>
                  {raccordo.map((value) => {
                    return value.partner.companies.map((value) => <option value={value._id} key={value._id}>{value.companyName}</option>
                    )
                  }
                  )}
                </NativeSelect>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl} margin='dense'>
                <InputLabel htmlFor="outlined-age-native-simple">Hotel</InputLabel>
                <NativeSelect
                  name="hotel"
                  value={newUserForm.hotel}
                  onChange={setNewUserForm}>
                  <option>
                  </option>
                  {raccordo.map((value) => {
                    return value.partner.companies.map((value) => { return value.hotels.map((value, index) => <option value={value.hotelID} key={index}>{value.hotelName}</option>) })
                  })}
                </NativeSelect>
              </FormControl>
              <CardActions>
                <Button variant="contained" onClick={handleSubmit} style={{ backgroundColor: '#1aa3ff', color: "white" }}>Inserisci</Button>
              </CardActions>
            </form>
          </CardContent>
        </Card>
      </div>

      <br></br>
      <div style={{ marginLeft: 290, marginTop: 11 }}>
        <Card style={{ borderRadius: 5 }} elevation={0}>
          <CardContent>
            <Typography style={{ fontSize: '16px', color: '#1aa3ff' }} variant="h5" gutterBottom>Lista Utenti</Typography>
            <TableContainer >
              <Table className={classes.table} size="small" aria-label="a dense table" >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" variant="h5" width="10%">Email</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Nome</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Cognome</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Ruoli</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Partner</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Company</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Hotel</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Operazioni</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users !== [] && users !== [""] && users &&
                    users.map((row) =>
                      <TableRow key={row._id}>
                          <TableCell component="th" scope="row">{row.email}</TableCell>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="left">{row.surname}</TableCell>
                          <TableCell align="left">{row.roles.join(' - ')}</TableCell>
                          <TableCell align="left">
                            {
                              raccordo.find((value) =>
                                value._id == row.partner
                              ) ? raccordo.find((value) =>
                                value._id == row.partner
                              ).partner.partnerName : null

                            }
                          </TableCell>
                          <TableCell align="left">
                            {
                              raccordo.map(value => {
                                  return value.partner.companies.find(value => value._id == row.company)?.companyName
                              })
                            }
                          </TableCell>
                          <TableCell align="left">
                            {
                              raccordo.map(value => {
                                const company = value.partner.companies.find(value => value._id == row.company)
                                if (company) {
                                  return company.hotels.find(value => value.hotelID == row.hotel)?.hotelName
                                }
                              })                       
                            }
                          </TableCell>
                          <TableCell align="left">
                            {(currentUser.roles.includes("ADMIN") || !row.roles.includes("ADMIN")) &&
                              <>
                                <EditIcon style={{ fontSize: '15px', fill: "black" }} button onClick={() => handleUpdUser(row, row._id)} />
                                <DeleteIcon style={{ fontSize: '15px', fill: "black" }} button onClick={() => handleOpenDelete(row, row._id)} />
                              </>
                            }
                          </TableCell>
                        </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </div>
    </div >
  );
}

export default FormUser;
