import React, { useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import config from '../../config'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete'

function FormPartner() {
  const [nazione, setNazione] = React.useState("");
  const [nome, setNome] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [raccordo, setRaccordo] = React.useState([]);
  const [openError, setOpenError] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [id, setId] = React.useState();
  const [rowName, setRowName] = React.useState();
  const [row, setRow] = React.useState();

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '14ch',
      },
    },
  }));

  const handleUpdPartner = (row, value, ro) => {

    setRow(ro)
    setRowName(value)
    setId(row)
    setOpenDialog(true)
  }

  //dialog
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleCloseDelete = () => {
    setOpenDialogDelete(false);
  };

  const handleDelete = () => {

    var dataD = JSON.stringify({
      "_id": id
    });

    var configD = {
      method: 'delete',
      url: `${config.protelExpressUri}/api/deletePartner`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataD
    };

    axios(configD)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setOpenDialogDelete(false);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });

  };
  const handleOpenDelete = (value) => {
    setOpenDialogDelete(true);
    setId(value)
  }
  //

  const handleSubmit = (event) => {
    event.preventDefault();

    const partnerData = {
      "nazione": nazione,
      "name": nome,
    };
    //alert(JSON.stringify(partnerData))
    axios
      .post(`${config.protelExpressUri}/api/partners`, partnerData)
      .then(res => {
        console.log(res)

      })
      .catch(err => {
        console.log(err);
        console.log(err.response)
      })

    const partnerRaccordoData = {
      partner: {
        "partnerName": nome,
      }
    };
    //alert(JSON.stringify(partnerRaccordoData))
    axios
      .post(`${config.protelExpressUri}/api/raccordo`, partnerRaccordoData)
      .then(res => {
        console.log(res)
        handleOpenSuccess()
        window.location.reload()
      })
      .catch(err => {
        console.log(err);
        console.log(err.response)
        handleOpenError()
      })
  }

  const handleCloseSuccess = (òevent, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


  const handleUpdPartners = () => {
    var dataUD = JSON.stringify({
      "_id": id,
      "partnerName": rowName
    });

    var configUD = {
      method: 'put',
      url: `${config.protelExpressUri}/api/updPartner`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataUD
    };

    axios(configUD)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setOpenDialog(false)
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //snackbar success
  const handleOpenSuccess = () => {
    setOpen(true);
  };

  const handleOpenError = () => {
    setOpenError(true);
  };

  const getRaccordo = async () => {
    try {
      const userRaccordo = await axios.get(`${config.protelExpressUri}/api/raccordo`)
      setRaccordo(userRaccordo.data);
      //console.log(JSON.stringify(userRaccordo.data))
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getRaccordo()

  }, [])

  const classes = useStyles();

  return (<div style={{ height: '100vh', backgroundColor: '#F8F8F8' }}>
    <div style={{ height: '64px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
    <div style={{ backgroundColor: "#1aa3ff", height: '60px', display: 'flex', /*justifyContent: 'center',*/ alignItems: 'center', marginLeft: 270 }} >
      <Typography variant="h5" style={{
        color: "white", //position: 'absolute',
        //left: '20%',
        fontSize: 20,
        marginLeft: 40
      }} gutterBottom>Gestione Partner</Typography>
    </div>
    <div onSubmit={handleSubmit} style={{
      marginLeft: 290,
      display: 'flex'
    }}>



      <Dialog open={openDialogDelete} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Cancellare Partner: {row ? row.partner.partnerName : ''}?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDelete} style={{ color: '#1aa3ff' }}>
            Annulla
          </Button>
          <Button onClick={handleDelete} style={{ color: '#1aa3ff' }}>
            Cancella
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Modifica Partner: {row ? row.partner.partnerName : ''}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome"
            type="Nome"
            fullWidth
            defaultValue={row ? row.partner.partnerName : ''}
            onChange={e => setRowName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: '#1aa3ff' }}>
            Annulla
          </Button>
          <Button onClick={handleUpdPartners} style={{ color: '#1aa3ff' }}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSuccess}>
        <div><Alert severity="success">SUCCESS!</Alert></div>
      </Snackbar>

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
        <div><Alert severity="error">Errore</Alert></div>
      </Snackbar>

      <Card style={{ /*marginLeft: 290,*/ minWidth: 250, marginTop: 11, marginRight: 11, borderRadius: 5 }} elevation={0}>
        <CardContent>
          <Typography style={{ fontSize: '16px', color: '#1aa3ff' }} variant="h5" gutterBottom>Inserisci Partner</Typography>
          <form className={classes.root} onSubmit={handleSubmit}>
            <TextField id="standard-basic" label="Nazione" value={nazione} onChange={e => setNazione(e.target.value)} />
            <TextField id="standard-basic" label="Nome" value={nome} onChange={e => setNome(e.target.value)} />
            <CardActions>
              <Button variant="contained" onClick={handleSubmit} style={{ backgroundColor: '#1aa3ff', color: "white" }}>Inserisci</Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>

    <br></br>
    <div style={{ marginLeft: 290, marginTop: 11 }}>
      <Card style={{ /*marginLeft: 290,*/ minWidth: 250, marginTop: 11, marginRight: 11, borderRadius: 5 }} elevation={0}>
        <CardContent>
          <Typography style={{ fontSize: '16px', color: '#1aa3ff' }} variant="h5" gutterBottom>Lista Partner</Typography>
          <TableContainer >
            <Table className={classes.table} size="small" aria-label="a dense table" >
              <TableHead>
                <TableRow>
                  <TableCell align="left" variant="h5" width="10%">Nome</TableCell>
                  <TableCell align="left" variant="h5" width="10%">Nazione</TableCell>
                  <TableCell align="left" variant="h5" width="10%">Operazioni</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {raccordo !== [] && raccordo !== [""] && raccordo &&
                  raccordo.map((row) =>
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">{row.partner.partnerName}</TableCell>
                      <TableCell align="left">{row.surname}</TableCell>
                      <TableCell align="left"><EditIcon style={{ fontSize: '15px', fill: "black" }} button onClick={() => handleUpdPartner(row._id, row.partnerName, row)} /><DeleteIcon style={{ fontSize: '15px', fill: "black" }} button onClick={() => handleOpenDelete(row._id)} /></TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  </div >

  );
}

export default FormPartner;
