import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import FileDownload from "js-file-download";
import CircularProgress from "@mui/material/CircularProgress";
import config from "../config";

//Tabella con titolo, paginazione e bottone per esportare i dati
function CustomTable({ rows, title = "Table" }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //paginazione
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //nomi delle colonne
  const head = Object.keys(rows[0]);

  return (
    <Card style={{ margin: 10 }}>
      <CardContent>
        <Typography
          style={{ fontSize: "16px", color: "#1aa3ff" }}
          variant="h5"
          gutterBottom
        >
          {title}
        </Typography>
        <TableContainer component={Paper}>
          <Table
            style={{ minWidth: 100 }}
            aria-label="simple table"
            size="small"
          >
            <TableHead>
              <TableRow>
                {head.map((key) => (
                  <TableCell key={key} align="left">{key}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //seleziona righe di un pagina
                .map((row, index) => (
                  <TableRow key={index}>
                    {head.map((column) => (
                      <TableCell key={column} align="left">{row[column]}</TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => exportTables([{ [title]: rows }])}
            style={{
              backgroundColor: "#1aa3ff",
              color: "white",
              marginTop: 10,
            }}
          >
            Esporta CSV
          </Button>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </CardContent>
    </Card>
  );
}

//esporta tabella/e in csv
function exportTables(tables) {
  tables.forEach((table) => {
    const title = Object.keys(table)[0];
    const rows = table[title];
    const header = Object.keys(rows[0]);
    const csv = [
      header.join(";"),
      ...rows.map((row) => header.map((fieldName) => row[fieldName]).join(";")),
    ].join("\r\n");
    FileDownload(csv, `${title}.csv`);
  });
}

function ExportConfig() {
  const [configData, setConfigData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const handleLeggiConfig = async () => {
    setIsLoading(true);
    axios
      .get(`${config.requestsUri}/SYSDATA?hotelID=${id}`)
      //.get("http://172.22.163.26Z:8080/setupExample.json")
      .then((res) => {
        setIsLoading(false);
        const data = res.data.data.IO_SystemDataRS.SystemData;
        console.log(data);

        const paymentMethodsRaw = data.Accounting.PaymentMethods.PaymentMethod;
        const paymentMethods = paymentMethodsRaw.map((pm) => {
          return { ID: pm.$.ID, Testo: pm.Name._, Abbreviazione: pm.$.Code };
        });

        const taxesRaw = data.Accounting.Taxes.Tax;
        const taxes = taxesRaw.map((tax) => {
          return {
            ID: tax.$.ID,
            Nome: tax?.Name?._,
            Percentuale: Number(tax.$.Percentage),
            Descrizione: tax?.Description?._,
          };
        });

        const fiscalCodesRaw = data.Accounting.FiscalCodes.FiscalCode;
        const fiscalCodesArray = Array.isArray(fiscalCodesRaw) ? fiscalCodesRaw : [fiscalCodesRaw]
        const fiscalCodes = fiscalCodesArray
          .filter((f) => f.$.Visible === "true")
          .map((fc) => {
            return { ID: fc.$.ID, Nome: fc.Name._, Testo: fc.Description._ };
        });

        let transactionAccounts = [];
        const transactionAccountsRaw =
          data.Accounting.TransactionAccounts.TransactionAccount;
        transactionAccountsRaw.forEach((ta) => {
          const items = ta.Items.Item;
          if (Array.isArray(items)) {
            items.forEach((item) => (item.groupName = ta.Name._));
            transactionAccounts.push(...items);
          } else {
            items.groupName = ta.Name._;
            transactionAccounts.push(items);
          }
        });

        const cot = [];

        for (const item of transactionAccounts) {
          if (!("Code" in item.$)) continue;

          cot.push({
            ID: item.$.ID,
            "Numero Articolo": item.$.Code,
            "Testo Addebito": item.Name._,
            "Gruppo Addebito": item.groupName,
            Valute: item.$.CurrencyCode,
            "Aliquota IVA": Number(item.$.TaxPercentage),
            "Codice Tassa": item.$?.TaxCode,
            "Gruppo Statistico": item.$.StatisticsCode,
            "Attivo": item.$.Visible
          });
        }

        setConfigData((configData) => {
          const config = [...configData];

          if (paymentMethods.length > 0) {
            config.push({ "Metodi di Pagamento": paymentMethods })
          }
          if (taxes.length > 0) {
            config.push({ Tasse: taxes })
          }
          if (fiscalCodes.length > 0) {
            config.push({ "Codici Fiscali": fiscalCodes })
          }
          if (cot.length > 0) {
            config.push({ "COT": cot })
          }
          return config
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#1aa3ff",
          height: "60px",
          display: "flex",
          alignItems: "center",
          marginLeft: 250,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "white",
            fontSize: 20,
            marginLeft: 40,
          }}
          gutterBottom
        >
          Esporta Configurazione
        </Typography>
      </div>

      <div style={{ margin: "10px 10px 0px 290px" }}>
        <Card elevation={0} style={{ display: "inline-block" }}>
          <CardContent>
            <Typography
              style={{ fontSize: "16px", color: "#1aa3ff" }}
              variant="h5"
              gutterBottom
            >
              Leggi Configurazione pAir
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              onClick={handleLeggiConfig}
              style={{ backgroundColor: "#1aa3ff", color: "white" }}
              disabled={isLoading}
            >
              Leggi Config
            </Button>
            {configData.length > 0 && (
              <Button
                variant="contained"
                onClick={() => exportTables(configData)}
                style={{ backgroundColor: "#1aa3ff", color: "white" }}
              >
                Esporta tutto
              </Button>
            )}
            {isLoading && <CircularProgress />}
          </CardActions>
        </Card>

        {configData.map((data) => {
          const key = Object.keys(data);
          return <CustomTable key={key} rows={data[key]} title={key} />;
        })}
      </div>
    </div>
  );
}

export default ExportConfig;
