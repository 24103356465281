import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isAuthenticated from '../authentication';
import { useLocation } from 'react-router-dom';

const getAccessToken = (hash) => {
  if (hash) {
    const realHash = hash.substring(1);
    const result = realHash.split('&')
      .filter(el => el.match('accessToken') !== null);
    if (result.length > 0) {
      const accessTokenPart = result[0];
      return accessTokenPart.split('=')[1];
    }
    return null;
  }
  return null;
};

export const AuthenticationContext = React.createContext();

export const AuthenticationProvider = (props) => {
  const { children } = props;
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState(isAuthenticated());

  const accessToken = getAccessToken(location.hash);

  const login = (token) => {
    localStorage.setItem('token', token); // eslint-disable-line
    setCurrentUser(isAuthenticated());
  };

  const logout = () => {
    localStorage.removeItem('token'); // eslint-disable-line
    // setCurrentUser(false);
  };

  if (accessToken) {
    const state = {
      returnUrl: location.pathname,
    };
    window.location.replace(`/login#access_token=${accessToken}&state=${btoa(JSON.stringify(state))}`);
    return null;
  }

  return (
    <AuthenticationContext.Provider
      value={{ currentUser, login, logout }}
    >
      { children }
    </AuthenticationContext.Provider>
  );
};

const propTypes = {
  children: PropTypes.node.isRequired,
};

AuthenticationProvider.propTypes = propTypes;

export const { Consumer: AuthenticationConsumer } = AuthenticationContext;
