import React, { useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import MuiAlert from '@mui/material/Alert';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  CircularProgress,
  TextField,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  LinearProgress,
  Paper,
  Collapse,
  Box,
  IconButton,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import config from "../config";
import { AuthenticationContext } from "../contexts";
// import { useForm } from "../utils/hooks"

const codiciEsenzioni = [
  "MIN-MINORI",
  "MAL-MALATI",
  "AMA-ACCOMPAGNATORI MALATI",
  "AMI-ACCOMPAGNATORI MINORI",
  "DIS-DISABILE",
  "ADI-ACCOMPAGNATORI DISABILI",
  "AUT-AUTISTA BUS/CAPOGRUPPO",
  "POL-FORZE DELL'ORDINE",
  "COM-OSPITI COMUNE",
  "EPT-EVENTI PROMOZIONALI TURISTICI",
  "TUR-COMPONENTI AZIENDE TURISTICHE",
  "EME-EMERGENZE",
  "PER-PERSONALE STRUTTURA RICETTIVA",
  "RES-RESIDENTI",
  "MED-MEDICI OPERATORI SANITARI",
  "STU-STUDENTI",
  "RIF-RIFIUTO",
];

const codiciRiduzioni = [
  "EVE-EVENTI CON PIU' 15 PERSONE",
  "GSC-GRUPPI SCOLASTICI",
  "S16-SPORTIVI MINORI 16 ANNI",
];

function GestioneCityTax() {
  const [properties, setProperties] = React.useState([]);
  const [selectedProperty, setSelectedProperty] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isProgress, setIsProgress] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );
  const [toDate, setToDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );
  const [reportDate, setReportDate] = React.useState("");
  const [update, setUpdate] = React.useState(0);
  const [reservationList, setReservationList] = React.useState([]);
  const [open, setOpen] = React.useState([]);
  const [openButton, setOpenButton] = React.useState("ESPANDI");
  const [dialogLoading, setDialogLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
  const [dialogError, setDialogError] = React.useState({
    title: "Errori",
    open: false,
    errors: [],
  });
  const [dialogUpdateEsente, setDialogUpdateEsente] = React.useState({
    open: false,
    reservationID: "",
    profileID: "",
    esenzione: "",
    riduzione: "",
    name: "",
    surname: "",
  });
  
  //eslint-disable-next-line
  const [esenzioni, setEsenzioni] = React.useState(codiciEsenzioni);
  //eslint-disable-next-line
  const [riduzioni, setRiduzioni] = React.useState(codiciRiduzioni);

  const { id } = useParams();

  const { currentUser } = useContext(AuthenticationContext);

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(`${config.protelExpressUri}/api/findsProperty`, { hotelID: id })
      .then((res) => {
        let setup = res.data;
        let propertyCodes = [""];
        if (setup) {
          propertyCodes = setup.propertyConfiguration.map(
            (p) => p.propertyCode
          );
        }
        setProperties(["", ...propertyCodes]);
        setSelectedProperty("");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });

    axios
      .post(`${config.protelExpressUri}/api/hotelStatus`, { hotelID: id })
      .then((res) => {
        const status = res.data;
        if (status) {
          setReportDate(status.citytaxReportDate);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, update]);

  const handleSave = async () => {
    setIsLoading(true);
    axios
      .get(
        `${config.requestsUri}/citytax?hotelID=${id}&from=${fromDate}&to=${toDate}&eod=true`
      )
      .then((res) => {
        console.log(res.data);
        setIsLoading(false);
        setOpenSnackbar(true);
        setUpdate(update + 1);
      })
      .catch(() => {
        setIsLoading(false);
        setOpenSnackbarError(true);
      });
  };

  const handleViewEsenti = async (update) => {
    let rl = reservationList;
    if (!update) {
      setReservationList([]);
      setIsProgress(true);
      setOpenButton("ESPANDI");
      //eslint-disable-next-line
      const [reservations, sd] = await Promise.all([
        getReservationList() /*, getUDFs()*/,
      ]);
      rl = reservations;

      /* TODO: assegnare udf presi da SYSTEM DATA
      setEsenzioni(["ES1","ES2"])
      setRiduzioni(["RID1","RID2"])
    */
    }

    if (!rl || rl.length === 0) {
      setIsProgress(false);
      return;
    }

    //TODO: resnos puo essere vuoto?
    const resNos = rl.map((reservation) => reservation.reservationID);
    const esenti = await getEsenti(resNos);

    setIsProgress(false);

    const resJoinedEsenti = rl.map((res) => {
      const ospiti = res.ospiti.map((ospite) => {
        const esenteRidotto = esenti.find(
          (esente) =>
            esente.resNo === res.reservationID &&
            esente.profileID === ospite.guestID
        );
        return {
          ...ospite,
          esenzione: esenteRidotto?.esenzione,
          riduzione: esenteRidotto?.riduzione,
        };
      });
      return {
        ...res,
        ospiti,
      };
    });
    setReservationList(resJoinedEsenti);
    if (!update) {
      setOpen(new Array(rl.length).fill(false));
    }
  };

  const getReservationList = async () => {
    try {
      const res = await axios.get(
        `${config.requestsUri}/citytax?hotelID=${id}&from=${fromDate}&to=${toDate}&propertyCode=${selectedProperty ?? ""}`
        // `http://localhost:8080/ct.json` //FIXME richiesta ambiente dev
      );
      return res.data.data;
    } catch (error) {
      console.log(error);
      setIsProgress(false);
    }
  };

  /* const getUDFs = async () => {
    try {
      const res = await axios.get(
        `${config.requestsUri}/SYSDATA?hotelID=${id}`
      );
      return res.data.data.IO_SystemDataRS.SystemData;
    } catch (error) {
      console.log(error);
    }
  }; */

  const getEsenti = async (resNos) => {
    try {
      const res = await axios.post(`${config.protelExpressUri}/api/getEsenti`, {
        hotelID: id,
        resNos,
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateEsente = async () => {
    setDialogLoading(true);
    const data = {
      hotelID: id,
      resNo: dialogUpdateEsente.reservationID,
      profileID: dialogUpdateEsente.profileID,
      esenzione: dialogUpdateEsente.esenzione,
      riduzione: dialogUpdateEsente.riduzione,
    };
    axios
      .post(`${config.protelExpressUri}/api/updateEsente`, data)
      .then(() => {
        setOpenSnackbar(true);
        handleViewEsenti(true);
        setDialogUpdateEsente({ open: false });
        setDialogLoading(false);
      })
      .catch(() => {
        setOpenSnackbarError(true);
        setDialogLoading(false);
      });
  };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#1aa3ff",
          height: "60px",
          display: "flex",
          alignItems: "center",
          marginLeft: 250,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "white",
            fontSize: 20,
            marginLeft: 40,
          }}
          gutterBottom
        >
          Gestione Tassa di Soggiorno
        </Typography>
      </div>

      <div style={{ margin: "10px 10px 0px 290px" }}>
        <Card style={{ marginBottom: 10 }}>
          <CardContent>
            <Typography
              style={{
                color: "#1aa3ff",
                fontSize: "1rem",
                marginBottom: "1rem",
              }}
              variant="h5"
              gutterBottom
            >
              Gestione ospiti esenti
            </Typography>
            {(currentUser.roles.includes("ADMIN") ||
              currentUser.roles.includes("SUPPORT")) && (
              <p>
                {`Data ultimo salvataggio: ${
                  reportDate && new Date(reportDate).toLocaleDateString()
                }`}
              </p>
            )}
            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
              <FormControl>
                <InputLabel id="property-label">Property</InputLabel>
                <Select
                  labelId="property-label"
                  value={selectedProperty}
                  name="property"
                  onChange={(e) => {
                    setSelectedProperty(e.target.value);
                  }}
                  style={{ width: 150 }}
                >
                  {properties.map((prp) =>
                    prp === "" ? (
                      <MenuItem key={prp} value="">
                        TUTTE
                      </MenuItem>
                    ) : (
                      <MenuItem key={prp} value={prp}>
                        {prp}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <TextField
                id="standard-basic"
                label="Da Data"
                value={fromDate}
                type="date"
                format={"yyyy/MM/dd"}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <TextField
                id="standard-basic"
                label="A Data"
                value={toDate}
                type="date"
                format={"yyyy/MM/dd"}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
            <CardActions style={{ padding: "1rem 0 0 0" }}>
              {(currentUser.roles.includes("ADMIN") ||
                currentUser.roles.includes("SUPPORT")) && (
                <Button
                  variant="contained"
                  onClick={handleSave}
                  style={{
                    backgroundColor: "rgb(3, 155, 229)",
                    color: "white",
                  }}
                  elevation={0}
                  disabled={isLoading}
                >
                  SALVA ESENTI
                </Button>
              )}

              <Button
                variant="contained"
                onClick={() => handleViewEsenti()}
                style={{
                  backgroundColor: "rgb(3, 155, 229)",
                  color: "white",
                }}
                elevation={0}
                disabled={isProgress}
              >
                VISUALIZZA ESENTI SALVATI
              </Button>

              {isLoading && <CircularProgress />}
            </CardActions>
            <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
              {isProgress && <LinearProgress />}
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Button
                        onClick={() => {
                          if (openButton === "ESPANDI") {
                            setOpen(new Array(open.length).fill(true));
                            setOpenButton("RIDUCI");
                          } else {
                            setOpen(new Array(open.length).fill(false));
                            setOpenButton("ESPANDI");
                          }
                        }}
                        elevation={0}
                        disabled={reservationList.length === 0 || isProgress}
                        size="small"
                      >
                        {openButton}
                      </Button>
                    </TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell align="left">Ospite</TableCell>
                    <TableCell align="left">Camera</TableCell>
                    <TableCell align="left">Esenzioni</TableCell>
                    <TableCell align="left">Riduzioni</TableCell>
                    <TableCell align="left">Arrivo</TableCell>
                    <TableCell align="left">Partenza</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reservationList.map((row, index) => (
                    <Row
                      key={row.reservationID}
                      row={row}
                      handleOpen={() => {
                        const openUpd = [...open];
                        openUpd[index] = !open[index];
                        setOpen(openUpd);
                      }}
                      open={open[index]}
                      disabled={row.completata}
                      handleOpenDialogUpdate={(ospite) => {
                        setDialogUpdateEsente({
                          open: true,
                          reservationID: row.reservationID,
                          profileID: ospite.guestID,
                          name: ospite.name,
                          surname: ospite.surname,
                          esenzione: ospite.esenzione,
                          riduzione: ospite.riduzione,
                        });
                      }}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>

        <Snackbar
          open={openSnackbar}
          autoHideDuration="6000"
          onClose={() => setOpenSnackbar(false)}
        >
          <div><Alert severity="success">Operazione effettuata</Alert></div>
        </Snackbar>
        <Snackbar
          open={openSnackbarError}
          autoHideDuration="6000"
          onClose={() => {
            setOpenSnackbarError(false);
          }}
        >
          <div><Alert severity="error">Operazione fallita</Alert></div>
        </Snackbar>
        <Dialog
          open={dialogError.open}
          onClose={() =>
            setDialogError({ open: false, errors: [], title: "Errori" })
          }
        >
          <DialogTitle style={{ paddingBottom: 0 }}>
            {dialogError.title}
          </DialogTitle>
          <DialogContent>
            {dialogError.errors.map((error, i) => (
              <p key={i}>{error}</p>
            ))}
          </DialogContent>
        </Dialog>
        <Dialog
          open={dialogUpdateEsente.open}
          onClose={() => setDialogUpdateEsente({ open: false })}
          style={{ color: "blue" }}
        >
          {dialogLoading && <LinearProgress />}
          <DialogTitle>
            Prenotazione #{dialogUpdateEsente.reservationID}:{" "}
            {dialogUpdateEsente.surname} {dialogUpdateEsente.name} (#
            {dialogUpdateEsente.profileID})
          </DialogTitle>
          <DialogContent
            style={{ display: "flex", justifyContent: "center", gap: "1rem", paddingTop: "0.5rem" }}
          >
            <FormControl>
              <InputLabel id="property-label">Esenzione</InputLabel>
              <Select
                labelId="property-label"
                value={dialogUpdateEsente.esenzione}
                name="property"
                onChange={(e) => {
                  setDialogUpdateEsente({
                    ...dialogUpdateEsente,
                    esenzione: e.target.value,
                  });
                }}
                style={{ width: 150 }}
              >
                <MenuItem key="" value="">
                  NON ESENTE
                </MenuItem>
                {esenzioni.map((codice) => (
                  <MenuItem key={codice} value={codice}>
                    {codice}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="property-label">Riduzione</InputLabel>
              <Select
                labelId="property-label"
                value={dialogUpdateEsente.riduzione}
                name="property"
                onChange={(e) => {
                  setDialogUpdateEsente({
                    ...dialogUpdateEsente,
                    riduzione: e.target.value,
                  });
                }}
                style={{ width: 150 }}
              >
                <MenuItem key="" value="">
                  NON RIDOTTO
                </MenuItem>
                {riduzioni.map((codice) => (
                  <MenuItem key={codice} value={codice}>
                    {codice}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogUpdateEsente({ open: false });
              }}
              style={{ color: "#1aa3ff" }}
              disabled={dialogLoading}
            >
              Annulla
            </Button>
            <Button
              onClick={handleUpdateEsente}
              style={{ color: "#1aa3ff" }}
              disabled={dialogLoading}
            >
              Modifica
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

const Row = ({ row, handleOpen, open, handleOpenDialogUpdate }) => {
  const useStyles = makeStyles(() => ({
    row: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
  }));
  const classes = useStyles();

  //const [open, setOpen] = React.useState(false);
  const dataArrivo = new Date(row.arrivo).toLocaleDateString(undefined, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const dataPartenza = new Date(row.partenza).toLocaleDateString(undefined, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  let esenzioni = 0;
  let riduzioni = 0;
  row.ospiti.forEach((ospite) => {
    if (ospite.esenzione) esenzioni += 1;
    if (ospite.riduzione) riduzioni += 1;
  });

  return (
    <>
      <TableRow>
        <TableCell padding="checkbox" align="center">
          <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.reservationID}
        </TableCell>
        <TableCell align="left">
          {row.ospiti[0]?.surname} {row.ospiti[0]?.name}
        </TableCell>
        <TableCell align="left">{row.roomIDs.join("/")}</TableCell>
        <TableCell align="left">{esenzioni}</TableCell>
        <TableCell align="left">{riduzioni}</TableCell>
        <TableCell align="left">{dataArrivo}</TableCell>
        <TableCell align="left">{dataPartenza}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#d7d7d7",
          }}
          colSpan={10}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Ospite</TableCell>
                    <TableCell>Esenzione</TableCell>
                    <TableCell>Riduzione</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.ospiti.map((ospite) => (
                    <TableRow
                      className={classes.row}
                      key={ospite.guestID}
                      onClick={() => {
                        handleOpenDialogUpdate(ospite);
                      }}
                    >
                      <TableCell component="th" scope="row" width="40%">
                        {ospite.surname} {ospite.name} (#{ospite.guestID})
                      </TableCell>
                      <TableCell width="25%">{ospite.esenzione}</TableCell>
                      <TableCell width="25%">{ospite.riduzione}</TableCell>
                      <TableCell width="10%">
                        <Button
                          onClick={() => {
                            handleOpenDialogUpdate(ospite);
                          }}
                        >
                          modifica
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default GestioneCityTax;
