import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Snackbar,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
// import makeStyles from "@mui/styles/makeStyles";
import { DataGrid } from "@mui/x-data-grid";
import TitleBar from "./TitleBar";
import config from "../config";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const columns = [
  { field: "id", headerName: "ID" },
  { field: "code", headerName: "Code" },
  { field: "name", headerName: "Name", flex: 1 },
  { field: "HSCode", headerName: "HSCode", flex: 0.5, editable: true },
  {
    field: "isAccomodation",
    type: "boolean",
    headerName: "Accomodation",
    width: 150,
    editable: true,
  },
];

function FiscalConfig() {
  const [isLoading, setIsLoading] = useState(true);
  const [kenyaFiscal, setKenyaFiscal] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [openSnackbarError, setOpenSnackbarError] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    axios
      .post(
        `${config.protelExpressUri}/api/findHotels`,
        { hotelID: id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        setKenyaFiscal(res.data.kenyaFiscal);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, [id]);

  /* function handleChangeSetup(e) {
    const { name, value } = e.target;
    setKenyaFiscal((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  } */

  function handleSubmitSetups(e) {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData(e.target);
    const form = Object.fromEntries(formData.entries());
    console.log(form);
    axios
      .post(`${config.protelExpressUri}/api/updateKenyaFiscal`, {
        hotelID: id,
        kenyaFiscal: form,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }

  /* const useStyles = makeStyles({
    table: {
      marginTop: "1rem",
      borderCollapse: "collapse",
      width: "90%",

      "& td, & th": {
        border: "1px solid #dddddd",
        textAlign: "left",
        padding: "8px",
      },

      "& tr:nth-child(even)": {
        backgroundColor: "#dddddd",
      },
    },
  });
  const classes = useStyles(); */

  const rows = useMemo(
    () =>
      kenyaFiscal?.items?.map((item) => {
        return {
          id: item.id,
          code: item.code,
          name: item.name,
          HSCode: item.HSCode,
          isAccomodation: item.isAccomodation,
        };
      }),
    [kenyaFiscal]
  );

  const handleEditItem = useCallback(
    (params) => {
      setIsLoading(true);
      console.log(params);
      axios
        .post(`${config.protelExpressUri}/api/updateItem`, {
          hotelID: id,
          id: params.id,
          field: params.field,
          value: params.value,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          setOpenSnackbarError(true);
          // delete items from state so we don't show the wrong data
          setKenyaFiscal((prev) => {
            return {
              ...prev,
              items: [],
            };
          });
        })
        .finally(() => setIsLoading(false));
    },
    [id]
  );

  const handleCloseSnackbarError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarError(false);
  };

  function getpAirItems() {
    setIsLoading(true);
    axios
      .get(`${config.requestsUri}/SYSDATA?hotelID=${id}`)
      //.get("http://172.22.163.26Z:8080/setupExample.json")
      .then((res) => {
        setIsLoading(false);
        const data = res.data.data.IO_SystemDataRS.SystemData;
        console.log(data);

        let transactionAccounts = [];
        const transactionAccountsRaw =
          data.Accounting.TransactionAccounts.TransactionAccount;
        transactionAccountsRaw.forEach((ta) => {
          const items = ta.Items.Item;
          if (Array.isArray(items)) {
            items.forEach((item) => (item.groupName = ta.Name._));
            transactionAccounts.push(...items);
          } else {
            items.groupName = ta.Name._;
            transactionAccounts.push(items);
          }
        });

        const cot = [];

        for (const item of transactionAccounts) {
          if (!("Code" in item.$) || item.$.Visible !== "true") continue;

          cot.push({
            id: item.$.ID,
            code: item.$.Code,
            name: item.Name._,
          });
        }

        axios
          .post(`${config.protelExpressUri}/api/pAirItems`, {
            hotelID: id,
            items: cot,
          })
          .then((res) => {
            setKenyaFiscal((prev) => {
              return {
                ...prev,
                items: res.data,
              };
            })
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }

  function handleAddItem(e) {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData(e.target);
    const form = Object.fromEntries(formData.entries());
    console.log(form);
    axios
          .post(`${config.protelExpressUri}/api/pAirItems`, {
            hotelID: id,
            items: [
              {
                id: form.id,
                code: form.code,
                name: form.name,
                HSCode: form.HSCode,
                isAccomodation: !!form.isAccomodation,
              },
            ],
          })
          .then((res) => {
            setKenyaFiscal((prev) => {
              return {
                ...prev,
                items: res.data,
              };
            })
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => setIsLoading(false));
  }

  function deleteItems() {
    setIsLoading(true);
    axios
      .post(`${config.protelExpressUri}/api/deleteItems`, {
        hotelID: id,
        items: selectedItems,
      })
      .then((res) => {
        console.log(res);
        setKenyaFiscal((prev) => {
          return {
            ...prev,
            items: res.data,
          };
        })
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }

  function isCellEditable() {
    return !isLoading;
  }

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <TitleBar title="Fiscal Configuration" loading={isLoading} />

      <div style={{ margin: "10px 10px 0px 290px" }}>
        <Card>
          <CardContent>
            <div style={{ marginBottom: "2rem" }}>
              <Typography
                style={{ fontSize: "16px", color: "#1aa3ff" }}
                variant="h5"
                gutterBottom
              >
                Setup
              </Typography>
              {kenyaFiscal && <form onSubmit={handleSubmitSetups}>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    label="Endpoint"
                    defaultValue={kenyaFiscal.endpoint}
                    name="endpoint"
                  />
                  <TextField
                    label="Company ID"
                    defaultValue={kenyaFiscal.companyID}
                    name="companyID"
                  />
                  <TextField
                    label="Trade Name"
                    defaultValue={kenyaFiscal.tradeName}
                    name="tradeName"
                  />
                  <TextField
                    label="Branch ID"
                    defaultValue={kenyaFiscal.branchID}
                    name="branchID"
                  />
                </div>
                <Button
                  variant="contained"
                  elevation={0}
                  type="submit"
                  disabled={isLoading}
                  style={{ marginTop: "1rem" }}
                >
                  Save
                </Button>
              </form>}
            </div>

            <Typography
              style={{ fontSize: "16px", color: "#1aa3ff" }}
              variant="h5"
              gutterBottom
            >
              Add Item
            </Typography>
            <form onSubmit={handleAddItem}>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    label="ID"
                    name="id"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Code"
                    name="code"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Name"
                    name="name"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="HSCode"
                    name="HSCode"
                    InputLabelProps={{ shrink: true }}
                  />
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{
                        name: "isAccomodation",
                      }}
                    />
                  }
                  label="Accomodation"
                />
                
                </div>
                <Button
                  variant="contained"
                  elevation={0}
                  type="submit"
                  disabled={isLoading}
                  style={{ marginTop: "1rem" }}
                >
                  Add
                </Button>
            </form>
            <Typography
              style={{ fontSize: "16px", color: "#1aa3ff", marginTop: "1rem" }}
              variant="h5"
              gutterBottom
            >
              Items
            </Typography>
            <Button
              style={{ marginBottom: "1rem", marginRight: "1rem" }}
              variant="contained"
              elevation={0}
              onClick={getpAirItems}
              disabled={isLoading}
            >
              Import protel items
            </Button>
            <Button
              style={{ marginBottom: "1rem" }}
              variant="contained"
              elevation={0}
              onClick={deleteItems}
              disabled={selectedItems.length === 0 || isLoading}
            >
              Delete selected items
            </Button>
            <div style={{ height: "80vh" }}>
              {kenyaFiscal && <DataGrid
                rows={rows}
                columns={columns}
                onCellEditCommit={handleEditItem}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={setSelectedItems}
                isCellEditable={isCellEditable}
              />}
            </div>
          </CardContent>
        </Card>
      </div>
      <Snackbar
        open={openSnackbarError}
        autoHideDuration="6000"
        onClose={handleCloseSnackbarError}
      >
        <div>
          <Alert severity="error">Error</Alert>
        </div>
      </Snackbar>
    </div>
  );
}

export default FiscalConfig;
