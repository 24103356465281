import React, { useState, useEffect, useContext } from "react";
import makeStyles from '@mui/styles/makeStyles';
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link as RouterLink } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import { AuthenticationContext } from "../contexts";
import Divider from "@mui/material/Divider";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import Typography from "@mui/material/Typography";
import logo from "./img/logo.png";
import DehazeIcon from "@mui/icons-material/Dehaze";
import config from "../config";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import axios from "axios";
import SettingsIcon from "@mui/icons-material/Settings";
import GavelIcon from "@mui/icons-material/Gavel";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DescriptionIcon from "@mui/icons-material/Description";
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import SvgIcon from '@mui/material/SvgIcon';
import AddIcon from '@mui/icons-material/Add';
import BedIcon from '@mui/icons-material/Bed';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import HotelIcon from '@mui/icons-material/Hotel';
import BarChartIcon from '@mui/icons-material/BarChart';



const drawerWidth = 270;

const useStyles = makeStyles(() => ({
  root: { flexGrow: 1 },
  title: { flexGrow: 1 },
  list: { width: 270 },
  nested: { paddingLeft: "2rem" },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundImage: `linear-gradient(#cfd9df,#e2ebf0)`,
    color: "grey",
  },
  bigAvatar: {
    margin: 30,
    width: 300,
    height: 200,
  },
  paper: {
    height: "calc(100% - 0px)",
    top: 0,
    background: "#F8F8F8",
  },
  listItemText: {
    fontSize: "10px",
  },
}));

const SideMenu = ({ id }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [financialConfiguration, setfinancialConfiguration] = useState(false);
  const [hotelOpen, setHotelOpen] = useState(false);
  const [fatturaElettronicaOpen, setFatturaElettronicaOpen] = useState(false);
  const [documentiOpen, setDocumentiOpen] = useState(false);
  const [setupOpen, setSetupOpen] = useState(false);
  const [cityTaxOpen, setCityTaxOpen] = useState(false);
  const [gestioneDocOpen, setGestioneDocOpen] = useState(false);
  const [istatOpen, setIstatOpen] = useState(false);
  const [ine, setIne] = useState(false);
  const [schedineOpen, setSchedineOpen] = useState(false);
  const [hotelData, setHotelData] = useState();

  // gestisce quali elementi sono visibili nel menu
  const [visibleItems, setVisibleItems] = useState({
    cityTax: false,
    exportCityTax: false,
    cityTaxPosting: false,
    backOffice: false,
    gestioneDocumenti: false,
    greenTax: false,
    invoiceOverview: false,
    schedineWs: false,
    ine : false,
    kenyaFiscal: false,
  });

  const { currentUser } = useContext(AuthenticationContext);
  const { logout } = useContext(AuthenticationContext);

  // const { id } = useParams()

  const handleFinancialConfiguration = () => {
    setfinancialConfiguration(!financialConfiguration);
  };
  const handleHotelClick = () => {
    setHotelOpen(!hotelOpen);
  };

  const handleFatturaClick = () => {
    setFatturaElettronicaOpen(!fatturaElettronicaOpen);
  };

  const handleDocumentiOpen = () => {
    setDocumentiOpen(!documentiOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleLogOut = () => {
    const { redirectUri, logoutUri } = config.authentication;
    logout();
    window.location = `${logoutUri}/?redirectTo=${redirectUri}`;
  };

  useEffect(() => {  //esegue una funzione ofni volta che il componente viene renderizzato (quando cambia l'id)
    var dataP = JSON.stringify({ hotelID: id }); //singolo campo che viene convertito in stringa 
    var configPr = { //viene creato un oggetto configpr che contiene il metodo post, l'url e l'header
      method: "post",
      url: `${config.protelExpressUri}/api/findHotels`, //ivierà l'id dell'hotel al server 
      headers: {
        "Content-Type": "application/json",
      },
      data: dataP,
    };
    async function getDataP() { //funzione asincrona che esegue la chiamata api utilizzando la libreria axios e le configurazione specificate. il risultato viene salvato nel in hoteldata utilizzando sethotel data 
      const result = await axios(configPr);
      setHotelData(result.data);
    }
    getDataP(); //questo frammento di codice prepare e esegue una chimata api post che inviera l'id dell'hotel al server ogni volta che il componente viene renderizzato. i dati ricevuti dalla chiamat ap vengono poi salvati nel state hoteldata 

    setVisibleItems({
      cityTax: false,
      reportBI: false,
      exportCityTax: false,   //setta quali elemtenti sono visibili nel menu 
      cityTaxPosting: false,
      backOffice: false,
      gestioneDocumenti: false,
      greenTax: false,
      invoiceOverview: false,
      schedineWs: false,
      ine : false,
      kenyaFiscal: false,
    });

    // legge i setup per decidere quali elementi mostrare
    axios
      .post(`${config.protelExpressUri}/api/findsProperty`, { hotelID: id })
      .then((res) => {
        const setup = res.data;
        if (setup) {
          for (const ct of setup.cityTax) {
            if (ct.active) {
              setVisibleItems((visibleItems) => {
                return {
                  ...visibleItems,
                  cityTax: true,
                };
              });
            }
            if (ct.postingActive) {
              setVisibleItems((visibleItems) => {
                return {
                  ...visibleItems,
                  cityTaxPosting: true,
                };
              });
            }
            if (ct.export.tipoExport) {
              setVisibleItems((visibleItems) => {
                return {
                  ...visibleItems,
                  exportCityTax: true,
                };
              });
            }
            if (ct.urlReportBI) {
              setVisibleItems((visibleItems) => {
                return {
                  ...visibleItems,
                  reportBI: true,
                };
              });
            }
          }
          for (const bo of setup.backOffice) {
            if (bo.tipo) {
              setVisibleItems((visibleItems) => {
                return {
                  ...visibleItems,
                  backOffice: true,
                };
              });
            }
          }
          if (setup.setup) {
            const gestione = setup.setup.find(s => s.code === "gestioneDocumenti");
            const greenTax = setup.setup.find(s => s.code === "greenTax");
            const invoiceOverview = setup.setup.find(s => s.code === "invoiceOverview");
            const schedineWs = setup.setup.find(s => s.code === 'schedineWsAbil')
            const ine = setup.setup.find(s => s.code === 'ine')

            const kenyaFiscal = setup.setup.find(s => s.code === 'kenyaFiscal');
            if (gestione?.value) {
              setVisibleItems((visibleItems) => {
                return {
                  ...visibleItems,
                  gestioneDocumenti: true,
                };
              });
            }
            if (greenTax?.value) {
              setVisibleItems((visibleItems) => {
                return {
                  ...visibleItems,
                  greenTax: true,
                };
              });
            }
            if (invoiceOverview?.value) {
              setVisibleItems((visibleItems) => {
                return {
                  ...visibleItems,
                  invoiceOverview: true,
                };
              });
            }
            if (schedineWs?.value) {
              setVisibleItems((visibleItems) => {
                return {
                  ...visibleItems,
                  schedineWs: true,
                };
              });
            }
            
            if (ine?.value) {
              setVisibleItems((visibleItems) => {
                return {
                  ...visibleItems,
                  ine: true,
                };
              });
            }

            if (kenyaFiscal?.value) {
              setVisibleItems((visibleItems) => {
                return {
                  ...visibleItems,
                  kenyaFiscal: true,
                };
              });
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Drawer
        anchor="left"
        variant="permanent"
        classes={{ paper: classes.paper }}
        PaperProps={{ elevation: 3 }}
      >
        <div className={classes.list}>
          <List
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <ListItem style={{ height: "56px" }}>
              <img
                src={logo}
                alt="logo"
                style={{
                  height: "50px",
                  width: "110px",
                }}
              />

              <DehazeIcon style={{ /*alignItems: 'right'*/ marginLeft: 100 }} />
            </ListItem>
            <Divider />

            <ListItem style={{ height: "58px", backgroundColor: "white" }}>
              <div
                style={{
                  textAlign: "left",
                  flex: "1 1 0%",
                  boxSizing: "border-box",
                  color: "rgb(136, 136, 136)",
                  fontFamily: 'Muli, "Helvetica Neue", Arial, sans-serif',
                  fontSize: "17px",
                  height: "16px",
                  letterSpacing: "-0.1px",
                  lineHeight: "16.8px",
                }}
              >
                {hotelData ? hotelData.hotelName : ""}
              </div>
            </ListItem>
            <Divider />

            {!currentUser.roles.includes("CUSTOMER") && (
              <>
                <ListItem button onClick={() => handleFinancialConfiguration()}>
                  <ListItemIcon>
                    <SettingsApplicationsIcon style={{ fontSize: "20px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography type="body2" style={{ fontSize: 15 }}>
                        Financial Configuration
                      </Typography>
                    }
                  />
                  {financialConfiguration ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={financialConfiguration}
                  timeout="auto"
                  unmountOnExit
                >
                  <ListItem
                    button
                    onClick={() => handleHotelClick()}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <HotelIcon style={{ fontSize: "20px" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography type="body2" style={{ fontSize: 15 }}>
                          Hotel
                        </Typography>
                      }
                    />
                    {hotelOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={hotelOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        to={`/Hotel/${id}`}
                      >
                        <ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography type="body2" style={{ fontSize: 15 }}>
                                Parametri
                              </Typography>
                            }
                          />
                        </ListItemIcon>
                      </ListItem>
                    </List>
                  </Collapse>
                  <ListItem
                    button
                    onClick={() => handleFatturaClick()}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <ReceiptIcon style={{ fontSize: "20px" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography type="body2" style={{ fontSize: 15 }}>
                          Fattura Elettronica
                        </Typography>
                      }
                    />
                    {fatturaElettronicaOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    in={fatturaElettronicaOpen}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        to={`/FatturaElettronica/${id}`}
                      >
                        <ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography type="body2" style={{ fontSize: 15 }}>
                                Parametri
                              </Typography>
                            }
                          />
                        </ListItemIcon>
                      </ListItem>
                    </List>
                  </Collapse>
                  <ListItem
                    button
                    onClick={() => handleDocumentiOpen()}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <DescriptionIcon style={{ fontSize: "20px" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography type="body2" style={{ fontSize: 15 }}>
                          Documenti Commerciali
                        </Typography>
                      }
                    />
                    {documentiOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={documentiOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested} //button
                        component={RouterLink}
                        to={`/DocumentiCommerciali/${id}`}
                      >
                        <ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography type="body2" style={{ fontSize: 15 }}>
                                Parametri
                              </Typography>
                            }
                          />
                        </ListItemIcon>
                      </ListItem>
                    </List>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested} //button
                        component={RouterLink}
                        to={`/Printers/${id}`}
                      >
                        <ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography type="body2" style={{ fontSize: 15 }}>
                                Printers
                              </Typography>
                            }
                          />
                        </ListItemIcon>
                      </ListItem>
                    </List>
                  </Collapse>
                </Collapse>

                <ListItem button component={RouterLink} to={`/Property/${id}`}>
                  <ListItemIcon>
                    <AddIcon style={{ fontSize: "20px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography type="body2" style={{ fontSize: 15 }}>
                        Property
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem
                  button
                  component={RouterLink}
                  to={`/CategorieCamera/${id}`}
                >
                  <ListItemIcon>
                    <BedIcon style={{ fontSize: "20px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography type="body2" style={{ fontSize: 15 }}>
                        Gestione Camere
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem button onClick={() => setSetupOpen(!setupOpen)}>
                  <ListItemIcon>
                    <EngineeringOutlinedIcon style={{ fontSize: "20px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography type="body2" style={{ fontSize: 15 }}>
                        Setup
                      </Typography>
                    }
                  />
                  {setupOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={setupOpen} timeout="auto" unmountOnExit>
                  <ListItem
                    button
                    className={classes.nested} //button
                    component={RouterLink}
                    to={`/Setup/${id}`}
                  >
                    <ListItemText
                      primary={
                        <Typography type="body2" style={{ fontSize: 15 }}>
                          Generale
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    component={RouterLink}
                    to={`/SetupCityTax/${id}`}
                  >
                    <ListItemText
                      primary={
                        <Typography type="body2" style={{ fontSize: 15 }}>
                          Tassa di Soggiorno
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    component={RouterLink}
                    to={`/SetupBackOffice/${id}`}
                  >
                    <ListItemText
                      primary={
                        <Typography type="body2" style={{ fontSize: 15 }}>
                          Back Office
                        </Typography>
                      }
                    />               
                  </ListItem>

                  <ListItem
                    button
                    className={classes.nested}
                    component={RouterLink}
                    to={`/Ine/${id}`}
                  >

                  

                    <ListItemText
                      primary={
                        <Typography type="body2" style={{ fontSize: 15 }}>
                          Ine
                        </Typography>
                      }
                    />               
                  </ListItem>

                </Collapse>

                <ListItem
                  button
                  component={RouterLink}
                  to={`/ExportConfig/${id}`}
                >
                  <ListItemIcon>
                    <ImportExportIcon style={{ fontSize: "20px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography type="body2" style={{ fontSize: 15 }}>
                        Esporta Configurazione
                      </Typography>
                    }
                  />
                </ListItem>
              </>
            )}

            {currentUser.roles.includes("CUSTOMER") && (
              <ListItem button component={RouterLink} to={`/Home/${id}`}>
                <ListItemIcon>
                  <HomeIcon style={{ fontSize: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      type="body2"
                      style={{ fontSize: 15, color: "rgba(0, 0, 0, 0.87)" }}
                    >
                      Dashboard
                    </Typography>
                  }
                />
              </ListItem>
            )}

            <ListItem button onClick={() => setIstatOpen(!istatOpen)}>  {/* stiamo creando il bottone istat con sotto crea file ISTAT, periodo di chiusura e blocca camere */}
              <ListItemIcon>
                <BarChartIcon style={{ fontSize: "20px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography type="body2" style={{ fontSize: 15 }}>
                    ISTAT
                  </Typography>
                }
              />
              {istatOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={istatOpen} timeout="auto" unmountOnExit>
              <ListItem
                button
                className={classes.nested}
                component={RouterLink}
                to={`/FileIstat/${id}`}
              >
                <ListItemText
                  primary={
                    <Typography type="body2" style={{ fontSize: 15 }}>
                      Crea File ISTAT
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={RouterLink}
                to={`/Chiusura/${id}`}
              >
                <ListItemText
                  primary={
                    <Typography type="body2" style={{ fontSize: 15 }}>
                      Periodo di Chiusura
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={RouterLink}
                to={`/BloccaCamera/${id}`}
              >
                <ListItemText
                  primary={
                    <Typography type="body2" style={{ fontSize: 15 }}>
                      Blocca Camere
                    </Typography>
                  }
                />
              </ListItem>
            </Collapse>
            
            {visibleItems.ine && (
            <>
            <ListItem button onClick={() => setIne(!ine)}>  {/* stiamo creando il bottone istat con sotto crea file ISTAT, periodo di chiusura e blocca camere */}
              <ListItemIcon>
                <BarChartIcon style={{ fontSize: "20px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography type="body2" style={{ fontSize: 15 }}>
                    INE
                  </Typography>
                }
              />
              {ine ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={ine} timeout="auto" unmountOnExit>
              <ListItem
                button
                className={classes.nested}
                component={RouterLink}
                to={`/FileIne/${id}`}
              >
                <ListItemText
                  primary={
                    <Typography type="body2" style={{ fontSize: 15 }}>
                      Crear archivos Ine
                    </Typography>
                  }
                />
                </ListItem>
                
                <ListItem
                 button
                 className={classes.nested}
                  component={RouterLink}
                  to={`/Cierre/${id}`}
                  >
                <ListItemText
                  primary={
                    <Typography type="body2" style={{ fontSize: 15 }}>
                      Periodo de Cierre
                    </Typography>
                  }
                />

              </ListItem>
            </Collapse>
            </>
            )}
            
            
            
            

            <ListItem button onClick={() => setSchedineOpen(!schedineOpen)}>
              <ListItemIcon>
                <GavelIcon style={{ fontSize: "20px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography type="body2" style={{ fontSize: 15 }}>
                    Schedine
                  </Typography>
                }
              />
              {schedineOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={schedineOpen} timeout="auto" unmountOnExit>
              <ListItem
                button
                className={classes.nested}
                component={RouterLink}
                to={`/FileSchedine/${id}`}
              >
                <ListItemText
                  primary={
                    <Typography type="body2" style={{ fontSize: 15 }}>
                      Crea File Schedine
                    </Typography>
                  }
                />
              </ListItem>
              {visibleItems.schedineWs && 
                <>
                  <ListItem
                    button
                    className={classes.nested}
                    component={RouterLink}
                    to={`/InviaSchedine/${id}`}
                  >
                    <ListItemText
                      primary={
                        <Typography type="body2" style={{ fontSize: 15 }}>
                          Invia Schedine
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    component={RouterLink}
                    to={`/RicevutaSchedine/${id}`}
                  >
                    <ListItemText
                      primary={
                        <Typography type="body2" style={{ fontSize: 15 }}>
                          Scarica Ricevuta
                        </Typography>
                      }
                    />
                  </ListItem>
                </>
              }
            </Collapse>

            {visibleItems.cityTax &&
              (visibleItems.cityTaxPosting || visibleItems.exportCityTax || visibleItems.reportBI) && (
                <>
                  <ListItem button onClick={() => setCityTaxOpen(!cityTaxOpen)}>
                    <ListItemIcon>
                      <EuroSymbolOutlinedIcon style={{ fontSize: "20px" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography type="body2" style={{ fontSize: 15 }}>
                          Tassa di Soggiorno
                        </Typography>
                      }
                    />
                    {cityTaxOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={cityTaxOpen} timeout="auto" unmountOnExit>
                    {visibleItems.cityTaxPosting && (
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        to={`/CityTax/${id}`}
                      >
                        <ListItemText
                          primary={
                            <Typography type="body2" style={{ fontSize: 15 }}>
                              Addebiti
                            </Typography>
                          }
                        />
                      </ListItem>
                    )}
                    <ListItem
                      button
                      className={classes.nested}
                      component={RouterLink}
                      to={`/GestioneCityTax/${id}`}
                    >
                      <ListItemText
                        primary={
                          <Typography type="body2" style={{ fontSize: 15 }}>
                            Gestione
                          </Typography>
                        }
                      />
                    </ListItem>
                    {visibleItems.exportCityTax && (
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        to={`/ExportCityTax/${id}`}
                      >
                        <ListItemText
                          primary={
                            <Typography type="body2" style={{ fontSize: 15 }}>
                              Esporta File
                            </Typography>
                          }
                        />
                      </ListItem>
                    )}
                    {visibleItems.reportBI && (
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        to={`/ReportCityTax/${id}`}
                      >
                        <ListItemText
                          primary={
                            <Typography type="body2" style={{ fontSize: 15 }}>
                              Report
                            </Typography>
                          }
                        />
                      </ListItem>
                    )}
                  </Collapse>
                </>
              )}

            {visibleItems.backOffice && (
              <ListItem button component={RouterLink} to={`/BackOffice/${id}`}>
                <ListItemIcon>
                  <DescriptionIcon style={{ fontSize: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography type="body2" style={{ fontSize: 15 }}>
                      Convert Back Office File
                    </Typography>
                  }
                />
              </ListItem>
            )}

            {visibleItems.gestioneDocumenti &&
              (
                <>
                  <ListItem button onClick={() => setGestioneDocOpen(!gestioneDocOpen)}>
                    <ListItemIcon>
                      <ReceiptIcon style={{ fontSize: "20px" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography type="body2" style={{ fontSize: 15 }}>
                          Gestione Doc. Fiscali
                        </Typography>
                      }
                    />
                    {gestioneDocOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={gestioneDocOpen} timeout="auto" unmountOnExit>
                      <ListItem
                        button
                        className={classes.nested}
                        component={RouterLink}
                        to={`/GestioneFatture/${id}`}
                      >
                        <ListItemText
                          primary={
                            <Typography type="body2" style={{ fontSize: 15 }}>
                              Fatture Elettroniche
                            </Typography>
                          }
                        />
                      </ListItem>
                  </Collapse>
                </>
              )}

            {visibleItems.greenTax && (
              <ListItem button component={RouterLink} to={`/GreenTax/${id}`}>
                <ListItemIcon>
                  <AttachMoneyIcon style={{ fontSize: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography type="body2" style={{ fontSize: 15 }}>
                      Green Tax
                    </Typography>
                  }
                />
              </ListItem>
            )}

            {visibleItems.invoiceOverview && (
              <ListItem button component={RouterLink} to={`/InvoiceOverview/${id}`}>
                <ListItemIcon>
                  <ReceiptIcon style={{ fontSize: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography type="body2" style={{ fontSize: 15 }}>
                      Invoice Overview
                    </Typography>
                  }
                />
              </ListItem>
            )}
            {(visibleItems.kenyaFiscal && currentUser.roles.some(r => ["ADMIN", "SUPPORT", "CUSTOMER_ADMIN"].includes(r))) && (
              <ListItem button component={RouterLink} to={`/FiscalConfig/${id}`}>
                <ListItemIcon>
                  <ReceiptIcon style={{ fontSize: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography type="body2" style={{ fontSize: 15 }}>
                      Fiscal Configuration
                    </Typography>
                  }
                />
              </ListItem>
            )}
          </List>
        </div>
        <List style={{ marginTop: `auto` }} PaperProps={{ elevation: 20 }}>
          <Divider />
          <ListItem
            button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography type="body2" style={{ fontSize: 15 }}>
                  {currentUser.displayName}
                </Typography>
              }
            ></ListItemText>
            <KeyboardArrowDownIcon />
          </ListItem>
          <Menu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem style={{ backgroundColor: "white" }}>
              <ListItem button onClick={handleLogOut}>
                <ListItemIcon>
                  <ExitToAppIcon
                    fontSize="small"
                    style={{ color: "rgba(0, 0, 0, 0.87)" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Logout"
                  style={{ color: "rgba(0, 0, 0, 0.87)" }}
                />
              </ListItem>
            </MenuItem>
          </Menu>
        </List>
      </Drawer>
    </div>
  );
};

export default SideMenu;
