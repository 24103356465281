import React, { useEffect } from 'react';
import axios from 'axios'
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import config from '../../config'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import NativeSelect from '@mui/material/NativeSelect';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete'

function FormHotel() {
  const [hotelID, setHotelID] = React.useState("");
  const [hotelName, setHotelName] = React.useState("");
  const [blocked, setBlocked] = React.useState(false);
  const [blockReason, setBlockReason] = React.useState("");
  const [raccordo, setRaccordo] = React.useState([]);
  const [partnerId, setPartnerId] = React.useState();
  const [companyId, setCompanyId] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [companyIdUpd, setCompanyIdUp] = React.useState();
  const [row, setRow] = React.useState();

  const [hotelNameUpd, setHotelNameUpd] = React.useState("");
  const [blockedUpd, setBlockedUpd] = React.useState(false);
  const [blockReasonUpd, setBlockReasonUpd] = React.useState("");

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '17ch',
      },
    },
  }));

  const handleUpdHotel = (value, row) => {
    setCompanyIdUp(value);
    setRow(row);
    setHotelNameUpd(row.hotelName);
    setBlockReasonUpd(row.blockReason)
    setBlockedUpd(row.blocked)
    setOpenDialog(true)
  }

  //dialog
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleCloseDelete = () => {
    setOpenDialogDelete(false);
  };

  const handleDelete = () => {
    var data = {
      "companyId": companyIdUpd,
      "hotelID": row.hotelID
    }

    var config1 = {
      method: 'delete',
      url: `${config.protelExpressUri}/api/delete/raccordoHotel`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    const config2 = {
      method: 'delete',
      url: `${config.protelExpressUri}/api/hotels/${row._id}`,
      headers: {
        'Content-Type': 'application/json'
      },
    };

    Promise.all([axios(config1), axios(config2)])
      .then(() => {
        setOpenDialogDelete(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        handleOpenError();
      })
  }

  const handleOpenDelete = (value, row) => {
    setOpenDialogDelete(true);
    setCompanyIdUp(value);
    setRow(row);
  }

  const handleUpdHotels = () => {
    
    const config1 = {
      method: 'put',
      url: `${config.protelExpressUri}/api/hotels/formhotel/${row._id}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ "hotelName": hotelNameUpd, "hotelID": "", "blocked": blockedUpd, "blockReason": blockReasonUpd === "" ? " " : blockReasonUpd, "token": "", tokens: { "fiscal": "", "istat": "" } })
    };

    var dataSubmit = {
      "companyId": companyIdUpd,
      "hotelName": hotelNameUpd,
      "hotelID": row.hotelID,
      "blocked": blockedUpd, "blockReason": blockReasonUpd
    };

    var config2 = {
      method: 'put',
      url: `${config.protelExpressUri}/api/raccordoUpdatehotel`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataSubmit
    };

    Promise.all([axios(config1), axios(config2)])
      .then(() => {
        setOpenDialog(false);
        handleOpenSuccess();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        handleOpenError()
      })
  }



  const handleSubmit = (event) => {
    event.preventDefault();
    if (!hotelID || !hotelName || !companyId || !partnerId) {
      handleOpenError();
      return;
    }

    const homeData = {
      "hotelID": hotelID,
      "hotelName": hotelName,
      "blocked": blocked,
      "blockReason": blockReason,
    }

    axios
      .post(`${config.protelExpressUri}/api/hotels`, homeData)
      .then(() => {
        handleOpenSuccess()
      })
      .catch(err => {
        console.log(err);
        console.log(err.response)
        handleOpenError()
      })

    //Submit Company
    var data = {
      "companyId": companyId,
      "hotelName": hotelName,
      "hotelID": hotelID
    };

    var config0 = {
      method: 'put',
      url: `${config.protelExpressUri}/api/raccordo/hotel/${partnerId}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config0)
      .then(function () {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const getRaccordo = async () => {
    try {
      const userRaccordo = await axios.get(`${config.protelExpressUri}/api/raccordo`)
      const hotels = await axios.get(`${config.protelExpressUri}/api/hotels`)
    
    //aggiunge i dati completi degli hotel in raccordo
      userRaccordo.data[0].partner.companies.forEach(company => (
        company.hotels = company.hotels.map(hotel => {
          const hotelData = hotels.data.find((h) => h.hotelID === hotel.hotelID)
          return {...hotel, ...hotelData}
        })
      ))
     
      setRaccordo(userRaccordo.data);

    } catch (err) {
      console.error(err.message);
    }
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //snackbar success
  const handleOpenSuccess = () => {
    setOpen(true);
  };

  const handleOpenError = () => {
    setOpenError(true);
  };

  useEffect(() => {
    getRaccordo()
  }, [])

  const classes = useStyles();

  return (<div style={{ height: '100vh', backgroundColor: '#F8F8F8' }}>
    <div style={{ height: '64px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
    <div style={{ backgroundColor: "#1aa3ff", height: '60px', display: 'flex', alignItems: 'center', marginLeft: 270 }} >
      <Typography variant="h5" style={{
        color: "white",
        fontSize: 20,
        marginLeft: 40
      }} gutterBottom>Gestione Hotel</Typography>
    </div>
    <div onSubmit={handleSubmit} style={{
      marginLeft: 290,
      display: 'flex'
    }}>

      <Dialog open={openDialogDelete} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Cancellare Hotel: {row ? row.hotelName : ''}?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDelete} style={{ color: '#1aa3ff' }}>
            Annulla
          </Button>
          <Button onClick={handleDelete} style={{ color: '#1aa3ff' }}>
            Cancella
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Modifica Hotel: {row ? row.hotelName : ''} </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome Hotel"
            type="Cognome"
            fullWidth
            value={hotelNameUpd}
            onChange={e => setHotelNameUpd(e.target.value)}
          />
          <FormControlLabel
            value="Bloccato"
            control={<Checkbox
              checked={blockedUpd}
              onClick={e => {
                setBlockedUpd(e.target.checked);
              }}
            />}
            label="Bloccato"
            labelPlacement="top"
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Ragione del Blocco"
            type="Company"
            fullWidth
            value={blockReasonUpd}
            onChange={e => setBlockReasonUpd(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: '#1aa3ff' }}>
            Annulla
          </Button>
          <Button onClick={handleUpdHotels} style={{ color: '#1aa3ff' }}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>


      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSuccess}>
        <div><Alert severity="success">SUCCESS!</Alert></div>
      </Snackbar>

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
        <div><Alert severity="error">Errore</Alert></div>
      </Snackbar>

      <Card style={{minWidth: 250, marginTop: 11, marginRight: 11, borderRadius: 5 }} elevation={0}>
        <CardContent>
          <Typography style={{ fontSize: '16px', color: '#1aa3ff' }} variant="h5" gutterBottom>Inserisci Hotel</Typography>
          <form className={classes.root} onSubmit={handleSubmit}>
            <TextField id="standard-basic" label="Hotel ID" value={hotelID} onChange={e => setHotelID(e.target.value)} />
            <TextField id="standard-basic" label="Hotel Name" value={hotelName} onChange={e => setHotelName(e.target.value)} />
            <FormControlLabel
              value="Bloccato"
              control={<Checkbox
                checked={blocked}
                onClick={e => {
                  setBlocked(e.target.checked);
                }}
              />}
              label="Bloccato"
              labelPlacement="top"
            />
            <TextField id="standard-basic" label="Ragione del Blocco" value={blockReason} onChange={e => setBlockReason(e.target.value)} />
            <FormControl variant="outlined" className={classes.formControl} margin='dense'>
              <InputLabel htmlFor="outlined-age-native-simple">Partner</InputLabel>
              <NativeSelect
                label="Age"
                onChange={e => {
                  setPartnerId(e.target.value)
                  setCompanyId("");
                }
                }
              ><option></option>
                {raccordo.map((value) =>
                  <option key={value._id} value={value._id}>{value.partner.partnerName}</option>
                )}
              </NativeSelect>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl} margin='dense'>
              <InputLabel htmlFor="outlined-age-native-simple">Company</InputLabel>
              <NativeSelect
                native
                label="Age"
                onChange={e => setCompanyId(e.target.value)}
              ><option>
                </option>
                {raccordo.filter((value) => value._id === partnerId).map((value) => {
                  return value.partner.companies.map((value) => <option key={value._id} value={value._id}>{value.companyName}</option>
                  )
                }
                )}
              </NativeSelect>
            </FormControl>
            <CardActions>
              <Button variant="contained" onClick={handleSubmit} style={{ backgroundColor: '#1aa3ff', color: "white" }}>Inserisci</Button>
            </CardActions>
          </form >
        </CardContent></Card>
    </div><br></br><div style={{ marginLeft: 290, marginTop: 11 }}>
      <Card style={{ minWidth: 250, marginTop: 11, marginRight: 11, borderRadius: 5 }} elevation={0}>
        <CardContent>
          <Typography style={{ fontSize: '16px', color: '#1aa3ff' }} variant="h5" gutterBottom>Lista Hotel</Typography>
          <TableContainer >
            <Table className={classes.table} size="small" aria-label="a dense table" >
              <TableHead>
                <TableRow>
                  <TableCell align="left" variant="h5" width="10%">Nome Hotel</TableCell>
                  <TableCell align="left" variant="h5" width="10%">Hotel ID</TableCell>
                  <TableCell align="left" variant="h5" width="10%">Bloccato</TableCell>
                  <TableCell align="left" variant="h5" width="10%">Ragione Blocco</TableCell>
                  <TableCell align="left" variant="h5" width="10%">Operazioni</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {raccordo !== [] && raccordo !== [""] && raccordo &&
                  raccordo.map((row) => 
                    row.partner.companies.map((value) =>
                      value.hotels.map((row) =>
                        <TableRow key={row._id}>
                          <TableCell component="th" scope="row">{row.hotelName}</TableCell>
                          <TableCell align="left">{row.hotelID}</TableCell>
                          <TableCell align="left">{row.blocked}
                            <FormControlLabel
                              value="Bloccato"
                              control={<Checkbox
                                disabled
                                key={row.blocked}
                                defaultChecked={row.blocked}
                              />}
                            />
                          </TableCell>
                          <TableCell align="left">{row.blockReason}</TableCell>
                          <TableCell align="left"><EditIcon style={{ fontSize: '15px', fill: "black" }} button onClick={() => handleUpdHotel(value._id, row)} /><DeleteIcon style={{ fontSize: '15px', fill: "black" }} button onClick={() => handleOpenDelete(value._id, row)} /></TableCell>


                        </TableRow>)                   
                    )
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </div >
  </div>
  );
}

export default FormHotel;
