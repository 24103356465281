import React, { useEffect } from "react";
import axios from "axios";
import config from "../config";
import {
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function HotelStatus() {
  const [status, setStatus] = React.useState([]);

  useEffect(() => {
    axios.post(`${config.protelExpressUri}/api/hotelStatus`).then((res) => {
      setStatus(res.data);
    });
  }, []);

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#1aa3ff",
          height: "60px",
          display: "flex",
          alignItems: "center",
          marginLeft: 250,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "white",
            fontSize: 20,
            marginLeft: 40,
          }}
          gutterBottom
        >
          Stato Hotel
        </Typography>
      </div>

      <div style={{ margin: "10px 10px 0px 290px" }}>
        {status.length > 0 && (
          <TableContainer>
            <TableHead>
              <TableRow>
                <TableCell>Hotel ID</TableCell>
                <TableCell>citytaxReportDate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status.map((hotel) => (
                <TableRow key={hotel.hotelID}>
                  <TableCell>{hotel.hotelID}</TableCell>
                  <TableCell>{hotel?.citytaxReportDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
        )}

        <div style={{ marginTop: "2rem" }}>
          <a href="https://fiscalizeit.protelio.serinf-cloud.com/hotels?pwd=Serenissima1-">
            Stato Clients Fiscalizzazione
          </a>
        </div>
      </div>
    </div>
  );
}

export default HotelStatus;
