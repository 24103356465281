import React, { useContext } from 'react';
import { Routes, useMatch } from 'react-router-dom';
import preval from 'preval.macro';
import MenuAppBar from '../components/header'
import HomeDetailed from './HomeDetailed'
import "./css/styles.css"
import { AuthenticationContext } from '../contexts';
import HomeCustomer from './HomeCustomer';
import MenuAppBarCustomer from '../components/headerCustomer'
import { Navigate, Route } from 'react-router-dom'
import SideBar from '../components/homeprincipale/SideBar'
import FormPartner from '../components/homeprincipale/FormPartner'
import FormCompany from '../components/homeprincipale/FormCompany'
import FormHotel from '../components/homeprincipale/FormHotel'
import FormNewUser from '../components/homeprincipale/FormNewUser'
import HotelSelection from '../components/Customers/HotelSelection'
import FormFE from "../components/FormFE"
import FormHome from "../components/FormHome"
import FormPrinter from "../components/FormPrinter"
import FormDC from "../components/FormDC"
import FormProperty from "../components/FormProperty"
import FormSetup from "../components/FormSetup"
import ExportConfig from "../components/ExportConfig"
import FormCategoriaCamera from "../components/FormCategoriaCamera"
import config from '../config'
import FormSchedine from '../components/Customers/FormSchedine'
import BlockRoom from '../components/Customers/BlockRoom'
import Cierra from '../components/Customers/Cierre';
import BloccaCamere from '../components/Customers/BloccaCamere'

import FormIstat from '../components/Customers/FormIstat';
import FormIne from '../components/Customers/FormIne';
import Ine from '../components/Ine';
import CityTax from '../components/CityTax';
import SetupCityTax from '../components/SetupCityTax';
import BackOffice from '../components/BackOffice';
import HotelStatus from '../components/HotelStatus';
import ExportCityTax from '../components/ExportCityTax';
import SideMenu from '../components/SideMenu'
import GestioneCityTax from '../components/GestioneCityTax';
import SetupBackOffice from '../components/SetupBackOffice';
import GestioneFatture from '../components/GestioneFatture';
import GreenTax from '../components/GreenTax';
import ReportCityTax from '../components/ReportCityTax';
import InvoiceOverview from '../components/InvoiceOverview';
import InviaSchedine from '../components/Customers/InviaSchedine';
import RicevutaSchedine from '../components/Customers/RicevutaSchedine';
import FiscalConfig from '../components/FiscalConfig';
import Typography from '@mui/material/Typography';

function Home() {
  const { currentUser } = useContext(AuthenticationContext);
  const { logout } = useContext(AuthenticationContext);

  const handleLogOut = () => {
    const { redirectUri, logoutUri } = config.authentication;
    logout();
    window.location = `${logoutUri}/?redirectTo=${redirectUri}`;
  };

  // controlla se mostrare sidemenu o sidebar
  const matchForSideMenu = useMatch('/:rt/:id')

  return (
    <div className="foo" data-build={preval`module.exports = new Date().toLocaleString();`} >
      {matchForSideMenu && <SideMenu id={matchForSideMenu.params.id} />}
        {(currentUser.roles.includes('ADMIN') || (currentUser.roles.includes('SUPPORT'))) ?
          <React.Fragment>
            <MenuAppBar />
            {!matchForSideMenu && <SideBar />}
              <Routes>
                <Route path="/" element={<>
                  <div style={{ height: '64px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5 }} elevation={0}></div>
                  <div style={{ backgroundColor: "#1aa3ff", height: '60px', display: 'flex', alignItems: 'center', marginLeft: 250}} >
                    <Typography variant="h5" style={{
                      color: "white",
                      fontSize: 20,
                      marginLeft: 40
                    }} gutterBottom>Home</Typography>
                  </div>
                  </>} />
                <Route path="/HomeDetailed/:id" element={<HomeDetailed />} />
                <Route path="/FormPartner" element={<FormPartner />} />
                <Route path="/FormCompany" element={<FormCompany />} />
                <Route path="/FormHotel" element={<FormHotel />} />
                <Route path="/FormUtenti" element={<FormNewUser />} />
                <Route path="/FatturaElettronica/:id" element={<FormFE />} />
                <Route path="/Printers/:id" element={<FormPrinter />} />
                <Route path="/Hotel/:id" element={<FormHome />} />
                <Route path="/DocumentiCommerciali/:id" element={<FormDC />} />
                <Route path="/Setup/:id" element={<FormSetup />} />
                <Route path="/SetupCityTax/:id" element={<SetupCityTax />} />
                <Route path="/SetupBackOffice/:id" element={<SetupBackOffice />} />
                <Route path="/Ine/:id" element={<Ine />} />
                <Route path="/ExportConfig/:id" element={<ExportConfig />} />
                <Route path="/Property/:id" element={<FormProperty />} />
                <Route path="/CategorieCamera/:id" element={<FormCategoriaCamera />} />          
                <Route path="/HotelStatus" element={<HotelStatus />} />
              </Routes>
          </React.Fragment>

        : currentUser.roles.includes('CUSTOMER') ?

              <React.Fragment>
                <MenuAppBarCustomer />
                <Routes>
                  <Route path="/" element={<Navigate to='/hotelselect' replace />} /> 
                  <Route path="/hotelselect" element={<HotelSelection />} />
                  <Route path="/Home/:id" element={<HomeCustomer />} />
                </Routes>
              </React.Fragment>
              : handleLogOut()
        }

        <Routes>
          <Route path="/FileIstat/:id" element={<FormIstat />} />
          <Route path="/FileIne/:id" element={<FormIne />} />
          <Route path="/FileSchedine/:id" element={<FormSchedine />} />
          <Route path="/InviaSchedine/:id" element={<InviaSchedine />} />
          <Route path="/RicevutaSchedine/:id" element={<RicevutaSchedine />} />
          <Route path="/Chiusura/:id" element={<BlockRoom />} />
          <Route path="/Cierre/:id" element={<Cierra />} />

          <Route path="/BloccaCamera/:id" element={<BloccaCamere />} />
          <Route path="/CityTax/:id" element={<CityTax />} />
          <Route path="/ExportCityTax/:id" element={<ExportCityTax />} />
          <Route path="/GestioneCityTax/:id" element={<GestioneCityTax />} />
          <Route path="/BackOffice/:id" element={<BackOffice />} />
          <Route path="/GestioneFatture/:id" element={<GestioneFatture />} />
          <Route path="/GreenTax/:id" element={<GreenTax />} />
          <Route path="/ReportCityTax/:id" element={<ReportCityTax />} />
          <Route path="/InvoiceOverview/:id" element={<InvoiceOverview />} />
          <Route path="/FiscalConfig/:id" element={<FiscalConfig />} />
        </Routes>
    </div >
  );
}

export default Home;