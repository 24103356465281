import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import '../../pages/css/styles.css'

const drawerWidth = 285;

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1, zIndex: 1000 },
  title: { flexGrow: 1 },
  list: { width: 280 },
  nested: { paddingLeft: theme.spacing.unit * 4, },
  nestedCompany: { paddingLeft: theme.spacing.unit * 6 },
  nestedHotel: { paddingLeft: theme.spacing.unit * 9 },
  drawer: {
    height: 1,
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundImage: `linear-gradient(#cfd9df,#e2ebf0)`,
    color: '#F8F8F8',
  },
  drawerContainer: {
    overflow: 'none',
  },

  paper: {
    top: 63,
    background: "#F8F8F8",
    elevation: 0,
    zIndex: 1199
  },
  listItemText: {
    fontSize: '10px',
  }
}));

const SideBar = () => {
  const [date, setDate] = useState(new Date());


  useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setDate(new Date());
  }

  const classes = useStyles();
  const days = ['DOMENICA', 'LUNEDÌ', 'MARTEDÌ', 'MERCOLEDÌ', 'GIOVEDÌ', 'VENERDÌ', 'SABATO']
  const monthNames = ["Gennaio", "Febraio", "Marzo", "Aprile", "Maggio", "Giugno",
    "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
  ];


  return (
    <div className={classes.root}>
      <Drawer
        anchor="right"
        variant="permanent"
        classes={{ paper: classes.paper }}
        style={{ background: '#EBEBEB' }}
        position="relative"
      >
        <div className={classes.list}>
          <List className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}>
            <Card style={{ marginTop: 5, marginRight: 15, marginLeft: 12, borderRadius: 7, minHeight: 300 }} elevation={0} variant="outlined">
              <div style={{ display: 'flex' }}>
                <div style={{ marginLeft: 15, marginTop: 17, color: '#888', fontSize: '16px', fontWeight: 400, height: '44px', lineHeight: '22.4px', letterSpacing: '-0.1px', minHeight: '44px' }}>{days[date.getDay()]}, {date.toLocaleTimeString()}</div>
                <MoreVertIcon style={{ marginTop: 27, marginLeft: 47 }} className='fons' /></div>
              <CardContent style={{ marginTop: 10 }}>

                <div className='fons' style={{ backgroundColor: 'rgba(0, 0, 0, 0)', borderBlockEndColor: 'rgb(136, 136, 136)', borderBottomColor: 'rgb(136, 136, 136)', borderInlineEndColor: 'rgb(136, 136, 136)', color: '#888', fontSize: '24px', fontWeight: 400, fontFamily: 'Muli, "Helvetica Neue", Arial, sans-serif', textAlign: 'center', letterSpacing: '-0.1px', lineHeight: '33.6px', textSizeAdjust: '100%' }}>{monthNames[date.getMonth()]}</div>

                <div style={{ display: 'block', color: '#888', fontSize: '72px', lineHeight: '88px', fontWeight: 400, fontFamily: 'Muli, "Helvetica Neue", Helvetica, Arial"', textAlign: 'center' }}>{date.getDate()}</div>
                <div style={{ color: '#888', fontSize: '24px', fontWeight: 400, fontFamily: 'Muli, "Helvetica Neue", Arial, sans-serif', textAlign: 'center' }}>{date.getFullYear()}</div>
              </CardContent>
            </Card>
          </List>
        </div>
      </Drawer >
    </div >
  );
};

export default SideBar;
