import React, { useEffect } from "react";
import axios from "axios";
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from "react-router-dom";
import "../pages/css/styles.css";
import config from "../config";
import { countries, province } from "../values";
import MuiAlert from "@mui/material/Alert";
import {
  TextField,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  LinearProgress,
  List,
  ListItem,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  Card,
  CardContent,
  CardActions,
  FormHelperText,
  NativeSelect,
  Snackbar,
} from "@mui/material";

// funzione per eliminare proprietà vuote di un oggetto
function deleteEmpty(obj) {
  Object.keys(obj).forEach((k) => {
    if (!obj[k]) {
      delete obj[k];
    } else if (typeof obj[k] === "object") {
      deleteEmpty(obj[k]);
      if (Object.keys(obj[k]).length === 0) {
        delete obj[k];
      }
    }
  });
}

function FormFE() {
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [_id, set_id] = React.useState();
  const [EI, setEI] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState("Errore");

  const [systemData, setSystemData] = React.useState();
  const [loadingSD, setLoadingSD] = React.useState(false);
  const [sharedCounters, setSharedCounters] = React.useState([[]]);
  const [countersEdited, setCountersEdited] = React.useState(false);

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
      "& .MuiNativeSelect-root": {
        maxWidth: 200,
      },
    },
  }));

  const handleSubmit = (event) => {
    event.preventDefault();
    var url = `${config.protelExpressUri}/api/updateEIConfig/${_id}`;

    const formData = new FormData(event.target);
    const form = Object.fromEntries(formData.entries());

    // cancella campi stringa vuota
    Object.keys(form).forEach((k) => {
      if (form[k] === "") {
        delete form[k];
      }
    });

    // validazione
    if (form.autoCausText) {
      const txt = form.autoCausText;
      // controlla che il numero di parentesi quadre aperte sia uguale a quelle chiuse
      if (
        (txt.match(/\[/g) || []).length !== (txt.match(/\]/g) || []).length
      ) {
        handleOpenError("Testo Causale: numero di parentesi quadre aperte e chiuse non corrispondenti");
        return;
      }
      // controlla che all'interno delle parentesi quadre ci siano soltanto i valori: #resno, #crsnumber, #guestfullname, #arrival, #departure
      const acceptedValues = ["#resno", "#crsnumber", "#guestfullname", "#arrival", "#departure"];
      const matches = txt.match(/\[(.*?)\]/g);
      for (const match of matches) {
        if (!acceptedValues.includes(match.slice(1, -1))) {
          handleOpenError(`Testo Causale: codice ${match} non valido`);
          return;
        }
      }
    }

    const feData = {
      version: {
        number: form.number,
        startFrom: form.startFrom,
      },
      fiscalTarget: form.fiscalTarget,
      storageKind: form.storageKind,
      transmitter: {
        contact: {
          phone: form.phone,
          email: form.email,
        },
        country: form.country,
        cf: form.cf,
      },
      profile: {
        hq: {
          address: form.address,
          civicNo: form.civicNo,
          postCode: form.postCode,
          city: form.city,
          region: form.region,
          country: form.countryHq,
        },
        stableOrg: {
          address: form.addressStable,
          civicNo: form.civicNoStable,
          postCode: form.postCodeStable,
          city: form.cityStable,
          region: form.regionStable,
          country: form.countryStable,
        },
        rea: {
          sigla: form.sigla,
          reaNo: form.reaNo,
          shareCapital: form.shareCapital,
          soleShareholder: form.soleShareholder ? true : false,
          liquidation: form.liquidation ? true : false,
        },
        contact: {
          phone: form.phoneContact,
          fax: form.faxContact,
          email: form.emailContact,
        },
        bank: {
          name: form.nameBank,
          IBAN: form.IBAN ? form.IBAN.replace(/\s+/g, "") : undefined,
        },
        name: form.profileName,
        lastName: form.profileLastName,
        piva: form.profilePiva,
        cf: form.profileCf,
      },
      fiscalRepresentative: {
        name: form.rappresentativeName,
        lastName: form.rappresentativeLastName,
        country: form.rappresentativeCountry,
        piva: form.rappresentativePiva,
        cf: form.rappresentativeCf,
      },
      intermediary: {
        name: form.intermediaryName,
        lastName: form.intermediaryLastName,
        country: form.intermediaryCountry,
        piva: form.intermediaryPiva,
        cf: form.intermediaryCf,
      },
      eiEmail: form.eiEmail,
      sftp: {
        host: form.host,
        port: form.port,
        username: form.username,
        password: form.password,
        dir: form.dir,
        email: form.emailSftp,
        startFrom: form.startFromSftp,
      },
      bolloCli: form.bolloCli ? true : false,
      bolloValue: form.bolloValue,
      sharedCounters: countersEdited
        ? sharedCounters.filter((array) => array.length > 0)
        : EI.sharedCounters,
      autoCaus: form.autoCaus ? true : false,
      autoCausText: form.autoCausText,
    };

    deleteEmpty(feData);

    var config1 = {
      method: "put",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: feData,
    };

    axios(config1)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        handleOpenSuccess();
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        handleOpenError(`Errore: ${error.message}`);
      });
  };

  const { id } = useParams();

  useEffect(() => {
    var dataGet = JSON.stringify({ hotelID: `${id}` });

    var config0 = {
      method: "post",
      url: `${config.protelExpressUri}/api/findHotels`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataGet,
    };
    async function getData() {
      const result = await axios(config0);
      set_id(result.data._id);
      setEI(result.data?.EI);
    }
    getData();
  }, [id]);

  const handleCloseSuccess = (òevent, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setErrorMessage("Errore")
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //snackbar success
  const handleOpenSuccess = () => {
    setOpen(true);
  };

  const handleOpenError = (message) => {
    setErrorMessage(message)
    setOpenError(true);
  };

  const classes = useStyles();

  const getSystemData = async () => {
    setLoadingSD(true);
    axios
      .get(`${config.requestsUri}/SYSDATA?hotelID=${id}`)
      .then((res) => {
        const data = res.data.data.IO_SystemDataRS.SystemData;
        setSystemData(data);
        setCountersEdited(true);
        setLoadingSD(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingSD(false);
      });
  };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#1aa3ff",
          height: "60px",
          display: "flex",
          alignItems: "center",
          marginLeft: 250,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "white",
            fontSize: 20,
            marginLeft: 40,
          }}
          gutterBottom
        >
          Fattura Elettronica
        </Typography>
      </div>
      <div
        style={{
          marginLeft: 290,
          display: "flex",
        }}
      >
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        >
          <div>
            <Alert severity="success">SUCCESS!</Alert>
          </div>
        </Snackbar>

        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleCloseError}
        >
          <div>
            <Alert severity="error">{errorMessage}</Alert>
          </div>
        </Snackbar>
        <Card
          style={{
            minWidth: 800,
            width: "100%",
            marginTop: 11,
            marginRight: 11,
            borderRadius: 5,
          }}
          elevation={0}
        >
          <LinearProgress style={{ visibility: !EI ? "visible" : "hidden" }} />
          <CardContent>
            {EI && (
              <form className={classes.root} onSubmit={handleSubmit}>
                <Typography
                  style={{ fontSize: "16px", color: "#1aa3ff" }}
                  variant="h5"
                  gutterBottom
                >
                  EI
                </Typography>
                <TextField
                  name="eiEmail"
                  label="Email EI"
                  defaultValue={EI?.eiEmail}
                />
                <FormControlLabel
                  value="bolloCli"
                  control={
                    <Checkbox
                      inputProps={{
                        name: "bolloCli",
                      }}
                      defaultChecked={EI?.bolloCli}
                    />
                  }
                  label="Bollo Cli"
                  labelPlacement="top"
                />
                <TextField
                  name="bolloValue"
                  label="Valore del Bollo"
                  defaultValue={EI?.bolloValue}
                />

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: 190, marginTop: 27 }}
                  margin="dense"
                >
                  <NativeSelect
                    native
                    label="Fiscal Target"
                    inputProps={{
                      name: "fiscalTarget",
                    }}
                  >
                    <option value="NONE" selected={EI?.fiscalTarget === "NONE"}>
                      NONE
                    </option>
                    <option value="IT" selected={EI?.fiscalTarget === "IT"}>
                      IT
                    </option>
                    <option value="KE" selected={EI?.fiscalTarget === "KE"}>
                      KE
                    </option>
                  </NativeSelect>
                  <FormHelperText>Fiscalità</FormHelperText>
                </FormControl>

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: 190, marginTop: 27 }}
                  margin="dense"
                >
                  <NativeSelect
                    native
                    label="Storage Kind"
                    inputProps={{
                      name: "storageKind",
                    }}
                  >
                    <option value="NONE" selected={EI?.storageKind === "NONE"}>
                      NO
                    </option>
                    <option
                      value="MYSQL"
                      selected={EI?.storageKind === "MYSQL"}
                    >
                      SI
                    </option>
                  </NativeSelect>
                  <FormHelperText>Storico Fatture</FormHelperText>
                </FormControl>
                <div style={{ display: "flex" }}>
                <FormControlLabel
                  value="autoCaus"
                  control={
                    <Checkbox
                      inputProps={{
                        name: "autoCaus",
                      }}
                      defaultChecked={EI?.autoCaus}
                    />
                  }
                  label="Causale automatica"
                  labelPlacement="top"
                />
                <TextField
                    name="autoCausText"
                    label="Testo Causale"
                    defaultValue={EI?.autoCausText}
                    style={{ flexGrow: 1 }}
                />
                </div>

                <Typography
                  style={{ fontSize: "16px", color: "#1aa3ff" }}
                  variant="h5"
                  gutterBottom
                >
                  Versione
                </Typography>
                <TextField
                  name="number"
                  label="Numero"
                  defaultValue={EI?.version?.number}
                />
                <TextField
                  name="startFrom"
                  label="Inizio"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={EI?.version?.startFrom}
                  type="date"
                  format={"yyyy/MM/dd"}
                />

                <Typography
                  style={{ fontSize: "16px", color: "#1aa3ff" }}
                  variant="h5"
                  gutterBottom
                >
                  Trasmittente
                </Typography>
                <TextField
                  name="phone"
                  label="Telefono"
                  defaultValue={EI?.transmitter?.contact?.phone}
                />
                <TextField
                  name="email"
                  label="Email"
                  defaultValue={EI?.transmitter?.contact?.email}
                />
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: 190, marginTop: 27 }}
                  margin="dense"
                >
                  <NativeSelect
                    native
                    label="Paese"
                    inputProps={{
                      name: "country",
                    }}
                  >
                    <option></option>
                    {countries.map((value) => (
                      <option
                        key={value.code}
                        value={value.code}
                        selected={EI?.transmitter?.country === value.code}
                      >
                        {value.label}
                      </option>
                    ))}
                  </NativeSelect>
                  <FormHelperText>Paese</FormHelperText>
                </FormControl>
                <TextField
                  name="cf"
                  label="Codice Fiscale"
                  defaultValue={EI?.transmitter?.cf}
                />

                <Typography
                  style={{ fontSize: "16px", color: "#1aa3ff" }}
                  variant="h5"
                  gutterBottom
                >
                  SFTP
                </Typography>
                <TextField
                  name="host"
                  label="Host"
                  defaultValue={EI?.sftp?.host}
                />
                <TextField
                  name="port"
                  label="Port"
                  defaultValue={EI?.sftp?.port}
                />
                <TextField
                  name="username"
                  label="Username"
                  defaultValue={EI?.sftp?.username}
                />
                <TextField
                  name="password"
                  label="Password"
                  type="password"
                  defaultValue={EI?.sftp?.password}
                />
                <TextField
                  name="dir"
                  label="Dir"
                  defaultValue={EI?.sftp?.dir}
                />
                <TextField
                  name="emailSftp"
                  label="Email"
                  defaultValue={EI?.sftp?.email}
                />
                <TextField
                  name="startFromSftp"
                  label="Startfrom"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={EI?.sftp?.startFrom}
                  type="date"
                  format={"yyyy/MM/dd"}
                />

                <Typography
                  style={{ fontSize: "16px", color: "#1aa3ff" }}
                  variant="h5"
                  gutterBottom
                >
                  Contatori Fiscali Condivisi
                </Typography>
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", width: "100%" }}>
                    {EI?.sharedCounters.map((group, i) => (
                      <List key={i}>
                        <ListItem style={{ color: "gray" }}>
                          Gruppo #{i + 1}
                        </ListItem>
                        {group.map((code) => (
                          <ListItem key={code}>{code}</ListItem>
                        ))}
                      </List>
                    ))}
                  </div>
                  <Button
                    onClick={getSystemData}
                    disabled={systemData || loadingSD}
                  >
                    MODIFICA
                  </Button>
                  <div>{loadingSD && <CircularProgress />}</div>
                  {systemData && (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {sharedCounters.map((s, i) => (
                        <SelectContatori
                          key={i}
                          systemData={systemData}
                          selectedCounters={sharedCounters[i]}
                          handleChange={(e) => {
                            const newCounters = [...sharedCounters];
                            newCounters[i] = e.target.value;
                            setSharedCounters(newCounters);
                          }}
                        />
                      ))}
                    </div>
                  )}
                  {systemData && (
                    <Button
                      onClick={() => setSharedCounters([...sharedCounters, []])}
                    >
                      Nuovo gruppo
                    </Button>
                  )}
                </div>

                <Typography
                  style={{ fontSize: "16px", color: "#1aa3ff" }}
                  variant="h5"
                  gutterBottom
                >
                  Dati Profilo
                </Typography>
                <TextField
                  name="profileName"
                  label="Nome"
                  defaultValue={EI?.profile?.name}
                />
                <TextField
                  name="profileLastName"
                  label="Cognome"
                  defaultValue={EI?.profile?.lastName}
                />
                <TextField
                  name="profilePiva"
                  label="Partita Iva"
                  defaultValue={EI?.profile?.piva}
                />
                <TextField
                  name="profileCf"
                  label="Codice Fiscale"
                  defaultValue={EI?.profile?.cf}
                />

                <Typography
                  style={{ fontSize: "16px", color: "#1aa3ff" }}
                  variant="h5"
                  gutterBottom
                >
                  Hq
                </Typography>
                <TextField
                  name="address"
                  label="Indirizzo"
                  defaultValue={EI?.profile?.hq?.address}
                />
                <TextField
                  name="civicNo"
                  label="Numero Civico"
                  defaultValue={EI?.profile?.hq?.civicNo}
                />
                <TextField
                  name="postCode"
                  label="Codice Postale"
                  defaultValue={EI?.profile?.hq?.postCode}
                />
                <TextField
                  name="city"
                  label="Citta"
                  defaultValue={EI?.profile?.hq?.city}
                />

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: 190, marginTop: 27 }}
                  margin="dense"
                >
                  <NativeSelect
                    native
                    label="Paese"
                    inputProps={{
                      name: "region",
                    }}
                  >
                    <option></option>
                    {province.map((value) => (
                      <option
                        key={value.sigla}
                        value={value.sigla}
                        selected={EI?.profile?.hq?.region === value.sigla}
                      >
                        {value.nome}
                      </option>
                    ))}
                  </NativeSelect>
                  <FormHelperText>Provincia</FormHelperText>
                </FormControl>

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: 190, marginTop: 27 }}
                  margin="dense"
                >
                  <NativeSelect
                    native
                    label="Paese"
                    inputProps={{
                      name: "countryHq",
                    }}
                  >
                    <option />
                    {countries.map((value) => (
                      <option
                        key={value.code}
                        value={value.code}
                        selected={EI?.profile?.hq?.country === value.code}
                      >
                        {value.label}
                      </option>
                    ))}
                  </NativeSelect>
                  <FormHelperText>Paese</FormHelperText>
                </FormControl>

                <Typography
                  style={{ fontSize: "16px", color: "#1aa3ff" }}
                  variant="h5"
                  gutterBottom
                >
                  Stabile Organizzazione
                </Typography>
                <TextField
                  name="addressStable"
                  label="Indirizzo"
                  defaultValue={EI?.profile?.stableOrg?.address}
                />
                <TextField
                  name="civicNoStable"
                  label="Numero Civico"
                  defaultValue={EI?.profile?.stableOrg?.civicNo}
                />
                <TextField
                  name="postCodeStable"
                  label="Codice Postale"
                  defaultValue={EI?.profile?.stableOrg?.postCode}
                />
                <TextField
                  name="cityStable"
                  label="Citta"
                  defaultValue={EI?.profile?.stableOrg?.city}
                />

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: 190, marginTop: 27 }}
                  margin="dense"
                >
                  <NativeSelect
                    native
                    label="Paese"
                    inputProps={{
                      name: "regionStable",
                    }}
                  >
                    <option />
                    {province.map((value) => (
                      <option
                        key={value.sigla}
                        value={value.sigla}
                        selected={
                          EI?.profile?.stableOrg?.region === value.sigla
                        }
                      >
                        {value.nome}
                      </option>
                    ))}
                  </NativeSelect>
                  <FormHelperText>Provincia</FormHelperText>
                </FormControl>

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: 190, marginTop: 27 }}
                  margin="dense"
                >
                  <NativeSelect
                    native
                    label="Paese"
                    inputProps={{
                      name: "countryStable",
                    }}
                  >
                    <option />
                    {countries.map((value) => (
                      <option
                        key={value.code}
                        value={value.code}
                        selected={
                          EI?.profile?.stableOrg?.country === value.code
                        }
                      >
                        {value.label}
                      </option>
                    ))}
                  </NativeSelect>
                  <FormHelperText>Paese</FormHelperText>
                </FormControl>

                <Typography
                  style={{ fontSize: "16px", color: "#1aa3ff" }}
                  variant="h5"
                  gutterBottom
                >
                  Rea
                </Typography>
                <TextField
                  name="sigla"
                  label="Sigla"
                  defaultValue={EI?.profile?.rea?.sigla}
                />
                <TextField
                  name="reaNo"
                  label="Numero REA"
                  defaultValue={EI?.profile?.rea?.reaNo}
                />
                <TextField
                  name="shareCapital"
                  label="Capitale Sociale"
                  defaultValue={EI?.profile?.rea?.shareCapital}
                />
                <FormControlLabel
                  value="socioUnico"
                  control={
                    <Checkbox
                      inputProps={{
                        name: "soleShareholder",
                      }}
                      defaultChecked={EI?.profile?.rea?.soleShareholder}
                    />
                  }
                  label="Socio Unico"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="liquidazione"
                  control={
                    <Checkbox
                      inputProps={{
                        name: "liquidation",
                      }}
                      defaultChecked={EI?.profile?.rea?.liquidation}
                    />
                  }
                  label="In Liquidazione"
                  labelPlacement="top"
                />

                <Typography
                  style={{ fontSize: "16px", color: "#1aa3ff" }}
                  variant="h5"
                  gutterBottom
                >
                  Contatti
                </Typography>
                <TextField
                  name="phoneContact"
                  label="Numero di telefono"
                  defaultValue={EI?.profile?.contact?.phone}
                />
                <TextField
                  name="faxContact"
                  label="Fax"
                  defaultValue={EI?.profile?.contact?.fax}
                />
                <TextField
                  name="emailContact"
                  label="Email"
                  defaultValue={EI?.profile?.contact?.email}
                />

                <Typography
                  style={{ fontSize: "16px", color: "#1aa3ff" }}
                  variant="h5"
                  gutterBottom
                >
                  Dati Bancari
                </Typography>
                <TextField
                  name="nameBank"
                  label="Nome Banca"
                  defaultValue={EI?.profile?.bank?.name}
                />
                <TextField
                  name="IBAN"
                  label="IBAN"
                  defaultValue={EI?.profile?.bank?.IBAN}
                />

                <Typography
                  style={{ fontSize: "16px", color: "#1aa3ff" }}
                  variant="h5"
                  gutterBottom
                >
                  Rappresentante Fiscale
                </Typography>
                <TextField
                  name="rappresentativeName"
                  label="Nome"
                  defaultValue={EI?.fiscalRepresentative?.name}
                />
                <TextField
                  name="rappresentativeLastName"
                  label="Cognome"
                  defaultValue={EI?.fiscalRepresentative?.lastName}
                />

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: 190, marginTop: 27 }}
                  margin="dense"
                >
                  <NativeSelect
                    native
                    label="Paese"
                    inputProps={{
                      name: "rappresentativeCountry",
                    }}
                  >
                    <option />
                    {countries.map((value) => (
                      <option
                        key={value.code}
                        value={value.code}
                        selected={
                          EI?.fiscalRepresentative?.country === value.code
                        }
                      >
                        {value.label}
                      </option>
                    ))}
                  </NativeSelect>
                  <FormHelperText>Paese</FormHelperText>
                </FormControl>

                <TextField
                  name="rappresentativePiva"
                  label="Partita Iva"
                  defaultValue={EI?.fiscalRepresentative?.piva}
                />
                <TextField
                  name="rappresentativeCf"
                  label="Codice Fiscale"
                  defaultValue={EI?.fiscalRepresentative?.cf}
                />

                <Typography
                  style={{ fontSize: "16px", color: "#1aa3ff" }}
                  variant="h5"
                  gutterBottom
                >
                  Intermediario
                </Typography>
                <TextField
                  name="intermediaryName"
                  label="Nome"
                  defaultValue={EI?.intermediary?.name}
                />
                <TextField
                  name="intermediaryLastName"
                  label="Cognome"
                  defaultValue={EI?.intermediary?.lastName}
                />

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: 190, marginTop: 27 }}
                  margin="dense"
                >
                  <NativeSelect
                    native
                    label="Paese"
                    inputProps={{
                      name: "intermediaryCountry",
                    }}
                  >
                    <option />
                    {countries.map((value) => (
                      <option
                        key={value.code}
                        value={value.code}
                        selected={EI?.intermediary?.country === value.code}
                      >
                        {value.label}
                      </option>
                    ))}
                  </NativeSelect>
                  <FormHelperText>Paese</FormHelperText>
                </FormControl>

                <TextField
                  name="intermediaryPiva"
                  label="Partita Iva"
                  defaultValue={EI?.intermediary?.piva}
                />
                <TextField
                  name="intermediaryCf"
                  label="Codice Fiscale"
                  defaultValue={EI?.intermediary?.cf}
                />
                <CardActions>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#1aa3ff", color: "white" }}
                    type="submit"
                  >
                    Salva
                  </Button>
                </CardActions>
              </form>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

const SelectContatori = ({ systemData, selectedCounters, handleChange }) => {
  return (
    <FormControl style={{ margin: "1rem", minWidth: 250 }}>
      <InputLabel id="shared-counters-label">
        Gruppo Contatori Condivisi
      </InputLabel>
      <Select
        name="sharedCounters"
        labelId="shared-counters-label"
        multiple
        value={selectedCounters}
        onChange={handleChange}
        style={{ minWidth: 250 }}
      >
        {systemData.Accounting.FiscalCodes.FiscalCode.map((codice, i) => (
          <MenuItem key={i + 1} value={codice.Name._}>
            {codice.Name._}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FormFE;
