import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../config";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  Typography,
  TextField,
  Card,
  CardContent,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  CircularProgress,
  LinearProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import { codiciBackOffice } from "../values";

const initialStateParametri = {
  tipo: "",
  pagamentiIvaSplit: "",
  documentiPA: "",
  codiceHotel: "",
  ftpActive: false,
  ftp: "",
  ip: "",
  port: "",
  user: "",
  password: "",
  hostkey: "",
  path: "",
  codFS: "",
  customersAccount: "",
  customersAccountFS: "",
  clientesReception: "",
  contoAnticipo: "",
  codPagSosp: "",
  codDocFisc: "",
  codDocFattura: "",
  codDocNC: "",
  contoVendite: "",
  cotDepositi: "",
  cassa: "",
  sunsystem: {
    taxes: [],
  },
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function SetupBackOffice() {
  const [setupData, setSetupData] = React.useState();
  const [selectedProperty, setSelectedProperty] = React.useState("");
  const [properties, setProperties] = React.useState([]);
  const [parametri, setParametri] = React.useState(initialStateParametri);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDataLoading, setIsDataLoading] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);

  const { id } = useParams();

  useEffect(() => {
    setIsDataLoading(true);
    axios
      .post(`${config.protelExpressUri}/api/findsProperty`, { hotelID: id })
      .then((res) => {
        let setup = res.data;
        if (setup === "" || !setup) {
          setup = { propertyConfiguration: [], backOffice: [] };
        }
        setSetupData(setup);
        let propertyCodes = setup.propertyConfiguration.map(
          (p) => p.propertyCode
        );
        if (propertyCodes.length > 0) {
          setProperties(["", ...propertyCodes]);
          setSelectedProperty("");
        } else {
          propertyCodes = [""];
          setProperties([""]);
          setSelectedProperty("");
        }

        //setCityTaxSetup(setup?.cityTax ?? []);
        const selectedSetup = setup.backOffice.find(
          (p) => p.propertyCode === ""
        );

        if (selectedSetup) {
          setParametri({ ...selectedSetup });
        }
        setIsDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoading(false);
      });
  }, [id, reload]);

  const handleChangeParametri = (e) => {
    if (e.target.name.split("-").length === 2) {
      const [tipo, name] = e.target.name.split("-");
      setParametri({
        ...parametri,
        [tipo]: { ...parametri[tipo], [name]: e.target.value },
      });
    } else {
      setParametri({ ...parametri, [e.target.name]: e.target.value });
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    axios
      .put(`${config.protelExpressUri}/api/updateBackOfficeSetup`, {
        hotelID: id,
        propertyCode: selectedProperty,
        parametri,
      })
      .then(() => {
        setIsLoading(false);
        setOpenSnackbar(true);
        setReload(!reload);
      })
      .catch((error) => {
        console.log(error);
        setOpenSnackbarError(true);
        setIsLoading(false);
      });
  };

  const useStyles = makeStyles(() => ({
    fieldsContainer: {
      "& > .MuiTextField-root": {
        minWidth: "20vw",
      },
    },
  }));

  const classes = useStyles();

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#1aa3ff",
          height: "60px",
          display: "flex",
          alignItems: "center",
          marginLeft: 250,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "white",
            fontSize: 20,
            marginLeft: 40,
          }}
          gutterBottom
        >
          Setup Back Office
        </Typography>
      </div>

      <div style={{ margin: "10px 10px 0px 290px" }}>
        <Card style={{ marginBottom: "1rem" }}>
          <CardContent>
            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <FormControl>
                <InputLabel id="property-label">Property</InputLabel>
                <Select
                  labelId="property-label"
                  value={selectedProperty}
                  name="property"
                  onChange={(e) => {
                    setSelectedProperty(e.target.value);
                    const selectedSetup = setupData.backOffice.find(
                      (p) => p.propertyCode === e.target.value
                    );
                    if (selectedSetup) {
                      setParametri({ ...selectedSetup });
                    } else {
                      setParametri(initialStateParametri);
                    }
                  }}
                  style={{ width: 150 }}
                >
                  {properties.map((prp) =>
                    prp === "" ? (
                      <MenuItem key={prp} value="">
                        TUTTE
                      </MenuItem>
                    ) : (
                      <MenuItem key={prp} value={prp}>
                        {prp}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                onClick={handleSave}
                style={{
                  backgroundColor: "rgb(3, 155, 229)",
                  color: "white",
                }}
                elevation={0}
                disabled={isLoading}
              >
                SALVA
              </Button>
              {isLoading && <CircularProgress />}
            </div>
          </CardContent>
        </Card>
        <Card>
          {isDataLoading && <LinearProgress />}
          <CardContent style={{ paddingTop: 0 }}>
            <Title>Parametri</Title>
            <div>
              <div>
                <FormControl style={{ margin: "1rem" }}>
                  <InputLabel id="tipo-label">Tipo</InputLabel>
                  <Select
                    labelId="tipo-label"
                    value={parametri.tipo}
                    name="tipo"
                    onChange={handleChangeParametri}
                    style={{ width: 150 }}
                  >
                    <MenuItem key="" value="">
                      NON ATTIVO
                    </MenuItem>
                    {Object.keys(codiciBackOffice).map((codice) => (
                      <MenuItem key={codice} value={codice}>
                        {codiciBackOffice[codice]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextInput
                  label="Pagamenti IVA Split"
                  value={parametri.pagamentiIvaSplit ?? ""}
                  name="pagamentiIvaSplit"
                  onChange={handleChangeParametri}
                />
                <TextInput
                  label="Documenti PA"
                  value={parametri.documentiPA ?? ""}
                  name="documentiPA"
                  onChange={handleChangeParametri}
                />
                <TextInput
                  label="Codice Hotel"
                  value={parametri.codiceHotel ?? ""}
                  name="codiceHotel"
                  onChange={handleChangeParametri}
                />
              </div>
              <div
                style={{ display: "inline-block", margin: "2rem 1rem 0 1rem" }}
              >
                FTP Attivo
                <Checkbox
                  name="ftpActive"
                  checked={parametri.ftpActive}
                  onChange={(e) =>
                    setParametri({ ...parametri, ftpActive: e.target.checked })
                  }
                />
              </div>
              <div>
                <FormControl style={{ margin: "1rem" }}>
                  <InputLabel id="ftp-label">Tipo FTP</InputLabel>
                  <Select
                    labelId="ftp-label"
                    value={parametri.ftp ?? ""}
                    name="ftp"
                    onChange={handleChangeParametri}
                    style={{ width: 150 }}
                  >
                    <MenuItem key="" value="">
                      Tipo FTP
                    </MenuItem>
                    <MenuItem key="FTP" value="FTP">
                      FTP
                    </MenuItem>
                    <MenuItem key="SFTP" value="SFTP">
                      SFTP
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextInput
                  label="Ip"
                  value={parametri.ip ?? ""}
                  name="ip"
                  onChange={handleChangeParametri}
                />
                <TextInput
                  label="Port"
                  value={parametri.port ?? ""}
                  name="port"
                  onChange={handleChangeParametri}
                />
                <TextInput
                  label="User"
                  value={parametri.user ?? ""}
                  name="user"
                  onChange={handleChangeParametri}
                />
                <TextInput
                  label="Password"
                  value={parametri.password ?? ""}
                  name="password"
                  onChange={handleChangeParametri}
                />
                <TextInput
                  label="Hostkey"
                  value={parametri.hostkey ?? ""}
                  name="hostkey"
                  onChange={handleChangeParametri}
                />
                <TextInput
                  label="Percorso"
                  value={parametri.path ?? ""}
                  name="path"
                  onChange={handleChangeParametri}
                />
                {parametri.tipo === "6" && (
                  <div className={classes.fieldsContainer}>
                    <TextInput
                      label="Codici Fact. Semplificada"
                      value={parametri.codFS ?? ""}
                      name="codFS"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Customers account"
                      value={parametri.customersAccount ?? ""}
                      name="customersAccount"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Customers account Fact. Semplificada"
                      value={parametri.customersAccountFS ?? ""}
                      name="customersAccountFS"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Descrizione cliente generico"
                      value={parametri.clientesReception ?? ""}
                      name="clientesReception"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Conto anticipi (utilizzo depositi)"
                      value={parametri.contoAnticipo ?? ""}
                      name="contoAnticipo"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Lista pagamenti a sospeso"
                      value={parametri.codPagSosp ?? ""}
                      name="codPagSosp"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Codici doc. fiscali"
                      value={parametri.codDocFisc ?? ""}
                      name="codDocFisc"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Codici doc. fattura"
                      value={parametri.codDocFattura ?? ""}
                      name="codDocFattura"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Codici doc. Nota di credito"
                      value={parametri.codDocNC ?? ""}
                      name="codDocNC"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Conto Vendite"
                      value={parametri.contoVendite ?? ""}
                      name="contoVendite"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Codici addebiti deposito in negativo"
                      value={parametri.cotDepositi ?? ""}
                      name="cotDepositi"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Conto Cassa"
                      value={parametri.cassa ?? ""}
                      name="cassa"
                      onChange={handleChangeParametri}
                    />
                  </div>
                )}
                {parametri.tipo === "10" && (
                  <div>
                    <TextInput
                      label="Business Unit"
                      value={parametri.sunsystem?.businessUnit ?? ""}
                      name="sunsystem-businessUnit"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Budget Code"
                      value={parametri.sunsystem?.budgetCode ?? ""}
                      name="sunsystem-budgetCode"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Allow Bal. Tran."
                      value={parametri.sunsystem?.allowBalTran ?? ""}
                      name="sunsystem-allowBalTran"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Description"
                      value={parametri.sunsystem?.description ?? ""}
                      name="sunsystem-description"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Journal Type"
                      value={parametri.sunsystem?.journalType ?? ""}
                      name="sunsystem-journalType"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Posting Type"
                      value={parametri.sunsystem?.postingType ?? ""}
                      name="sunsystem-postingType"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Suspense Acc."
                      value={parametri.sunsystem?.suspenseAcc ?? ""}
                      name="sunsystem-suspenseAcc"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Trans. Amount Acc."
                      value={parametri.sunsystem?.transAmountAcc ?? ""}
                      name="sunsystem-transAmountAcc"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Reporting Acc."
                      value={parametri.sunsystem?.reportingAcc ?? ""}
                      name="sunsystem-reportingAcc"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Calc. Debit/Credit Marker"
                      value={parametri.sunsystem?.calcDebitCreditMarker ?? ""}
                      name="sunsystem-calcDebitCreditMarker"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Suppress Subs. Messages"
                      value={parametri.sunsystem?.suppressSubsMessages ?? ""}
                      name="sunsystem-suppressSubsMessages"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Report Errors Only"
                      value={parametri.sunsystem?.reportErrorsOnly ?? ""}
                      name="sunsystem-reportErrorsOnly"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Guest Ledger Acc."
                      value={parametri.sunsystem?.guestLedgerAcc ?? ""}
                      name="sunsystem-guestLedgerAcc"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="City Ledger Acc."
                      value={parametri.sunsystem?.cityLedgerAcc ?? ""}
                      name="sunsystem-cityLedgerAcc"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Deposit Ledger Acc."
                      value={parametri.sunsystem?.depositLedgerAcc ?? ""}
                      name="sunsystem-depositLedgerAcc"
                      onChange={handleChangeParametri}
                    />
                    {/* <TextInput
                      label="Tax Account"
                      value={parametri.sunsystem?.taxAccount ?? ""}
                      name="sunsystem-taxAccount"
                      onChange={handleChangeParametri}
                    /> */}
                    <TextInput
                      label="Hotel Code"
                      value={parametri.sunsystem?.hotelCode ?? ""}
                      name="sunsystem-hotelCode"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Currency Code"
                      value={parametri.sunsystem?.currencyCode ?? ""}
                      name="sunsystem-currencyCode"
                      onChange={handleChangeParametri}
                    />
                    <TextInput
                      label="Suspense Payment Codes"
                      value={parametri.sunsystem?.suspensePaymentCodes ?? ""}
                      name="sunsystem-suspensePaymentCodes"
                      onChange={handleChangeParametri}
                    />
                    <FormControlLabel
                      label="External Tax"
                      labelPlacement="top"
                      control={
                        <Checkbox
                          name="sunsystem-externalTax"
                          checked={!!parametri.sunsystem?.externalTax}
                          onChange={(e) => {
                            setParametri((parametri) => {
                              return {
                                ...parametri,
                                sunsystem: {
                                  ...parametri.sunsystem,
                                  externalTax: e.target.checked,
                                },
                              };
                            });
                          }}
                        />
                      }
                    />
                    <TextInput
                      label="No VAT tax groups"
                      value={parametri.sunsystem?.grpTaxNoVAT ?? ""}
                      name="sunsystem-grpTaxNoVAT"
                      onChange={handleChangeParametri}
                    />
                    {/* <TextInput
                      label="Ext. tax sunsystem code"
                      value={parametri.sunsystem?.extSunTaxCode ?? ""}
                      name="sunsystem-extSunTaxCode"
                      onChange={handleChangeParametri}
                    /> */}
                    <TextInput
                      label="No VAT tax description"
                      value={parametri.sunsystem?.extTaxNoVAT ?? ""}
                      name="sunsystem-extTaxNoVAT"
                      onChange={handleChangeParametri}
                    />
                    <div>
                      <Title>Taxes</Title>
                      <Button
                        onClick={() => {
                          setParametri((parametri) => {
                            return {
                              ...parametri,
                              sunsystem: {
                                ...parametri.sunsystem,
                                taxes: [
                                  ...(parametri.sunsystem?.taxes ?? []),
                                  {},
                                ],
                              },
                            };
                          });
                        }}
                      >
                        Aggiungi
                      </Button>
                    </div>
                    {parametri.sunsystem?.taxes.map((tax, i) => (
                      <Tax
                        key={i}
                        handleChangeTax={(e) => {
                          console.log(e);
                          const newTaxes = [...parametri.sunsystem.taxes];
                          if (e.target.type === "checkbox") {
                            newTaxes[i][e.target.name] = e.target.checked;
                          } else {
                            newTaxes[i][e.target.name] = e.target.value;
                          }
                          setParametri((parametri) => {
                            return {
                              ...parametri,
                              sunsystem: {
                                ...parametri.sunsystem,
                                taxes: newTaxes,
                              },
                            };
                          });
                        }}
                        handleDeleteTax={() => {
                          const newTaxes = [...parametri.sunsystem.taxes];
                          newTaxes.splice(i, 1);
                          setParametri((parametri) => {
                            return {
                              ...parametri,
                              sunsystem: {
                                ...parametri.sunsystem,
                                taxes: newTaxes,
                              },
                            };
                          });
                        }}
                        tax={tax}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
        <Snackbar
          open={openSnackbar}
          autoHideDuration="6000"
          onClose={() => setOpenSnackbar(false)}
        >
          <div>
            <Alert severity="success">Operazione effettuata</Alert>
          </div>
        </Snackbar>
        <Snackbar
          open={openSnackbarError}
          autoHideDuration="6000"
          onClose={() => {
            setOpenSnackbarError(false);
          }}
        >
          <div>
            <Alert severity="error">Operazione fallita</Alert>
          </div>
        </Snackbar>
      </div>
    </div>
  );
}

const TextInput = (props) => {
  const { small, ...textFieldProps } = props;
  return (
    <TextField
      style={{ margin: "1rem", ...(small && { width: "50px" }) }}
      {...textFieldProps}
    />
  );
};

const Title = (props) => (
  <Typography
    style={{
      color: "#1aa3ff",
      marginTop: "1rem",
      fontSize: "1rem",
    }}
    variant="h5"
    gutterBottom
    {...props}
  />
);

const Tax = ({ tax, handleChangeTax, handleDeleteTax }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        borderBottom: "1px solid lightgray",
      }}
    >
      <DeleteIcon
        style={{ fontSize: "20px" }}
        button
        onClick={handleDeleteTax}
      />
      <TextInput
        label="Group"
        value={tax.group ?? ""}
        name="group"
        onChange={handleChangeTax}
        small
      />
      <TextInput
        label="Code"
        value={tax.code ?? ""}
        name="code"
        onChange={handleChangeTax}
      />
      <TextInput
        label="Description"
        value={tax.description ?? ""}
        name="description"
        onChange={handleChangeTax}
      />
      <TextInput
        label="Percentage"
        value={tax.percentage ?? ""}
        name="percentage"
        onChange={handleChangeTax}
      />
      <TextInput
        label="Sunsystem Acc."
        value={tax.sunsystemAcc ?? ""}
        name="sunsystemAcc"
        onChange={handleChangeTax}
      />
      <TextInput
        label="Sunsystem Tax Code"
        value={tax.sunsystemTaxCode ?? ""}
        name="sunsystemTaxCode"
        onChange={handleChangeTax}
      />
      <TextInput
        label="Net Percentage Calc"
        value={tax.netPercentageCalc ?? ""}
        name="netPercentageCalc"
        onChange={handleChangeTax}
      />
      <FormControlLabel
        label="VAT"
        labelPlacement="top"
        control={
          <Checkbox
            name="isVAT"
            checked={tax.isVAT ?? false}
            onChange={handleChangeTax}
          />
        }
      />
    </div>
  );
};

export default SetupBackOffice;
