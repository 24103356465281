import React, { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardActions,
  CardContent,
  Typography,
  CircularProgress,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import config from "../config";

function ReportCityTax() {
  const [setups, setSetups] = React.useState([]);
  const [properties, setProperties] = React.useState([]);
  const [selectedProperty, setSelectedProperty] = React.useState("");
  const [reportUrl, setReportUrl] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
  const [dialogError, setDialogError] = React.useState({
    title: "Errori",
    open: false,
    errors: [],
  });

  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(`${config.protelExpressUri}/api/findsProperty`, { hotelID: id })
      .then((res) => {
        // TODO: refactor
        let setup = res.data;
        let properties = [];
        if (setup) {
          const cityTaxSetups = setup?.cityTax;
          if (cityTaxSetups) {
            properties = cityTaxSetups.filter((p) => p.urlReportBI)
            setSetups(properties)
          }
        }
        setSelectedProperty(properties[0].propertyCode);
        setReportUrl(properties[0]?.urlReportBI ?? "")
        setProperties(properties.map(p => p.propertyCode));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [id]);

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#1aa3ff",
          height: "60px",
          display: "flex",
          alignItems: "center",
          marginLeft: 250,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "white",
            fontSize: 20,
            marginLeft: 40,
          }}
          gutterBottom
        >
          Report Tassa di Soggiorno
        </Typography>
      </div>

      <div style={{ margin: "10px 10px 0px 290px" }}>
        <Card style={{ marginBottom: 10 }}>
          <CardContent>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
              <FormControl>
                <InputLabel id="property-label">Property</InputLabel>
                <Select
                  labelId="property-label"
                  value={selectedProperty}
                  name="property"
                  onChange={(e) => {
                    setSelectedProperty(e.target.value);
                    setReportUrl(setups.find(s => s.propertyCode === e.target.value).urlReportBI)
                  }}
                  style={{ width: 150 }}
                >
                  {properties.map((prp) =>
                    prp === "" ? (
                      <MenuItem key={prp} value="">
                        TUTTE
                      </MenuItem>
                    ) : (
                      <MenuItem key={prp} value={prp}>
                        {prp}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
            <CardActions style={{ padding: "1rem 0 0 0" }}>
              {isLoading && <CircularProgress />}
            </CardActions>
            <iframe
              src={reportUrl}
              width="100%"
              style={{ height: "80vh", marginTop: "1rem", borderRadius: "4px", border: "none" }}
            />
          </CardContent>
        </Card>

        <Snackbar
          open={openSnackbar}
          autoHideDuration="6000"
          onClose={() => setOpenSnackbar(false)}
        >
          <div><Alert severity="success">Operazione effettuata</Alert></div>
        </Snackbar>
        <Snackbar
          open={openSnackbarError}
          autoHideDuration="6000"
          onClose={() => {
            setOpenSnackbarError(false);
          }}
        >
          <div><Alert severity="error">Operazione fallita</Alert></div>
        </Snackbar>
        <Dialog
          open={dialogError.open}
          onClose={() =>
            setDialogError({ open: false, errors: [], title: "Errori" })
          }
        >
          <DialogTitle style={{ paddingBottom: 0 }}>
            {dialogError.title}
          </DialogTitle>
          <DialogContent>
            {dialogError.errors.map((error, i) => (
              <p key={i}>{error}</p>
            ))}
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default ReportCityTax;
