import React from 'react';
import './App.css';
import Home from './pages/Home';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { LoginPage } from './views/authentications';
import { AuthenticationProvider, AuthenticationContext } from './contexts';

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        }
      }
    }
  }
});

function App() {
  document.title = 'Protel I/O';

  return (
    <BrowserRouter>
      <AuthenticationProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/*" element={
          <RequireAuth redirectTo="/login">
            <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Home />
            </ThemeProvider>
          </StyledEngineProvider>
          </RequireAuth>
          }
        />
        </Routes>
      </AuthenticationProvider>
    </BrowserRouter>
  );
}

function RequireAuth({ children, redirectTo }) {
  const { currentUser } = React.useContext(AuthenticationContext);
  return currentUser ? children : <Navigate to={redirectTo} replace />;
}

export default App
