import React, { useEffect } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import config from "../../config";
import NativeSelect from "@mui/material/NativeSelect";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useParams } from "react-router-dom";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import FileDownload from "js-file-download";
import GetAppIcon from "@mui/icons-material/GetApp";
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@mui/material/Dialog";
import TitleBar from "../TitleBar";

function FormIstat() {
  const [daData, setDaData] = React.useState("");
  const [aData, setAData] = React.useState("");
  const [dataC59, setDataC59] = React.useState("");
  const [propertyData, setPropertyData] = React.useState([]);
  const [codiceProperty, setCodiceProperty] = React.useState("");
  const [propertyC59, setPropertyC59] = React.useState("");
  const [propertyC59Mod, setPropertyC59Mod] = React.useState("");
  const [dataASTAT, setDataASTAT] = React.useState("");
  const [propertyASTAT, setPropertyASTAT] = React.useState("");

  const [showLoad, setShowLoad] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState(
    "Errori nella generazione"
  );
  const [modalLink, setModalLink] = React.useState("");
  const { id } = useParams();

  //data odierna
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  var dataOdierna = yyyy + mm + dd;

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    form: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "baseline",
      gap: "1rem",
      margin: "1rem",
    },
  }));

  //modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalTitle("Errori nella generazione");
    setModalLink("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    var configIstat = {
      method: "get",
      url: `${config.requestsUri}/istat/?hotelID=${id}&from=${daData}&to=${aData}&propertyCode=${codiceProperty}`,
      headers: {},
    };

    axios(configIstat)
      .then(showLoading())
      .then(function (response) {
        handleOpenSuccess();
        setShowLoad(false);
        FileDownload(response.data.data, response.data.fileName);
      })
      .catch(function (error) {
        setShowLoad(false);
        handleOpenModal();
        error.response && error.response.data !== ""
          ? setModalMessage(error.response.data)
          : setModalMessage(error.message);
      });
  };

  const handleInfoASTAT = (e) => {
    e.preventDefault();
    axios
      .post(
        `${config.requestsUri}/qsvc?service=GETDATICHIUSURAASTAT&hotelID=${id}&from=${dataASTAT}&to=${dataASTAT}&propertyCode=${propertyASTAT}`
      )
      .then(showLoading())
      .then(function (response) {
        setShowLoad(false);
        handleOpenModal();
        setModalTitle("Info Mese");
        setModalMessage(response.data.data);
      })
      .catch(function (error) {
        setShowLoad(false);
        handleOpenModal();
        error.response && error.response.data !== ""
          ? setModalMessage(error.response.data)
          : setModalMessage(error.message);
      });
  };

  const handleChiudiASTAT = (e) => {
    e.preventDefault();
    axios
      .post(
        `${config.requestsUri}/qsvc?service=CHIUDIMESEASTAT&hotelID=${id}&from=${dataASTAT}&to=${dataASTAT}&propertyCode=${propertyASTAT}`
      )
      .then(showLoading())
      .then(function (response) {
        setShowLoad(false);
        handleOpenModal();
        setModalTitle("Chiusura Mese");
        setModalMessage(["Mese chiuso con successo.", "Avviso di Pagamento:"]);
        setModalLink(response.data.data);
      })
      .catch(function (error) {
        setShowLoad(false);
        handleOpenModal();
        error.response && error.response.data !== ""
          ? setModalMessage(error.response.data)
          : setModalMessage(error.message);
      });
  };

  const handleSubmitC59 = (event, service, property, data = "") => {
    event.preventDefault();
    axios
      .post(
        `${config.requestsUri}/qsvc?service=${service}&hotelID=${id}&data=${data}&propertyCode=${property}`
      )
      .then(showLoading())
      .then(function () {
        handleOpenSuccess();
        setShowLoad(false);
      })
      .catch(function (error) {
        setShowLoad(false);
        handleOpenModal();
        error.response && error.response.data !== ""
          ? setModalMessage(error.response.data)
          : setModalMessage(error.message);
      });
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //Spinner
  const showLoading = () => {
    setShowLoad(!showLoad);
  };

  //snackbar success
  const handleOpenSuccess = () => {
    setOpen(true);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  //end success

  const downloadError = () => {
    let erroreModal = modalMessage.toString();
    var erroreFormattato = erroreModal.split(" ,").join("\n");
    FileDownload(erroreFormattato, `IstatError_${dataOdierna}.txt`);
  };

  useEffect(() => {
    var dataP = JSON.stringify({ hotelID: id });

    var configPr = {
      method: "post",
      url: `${config.protelExpressUri}/api//findsProperty`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataP,
    };

    async function getDataP() {
      const result = await axios(configPr);
      setPropertyData((propertyData) => [...propertyData, result.data]);
    }
    getDataP();
  }, [id]);

  const classes = useStyles();

  return (
    <>
      <div style={{ height: "100vh", backgroundColor: "#F8F8F8" }}>
        <div
          style={{
            height: "64px",
          }}
        ></div>

        <TitleBar title="Genera File Istat" loading={showLoad} />

        <div style={{ marginLeft: 290, display: "flex" }}>
          <Dialog
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModal}
            onClose={handleCloseModal}
            closeAfterTransition
          >
            <Fade in={openModal}>
              <div className={classes.paper}>
                <div style={{ display: "flex" }}>
                  <GetAppIcon
                    button
                    onClick={downloadError}
                    style={{}}
                  ></GetAppIcon>
                  <CloseIcon
                    button
                    onClick={handleCloseModal}
                    style={{
                      flex: "0 0 50%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  ></CloseIcon>
                </div>
                <h4 id="transition-modal-title">{modalTitle}</h4>
                {Array.isArray(modalMessage) ? (
                  modalMessage.map((value, index) => (
                    <Typography key={index} id="transition-modal-description">
                      {typeof value === "string" ? value : JSON.stringify(value, null, 2)}
                    </Typography>
                  ))
                ) : (
                  <Typography>{modalMessage}</Typography>
                )}
                {modalLink ? (
                  <a href={modalLink} target="_blank" rel="noreferrer">
                    {modalLink}
                  </a>
                ) : null}
              </div>
            </Fade>
          </Dialog>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseSuccess}
          >
            <div>
              <Alert severity="success">SUCCESS!</Alert>
            </div>
          </Snackbar>
          <Card
            style={{ minWidth: 250, marginTop: 11, borderRadius: 5 }}
            elevation={0}
          >
            <CardContent>
              <Typography gutterBottom color="primary">
                Genera File Istat
              </Typography>
              <form className={classes.form}>
                <TextField
                  helperText={
                    <Typography variant="title" display="block">
                      Da Data
                    </Typography>
                  }
                  value={daData}
                  type="date"
                  format={"yyyy/MM/dd"}
                  onChange={(e) => setDaData(e.target.value)}
                />
                <TextField
                  helperText={
                    <Typography variant="caption" display="block">
                      A Data
                    </Typography>
                  }
                  value={aData}
                  type="date"
                  format={"yyyy/MM/dd"}
                  onChange={(e) => setAData(e.target.value)}
                />
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: 190 }}
                  margin="dense"
                >
                  <NativeSelect
                    native
                    label="Codice Property"
                    onChange={(e) => setCodiceProperty(e.target.value)}
                  >
                    <option></option>
                    {propertyData.map((value) => {
                      if (value.propertyConfiguration)
                        return value.propertyConfiguration.map((value) =>
                          value.propertyCode ? (
                            <option value={value.propertyCode}>
                              {value.propertyCode}
                            </option>
                          ) : null
                        );
                    })}
                  </NativeSelect>
                  <FormHelperText>Codice Proprietà</FormHelperText>
                </FormControl>
                <CardActions>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={showLoad || !daData || !aData}
                  >
                    Genera
                  </Button>
                </CardActions>
              </form>
              {propertyData.map((data) => {
                if (data.setup) {
                  const istatType = data.setup.find(
                    (s) => s.code === "istatType"
                  );
                  if (istatType) {
                    if (istatType.value === "10") {
                      return (
                        <div>
                          <Typography gutterBottom color="primary">
                            Inserisci/Elimina C59
                          </Typography>
                          <form className={classes.form}>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                              style={{ minWidth: 190 }}
                              margin="dense"
                            >
                              <NativeSelect
                                native
                                label="Codice Property"
                                onChange={(e) => setPropertyC59(e.target.value)}
                              >
                                <option></option>
                                {propertyData.map((value) => {
                                  if (value.propertyConfiguration)
                                    return value.propertyConfiguration.map(
                                      (value) =>
                                        value.propertyCode ? (
                                          <option value={value.propertyCode}>
                                            {value.propertyCode}
                                          </option>
                                        ) : null
                                    );
                                })}
                              </NativeSelect>
                              <FormHelperText>Codice Proprietà</FormHelperText>
                            </FormControl>
                            <Button
                              variant="contained"
                              onClick={(e) =>
                                handleSubmitC59(e, "INSERISCIC59M", propertyC59)
                              }
                              disabled={showLoad}
                            >
                              Inserisci C59
                            </Button>
                            <Button
                              variant="contained"
                              onClick={(e) =>
                                handleSubmitC59(e, "ELIMINAC59M", propertyC59)
                              }
                              disabled={showLoad}
                            >
                              Elimina C59
                            </Button>
                          </form>
                          <Typography gutterBottom color="primary">
                            Modifica C59
                          </Typography>
                          <form className={classes.form}>
                            <TextField
                              id="standard-basic"
                              helperText={
                                <Typography variant="title" display="block">
                                  Data
                                </Typography>
                              }
                              value={dataC59}
                              type="date"
                              format={"yyyy/MM/dd"}
                              onChange={(e) => setDataC59(e.target.value)}
                            />
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                              style={{ minWidth: 190 }}
                              margin="dense"
                            >
                              <NativeSelect
                                native
                                label="Codice Property"
                                onChange={(e) =>
                                  setPropertyC59Mod(e.target.value)
                                }
                              >
                                <option></option>
                                {propertyData.map((value) => {
                                  if (value.propertyConfiguration)
                                    return value.propertyConfiguration.map(
                                      (value) =>
                                        value.propertyCode ? (
                                          <option value={value.propertyCode}>
                                            {value.propertyCode}
                                          </option>
                                        ) : null
                                    );
                                })}
                              </NativeSelect>
                              <FormHelperText>Codice Proprietà</FormHelperText>
                            </FormControl>
                            <Button
                              variant="contained"
                              onClick={(e) =>
                                handleSubmitC59(
                                  e,
                                  "MODIFICAC59M",
                                  propertyC59Mod,
                                  dataC59
                                )
                              }
                              disabled={showLoad || !dataC59}
                            >
                              Modifica C59
                            </Button>
                          </form>
                        </div>
                      );
                    }
                    if (istatType.value === "14") {
                      return (
                        <div>
                          <Typography gutterBottom color="primary">
                            Info/Chiusura Mese
                          </Typography>
                          <form className={classes.form}>
                            <TextField
                              id="standard-basic"
                              helperText={
                                <Typography variant="title" display="block">
                                  Mese
                                </Typography>
                              }
                              value={dataASTAT}
                              type="date"
                              format={"yyyy/MM/dd"}
                              onChange={(e) => setDataASTAT(e.target.value)}
                            />
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                              style={{ minWidth: 190 }}
                              margin="dense"
                            >
                              <NativeSelect
                                native
                                label="Codice Property"
                                onChange={(e) =>
                                  setPropertyASTAT(e.target.value)
                                }
                              >
                                <option></option>
                                {propertyData.map((value) => {
                                  if (value.propertyConfiguration)
                                    return value.propertyConfiguration.map(
                                      (value) =>
                                        value.propertyCode ? (
                                          <option value={value.propertyCode}>
                                            {value.propertyCode}
                                          </option>
                                        ) : null
                                    );
                                })}
                              </NativeSelect>
                              <FormHelperText>Codice Proprietà</FormHelperText>
                            </FormControl>
                            <Button
                              variant="contained"
                              disabled={showLoad || !dataASTAT}
                              onClick={handleInfoASTAT}
                            >
                              INFO MESE
                            </Button>
                            <Button
                              variant="contained"
                              disabled={showLoad || !dataASTAT}
                              onClick={handleChiudiASTAT}
                            >
                              CHIUDI MESE
                            </Button>
                          </form>
                        </div>
                      );
                    }
                  }
                }
              })}
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default FormIstat;
