const configDev = {
  protelExpressUri: process.env.EXPRESS || 'http://localhost:4000',
  protelUserUri: 'http://localhost:4001',
  requestsUri: 'http://localhost:9090',
  authentication: {
    clientId: process.env.CLIENT_ID || '60a38112e9c95384af0fff78', // 5c5beecf0db78b1a3cd35501 5d9c66754e801f5d585f8cee
    redirectUri: process.env.REDIRECT || 'http://localhost:3000/login',
    loginUri: process.env.LOGIN || 'http://localhost:4001/oauth/authorize',
    logoutUri: process.env.LOGOUT || 'http://localhost:4001/logout',
  },
  fiscalizeITServer: 'http://localhost:3030'
};

const configProd = {
  protelExpressUri: 'https://api.protelair.serinf-cloud.com',
  protelUserUri: 'https://login.protelair.serinf-cloud.com',
  requestsUri: 'https://sender.protelair.serinf-cloud.com',
  authentication: {
    clientId: '60a38112e9c95384af0fff78',
    redirectUri: 'https://app.protelair.serinf-cloud.com/login',
    loginUri: 'https://login.protelair.serinf-cloud.com/oauth/authorize',
    logoutUri: 'https://login.protelair.serinf-cloud.com/logout',
  },
  fiscalizeITServer: 'https://fiscalizeit.protelio.serinf-cloud.com'
};

const config = process.env.NODE_ENV === "production" ? configProd : configDev

export default config;
