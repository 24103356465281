import * as React from 'react';
import { useEffect } from "react";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../config";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  Typography, 
  TextField,
  Card,
  CardContent,
  Select,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Button,
  CircularProgress,
  LinearProgress,
  Checkbox,
  FormControlLabel,
  tableHeadClasses,
  
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import { codiciBackOffice } from "../values";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Popover from '@mui/material/Popover'; 
import HomeIcon from '@mui/icons-material/Home'; 
import ContactsIcon from '@mui/icons-material/Contacts';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import LooksFourIcon from '@mui/icons-material/LooksTwo';
import Looks5Icon from '@mui/icons-material/Looks5';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Menu from '@mui/material/Menu';
import TextareaAutosize from '@mui/material/TextareaAutosize';









// import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';



const initialStateParametri = {
  tipo: "",
  pagamentiIvaSplit: "",
  nombreEstablecimiento: "",
  razonSocial: "",
  cifnif: "",
  numeroregistro: "",
  direccion: "",
  codigoPostal:"",
  localidad:"",
  municipio:"",
  provincia:"",
  telefono: "",
  categoria: "",
  habitaciones: "",
  plazadisponibles: "",
  personalnoremunerado: "",
  personalremuneradofijo: "",
  personalremuneradoeventual: "",
  email: "",
  segmento: " ",
  tipisegmento: [
  ],
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function SetupIne() {
  const [setupData, setSetupData] = React.useState();
  const [selectedProperty, setSelectedProperty] = React.useState("");
  const [properties, setProperties] = React.useState([]);
  const [parametri, setParametri] = React.useState(initialStateParametri);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDataLoading, setIsDataLoading] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
  
  const [openHotel, setOpenHotel] = React.useState(false);
  const [openDireccion, setOpenDireccion] = React.useState(false);
  const [openDatos, setOpenDatos] = React.useState(false);
  const [openConctatos, setOpenConctatos] = React.useState(false);
  const [openPersonal, setOpenPersonal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openP, setOpenP] = React.useState(false);
  const [visible, setNotVisible] = React.useState(false);
  const [marketCodeData, setMarketCodeData]= React.useState([]); //ogliamo salvare qua dentro i dati della richiesta
  const [dati, setDati] = React.useState([]); //vogliamo salvare u dati della richiesta 
  
  const [showTable, setShowTable] = React.useState(false);

  const propertyInputRef = useRef(null);

  const handleInputChange = (event) => {
    // Controlla se il valore dell'input è vuoto
    if (event.target.value.trim() === '') {
      // Se sì, imposta il focus sull'elemento InputLabel
      propertyInputRef.current.focus();
    }
    // Altrimenti, gestisci il valore dell'input come desiderato
    // ...
  };

  // const MarketCode = [
  //   { value: 'ND', label: 'ND' },
  //   { value: 'TOUROPERATOR_TRADICIONAL', label: 'TOUROPERATOR_TRADICIONAL' },
  //   { value: 'TOUROPERATOR_ONLINE', label: 'TOUROPERATOR_ONLINE' },
  //   { value: 'EMPRESAS', label: 'EMPRESAS' },
  //   { value: 'AGENCIA_DE_VIAJE_TRADICIONAL', label: 'AGENCIA_DE_VIAJE_TRADICIONAL' },
  //   { value: 'AGENCIA_DE_VIAJE_ONLINE', label: 'AGENCIA_DE_VIAJE_ONLINE' },
  //   { value: 'ADR_PARTICULARES', label: 'ADR_PARTICULARES' },
  //   { value: 'GRUPOS', label: 'GRUPOS' },
  //   { value: 'INTERNET', label: 'INTERNET' },
  //   { value: 'OTROS', label: 'OTROS' },
  // ];

  const DistributionChannels = [
    { value: '" " ', label: '(campo vuoto)' },
    { value: 'TOUROPERATOR_TRADICIONAL', label: 'TOUROPERATOR_TRADICIONAL' },
    { value: 'TOUROPERATOR_ONLINE', label: 'TOUROPERATOR_ONLINE' },
    { value: 'EMPRESAS', label: 'EMPRESAS' },
    { value: 'AGENCIA_DE_VIAJE_TRADICIONAL', label: 'AGENCIA_DE_VIAJE_TRADICIONAL' },
    { value: 'AGENCIA_DE_VIAJE_ONLINE', label: 'AGENCIA_DE_VIAJE_ONLINE' },
    { value: 'ADR_PARTICULARES', label: 'ADR_PARTICULARES' },
    { value: 'GRUPOS', label: 'GRUPOS' },
    { value: 'INTERNET', label: 'INTERNET' },
    { value: 'OTROS', label: 'OTROS' },
  ];


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickHotel = () => {
    setOpenHotel(!openHotel);
    setOpenDireccion(false);
    setOpenDatos(false);
    setOpenConctatos(false);
    setOpenPersonal(false);
  }

  const handleClickDireccion = () => {
    setOpenDireccion(!openDireccion);
    setOpenHotel(false);
    setOpenDatos(false);
    setOpenConctatos(false);
    setOpenPersonal(false);
  }

  const handleClickDatos = () => {
    setOpenDatos(!openDatos);
    setOpenHotel(false);
    setOpenDireccion(false);
    setOpenConctatos(false);
    setOpenPersonal(false);
  }

  const handleClickConctatos = () => {
    setOpenConctatos(!openConctatos);
    setOpenHotel(false);
    setOpenDireccion(false);
    setOpenDatos(false);
    setOpenPersonal(false);
  }

  const handleClickPersonal = () => {
    setOpenPersonal(!openPersonal);
    setOpenHotel(false);
    setOpenDireccion(false);
    setOpenDatos(false);
    setOpenConctatos(false);
  }

  const handleCancel = () => {

    setOpenHotel(false);
    setOpenDireccion(false);
    setOpenDatos(false);
    setOpenConctatos(false);
    setOpenPersonal(false);
    
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenP = async () => {
    await handleLeggiData("MarketCode");
    setParametri({...parametri, segmento: "MarketCode"}) // Aspetta che handleLeggiMarketCode abbia completato l'esecuzione
    setOpenP(true);
  };

  const handleClickOpenD = async () => {
    await handleLeggiData("DistributionChannels");
    setParametri({ ...parametri, segmento: "DistributionChannels" });
    setOpenP(true);
  }

  const handleCloseP = () => {
    setOpenP(false);
  };


  const handleCloseL = () => {
    setAnchorEl(null);
  };

  const handleContainerClick = () => {
    if (anchorEl) {
      handleClose();
    }
  };

  const tableHeadStyle = {
    
    border: '1px solid #ddd',
    padding: '20px',
    textAlign: 'center',

  };

  const tableCellStyle = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center',
    
  };



  const { id } = useParams();



  useEffect(() => {
    /* setOpenConctatos(false);
    setOpenDatos(false);
    setOpenDireccion(false);
    setOpenHotel(false);
    setIsDataLoading(true);
    setOpenPersonal(false); */
    axios
      .post(`${config.protelExpressUri}/api/findsProperty`, { hotelID: id })
      .then((res) => {
        let setup = res.data;
        console.log(setup);
        if (setup === "" || !setup) {
          setup = { propertyConfiguration: [], ine: [] };
        }
        setSetupData(setup);
        let propertyCodes = setup.propertyConfiguration.map(
          (p) => p.propertyCode
        );
        if (propertyCodes.length > 0) {
          setProperties(["", ...propertyCodes]);
          //setSelectedProperty("");
          
        } else {
          propertyCodes = [""];
          setProperties([""]);
          setSelectedProperty("");
        }

        //setCityTaxSetup(setup?.cityTax ?? []);
        const selectedSetup = setup.ine.find(
          (p) => p.propertyCode === ""
        );

        if (selectedSetup) {
          setParametri({ ...selectedSetup });
        }
        else {
          setParametri(initialStateParametri);
        }
        setIsDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoading(false);
      });
  }, [id, reload]);

  useEffect(() => {
    const selectedSetup = setupData?.ine?.find((p) => p.propertyCode === selectedProperty);
    if (selectedSetup) {
      setParametri({ ...selectedSetup });
    }
    else {
      setParametri(initialStateParametri);
    }
  }, [selectedProperty, setupData])

  const handleChangeParametri = (e) => {
    if (e.target.name.split("-").length === 2) {
      const [tipo, name] = e.target.name.split("-");
      setParametri({
        ...parametri,
        [tipo]: { ...parametri[tipo], [name]: e.target.value },
      });
    } else {
      setParametri({ ...parametri, [e.target.name]: e.target.value });
    }
  };

  

  const handleSave = async () => {
    setIsLoading(true);
    axios
      .put(`${config.protelExpressUri}/api/updateIneSetup`, {
        hotelID: id,
        propertyCode: selectedProperty,
        parametri
        
        
        
        
      })
      .then(() => {
        setIsLoading(false);
        setOpenSnackbar(true);
        setReload(!reload);
      })
      .catch((error) => {
        console.log(error);
        setOpenSnackbarError(true);
        setIsLoading(false);
      });
  };

  const handleSaveSegmento = async () => {
    setIsLoading(true);

    // Aggiungi tipisegmento ai parametri

    axios
      .put(`${config.protelExpressUri}/api/updateSegmentoSetup`, {
        hotelID: id,
        propertyCode: selectedProperty,
        marketCodeData,  
        segmento : parametri.segmento 
      })
      .then(() => {
        setIsLoading(false);
        setOpenSnackbar(true);
        setOpenP(false);
        setReload(!reload);
      })
      .catch((error) => {
        console.log(error);
        setOpenSnackbarError(true);
        setIsLoading(false);
      });
  };

  const handleDeleteIne= async () => {
    setIsLoading(true);

    try {
      await axios.delete(`${config.protelExpressUri}/api/deleteIne`, {
        data: {
          hotelID: id,
          propertyCode: selectedProperty,
          parametri,
          // You might need to include other parameters needed for the delete request
        },
      });
     
    } catch (error) {
      console.error(error);
      setOpenSnackbarError(true);
      setIsLoading(false);
    }
  };

  const handleDelete = () => {
    handleDeleteIne();
    handleCancel();
    setIsLoading(false);
    setOpenSnackbar(true);
    setOpen(false);
  }



  
  // const handleSaveMarket = async () => {
  //   setIsLoading(true);
  //   axios
  //     .put(`${config.requestsUri}/SYSDATA?hotelID=${id}`, {
  //       hotelID: id,
  //       propertyCode: selectedProperty,
  //       parametri,
  //     })
  //     .then(() => {
  //       setIsLoading(false);
  //       setOpenSnackbar(true);
  //       setReload(!reload);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setOpenSnackbarError(true);
  //       setIsLoading(false);
  //     });
  // };

  
  // const fetchDati = async () => {
  //   try {
  //     const response = await axios.get(`${config.protelExpressUri}/api/getSegmentoSetup`, {
  //       params: {
  //         hotelID: id,
  //         propertyCode: selectedProperty,
  //         marketCodeData,  
  //         segmento : parametri.segmento 
  //       },
  //     });
  //     setDati(response.data);
  //   } catch (error) {
  //     console.error('Errore nel recupero dei dati:', error);
  //   }
  // };

  // useEffect(() => {
  //   // Esegui fetchDati quando il componente si monta
  //   fetchDati();
  // }, []); // L'array vuoto assicura che l'effetto venga eseguito solo una volta
   

    

            
     const handleLeggiData = async (segmento) => {
      setIsLoading(true);
   
      try {
        const response = await axios.get(`${config.requestsUri}/SYSDATA?hotelID=${id}`);
        const data = response.data.data.IO_SystemDataRS.SystemData;
        console.log(data);

        let rawData;
        

        if(segmento ==="MarketCode")
        {
          rawData = data.Reservations.MarketCodes.MarketCode;
          
          
        }
        else if (segmento === "DistributionChannels")
        {
          rawData = data.Reservations.DistributionChannels.DistributionChannel;
          
         
        }

        const newData = rawData.map((item) => ({
          code: item.$.Code,
          name: item.Name._,
          type: "",
          active: true
        }))
        console.log("stiamo su newdata", newData);
        
  
        setMarketCodeData(newData);
        console.log(marketCodeData);
        console.log ("siamo qua");
        setIsLoading(false);

       

        console.log (newData);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching distribution data: '. error);
        }
      }
          

  const useStyles = makeStyles(() => ({
    fieldsContainer: {
      "& > .MuiTextField-root": {
        minWidth: "20vw",
      },
    },
  }));

  const classes = useStyles();

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#1aa3ff",
          height: "60px",
          display: "flex",
          alignItems: "center",
          marginLeft: 250,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "white",
            fontSize: 20,
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",  // Centra il testo
            fontWeight: "bold",  // Imposta il testo in grassetto

          }}

        >
          Setup INE
        </Typography>
      </div>
      
      <div style={{ margin: "10px 10px 0px 290px" }}>
        <Card style={{ marginBottom: "1rem" }}>
          <CardContent>
            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <FormControl>
                <InputLabel 
                id="property-label" 
                ref={propertyInputRef}
                onChange={handleInputChange}>
                  Property(hotel o casa)
                  </InputLabel>
                <Select
                  labelId="property-label"
                  value={selectedProperty}
                  name="property"
                  onChange={(e) => {
                    setSelectedProperty(e.target.value);
                    /* const selectedSetup = setupData.ine.find(
                      (p) => p.propertyCode === e.target.value
                    );
                    if (selectedSetup) {
                      setParametri({ ...selectedSetup });
                    } else {
                      setParametri(initialStateParametri);
                    } */
                  }}
                  style={{ width: 150 }}
                >
                  {properties.map((prp) =>
                    prp === "" ? (
                      <MenuItem key={prp} value="">
                        
                      </MenuItem>
                    ) : (
                      <MenuItem key={prp} value={prp}>
                        {prp}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                onClick={handleSave}
                style={{
                  backgroundColor: "rgb(3, 155, 229)",
                  color: "white",
                  marginTop: "13px",
                }}
                elevation={0}
                disabled={isLoading}
              >
                SALVA
              </Button>

              <Button style={{
                  backgroundColor: "rgb(3, 155, 229)",
                  color: "white",
                  marginTop: "13px",
                }}
                variant="contained"
                onClick={handleClickOpen}>
                  

                Elimina
              </Button>
              
              <Dialog
                  open={open}
                  onClose={handleClose}
                  maxWidth="lg"
                  aria-labelledby="alert-dialog-title"
                   aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"PROPERTY"}
               </DialogTitle>
               <DialogContent>
                 <DialogContentText id="alert-dialog-description">
                  Sei sicuro di voler eliminare i dati all interno della property?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                 <Button onClick={handleClose}>Rifiuta</Button>
                 <Button onClick={handleDelete} autoFocus>
                  Conferma
                 </Button>
            </DialogActions>
             </Dialog>
             

                     
              
             
            </div>
          </CardContent>
        </Card>
        
        
        
        <Card>
          {isDataLoading && <LinearProgress />}
          <CardContent style={{ paddingTop: 0 }}>
            <Title>Parametri da inserire </Title>
            <div>
              <div>
              
                
            
                {/* qua inizia la parte co popup */}

              

              <Card sx={{ width: 1000 }}> {/* Imposta la larghezza desiderata */}
              <ListItemButton onClick={handleClickHotel}>
                <ListItemIcon>
                 <LooksOneIcon style={{ color: 'rgb(3, 155, 210)'}} />
                </ListItemIcon>
               <ListItemText primary="Hotel" />
              {openHotel ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openHotel} timeout="auto" unmountOnExit>
               <List component="div" disablePadding>

                <ListItemButton sx={{ pl: 4 }}>                
                   <ListItemText primary="Razon social" />
                   <TextInput
                  label="Razon Social"
                  value={parametri.razonSocial ?? ""}
                  name="razonSocial"
                  onChange={handleChangeParametri}
                />
                </ListItemButton>

                <ListItemButton sx={{ pl: 4 }}>
                
                   <ListItemText primary="Nombre establecimiento" />
                   <TextInput
                  label="Nombre Establicimiento"
                  value={parametri.nombreEstablecimiento ?? ""}
                  name="nombreEstablecimiento"
                  onChange={handleChangeParametri}
                  
                />
                </ListItemButton>

                <ListItemButton sx={{ pl: 4 }}>
                  
                   <ListItemText primary="CIF_NIF" />
                   <TextInput
                  label="CIF/NIF"
                  value={parametri.cifnif ?? ""}
                  name="cifnif"
                  onChange={handleChangeParametri}
                />
                </ListItemButton>

                <ListItemButton sx={{ pl: 4 }}>
                  
                   <ListItemText primary="Numero Registro" />
                   <TextInput
                  label="Numero Registro"
                  value={parametri.numeroregistro ?? ""}
                  name="numeroregistro"
                  onChange={handleChangeParametri}
                />
                </ListItemButton>

               </List>
             </Collapse>
             </Card>
              

             <Card sx={{ width: 1000 }}> {/* Imposta la larghezza desiderata */}
             <ListItemButton onClick={handleClickDireccion}>
                <ListItemIcon>
                 <LooksTwoIcon style={{ color: 'rgb(3, 155, 210)'}} />
                </ListItemIcon>
               <ListItemText primary="Direccion" />
              {openDireccion ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openDireccion} timeout="auto" unmountOnExit>
               <List component="div" disablePadding>

                <ListItemButton sx={{ pl: 4 }}>
                 
                   <ListItemText primary="Direccion" />
                   <TextInput
                  label="Dirección"
                  value={parametri.direccion ?? ""}
                  name="direccion"
                  onChange={handleChangeParametri}
                />
                </ListItemButton>

                <ListItemButton sx={{ pl: 4 }}>
                
                   <ListItemText primary="Codigo Postal" />
                   <TextInput
                  label="Codigo Postal"
                  value={parametri.codigoPostal ?? ""}
                  name="codigoPostal"
                  onChange={handleChangeParametri}
                />
                </ListItemButton>

                <ListItemButton sx={{ pl: 4 }}>
                  
                   <ListItemText primary="Localidad" />
                   <TextInput
                  label="Localidad"
                  value={parametri.localidad?? ""}
                  name="localidad"
                  onChange={handleChangeParametri}
                />
                </ListItemButton>

                <ListItemButton sx={{ pl: 4 }}>
                  
                   <ListItemText primary="Municipio" />
                   <TextInput
                  label="Municipio"
                  value={parametri.municipio ?? ""}
                  name="municipio"
                  onChange={handleChangeParametri}
                />
                </ListItemButton>

                <ListItemButton sx={{ pl: 4 }}>
                  
                   <ListItemText primary="Provincia" />
                   <TextInput
                  label="Provincia"
                  value={parametri.provincia ?? ""}
                  name="provincia"
                  onChange={handleChangeParametri}
                />
                </ListItemButton>

               </List>
             </Collapse>
             </Card>
            
             <Card sx={{ width: 1000 }}> {/* Imposta la larghezza desiderata */}
             <ListItemButton onClick={handleClickDatos}>
                <ListItemIcon>
                 <Looks3Icon style={{ color: 'rgb(3, 155, 210)'}} />
                </ListItemIcon>
               <ListItemText primary="Datos del Hotel" />
              {openDatos ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openDatos} timeout="auto" unmountOnExit>
               <List component="div" disablePadding>

               {selectedProperty === 'Hotel' && (
                <ListItemButton>
                <ListItemText primary="Tipo" sx={{marginLeft: '18px'}} />
                <FormControl sx={{ width: '70%', maxWidth: '185px' }}>
                <InputLabel id="tipo-label">Tipo</InputLabel>
                 <Select
                    labelId="tipo-label"
                    id="tipo"
                    value={parametri.tipo ?? ""}
                    name="tipo"
                    onChange={handleChangeParametri}
                    label="Tipo"
                 >
                     <MenuItem value="Hoteles">Hoteles</MenuItem>
                     <MenuItem value="Hoteles-apartamentos">Hoteles-apartamentos</MenuItem>
                      <MenuItem value="Hoteles-residencias">Hoteles-residencias</MenuItem>
                      <MenuItem value="Moteles">Moteles</MenuItem>
                  {/* Aggiungi altri valori prefissati secondo necessità */}
                </Select>
                </FormControl>
                 </ListItemButton>
                )}
                
                <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText primary="Categoria" />
                    <FormControl sx={{ width: '70%', maxWidth: '185px' }}>
                        <InputLabel id="tipo-label">Categoria</InputLabel>
                        <Select
                            labelId="tipo-label"
                            id="categoria"
                            value={parametri.categoria ?? ""}
                            name="categoria"
                            onChange={handleChangeParametri}
                            label="categoria"
                        >
                             {selectedProperty === 'Hotel' ? (
                                <MenuItem value="H1">H1(Hoteles)</MenuItem>
                                ) : selectedProperty === 'Apartamentos' ? (
                               <MenuItem value="0">0(CATALUÑA(08,17,25,43))</MenuItem>
                                ) : (
                                 // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                 null
                                 )}

                               {selectedProperty === 'Hotel' ? (
                                <MenuItem value="H2">H2</MenuItem>
                                ) : selectedProperty === 'Apartamentos' ? (
                               <MenuItem value="0">0(COMUNITAT VALENCIANA (3,12,46))</MenuItem>
                                ) : (
                                 // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                 null
                                 )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="H3">H3</MenuItem>
                                ) : selectedProperty === 'Apartamentos' ? (
                               <MenuItem value="0">0(Llaves)</MenuItem>
                                ) : (
                                 // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                 null
                                 )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="H4">H4</MenuItem>
                                ) : selectedProperty === 'Apartamentos' ? (
                               <MenuItem value="1">1(Llaves)</MenuItem>
                                ) : (
                                 // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                 null
                                 )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="H5">H5</MenuItem>
                                ) : selectedProperty === 'Apartamentos' ? (
                               <MenuItem value="2">2(Llaves)</MenuItem>
                                ) : (
                                 // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                 null
                                 )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="AP">AP</MenuItem>
                                ) : selectedProperty === 'Apartamentos' ? (
                               <MenuItem value="3">3(Llaves)</MenuItem>
                                ) : (
                                 // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                 null
                                 )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="HA">HA(Hoteles-apartamentos)</MenuItem>
                                ) : selectedProperty === 'Apartamentos' ? (
                                  <MenuItem value="4">4(Llaves)</MenuItem>
                                   ) : (
                                    // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                    null
                                    )}
   
                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="HA1">HA1</MenuItem>
                                ) : (
                                // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                null
                                )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="HA2">HA2</MenuItem>
                                ) : (
                                // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                null
                                )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="HA3">HA3</MenuItem>
                                ) : (
                                // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                null
                                )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="HA4">HA4</MenuItem>
                                ) : (
                                // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                null
                                )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="HA5">HA5</MenuItem>
                                ) : (
                                // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                null
                                )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="HR">HR(Hoteles-residencias)</MenuItem>
                                ) : (
                                // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                null
                                )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="HR1">HR1</MenuItem>
                                ) : (
                                // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                null
                                )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="HR2">HR2</MenuItem>
                                ) : (
                                // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                null
                                )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="HR3">HR3</MenuItem>
                                ) : (
                                // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                null
                                )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="HR4">HR4</MenuItem>
                                ) : (
                                // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                null
                                )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="HR5">HR5</MenuItem>
                                ) : (
                                // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                null
                                )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="M1">M1(Moteles)</MenuItem>
                                ) : (
                                // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                null
                                )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="M2">M2</MenuItem>
                                ) : (
                                // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                null
                                )}

                                {selectedProperty === 'Hotel' ? (
                                <MenuItem value="M3">M3</MenuItem>
                                ) : (
                                // Altri MenuItem o null se non soddisfa nessuna delle condizioni
                                null
                                )}


                            {/* Aggiungi altri valori prefissati secondo necessità */}
                        </Select>
                    </FormControl>
                </ListItemButton>

                <ListItemButton sx={{ pl: 4 }}>
                 
                   <ListItemText primary="Habitaciones" />
                   <TextInput
                  label="Habitaciones"
                  value={parametri.habitaciones ?? ""}
                  name="habitaciones"
                  onChange={handleChangeParametri}
                />
                </ListItemButton>

                <ListItemButton sx={{ pl: 4 }}>
               
                   <ListItemText primary="Plazas disponibles sin supletorias" />
                   <TextInput
                  label="Plazas disponibles sin supletorias"
                  value={parametri.plazadisponibles ?? ""}
                  name="plazadisponibles"
                  onChange={handleChangeParametri}
                />
                </ListItemButton>

               </List>
             </Collapse>
             </Card>

             <Card sx={{ width: 1000 }}> {/* Imposta la larghezza desiderata */}
             <ListItemButton onClick={handleClickConctatos}>
                <ListItemIcon>
                 <Looks4Icon style={{ color: 'rgb(3, 155, 210)'}} />
                </ListItemIcon>
               <ListItemText primary="Conctactos" />
              {openConctatos ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openConctatos} timeout="auto" unmountOnExit>
               <List component="div" disablePadding>

                <ListItemButton sx={{ pl: 4 }}>
                  
                   <ListItemText primary="Telefono" />
                   <TextInput
                  label="Telefono"
                  value={parametri.telefono ?? ""}
                  name="telefono"
                  onChange={handleChangeParametri}
                />
                </ListItemButton>

                <ListItemButton sx={{ pl: 4 }}>
                  
                   <ListItemText primary="Email"  />
                   <TextInput
                  label="Email"
                  value={parametri.email ?? ""}
                  name="email"
                  onChange={handleChangeParametri}
                  sx={{ width: '30%' }}
                  style={{ textAlign: 'center', width: '30%', margin: '0 auto' }}
                 
                />
                </ListItemButton>

               </List>
             </Collapse>
             </Card>

             <Card sx={{ width: 1000 }}> {/* Imposta la larghezza desiderata */}
              <ListItemButton onClick={handleClickPersonal}>
                <ListItemIcon>
                 <Looks5Icon style={{ color: 'rgb(3, 155, 210)'}} />
                </ListItemIcon>
               <ListItemText primary="PersonalOcupado" />
              {openPersonal? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openPersonal} timeout="auto" unmountOnExit>
               <List component="div" disablePadding>

                <ListItemButton sx={{ pl: 4 }}>                
                   <ListItemText primary="Personal no remunerado" />
                   <TextInput
                  label="Personal no remunerado"
                  value={parametri.personalnoremunerado ?? ""}
                  name="personalnoremunerado"
                  onChange={handleChangeParametri}
                  sx={{ width: '15%', textAlign: 'center' }}
                />
                </ListItemButton>

                <ListItemButton sx={{ pl: 4 }}>
                
                   <ListItemText primary="Personal remunerado fijo" />
                   <TextInput
                  label="Personal remunerado fijo"
                  value={parametri.personalremuneradofijo ?? ""}
                  name="personalremuneradofijo"
                  onChange={handleChangeParametri}
                  sx={{ width: '15%', textAlign: 'center' }}
                />
                </ListItemButton>

                <ListItemButton sx={{ pl: 4 }}>
                  
                   <ListItemText primary="Personal remunerado eventual" />
                   <TextInput
                  label="Personal remunerado eventual"
                  value={parametri.personalremuneradoeventual ?? ""}
                  name="personalremuneradoeventual"
                  onChange={handleChangeParametri}
                  sx={{ width: '15%', textAlign: 'center' }}
                />
                </ListItemButton>

               </List>
             </Collapse>
             
             </Card>

             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}
             onClick={handleContainerClick}>
             <Button
        variant="contained"
        onClick={handleClick}
        style={{
          backgroundColor: 'rgb(3, 155, 229)',
          color: 'white',
        }}
      >
        MarketCode/DistributionChannels

      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}      
        onClose={handleCloseL}
        style={{ marginTop: '20px' }} 
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom', // Cambia da 'bottom' a 'top'
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top', // Cambia da 'top' a 'bottom'
            horizontal: 'center',
          },
        }}
      >
        <MenuItem onClick={handleClickOpenP}>
          MarkCode
        </MenuItem>
        <MenuItem onClick={handleClickOpenD}>
          DistributionChannels
        </MenuItem>
      </Menu>
      </div>

             


                
              {isLoading && <CircularProgress />}

                
    
              </div>
                      
            </div>

            
              <Dialog
                  open={openP}
                  width="lg"
                  onClose={handleCloseP}
                  aria-labelledby="alert-dialog-title"
                   aria-describedby="alert-dialog-description"
              >

                  <DialogContent>
                 

                
                  <table style = {{ borderCollapse: 'collapse', width: 'auto'}}>
                  <thead>
                    <tr>
                      <th style={{ border: '1px solid #ddd', padding: '2px', textAlign: 'center', width: '2px' }}>Nr</th>
                      <th style={{width:'5px', ...tableHeadStyle}}>Activo</th>
                      <th style={{width: 'auto', ...tableHeadStyle}}>Tipo</th>
                      <th style={{width:'18%',...tableHeadStyle}}>Descrizione</th>
                      <th style={{width: '8%', ...tableHeadStyle}}>Code</th>
                    {/* Aggiungi più intestazioni se necessario */}
                    </tr>
                    </thead>
                    <tbody>
                    {marketCodeData.map((dataItem, index) => (
                       <tr key={index + 1}>
                          <td style={{width: '1px', ...tableCellStyle}}>{index + 1}</td>
                          <td style={{width: '1px', ...tableCellStyle}}>
                               <Checkbox
                                 checked={dataItem.active}                                
                                 onChange={(e) => {
                                  setMarketCodeData((md) => {
                                    const newMarketCodeData = [...md];
                                    newMarketCodeData[index].active = e.target.checked;
                                    return newMarketCodeData;
                                  })
                                 }}          
                                 inputProps={{ 'aria-label': 'controlled' }}
                               ></Checkbox>
                                 
                          </td>
                      <td style={{width: '1px', ...tableCellStyle}}>
                           <Select style={{ width: '100%' }}
                            onChange={(e)=>{
                              setMarketCodeData((md) => {
                                const newMarketCodeData = [...md];
                                newMarketCodeData[index].type = e.target.value;
                                return newMarketCodeData;
                              })
                            }}
                            value ={dataItem.type}
                           
                           >
                             {DistributionChannels.map((item) => (
                                <MenuItem key={item.value} value={item.value} style={{width: '7%' , fontSize: '15px'}}>
                                  <span style={{ fontSize: '13px' }}>{item.label}</span>
                                {item.description && (
                                    <span style={{ marginLeft: '6px', fontSize: '10px'}}>{item.description}</span>
                                 )}
                                 
                                </MenuItem>
                             ))}
                            </Select>
                           </td>
                          <td style={tableCellStyle}>{dataItem.name}</td>
                          <td style={tableCellStyle}>{dataItem.code}</td>
                       </tr>
                      ))}


                     
                      

                  
                  
                  


                  {/* Aggiungi più dati se necessario */}
                    
                      
                </tbody>
              </table>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                      <Button
                      variant="contained"
                       onClick={handleSaveSegmento}
                        style={{
                      backgroundColor: "rgb(3, 155, 229)",
                      color: "white",
                       }}
                        elevation={0}
                        disabled={isLoading}
                        
                      >
                     SALVA
                      </Button>
                      
                     
                      
                      
                     <Button
                 variant="contained"
                 onClick={handleCloseP}
                 style={{
                   backgroundColor: "rgb(3, 155, 229)",
                   color: "white",
                   marginLeft: '8px', // Aggiungi margine tra i due bottoni
                }}
                elevation={0}
                disabled={isLoading}
              >
                 ANNULLA
                </Button>

                </div>
      

            </DialogContent>
            </Dialog>

              

           

            {/* <Button
                 variant="contained"
                 onClick={handleLeggiMarketCode}
                 style={{
                   backgroundColor: "rgb(3, 155, 229)",
                   color: "white",
                   marginLeft: '8px', // Aggiungi margine tra i due bottoni
                }}
                elevation={0}
                
              >
                 MarketCode
                </Button>

                <Button
                 variant="contained"
                 onClick={handleLeggiDistribution}
                 style={{
                   backgroundColor: "rgb(3, 155, 229)",
                   color: "white",
                   marginLeft: '8px', // Aggiungi margine tra i due bottoni
                }}
                elevation={0}
                
              >
                DistributionChannels
                </Button> */}


    
    
                

              

           

           
      {/* Mostra i dati nel tuo componente */}
      {parametri.tipisegmento.length > 0 && (
        <>
        <div style={{ fontSize: '18px', marginBottom: '10px', width: '40%' , color: 'rgb(26, 163, 255)'}}>
        {parametri.segmento}
        </div>

      <table style={{ width: '40%', borderCollapse: 'collapse', margin: '20px 0', fontSize: '15px' }}>
         
          <tr>
          
            {/* Aggiungi le altre intestazioni della tabella qui */}
          </tr>
        
        <tbody>
          <tr>
          <th style={tableHeadStyle}>Codice</th>
          <th style={tableHeadStyle}>Nome</th>
          <th style={tableHeadStyle}>Tipo</th>
          <th style={tableHeadStyle}>Attivo</th>
    {/* Aggiungi qui gli altri titoli delle colonne */}
          </tr>
          {parametri.tipisegmento.map((dato, index) => (
            <tr key={index+1}>
              <td style={{ ...tableCellStyle, padding: '12px 15px', borderBottom: '1px solid #ddd' }}>{dato.code}</td>
              <td style={{ ...tableCellStyle, padding: '5px 5px', borderBottom: '1px solid #ddd' }}>{dato.name}</td>

              <td style={tableCellStyle}>
              <Select style={{ width: '100%' }}
              onChange={(e) => {
                setParametri((p) => {
                  const newParametri = {...p};
                  newParametri.tipisegmento[index].type = e.target.value;
                  return newParametri; 
                })
              }}
              
              value ={dato.type}
              >
              {DistributionChannels.map((item) => (
                <MenuItem key={item.value} value={item.value} style={{ width: '10%' }}>
                <span style={{ fontSize: '14px' }}>{item.label}</span>
                {item.description && (
                    <span style={{ marginLeft: '8px', fontSize: '5px', color: 'blue' }}>{item.description}</span>
                 )}
                 
                </MenuItem>
              ))}


              </Select>
              </td>
              
              <td style={tableCellStyle}>
                 <Checkbox
                  checked={dato.active}
                  onChange={(e) => {
                    setParametri((p) => {
                      const newParametri = {...p};
                      newParametri.tipisegmento[index].active = e.target.checked;
                      return newParametri; 
                    })
                  }}
                 

                 ></Checkbox>


              </td>
              
              {/* Aggiungi le altre colonne della tabella qui */}
            </tr>
          ))}
        </tbody>
      </table>
      </>
      )}
      

      {/* Bottone per ricaricare i dati */}
   
    
    
    </CardContent>
    </Card>


            
           
      </div>
           
  

        <Snackbar
          open={openSnackbar}
          autoHideDuration="6000"
          onClose={() => setOpenSnackbar(false)}
        >
          <div>
            <Alert severity="success">Operazione effettuata</Alert>
          </div>
        </Snackbar>
        <Snackbar
          open={openSnackbarError}
          autoHideDuration="6000"
          onClose={() => {
            setOpenSnackbarError(false);
          }}
        >
          <div>
            <Alert severity="error">Operazione fallita</Alert>
          </div>
        </Snackbar>
      </div>
    
  );
}

const TextInput = (props) => {
  const { small, ...textFieldProps } = props;
  return (
    <TextField
      style={{ margin: "1rem", ...(small && { width: "50px" }) }}
      {...textFieldProps}
    />
  );
};

const Title = (props) => (
  <Typography
    style={{
      color: "#1aa3ff",
      marginTop: "1rem",
      fontSize: "1rem",
    }}
    variant="h5"
    gutterBottom
    {...props}
  />
);

  
    
  


export default SetupIne;