import React, { useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import config from '../config'
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NativeSelect from '@mui/material/NativeSelect';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'
import DialogTitle from '@mui/material/DialogTitle';
import { codiciIstat, istatGiesZone, codiciSetup } from '../values'

function FormSetup() {
  const [codiceParam, setCodiceParam] = React.useState();
  const [codicePropertyParam, setCodicePropertyParam] = React.useState();
  const [valoreParam, setValoreParam] = React.useState();
  const [propertyData, setPropertyData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [propOld, setPropOld] = React.useState();
  const [valOld, setValOld] = React.useState();
  const [codeOld, setCodeOld] = React.useState();
  const [codiceParamUpd, setCodiceParamUpd] = React.useState();
  const [valoreParamUpd, setValoreParamUpd] = React.useState();
  const [propertyParamUpd, setPropertyParamUpd] = React.useState("");
  const [roomNumber, setRoomNumber] = React.useState('');
  const [externalID, setExternalID] = React.useState('');
  const [openDialogSchedine, setOpenDialogSchedine] = React.useState(false);
  const [openDialogDeleteSchedine, setOpenDialogDeleteSchedine] = React.useState(false);
  const [toDeleteId, setToDeleteId] = React.useState();
  const [toUpdId, setToUpdId] = React.useState();
  const [valSched, setValSched] = React.useState();

  const useStyles = makeStyles((theme) => ({
    form: {
      root: {
        '& > *': {
          margin: theme.spacing(3),
          width: '18ch',
        },
      }
    },
    card: {
      root: {
        minWidth: 500,
      },
      bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
      title: {
        fontSize: 14,
      },
      pos: {
        marginBottom: 12,
      }
    },
    table: {
      minWidth: 100,
    },
  }));

  const handleSubmit = (event) => {
    event.preventDefault();
    var data = JSON.stringify({
      "hotelID": id,
      "propertyConfiguration": [],
      "setup": [
        {
          "propertyCode": codicePropertyParam,
          "code": codiceParam,
          "value": valoreParam
        }
      ],
      "closingDays": []
    });

    var config0 = {
      method: 'put',
      url: `${config.protelExpressUri}/api/insertSetupData`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config0)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        handleOpenSuccess()
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        handleOpenError()
      });
  }

  //insert schedine-------------------------
  const handleSubmitS = (event) => {
    event.preventDefault();
    var data = JSON.stringify({
      "hotelID": id,
      "setup": [
        {
          "code": "idSchedineApp",
          "valueSchedina": [
            {
              "camera": roomNumber,
              "cameraSchedina": externalID
            }
          ]
        }
      ]
    });

    var config1 = {
      method: 'put',
      url: `${config.protelExpressUri}/api/hotelInsertSetupSchedine`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config1)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        window.location.reload();
        setRoomNumber('');
        setExternalID('');
        handleOpenSuccess()
      })
      .catch(function (error) {
        console.log(error);
        handleOpenError()
      });
  }
  //

  const handleUpdateIstat = (event) => {
    event.preventDefault();

    var dataI = JSON.stringify({
      "hotelID": id,
      "codeOld": codeOld,
      "codeNew": codiceParamUpd,
      "valueNew": valoreParamUpd,
      "propertyCode": propertyParamUpd
    });

    var configI = {
      method: 'put',
      url: `${config.protelExpressUri}/api/updateSetups`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataI
    };
    setOpenDialog(false);
    axios(configI)
      .then(function (response) {
        handleOpenSuccess()
        console.log(JSON.stringify(response.data));
        window.location.reload()
      })
      .catch(function (error) {
        console.log(error);
        handleOpenError()
      });
  }

  const handleDelete = () => {
    var dataDE = JSON.stringify({
      "hotelID": id,
      "propertyCodeOld": propOld,
      "codeOld": codeOld,
      "valueOld": valOld
    });

    var configDE = {
      method: 'delete',
      url: `${config.protelExpressUri}/api/deleteSetup`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataDE
    };

    axios(configDE)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setOpenDialogDelete(false)
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const { id } = useParams();

  useEffect(() => {

    var dataP = JSON.stringify({ "hotelID": id });

    var configPr = {
      method: 'post',
      url: `${config.protelExpressUri}/api//findsProperty`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataP
    };
    async function getDataP() {
      const result = await axios(configPr)
      setPropertyData(propertyData => [...propertyData, result.data]);
    }

    getDataP()
  }, [id])

  const handleOpenDialogSchedine = (val) => {
    setToUpdId(val);
    setOpenDialogSchedine(true);
  }

  const handleCloseDialogSchedine = () => {
    setOpenDialogSchedine(false);
  }

  const handleUpdateSchedine = () => {
    var data = JSON.stringify({
      "hotelID": id,
      "_id": toUpdId,
      "value": valSched
    });

    var config2 = {
      method: 'put',
      url: `${config.protelExpressUri}/api/updateSetupsSchedine`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config2)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setOpenDialogSchedine(false);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleDeleteDialogSchedine = (val) => {
    setToDeleteId(val);
    setOpenDialogDeleteSchedine(true);
  }

  const handleCloseDialogDeleteSchedine = () => {
    setOpenDialogDeleteSchedine(false)
  }

  const handleDeleteSchedine = () => {
    var data = JSON.stringify({
      "hotelID": id,
      "_id": toDeleteId
    });

    var config1 = {
      method: 'delete',
      url: `${config.protelExpressUri}/api/deleteSetupsSchedine`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config1)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setOpenDialogDeleteSchedine(false);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleClickOpenDialogDelete = (input, prop, val) => {
    setOpenDialogDelete(true);
    setCodeOld(input)
    setPropOld(prop)
    setValOld(val)
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  const handleClickOpenDialog = (input, vc, vv, prop) => {
    setOpenDialog(true);
    setCodeOld(input)
    setCodiceParamUpd(vc)
    setValoreParamUpd(vv)
    setPropertyParamUpd(prop)
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseSuccess = (òevent, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //snackbar success
  const handleOpenSuccess = () => {
    setOpen(true);
  };

  const handleOpenError = () => {
    setOpenError(true);
  };

  const classes = useStyles();

  return (
    <div style={{ minHeight: '100vh', backgroundColor: '#F8F8F8' }}>
      <div style={{ height: '64px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
      <div style={{ backgroundColor: "#1aa3ff", height: '60px', display: 'flex', alignItems: 'center', marginLeft: 250 }} >
        <Typography variant="h5" style={{
          color: "white", //position: 'absolute',
          //left: '20%',
          fontSize: 20,
          marginLeft: 40
        }} gutterBottom>Gestione Setup</Typography>
      </div>
      <div style={{ display: 'flex' }}
      >

        <Dialog open={openDialogDelete} onClose={handleCloseDialogDelete} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Cancellare Setup: {codiciSetup[codeOld]}?</DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDialogDelete} style={{ color: '#1aa3ff' }}>
              Annulla
            </Button>
            <Button onClick={handleDelete} style={{ color: '#1aa3ff' }}>
              Cancella
            </Button>
          </DialogActions>
        </Dialog>


        <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSuccess}>
          <div><Alert severity="success">SUCCESS!</Alert></div>
        </Snackbar>
        <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
          <div><Alert severity="error">Errore</Alert></div>
        </Snackbar>
        <Card className={classes.card.root} style={{ marginLeft: 290, minWidth: 250, marginTop: 11, marginRight: 11, borderRadius: 5 }} elevation={0} >
          <CardContent>
            <form className={classes.form.root}>
              <Typography style={{ fontSize: '16px', color: '#1aa3ff' }} variant="h5" gutterBottom>Inserisci Setup</Typography>
              <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: 190, width: "100%" }} margin='dense'>
                <InputLabel htmlFor="outlined-age-native-simple">Codice Property</InputLabel>
                <NativeSelect
                  native
                  label="Codice Property"
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                  onChange={e => setCodicePropertyParam(e.target.value)}
                >
                  <option></option>
                  {propertyData.map((value) => {
                    if (value.propertyConfiguration)
                      return value.propertyConfiguration.map((value) => value.propertyCode ? <option value={value.propertyCode}>{value.propertyCode}</option> : null)
                  }
                  )
                  }
                </NativeSelect>
              </FormControl>
              <br></br>
              <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: 190, width: "100%" }} margin='dense'>
                <InputLabel htmlFor="outlined-age-native-simple">Codice Parametro</InputLabel>
                <NativeSelect
                  native
                  label="Codice Parametro"
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                  onChange={e => setCodiceParam(e.target.value)}
                >
                  <option></option>
                  {Object.keys(codiciSetup).map(codice => <option key={codice} value={codice}>{codiciSetup[codice]}</option>)}
                </NativeSelect>
              </FormControl>
              <br></br>
              {codiceParam == 'istatType' ? <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: 190, width: "100%" }} margin='dense'>
                <InputLabel htmlFor="outlined-age-native-simple">Codice Parametro</InputLabel>
                <NativeSelect
                  native
                  label="Codice Parametro"
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                  onChange={e => setValoreParam(e.target.value)}
                >
                  <option></option>
                  {Object.keys(codiciIstat).map((key) => <option key={key} value={key}>{codiciIstat[key]}</option>)}

                </NativeSelect>
              </FormControl> : codiceParam == 'istatGiesZona' ?
                  
              <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: 190, width: "100%" }} margin='dense'>
                <InputLabel htmlFor="outlined-age-native-simple">Codice Parametro</InputLabel>
                <NativeSelect
                  native
                  label="Codice Parametro"
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                  onChange={e => setValoreParam(e.target.value)}
                >
                  <option></option>
                      {istatGiesZone.map((zona) => <option key={zona} value={zona}>{zona}</option>)}

                </NativeSelect>
            </FormControl> : codiceParam == 'idSchedineApp' ?
                <form className={classes.form.root} onSubmit={{/*handleSubmit*/ }} style={{
                }}>
                  <TextField id="standard-basic" label="Numero Camera" defaultValue={roomNumber}
                    onChange={e => setRoomNumber(e.target.value)} /><br></br>
                  <TextField id="standard-basic" label="ID Esterno" defaultValue={externalID}
                    onChange={e => setExternalID(e.target.value)} /><br></br>
                </form>
                    : <TextField style={{ width: "100%" }} id="standard-basic" label="Valore Parametro" value={valoreParam}
                  onChange={e => setValoreParam(e.target.value)} />}

            </form>
          </CardContent>
          {codiceParam == 'idSchedineApp' ?
            <CardActions>
              <Button variant="contained" onClick={handleSubmitS} style={{ backgroundColor: '#1aa3ff', color: "white" }}>Inserisci</Button>
            </CardActions> : <CardActions>
              <Button variant="contained" onClick={handleSubmit} style={{ backgroundColor: '#1aa3ff', color: "white" }}>Inserisci</Button>
            </CardActions>
          }
        </Card>
        <div style={{ minWidth: 50 }}></div>
      </div>

      <div style={{ marginLeft: 290, marginTop: 11 }}>
        <Card style={{ borderRadius: 5 }} >
          <CardContent>
            <Typography style={{ fontSize: '16px', color: '#1aa3ff' }} variant="h5" gutterBottom>Lista Setup</Typography>
            <TableContainer component={Paper} >
              <Table className={classes.table} size="small" aria-label="a dense table" >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" width="10%">Codice Property</TableCell>
                    <TableCell align="left" width="10%">Codice Parametro</TableCell>
                    <TableCell align="left" width="10%">Valore</TableCell>
                    <TableCell align="left" width="10%">Operazioni</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {propertyData !== [] && propertyData !== [""] && propertyData &&
                    propertyData.map((row) => {
                      if (row.setup)
                        return row.setup.map((value) => <TableRow key={value.code}>
                          <TableCell align="left">{value.propertyCode}</TableCell>
                          <TableCell align="left">{codiciSetup[value.code]}</TableCell>
                          <TableCell align="left">{
                            value.code !== 'istatType' ? value.value : codiciIstat[value.value]
                          }</TableCell>
                          <TableCell align="left"><EditIcon style={{ fontSize: '15px', fill: "black" }} button onClick={() => handleClickOpenDialog(value.code, value.code, value.value, value.propertyCode)} /><DeleteIcon style={{ fontSize: '15px', fill: "black" }} button onClick={() => handleClickOpenDialogDelete(value.code, value.propertyCode, value.value)} /></TableCell>
                        </TableRow>)
                    }
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </div>
      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Modifica di {codiciSetup[codeOld]}</DialogTitle>
        <DialogContent>

          {(codiceParamUpd == 'istatType' || codiceParamUpd == 'istatGiesZona') ? <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: 190 }} margin='dense'>
            <InputLabel htmlFor="outlined-age-native-simple">Codice Parametro</InputLabel>
            <NativeSelect
              native
              label="Codice Parametro"
              inputProps={{
                name: 'age',
                id: 'outlined-age-native-simple',
              }}
              onChange={e => setValoreParamUpd(e.target.value)}
              value={valoreParamUpd}
            >
              <option></option>
              {codiceParamUpd == 'istatType' ? Object.keys(codiciIstat).map((key) => <option key={key} value={key}>{codiciIstat[key]}</option>) : istatGiesZone.map((zona) => <option key={zona} value={zona}>{zona}</option>)}

            </NativeSelect>
          </FormControl> : <TextField id="standard-basic" label="Valore Parametro" value={valoreParamUpd}
            onChange={e => setValoreParamUpd(e.target.value)} />}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} style={{ color: '#1aa3ff' }}>
            Annulla
          </Button>
          <Button onClick={handleUpdateIstat} style={{ color: '#1aa3ff' }}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
      {/*tabella schedine*/}
      <div style={{ marginLeft: 290, marginTop: 11 }}>
        <Card style={{ borderRadius: 5 }} >
          <CardContent>
            <Typography style={{ fontSize: '16px', color: '#1aa3ff' }} variant="h5" gutterBottom>Setup Schedine Appartamenti (NON PIU UTILIZZATO. ASSEGNARE ID ESTERNO IN GESTIONE CAMERE)</Typography>
            <TableContainer component={Paper} >
              <Table className={classes.table} size="small" aria-label="a dense table" >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" variant="h5" width="10%">Camera</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Camera Schedine</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Operazioni</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {propertyData !== [] && propertyData !== [""] && propertyData &&
                    propertyData.map((row) => {
                      if (row.setup)
                        if (row.setup.find((value) => value.code == 'idSchedineApp'))
                          return row.setup.find((value) => value.code == 'idSchedineApp').valueSchedina.map((value) =>
                            <TableRow key={value._id}>
                              <TableCell align="left">{value.camera}</TableCell>
                              <TableCell align="left">{value.cameraSchedina}</TableCell>
                              <TableCell align="left"><EditIcon style={{ fontSize: '15px', fill: "black" }} button onClick={() => handleOpenDialogSchedine(value._id)} /><DeleteIcon style={{ fontSize: '15px', fill: "black" }} button
                                onClick={() => handleDeleteDialogSchedine(value._id)} /></TableCell>
                            </TableRow>
                          )
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            {/*delete dialogo*/}
            <Dialog open={openDialogDeleteSchedine} onClose={handleCloseDialogDelete} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Cancellare Id camera Schedine</DialogTitle>
              <DialogActions>
                <Button onClick={handleCloseDialogDeleteSchedine} style={{ color: '#1aa3ff' }}>
                  Annulla
                </Button>
                <Button onClick={handleDeleteSchedine} style={{ color: '#1aa3ff' }}>
                  Cancella
                </Button>
              </DialogActions>
            </Dialog>
            {/*fine delete dialogo*/}
            <Dialog open={openDialogSchedine} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Modifica categoria</DialogTitle>
              <DialogContent>
                <form className={classes.form.root} onSubmit={{/*handleSubmit*/ }} style={{
                }}>
                  <TextField id="standard-basic" label="Codice Schedina" defaultValue={valSched}
                    onChange={e => setValSched(e.target.value)} /><br></br>
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialogSchedine} style={{ color: '#1aa3ff' }}>
                  Annulla
                </Button>
                <Button onClick={handleUpdateSchedine} style={{ color: '#1aa3ff' }}>
                  Conferma
                </Button>
              </DialogActions>
            </Dialog>
          </CardContent>
        </Card>
      </div>
      {/*tabella shceinde fine*/}
    </div >
  );
}

export default FormSetup;
