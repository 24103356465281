import React, { useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import config from '../../config'
import NativeSelect from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useParams } from "react-router-dom";
import FormHelperText from '@mui/material/FormHelperText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'
import DialogTitle from '@mui/material/DialogTitle';


function FormIstat() {
  const [daData, setDaData] = React.useState("");
  const [aData, setAData] = React.useState("");
  const [statoCamera, setStatoCamera] = React.useState("");
  const [numeroCamera, setNumeroCamera] = React.useState();
  const [propertyData, setPropertyData] = React.useState([]);
  const [codiceProperty, setCodiceProperty] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [daDataUpd, setDaDataUpd] = React.useState("");
  const [aDataUpd, setADataUpd] = React.useState("");
  const [statoCameraUpd, setStatoCameraUpd] = React.useState("");
  const [numeroCameraUpd, setNumeroCameraUpd] = React.useState();
  const [codicePropertyUpd, setCodicePropertyUpd] = React.useState("");
  const hotels = useParams();

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '18ch',
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));


  //test id
  const handleSubmit = () => {
    var data = JSON.stringify({
      "hotelID": hotels.id,
      "propertyCode": codiceProperty,
      "propertyConfiguration": [
        {
          "propertyCode": "",
          "roomTypes": [
            {
              "rooms": [
                {}
              ]
            }
          ],
          "roomsStatus": [
            {
              "roomNumber": numeroCamera,
              "status": statoCamera,
              "from": daData,
              "to": aData
            }
          ]
        }
      ],
      "setup": [],
      "closingDays": []
    });

    var configI = {
      method: 'put',
      url: `${config.protelExpressUri}/api/insertBlockRooms`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(configI)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleUpdate = () => {
    var data = JSON.stringify({
      "hotelID": hotels.id,
      "propertyCode": codicePropertyUpd,
      "roomNumber": numeroCameraUpd,
      "status": statoCameraUpd,
      "from": daDataUpd,
      "to": aDataUpd
    });

    var configU = {
      method: 'put',
      url: `${config.protelExpressUri}/api/updBlockRooms`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(configU)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setOpenDialog(false)
        window.location.reload();

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleDelete = () => {
    var dataD = JSON.stringify({
      "hotelID": hotels.id,
      "propertyCode": codicePropertyUpd,
      "roomNumber": numeroCameraUpd,
    });

    var configD = {
      method: 'delete',
      url: `${config.protelExpressUri}/api/delBlockRooms`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataD
    };

    axios(configD)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const handleOpenDialog = (a, b, c, d, e) => {
    setOpenDialog(true)
    setCodicePropertyUpd(a);
    setStatoCameraUpd(b);
    setDaDataUpd(c);
    setADataUpd(d);
    setNumeroCameraUpd(e)
  }

  const handleOpenDialogDelete = (a, b) => {
    setOpenDialogDelete(true)
    setCodicePropertyUpd(a);
    setNumeroCameraUpd(b)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false)
  }

  useEffect(() => {
    var dataP = JSON.stringify({ "hotelID": hotels.id });

    var configPr = {
      method: 'post',
      url: `${config.protelExpressUri}/api//findsProperty`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataP
    };
    async function getDataP() {
      const result = await axios(configPr)
      //setSetupData(result.data);
      setPropertyData(propertyData => [...propertyData, result.data]);
    }
    getDataP()

  }, [hotels.id])

  const impostaDati = (v) => {
    let codeprop = v.split('-')[1]
    let numroom = v.split('-')[0]
    setNumeroCamera(numroom)
    setCodiceProperty(codeprop)
  }

  const classes = useStyles();

  return (
    <>
    <div style={{ height: '100vh', backgroundColor: '#F8F8F8' }}>
      <div style={{ height: '64px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
      <div style={{ backgroundColor: "rgb(3, 155, 229)", height: '60px', display: 'flex', /*justifyContent: 'center',*/ alignItems: 'center', marginLeft: 270 }} >
        <Typography variant="h5" style={{
          color: "white", //position: 'absolute',
          //left: '20%',
          fontSize: 20,
          marginLeft: 40
        }} gutterBottom>Blocca Camere</Typography>
      </div>
      <div style={{
        marginLeft: 290,
        display: 'flex'
      }}>
        <Card style={{ minWidth: 250, marginTop: 11, borderRadius: 5 }} elevation={0}>
          <CardContent>
            <form className={classes.root} style={{ display: 'flex' }}>

              <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: 190, marginTop: 23 }} margin='dense'>
                <NativeSelect
                  native
                  label="Codice Property"
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                  onChange={e => impostaDati(e.target.value)}
                >
                  <option></option>
                  {propertyData !== [] && propertyData !== [""] && propertyData &&
                    propertyData.map((row) => {
                      if (row.propertyConfiguration)
                        return row.propertyConfiguration.map((value) => {
                          return value.roomTypes.map((row) => {
                            return row.rooms.map((val) =>
                              <option key={`${val.roomNumber}-${value.propertyCode}`} value={val.roomNumber + '-' + value.propertyCode} >{val.roomName}</option>
                            )
                          })
                        }
                        )
                    }
                    )
                  }
                </NativeSelect>
                <FormHelperText>Camera</FormHelperText>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: 190, marginTop: 23 }} margin='dense'>
                <NativeSelect
                  native
                  label="Stato Camera"
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                  onChange={e => setStatoCamera(e.target.value)}>
                  <option></option>
                  <option value={1}>Bloccata</option>
                  <option value={2}>Out of order</option>
                  <option value={3}>Sporca</option>
                </NativeSelect>
                <FormHelperText>Stato</FormHelperText>
              </FormControl>
              <TextField id="standard-basic" helperText={
                <Typography
                  variant="title"
                  className={classes.centerText}
                  display="block">
                  Da Data
                </Typography>
              } value={daData} type="date"
                format={'yyyy/MM/dd'} onChange={e => setDaData(e.target.value)} style={{ marginTop: 20 }} />
              <TextField id="standard-basic" helperText={
                <Typography
                  variant="caption"
                  className={classes.centerText}
                  display="block">A Data</Typography>} value={aData} type="date" format={'yyyy/MM/dd'} onChange={e => setAData(e.target.value)} style={{ marginTop: 20 }} />
              <CardActions>
                <Button variant="contained" onClick={handleSubmit} style={{ backgroundColor: "rgb(3, 155, 229)", color: "white" }} elevation={0}>Blocca</Button>
              </CardActions>
            </form>
          </CardContent>
        </Card>
      </div>
      <div style={{ marginLeft: 290, marginTop: 11 }}>
        <Card style={{ minWidth: 250, marginTop: 11, borderRadius: 5 }} elevation={0}>
          <CardContent>
            <Typography style={{ fontSize: '16px', color: '#1aa3ff' }} variant="h5" gutterBottom>Camere Bloccate</Typography>
            <TableContainer component={Paper} >
              <Table className={classes.table} size="small" aria-label="a dense table" >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" variant="h5" width="10%">Numero Camera</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Stato</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Da</TableCell>
                    <TableCell align="left" variant="h5" width="10%">A</TableCell>
                    <TableCell align="left" variant="h5" width="10%">Operazioni</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {propertyData !== [] && propertyData !== [""] && propertyData &&
                    propertyData.map((row) => {
                      if (row.propertyConfiguration)
                        return row.propertyConfiguration.map((property) => {
                          return property.roomsStatus.map((row) => {
                            let roomName = "";
                            for (const roomType of property.roomTypes) {
                              const room = roomType.rooms.find((rm) => rm.roomNumber = row.roomNumber);
                              if (room) {
                                roomName = room.roomName;
                                break;
                              }
                            }
                            
                            return <TableRow
                              key={row._id}
                            >
                              <TableCell align="left">{roomName}</TableCell>
                              <TableCell align="left">{
                                row.status == 1 ? 'Bloccata' : row.status == 2 ? 'Out Of Order' : 'Sporca'
                              }</TableCell>
                              <TableCell align="left">{row.from}</TableCell>
                              <TableCell align="left">{row.to}</TableCell>
                              <TableCell align="left"><EditIcon onClick={() => handleOpenDialog(property.propertyCode, row.status, row.from, row.to, row.roomNumber)} /><DeleteIcon onClick={() => handleOpenDialogDelete(property.propertyCode, row.roomNumber)} /></TableCell>
                            </TableRow >
                   
                          })
                        }
                        )
                    }
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
        <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Aggiorna stato camera:{numeroCameraUpd}</DialogTitle>
          <DialogContent>
            <form onSubmit={{/*handleSubmit*/ }} style={{
            }}>
              <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: 190, marginTop: 23 }} margin='dense'>
                <NativeSelect
                  native
                  label="Stato Camera"
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                  onChange={e => setStatoCameraUpd(e.target.value)}>
                  <option></option>
                  <option value={1}>Bloccata</option>
                  <option value={2}>Out of order</option>
                  <option value={3}>Sporca</option>
                </NativeSelect>
                <FormHelperText>Stato</FormHelperText>
              </FormControl>
              <TextField id="standard-basic" helperText={
                <Typography
                  variant="title"
                  className={classes.centerText}
                  display="block">
                  Da Data
                </Typography>
              } value={daDataUpd} type="date"
                format={'yyyy/MM/dd'} onChange={e => setDaDataUpd(e.target.value)} style={{ marginTop: 20 }} />
              <TextField id="standard-basic" helperText={
                <Typography
                  variant="caption"
                  className={classes.centerText}
                  display="block">A Data</Typography>} value={aDataUpd} type="date" format={'yyyy/MM/dd'} onChange={e => setADataUpd(e.target.value)} style={{ marginTop: 20 }} />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} style={{ color: '#1aa3ff' }}>
              Annulla
            </Button>
            <Button onClick={handleUpdate} style={{ color: '#1aa3ff' }}>
              Conferma
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDialogDelete} onClose={handleCloseDialogDelete} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Cancellare Stato Camera {numeroCameraUpd}?</DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDialogDelete} style={{ color: '#1aa3ff' }}>
              Annulla
            </Button>
            <Button onClick={handleDelete} style={{ color: '#1aa3ff' }}>
              Cancella
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
    </>
  );
}

export default FormIstat;
