import React, { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import {
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  NativeSelect,
  Snackbar,
  Alert as MuiAlert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import config from "../config";

const apartmentTypes = [
  "ESTUDIO",
  "2-4pax",
  "4-6pax",
  "OTROS",
];

function FormCategoriaCamera() {
  const [codiceProperty, setCodiceProperty] = React.useState();
  const [tipoCamera, setTipoCamera] = React.useState();
  const [setupData, setSetupData] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [_id, set_Id] = React.useState();
  const [tipoCameraUpd, setTipoCameraUpd] = React.useState();
  const [occupancyUpd, setOccupancyUpd] = React.useState();
  const [apartmentTypeUpd, setApartmentTypeUpd] = React.useState();
  const [propertyCodeOl, setPropertyCodeOl] = React.useState();
  const [propertyCodeNew, setPropertyCodeNew] = React.useState();
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [openDialogUpdateRooms, setOpenDialogUpdateRooms] =
    React.useState(false);
  const [openDialogDeleteRooms, setOpenDialogDeleteRooms] =
    React.useState(false);
  const [roomName, setRoomName] = React.useState();
  const [idCamera, setIdCamera] = React.useState();
  const [roomNameUpd, setRoomNameUpd] = React.useState();
  const [externalIDUpd, setExternalIDUpd] = React.useState();
  const [isRoomsDialogOpen, setIsRoomsDialogOpen] = React.useState(false);

  const useStyles = makeStyles((theme) => ({
    form: {
      root: {
        "& > *": {
          margin: theme.spacing(3),
          width: "18ch",
        },
      },
    },
    card: {
      root: {
        minWidth: 500,
      },
      bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
      },
      title: {
        fontSize: 14,
      },
      pos: {
        marginBottom: 12,
      },
    },
    table: {
      minWidth: 100,
    },
  }));

  const handleDeleteRooms = () => {
    var dataDEL = JSON.stringify({
      hotelID: id,
      propertyCode: propertyCodeOl,
      roomTypeName: tipoCamera,
      _id: idCamera,
    });

    var configDEL = {
      method: "delete",
      url: `${config.protelExpressUri}/api/deleRoom`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataDEL,
    };

    axios(configDEL)
      .then(function (response) {
        window.location.reload();
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleUpdateRooms = () => {
    var dataUR = JSON.stringify({
      hotelID: id,
      propertyCode: propertyCodeOl,
      propertyCodeNew,
      _id: idCamera,
      externalID: externalIDUpd,
      roomType: tipoCamera,
    });

    var configUR = {
      method: "put",
      url: `${config.protelExpressUri}/api/updRoom`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataUR,
    };

    axios(configUR)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDelete = () => {
    var data = JSON.stringify({
      hotelID: id,
      propertyCode: propertyCodeOl,
      roomTypeName: _id,
    });

    var configDel = {
      method: "delete",
      url: `${config.protelExpressUri}/api/delRoomTypes`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(configDel)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleUpdateProperty = () => {
    const data = JSON.stringify({
      hotelID: id,
      roomTypeName: tipoCameraUpd,
      occupancy: Number(occupancyUpd),
      apartmentType: apartmentTypeUpd,
      oldPropertyCode: propertyCodeOl,
      newPropertyCode: codiceProperty,
    });

    const conf = {
      method: "put",
      url: `${config.protelExpressUri}/api/updateRoomTypeProperty`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(conf)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const { id } = useParams();

  useEffect(() => {
    var dataP = JSON.stringify({ hotelID: id });

    var configPr = {
      method: "post",
      url: `${config.protelExpressUri}/api/findsProperty`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataP,
    };
    async function getDataP() {
      const result = await axios(configPr);
      setSetupData(result.data);
    }

    getDataP();
  }, [id]);

  const handleClickOpenDialog = (input, val, tc, occupancy, apartmentType) => {
    setOpenDialog(true);
    set_Id(input);
    setPropertyCodeOl(val);
    setCodiceProperty(val);
    setTipoCameraUpd(tc);
    setOccupancyUpd(occupancy);
    setApartmentTypeUpd(apartmentType);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialogDelete = (input, val) => {
    setOpenDialogDelete(true);
    set_Id(input);
    setPropertyCodeOl(val);
  };

  const handleOpenDialogDeleteRooms = (pc, tc, rn, id) => {
    setOpenDialogDeleteRooms(true);
    setPropertyCodeOl(pc);
    setTipoCamera(tc);
    setRoomName(rn);
    setIdCamera(id);
  };
  const handleCloseDialogDeleteRooms = () => {
    setOpenDialogDeleteRooms(false);
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  const handleOpenUpdateRooms = (id, rnm, ei, pc, tc) => {
    setOpenDialogUpdateRooms(true);
    setIdCamera(id);
    setRoomNameUpd(rnm);
    setExternalIDUpd(ei);
    setPropertyCodeOl(pc);
    setPropertyCodeNew(pc);
    setTipoCamera(tc);
  };

  const handleCloseDialogUpdateRooms = () => {
    setOpenDialogUpdateRooms(false);
  };

  const handleCloseSuccess = (òevent, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //snackbar success
  const handleOpenSuccess = () => {
    setOpen(true);
  };

  const handleOpenError = () => {
    setOpenError(true);
  };

  const classes = useStyles();

  const properties = setupData
    ? setupData.propertyConfiguration.map((p) => p.propertyCode)
    : [];
  if (properties.length === 0) {
    properties.push("Hotel");
  }
  const checkRoomProperty = setupData
    ? !!setupData.setup.find((s) => s.code === "checkRoomProperty")
    : false;

  console.log({ checkRoomProperty });
  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#1aa3ff",
          height: "60px",
          display: "flex",
          alignItems: "center",
          marginLeft: 250,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "white",
            fontSize: 20,
            marginLeft: 40,
          }}
          gutterBottom
        >
          Gestione Camere
        </Typography>
      </div>
      <div style={{ display: "flex" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        >
          <div>
            <Alert severity="success">SUCCESS!</Alert>
          </div>
        </Snackbar>
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleCloseError}
        >
          <div>
            <Alert severity="error">Errore</Alert>
          </div>
        </Snackbar>

        <Card
          className={classes.card.root}
          style={{
            marginLeft: 290,
            minWidth: 250,
            marginTop: 11,
            marginRight: 11,
            borderRadius: 5,
          }}
          elevation={0}
        >
          <CardContent>
            <Typography
              style={{ fontSize: "16px", color: "#1aa3ff" }}
              variant="h5"
              gutterBottom
            >
              Configura camere da pAir
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              onClick={() => setIsRoomsDialogOpen(true)}
              variant="contained"
              style={{ backgroundColor: "#1aa3ff", color: "white" }}
            >
              Configura Camere
            </Button>
          </CardActions>
        </Card>
        <div style={{ minWidth: 50 }}></div>
      </div>
      {!checkRoomProperty && (
        <div style={{ marginLeft: 290, marginTop: 11 }}>
          <Card style={{ borderRadius: 5 }}>
            <CardContent>
              <Typography
                style={{ fontSize: "16px", color: "#1aa3ff" }}
                variant="h5"
                gutterBottom
              >
                Lista Categoria Camere
              </Typography>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Tipo Camera</TableCell>
                      <TableCell align="left">Property</TableCell>
                      <TableCell>Occupancy</TableCell>
                      <TableCell>Tipo Appartamento</TableCell>
                      <TableCell align="left">Operazioni</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {setupData &&
                      setupData.propertyConfiguration.map((value) => {
                        return value.roomTypes.map((row) => (
                          <TableRow key={row.roomTypeName}>
                            <TableCell align="left">
                              {row.roomTypeName}
                            </TableCell>
                            <TableCell align="left">
                              {value.propertyCode}
                            </TableCell>
                            <TableCell>{row.occupancy}</TableCell>
                            <TableCell>{row.apartmentType}</TableCell>
                            <TableCell align="left">
                              <EditIcon
                                style={{ fontSize: "15px", fill: "black" }}
                                button
                                onClick={() =>
                                  handleClickOpenDialog(
                                    row.roomTypeName,
                                    value.propertyCode,
                                    row.roomTypeName,
                                    row.occupancy,
                                    row.apartmentType,
                                  )
                                }
                              />
                              <DeleteIcon
                                style={{ fontSize: "15px", fill: "black" }}
                                button
                                onClick={() =>
                                  handleOpenDialogDelete(
                                    row.roomTypeName,
                                    value.propertyCode
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ));
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Modifica categoria: {tipoCameraUpd}
              </DialogTitle>
              <DialogContent>
                <form
                  className={classes.form.root}
                  onSubmit={handleUpdateProperty}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ minWidth: 190 }}
                    margin="dense"
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Codice Property
                    </InputLabel>
                    <NativeSelect
                      native
                      label="Codice Property"
                      inputProps={{
                        name: "age",
                        id: "outlined-age-native-simple",
                      }}
                      value={codiceProperty}
                      onChange={(e) => setCodiceProperty(e.target.value)}
                    >
                      {properties.map((value, i) => (
                        <option key={i} value={value}>
                          {value}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                  <TextField
                    value={occupancyUpd}
                    label="Occupancy"
                    onChange={(e) => setOccupancyUpd(e.target.value)}
                  />
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ minWidth: 190 }}
                    margin="dense"
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Tipo Appartamento (INE Spagna)
                    </InputLabel>
                    <NativeSelect
                      native
                      label="Codice Property"
                      inputProps={{
                        name: "age",
                        id: "outlined-age-native-simple",
                      }}
                      value={apartmentTypeUpd}
                      onChange={(e) => setApartmentTypeUpd(e.target.value)}
                    >
                      <option value="">
                        </option>
                      {apartmentTypes.map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>

                </form>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseDialog}
                  style={{ color: "#1aa3ff" }}
                >
                  Annulla
                </Button>
                <Button
                  onClick={handleUpdateProperty}
                  style={{ color: "#1aa3ff" }}
                >
                  Conferma
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDialogDelete}
              onClose={handleCloseDialogDelete}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Cancellare Categoria Camera: {_id}?
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={handleCloseDialogDelete}
                  style={{ color: "#1aa3ff" }}
                >
                  Annulla
                </Button>
                <Button onClick={handleDelete} style={{ color: "#1aa3ff" }}>
                  Cancella
                </Button>
              </DialogActions>
            </Dialog>
          </Card>
        </div>
      )}

      <div style={{ marginLeft: 290, marginTop: 11 }}>
        <Card style={{ borderRadius: 5 }}>
          <CardContent>
            <Typography
              style={{ fontSize: "16px", color: "#1aa3ff" }}
              variant="h5"
              gutterBottom
            >
              Lista Camere
            </Typography>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" width="10%">
                      Camera
                    </TableCell>
                    <TableCell align="left" width="10%">
                      ID Esterno
                    </TableCell>
                    <TableCell align="left" width="10%">
                      Categoria
                    </TableCell>
                    <TableCell align="left" width="10%">
                      Property
                    </TableCell>
                    <TableCell align="left" width="10%">
                      Operazioni
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {setupData &&
                    setupData.propertyConfiguration.map((value) =>
                      value.roomTypes.map((roomType) =>
                        roomType.rooms.map((room) => (
                          <TableRow key={room.roomName}>
                            <TableCell align="left">{room.roomName}</TableCell>
                            <TableCell align="left">
                              {room.externalID}
                            </TableCell>
                            <TableCell align="left">
                              {roomType.roomTypeName}
                            </TableCell>
                            <TableCell align="left">
                              {value.propertyCode}
                            </TableCell>
                            <TableCell align="left">
                              <EditIcon
                                style={{ fontSize: "15px", fill: "black" }}
                                button
                                onClick={() =>
                                  handleOpenUpdateRooms(
                                    room._id,
                                    room.roomName,
                                    room.externalID,
                                    value.propertyCode,
                                    roomType.roomTypeName
                                  )
                                }
                              />
                              <DeleteIcon
                                style={{ fontSize: "15px", fill: "black" }}
                                button
                                onClick={() =>
                                  handleOpenDialogDeleteRooms(
                                    value.propertyCode,
                                    roomType.roomTypeName,
                                    room.roomName,
                                    room._id
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      )
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </div>
      <Dialog
        open={openDialogUpdateRooms}
        onClose={handleCloseDialogUpdateRooms}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Modifica camera: {roomNameUpd}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form.root}>
            <TextField
              id="standard-basic"
              label="ID Esterno"
              defaultValue={externalIDUpd}
              onChange={(e) => setExternalIDUpd(e.target.value)}
            />
            <br></br>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ minWidth: 190 }}
              margin="dense"
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Codice Property
              </InputLabel>
              <NativeSelect
                disabled={!checkRoomProperty}
                native
                label="Codice Property"
                inputProps={{
                  name: "age",
                  id: "outlined-age-native-simple",
                }}
                value={propertyCodeNew}
                onChange={(e) => setPropertyCodeNew(e.target.value)}
              >
                {properties.map((value, i) => (
                  <option key={i} value={value}>
                    {value}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialogUpdateRooms}
            style={{ color: "#1aa3ff" }}
          >
            Annulla
          </Button>
          <Button onClick={handleUpdateRooms} style={{ color: "#1aa3ff" }}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogDeleteRooms}
        onClose={handleCloseDialogDeleteRooms}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{`Cancellare Camera: ${roomName}?`}</DialogTitle>
        <DialogActions>
          <Button
            onClick={handleCloseDialogDeleteRooms}
            style={{ color: "#1aa3ff" }}
          >
            Annulla
          </Button>
          <Button onClick={handleDeleteRooms} style={{ color: "#1aa3ff" }}>
            Cancella
          </Button>
        </DialogActions>
      </Dialog>
      {isRoomsDialogOpen && (
        <ConfigureDialog
          {...{
            id,
            isRoomsDialogOpen,
            setIsRoomsDialogOpen,
            properties,
            checkRoomProperty,
            handleOpenSuccess,
            handleOpenError,
          }}
        />
      )}
    </div>
  );
}

function ConfigureDialog({
  id,
  properties,
  checkRoomProperty,
  isRoomsDialogOpen,
  setIsRoomsDialogOpen,
  handleOpenSuccess,
  handleOpenError,
}) {
  const [step, setStep] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [rooms, setRooms] = React.useState([]);
  const [roomTypes, setRoomTypes] = React.useState([]);

  function nextStep() {
    setStep((step) => step + 1);
  }
  function previousStep() {
    setStep((step) => step - 1);
  }

  function handleSave() {
    const propertyConfiguration = [];
    rooms.forEach((room) => {
      let propertyObj = propertyConfiguration.find(
        (p) => p.propertyCode === room.propertyCode
      );
      if (!propertyObj) {
        propertyConfiguration.push({
          propertyCode: room.propertyCode,
          roomTypes: [],
        });
        propertyObj = propertyConfiguration[propertyConfiguration.length - 1];
      }
      const roomObj = {
        roomNumber: room.roomNumber,
        roomName: room.roomName,
        externalID: room.externalID,
      };
      const roomType = propertyObj.roomTypes.find(
        (type) => type.roomTypeName === room.roomTypeCode
      );
      // TODO: REFACTOR
      const occupancy = Number(roomTypes.find(
        (type) => type.roomTypeCode === room.roomTypeCode
      )?.occupancy);

      if (!roomType) {
        propertyObj.roomTypes.push({
          roomTypeName: room.roomTypeCode,
          rooms: [roomObj],
          occupancy,
          apartmentType: room.apartmentType,
        });
      } else {
        roomType.rooms.push(roomObj);
        roomType.occupancy = occupancy;
        roomType.apartmentType = room.apartmentType;
      }
    });
    console.log(propertyConfiguration);

    const configP = {
      method: "put",
      url: `${config.protelExpressUri}/api/insertRoomTypes`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        hotelID: id,
        propertyConfiguration: propertyConfiguration,
      }),
    };

    axios(configP)
      .then(function (response) {
        handleOpenSuccess();
        console.log(JSON.stringify(response.data));
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        handleOpenError();
      });
  }

  useEffect(() => {
    const fetchRooms = async () => {
      setIsLoading(true);
      axios
        .get(`${config.requestsUri}/SYSDATA?hotelID=${id}`)
        .then((res) => {
          setIsLoading(false);
          let rooms = [];
          let roomTypes =
            res.data.data.IO_SystemDataRS.SystemData.RoomTypes.RoomType;
          roomTypes = roomTypes.filter(
            (roomType) => roomType.$.IsRoom === "true"
          );
          roomTypes.forEach((roomType) => {
            const roomTypeCode = roomType.$.Code;
            console.log(roomTypeCode);
            let roomsRaw = roomType.Rooms.Room;
            if (roomsRaw) {
              if (Array.isArray(roomsRaw)) {
                roomsRaw = roomsRaw.map((room) => {
                  return { ...room, roomTypeCode };
                });
                rooms.push(...roomsRaw);
              } else {
                rooms.push({ ...roomsRaw, roomTypeCode });
              }
            }
          });
          rooms = rooms.map((room) => {
            return {
              roomName: room.$.Code,
              externalID: "",
              roomTypeCode: room.roomTypeCode,
              roomNumber: room.$.ID,
              propertyCode: properties[0],
            };
          });
          setRooms(rooms);
          roomTypes = roomTypes.map((roomType) => {
            return {
              roomTypeCode: roomType.$.Code,
              roomTypeName: roomType.$.Code,
              occupancy: roomType.Occupancy.$.DefaultOccupancy,
              apartmentType: "",
              propertyCode: properties[0],
            };
          });
          setRoomTypes(roomTypes);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    };
    fetchRooms();
  }, [id, properties]);

  return (
    <Dialog
      open={isRoomsDialogOpen}
      scroll="paper"
      onClose={() => setIsRoomsDialogOpen(false)}
      fullWidth
    >
      <DialogTitle>{step === 1 ? "Categorie" : "Camere"}</DialogTitle>
      <LinearProgress
        style={{ visibility: isLoading ? "visible" : "hidden" }}
      />
      {step === 1 ? (
        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Tipo Camera</TableCell>
                  <TableCell align="left">Property</TableCell>
                  <TableCell align="left">Occupancy</TableCell>
                  <TableCell align="left">Tipo Appartamento (INE SPAGNA)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roomTypes.map((roomType) => (
                  <TableRow key={roomType.roomTypeCode}>
                    <TableCell align="left">{roomType.roomTypeName}</TableCell>
                    <TableCell align="left">
                      <NativeSelect
                        value={roomType.propertyCode}
                        onChange={(e) => {
                          const newRoomTypes = [...roomTypes];
                          const roomType = newRoomTypes.find(
                            (type) => type.roomTypeCode === e.target.id
                          );
                          roomType.propertyCode = e.target.value;
                          setRoomTypes(newRoomTypes);

                          const newRooms = [...rooms];
                          const filteredRooms = newRooms.filter(
                            (r) => r.roomTypeCode === e.target.id
                          );
                          filteredRooms.forEach((r) => {
                            r.propertyCode = e.target.value;
                          });
                          setRooms(newRooms);
                        }}
                        id={roomType.roomTypeCode}
                      >
                        {properties.map((property, i) => (
                          <option key={i} value={property}>
                            {property}
                          </option>
                        ))}
                      </NativeSelect>
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        id={roomType.roomTypeCode}
                        value={roomType.occupancy}
                        onChange={(e) => {
                          const newRoomTypes = [...roomTypes];
                          const roomType = newRoomTypes.find(
                            (type) => type.roomTypeCode === e.target.id
                          );
                          roomType.occupancy = e.target.value;
                          setRoomTypes(newRoomTypes);
                        }}
                      ></TextField>
                    </TableCell>
                    <TableCell align="left">
                    <NativeSelect
                        value={roomType.apartmentType}
                        onChange={(e) => {
                          const newRoomTypes = [...roomTypes];
                          const roomType = newRoomTypes.find(
                            (type) => type.roomTypeCode === e.target.id
                          );
                          roomType.apartmentType = e.target.value;
                          setRoomTypes(newRoomTypes);

                          const newRooms = [...rooms];
                          const filteredRooms = newRooms.filter(
                            (r) => r.roomTypeCode === e.target.id
                          );
                          filteredRooms.forEach((r) => {
                            r.apartmentType = e.target.value;
                          });
                          setRooms(newRooms);
                        }}
                        id={roomType.roomTypeCode}
                      >
                        <option value="">
                          </option>
                        {apartmentTypes.map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))
                        }
                      </NativeSelect>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      ) : (
        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Camera</TableCell>
                  <TableCell align="left">Property</TableCell>
                  <TableCell align="left">ID Esterno</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rooms.map((row) => (
                  <TableRow key={row.roomName}>
                    <TableCell align="left">{row.roomName}</TableCell>
                    <TableCell align="left">
                      {
                        <FormControl
                          variant="outlined"
                          style={{ minWidth: 190 }}
                          margin="dense"
                        >
                          <NativeSelect
                            disabled={!checkRoomProperty}
                            native
                            value={row.propertyCode}
                            onChange={(e) => {
                              const newRooms = [...rooms];
                              const newRoom = newRooms.find(
                                (r) => r.roomNumber === row.roomNumber
                              );
                              newRoom.propertyCode = e.target.value;
                              setRooms(newRooms);
                            }}
                          >
                            {properties.map((value, i) => (
                              <option key={i} value={value}>
                                {value}
                              </option>
                            ))}
                          </NativeSelect>
                        </FormControl>
                      }
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        id={row.roomName}
                        value={row.externalID}
                        onChange={(e) => {
                          const newRooms = rooms.map((room) =>
                            room.roomName === e.target.id
                              ? { ...room, externalID: e.target.value }
                              : room
                          );
                          setRooms(newRooms);
                        }}
                      ></TextField>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={previousStep} disabled={step === 1}>
          Indietro
        </Button>
        {step === 2 ? (
          <Button onClick={handleSave}>Salva</Button>
        ) : (
          <Button onClick={nextStep} disabled={isLoading}>
            Avanti
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default FormCategoriaCamera;
