import React, { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Card, CardContent, Typography } from "@mui/material";
import config from "../config";

function InvoiceOverview() {
  const [reportUrl, setReportUrl] = React.useState("");

  const { id } = useParams();

  useEffect(() => {
    axios
      .post(`${config.protelExpressUri}/api/findsProperty`, { hotelID: id })
      .then((res) => {
        const setup = res.data.setup;
        const obj = setup.find((s) => s.code === "invoiceOverview");
        setReportUrl(obj?.value ?? "");
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#1aa3ff",
          height: "60px",
          display: "flex",
          alignItems: "center",
          marginLeft: 250,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "white",
            fontSize: 20,
            marginLeft: 40,
          }}
          gutterBottom
        >
          Invoice Overview
        </Typography>
      </div>

      <div style={{ margin: "10px 10px 0px 290px" }}>
        <Card>
          <CardContent>
            <iframe
              src={reportUrl}
              width="100%"
              style={{ height: "80vh", borderRadius: "4px", border: "none" }}
            />
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default InvoiceOverview;
