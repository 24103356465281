import React from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import config from "../../config";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import FileDownload from "js-file-download";
import GetAppIcon from "@mui/icons-material/GetApp";
import TitleBar from "../TitleBar";

function RicevutaSchedine() {
  const [daData, setDaData] = React.useState("");
  const [showLoad, setShowLoad] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState([]);

  const { id } = useParams();
  //data odierna
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  var dataOdierna = yyyy + mm + dd;
  //

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

  //modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  //

  //test id
  const handleSubmit = (event) => {
    event.preventDefault();

    var configIstat = {
      method: "post",
      url: `${config.requestsUri}/qsvc?service=SCHEDINEPDF&hotelID=${id}&from=${daData}`,
    };

    axios(configIstat)
      .then(showLoading())
      .then(function (response) {
        handleOpenSuccess();
        setShowLoad(false);

        // uso Fetch API per convertire base64 a blob e poi faccio download del pdf
        fetch(`data:application/pdf;base64,${response.data.data}`)
          .then((data) => data.blob())
          .then((pdf) => {
            FileDownload(pdf, response.data.fileName);
          });
      })
      .catch(function (error) {
        setShowLoad(false);
        handleOpenModal();
        error.response && error.response.data !== ""
          ? setModalMessage(error.response.data)
          : setModalMessage(error.message);
      });
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //Spinner
  const showLoading = () => {
    setShowLoad(!showLoad);
  };

  //snackbar success
  const handleOpenSuccess = () => {
    setOpen(true);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const downloadError = () => {
    let erroreModal = modalMessage.toString();
    var erroreFormattato = erroreModal.split(" ,").join("\n");
    FileDownload(erroreFormattato, `SchedineError_${dataOdierna}.txt`);
  };

  const classes = useStyles();
  return (
    <div style={{ height: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
        }}
      ></div>

      <TitleBar title="Scarica Ricevuta Schedine" loading={showLoad} />

      <div style={{ marginLeft: 290, display: "flex" }}>
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
        >
          <Fade in={openModal}>
            <div className={classes.paper}>
              <div style={{ display: "flex" }}>
                <GetAppIcon
                  button
                  onClick={downloadError}
                  style={{}}
                ></GetAppIcon>
                <CloseIcon
                  button
                  onClick={handleCloseModal}
                  style={{
                    flex: "0 0 50%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                ></CloseIcon>
              </div>
              <h4 id="transition-modal-title">Errori</h4>
              {Array.isArray(modalMessage) ? (
                modalMessage.map((value, index) => (
                  <Typography key={index} id="transition-modal-description">
                    {value}
                  </Typography>
                ))
              ) : (
                <Typography>{modalMessage}</Typography>
              )}
            </div>
          </Fade>
        </Dialog>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        >
          <div>
            <Alert severity="success">SUCCESS!</Alert>
          </div>
        </Snackbar>
        <Card
          style={{ minWidth: 250, marginTop: 11, borderRadius: 5 }}
          elevation={0}
        >
          <CardContent>
            <form
              style={{ display: "flex", alignItems: "center", gap: "2rem" }}
            >
              <TextField
                id="standard-basic"
                helperText={
                  <Typography
                    variant="title"
                    className={classes.centerText}
                    display="block"
                  >
                    Data
                  </Typography>
                }
                value={daData}
                type="date"
                format={"yyyy/MM/dd"}
                onChange={(e) => setDaData(e.target.value)}
                style={{ marginTop: 20 }}
              />
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={!daData || showLoad}
              >
                Scarica
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default RicevutaSchedine;
