import React, { useContext } from 'react';
import { AuthenticationContext } from '../contexts';
import "./css/styles.css"
import Typography from '@mui/material/Typography';
import SideBarClock from '../components/homeprincipale/SideBarClock'

function App() {
 
  const { currentUser } = useContext(AuthenticationContext);

  return (
    <div className="foo" style={{ height: '100vh', backgroundColor: '#F8F8F8' }}>
      <SideBarClock />
      <div style={{ height: '64px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>

      <div style={{ backgroundColor: "rgb(3, 155, 229)", height: '100px', display: 'flex', alignItems: 'center', marginLeft: 270, marginRight: 270, fontFamily: 'Muli, "Helvetica Neue", Arial, sans-serif', fontSize: '26px', fontStretch: '100%', fontWeight: '400', lineHeight: '40px' }} >
        <Typography style={{
          color: "white",
          marginLeft: 40,
          fontFamily: 'Muli, "Helvetica Neue", Arial, sans-serif',
          fontSize: '26px',
          fontStretch: '100%',
          fontWeight: '400',
          lineHeight: '40px'
        }} gutterBottom>Bentornato, {currentUser.name}!</Typography>
      </div>
    </div>
  );
}

export default App;
