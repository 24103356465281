import { useState } from "react";

export function useForm(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event, defaultValues) {
      if (defaultValues) {
        setValues({
          ...defaultValues,
        });
      }
      else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      }
    },
  ];
}
