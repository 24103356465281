import React, { useEffect } from 'react';
import axios from 'axios'
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import config from '../config'
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function FormHotel() {
  const [hotelID, setHotelID] = React.useState("");
  const [hotelName, setHotelName] = React.useState("");
  const [blocked, setBlocked] = React.useState(false);
  const [blockReason, setBlockReason] = React.useState("");
  const [token, setToken] = React.useState("");
  const [fiscalToken, setFiscalToken] = React.useState("");
  const [istatToken, setIstatToken] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [hotelData, setHotelData] = React.useState([]);

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '19ch',
      },
    },
  }));

  const handleSubmit = (event) => {
    event.preventDefault();
    var url;
    hotelData.map((value) => url = `${config.protelExpressUri}/api/hotels/formhotel/${value._id}`)

    var data = JSON.stringify({ "hotelName": hotelName, "hotelID": hotelID, "blocked": blocked, "blockReason": blockReason, "token": token, tokens: { "fiscal": fiscalToken, "istat": istatToken } });

    var config1 = {
      method: 'put', //utilizzato per aggiornare risorse esistennti 
      url: url, //specifica l'url a cui effettuare la richiesta, dovrebbe essere definito altrove 
      headers: {  //
        'Content-Type': 'application/json' //corpo della richiesta è in formato json
      },
      data: data
    };

    axios(config1) //chimata http utilizzando le cnonfigurazioni specificate nell'oggetto config1
      .then(function (response) { //una volta che la chiamata è stata effettuata con successo la funzione then viene eseguita
        console.log(JSON.stringify(response.data)); //i dati vengono estratti con response.data e convertiti in stringa
        handleOpenSuccess()
        window.location.reload()
      })
      .catch(function (error) {
        console.log(error);
        handleOpenError()
      });
  }

  const { id } = useParams();

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleOpenSuccess = () => {
    setOpen(true);
  };

  const handleOpenError = () => {
    setOpenError(true);
  };

  useEffect(() => {
    var dataGet = JSON.stringify({ "hotelID": `${id}` });

    var config0 = { //configurazione per la chinata http 
      method: 'post', //utilizzato per inviare dati a un server per creare(aggiornare una instanza )
      url: `${config.protelExpressUri}/api//find/hotels`,//specifica l'url a cui effettuare la richiesta, utilizza una variabile di congifurazione e il percorso ell'endpoint 
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataGet
    };
    async function getData() {
      const result = await axios(config0)
      setHotelData(result.data)
    }
    getData()
  }, [id])

  const classes = useStyles();

  return (
    <div style={{ height: '100vh', backgroundColor: '#F8F8F8' }}>
      <div style={{ height: '64px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
      <div style={{ backgroundColor: "#1aa3ff", height: '60px', display: 'flex', alignItems: 'center', marginLeft: 250 }} >
        <Typography variant="h5" style={{
          color: "white",
          fontSize: 20,
          marginLeft: 40
        }} gutterBottom>Gestione Hotel</Typography>
      </div>
      <div onSubmit={handleSubmit} style={{
        marginLeft: 290
      }}>
        <div style={{ display: 'flex', }}>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSuccess}>
            <div><Alert severity="success">SUCCESS!</Alert></div>
          </Snackbar>

          <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
            <div><Alert severity="error">Errore</Alert></div>
          </Snackbar>
          <Card style={{ minWidth: 250, marginTop: 11, marginRight: 11, borderRadius: 5 }} elevation={0}>
            <CardContent>
              <form className={classes.root} >
                {hotelData.map((value1, i) =>
                  <React.Fragment key={i}>
                    <TextField disabled id="standard-disabled" fullWidth={true} label="Hotel ID" defaultValue={value1.hotelID} onChange={e => setHotelID(e.target.value)} />
                    <TextField disabled id="standard-disabled" fullWidth={true} label="Nome Hotel" defaultValue={value1.hotelName} onChange={e => setHotelName(e.target.value)} />
                    <FormControlLabel
                      value="Bloccato"
                      control={<Checkbox
                        defaultChecked={value1.blocked}
                        onClick={e => {
                          setBlocked(e.target.checked);
                        }}
                      />}
                      label="Bloccato"
                      labelPlacement="top"
                    />
                    <TextField id="standard-basic" label="Ragione del Blocco" fullWidth={true} defaultValue={value1.blockReason} onChange={e => setBlockReason(e.target.value)} />
                    <TextField id="standard-basic" label="Access Token" defaultValue={value1.token} fullWidth={true} onChange={e => setToken(e.target.value)} />

                    <TextField id="standard-basic" label="Fiscal Token" defaultValue={value1.tokens ? value1.tokens.fiscal : value1.tokens} fullWidth={true} onChange={e => setFiscalToken(e.target.value)} />
                    <TextField id="standard-basic" label="Token OTA_ReadRQ" defaultValue={value1.tokens ? value1.tokens.istat : value1.tokens} fullWidth={true} onChange={e => setIstatToken(e.target.value)} />
                    <CardActions>
                      <Button variant="contained" onClick={handleSubmit} style={{ backgroundColor: '#1aa3ff', color: "white" }}>Inserisci</Button>
                    </CardActions>
                  </React.Fragment>
                )}
              </form>
            </CardContent>
          </Card>
        </div>
      </div >
    </div>
  );
}

export default FormHotel;
