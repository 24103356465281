import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../config";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import {
  Typography,
  TextField,
  Card,
  CardContent,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Checkbox,
  CircularProgress,
  LinearProgress,
} from "@mui/material";

const mesi = [
  "GEN",
  "FEB",
  "MAR",
  "APR",
  "MAG",
  "GIU",
  "LUG",
  "AGO",
  "SET",
  "OTT",
  "NOV",
  "DIC",
];

const initialStateParametri = {
  active: false,
  postingActive: false,
  importo: "",
  maxNotti: "",
  mesiEsenti: [],
  comune: "",
  provincia: "",
  minori1: "",
  minori2: "",
  adultiOver: "",
  stagioni: [],
  riduzioni: [],
  fascePrezzo: [],
  urlReportBI: "",
  export: {
    tipoExport: "",
    riduzioni: [],
    codiceLimitiImposta: "",
  },
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function SetupCityTax() {
  const [setupData, setSetupData] = React.useState();
  const [selectedProperty, setSelectedProperty] = React.useState("");
  const [properties, setProperties] = React.useState([]);
  const [parametri, setParametri] = React.useState(initialStateParametri);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDataLoading, setIsDataLoading] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);

  const { id } = useParams();

  useEffect(() => {
    setIsDataLoading(true);
    axios
      .post(`${config.protelExpressUri}/api/findsProperty`, { hotelID: id })
      .then((res) => {
        let setup = res.data;
        if (setup === "" || !setup) {
          setup = { propertyConfiguration: [], cityTax: [] };
        }
        setSetupData(setup);
        let propertyCodes = setup.propertyConfiguration.map(
          (p) => p.propertyCode
        );
        if (propertyCodes.length > 0) {
          setProperties(["", ...propertyCodes]);
          setSelectedProperty("");
        } else {
          propertyCodes = [""];
          setProperties([""]);
          setSelectedProperty("");
        }

        //setCityTaxSetup(setup?.cityTax ?? []);
        const selectedSetup = setup.cityTax.find((p) => p.propertyCode === "");

        if (selectedSetup) {
          deepCopyAndSetParametri(selectedSetup);
        }
        setIsDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoading(false);
      });
  }, [id, reload]);

  const handleChangeParametri = (e) => {
    setParametri({ ...parametri, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    setIsLoading(true);
    axios
      .put(`${config.protelExpressUri}/api/updateCityTaxSetup`, {
        hotelID: id,
        propertyCode: selectedProperty,
        parametri,
      })
      .then(() => {
        setIsLoading(false);
        setOpenSnackbar(true);
        setReload(!reload);
      })
      .catch((error) => {
        console.log(error);
        setOpenSnackbarError(true);
        setIsLoading(false);
      });
  };

  const deepCopyAndSetParametri = (selectedSetup) => {
    // deep copy
    const stagioni = [
      ...selectedSetup.stagioni.map((s) => {
        return { ...s };
      }),
    ];
    const riduzioni = [
      ...selectedSetup.riduzioni.map((r) => {
        return { ...r };
      }),
    ];
    const fascePrezzo = [
      ...selectedSetup.fascePrezzo.map((f) => {
        return { ...f };
      }),
    ];
    const riduzioniExport = [
      ...selectedSetup.export.riduzioni.map((r) => {
        return { ...r };
      }),
    ];
    setParametri({
      ...initialStateParametri,
      ...selectedSetup,
      stagioni: stagioni,
      riduzioni: riduzioni,
      fascePrezzo: fascePrezzo,
      export: {
        ...selectedSetup.export,
        riduzioni: riduzioniExport,
      },
    });
  };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#1aa3ff",
          height: "60px",
          display: "flex",
          alignItems: "center",
          marginLeft: 250,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "white",
            fontSize: 20,
            marginLeft: 40,
          }}
          gutterBottom
        >
          Setup Tassa di Soggiorno
        </Typography>
      </div>

      <div style={{ margin: "10px 10px 0px 290px" }}>
        <Card style={{ marginBottom: "1rem" }}>
          <CardContent>
            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <FormControl>
                <InputLabel id="property-label">Property</InputLabel>
                <Select
                  labelId="property-label"
                  value={selectedProperty}
                  name="property"
                  onChange={(e) => {
                    setSelectedProperty(e.target.value);
                    const selectedSetup = setupData.cityTax.find(
                      (p) => p.propertyCode === e.target.value
                    );
                    if (selectedSetup) {
                      deepCopyAndSetParametri(selectedSetup);
                    } else {
                      setParametri(initialStateParametri);
                    }
                  }}
                  style={{ width: 150 }}
                >
                  {properties.map((prp) =>
                    prp === "" ? (
                      <MenuItem key={prp} value="">
                        TUTTE
                      </MenuItem>
                    ) : (
                      <MenuItem key={prp} value={prp}>
                        {prp}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <div>
                <Checkbox
                  name="active"
                  checked={parametri.active}
                  onChange={(e) =>
                    setParametri({ ...parametri, active: e.target.checked })
                  }
                />
                Attiva
              </div>
              <div>
                <Checkbox
                  name="postingActive"
                  checked={parametri.postingActive}
                  onChange={(e) =>
                    setParametri({
                      ...parametri,
                      postingActive: e.target.checked,
                    })
                  }
                />
                Attiva Addebiti
              </div>
              <Button
                variant="contained"
                onClick={handleSave}
                style={{
                  backgroundColor: "rgb(3, 155, 229)",
                  color: "white",
                }}
                elevation={0}
                disabled={isLoading}
              >
                SALVA
              </Button>
              {isLoading && <CircularProgress />}
            </div>
          </CardContent>
        </Card>
        <Card>
          {isDataLoading && <LinearProgress />}
          <CardContent style={{ paddingTop: 0 }}>
            <Title>Parametri</Title>

            <TextInput
              label="Importo Base"
              value={parametri.importo ?? ""}
              name="importo"
              onChange={handleChangeParametri}
            />
            <TextInput
              label="Massimo Notti"
              value={parametri.maxNotti ?? ""}
              name="maxNotti"
              onChange={handleChangeParametri}
            />

            <FormControl style={{ margin: "1rem" }}>
              <InputLabel id="mesi-esenti-label">Mesi esenti</InputLabel>
              <Select
                labelId="mesi-esenti-label"
                multiple
                value={parametri.mesiEsenti}
                name="mesiEsenti"
                onChange={handleChangeParametri}
                style={{ width: 150 }}
              >
                {mesi.map((mese, i) => (
                  <MenuItem key={i + 1} value={i + 1}>
                    {mese}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Title>Esenzioni</Title>
            <TextInput
              label="Comune Residenza"
              value={parametri.comune}
              name="comune"
              onChange={handleChangeParametri}
            />

            {/* <TextInput
              label="Provincia Residenza"
              value={parametri.provincia}
              name="provincia"
              onChange={handleChangeParametri}
            /> */}
            <TextInput
              label="Età minori"
              value={parametri.minori1 ?? ""}
              name="minori1"
              onChange={handleChangeParametri}
            />
            <TextInput
              label="Età minori 2"
              value={parametri.minori2 ?? ""}
              name="minori2"
              onChange={handleChangeParametri}
            />
            <TextInput
              label="Età over"
              value={parametri.adultiOver ?? ""}
              name="adultiOver"
              onChange={handleChangeParametri}
            />

            <Title>Stagioni</Title>
            <div>
              <Button
                onClick={() => {
                  setParametri({
                    ...parametri,
                    stagioni: [
                      ...parametri.stagioni,
                      { code: "", periodo: [], importo: "" },
                    ],
                  });
                }}
              >
                AGGIUNGI
              </Button>
              {parametri.stagioni.map((stagione, i) => (
                <Stagione
                  key={i}
                  handleChangeStagione={(e) => {
                    const newStagioni = [...parametri.stagioni];
                    newStagioni[i][e.target.name] = e.target.value;
                    setParametri({ ...parametri, stagioni: newStagioni });
                  }}
                  handleDeleteStagione={() => {
                    const newStagioni = [...parametri.stagioni];
                    newStagioni.splice(i, 1);
                    setParametri({ ...parametri, stagioni: newStagioni });
                  }}
                  code={stagione.code}
                  periodo={stagione.periodo}
                  importo={stagione.importo}
                />
              ))}
            </div>
            <Title>Riduzioni</Title>
            <div>
              <Button
                onClick={() => {
                  setParametri({
                    ...parametri,
                    riduzioni: [
                      ...parametri.riduzioni,
                      { code: "", value: "", percentuale: false },
                    ],
                  });
                }}
              >
                Aggiungi
              </Button>
              {parametri.riduzioni.map((riduzione, i) => (
                <Riduzione
                  key={i}
                  code={riduzione.code}
                  value={riduzione.value}
                  percentuale={riduzione.percentuale}
                  handleChangeRiduzione={(e) => {
                    const newRiduzioni = [...parametri.riduzioni];
                    if (e.target.name !== "percentuale") {
                      newRiduzioni[i][e.target.name] = e.target.value;
                    } else {
                      newRiduzioni[i].percentuale = e.target.checked;
                    }
                    setParametri({ ...parametri, riduzioni: newRiduzioni });
                  }}
                  handleDeleteRiduzione={() => {
                    const newRiduzioni = [...parametri.riduzioni];
                    newRiduzioni.splice(i, 1);
                    setParametri({ ...parametri, riduzioni: newRiduzioni });
                  }}
                />
              ))}
            </div>
            <Title>Fasce Prezzo</Title>
            <div>
              <Button
                onClick={() => {
                  setParametri({
                    ...parametri,
                    fascePrezzo: [
                      ...parametri.fascePrezzo,
                      { code: "", min: "", max: "", importo: "" },
                    ],
                  });
                }}
              >
                Aggiungi
              </Button>
              {parametri.fascePrezzo.map((fascia, i) => (
                <FasciaPrezzo
                  key={i}
                  handleChangeFascia={(e) => {
                    const newFasce = [...parametri.fascePrezzo];
                    newFasce[i][e.target.name] = e.target.value;
                    setParametri({ ...parametri, fascePrezzo: newFasce });
                  }}
                  handleDeleteFascia={() => {
                    const newFasce = [...parametri.fascePrezzo];
                    newFasce.splice(i, 1);
                    setParametri({ ...parametri, fascePrezzo: newFasce });
                  }}
                  code={fascia.code}
                  importo={fascia.importo}
                  min={fascia.min}
                  max={fascia.max}
                />
              ))}
            </div>
            <Title>Report (BI)</Title>
            <div>
              <TextInput
                label="URL report"
                value={parametri.urlReportBI}
                name="urlReportBI"
                onChange={handleChangeParametri}
              />
            </div>
            <Title>Export</Title>
            <div>
              <FormControl style={{ margin: "1rem" }}>
                <InputLabel id="tipo-export-label">Tipo Export</InputLabel>
                <Select
                  labelId="tipo-export-label"
                  value={parametri.export.tipoExport}
                  name="tipoExport"
                  onChange={(e) =>
                    setParametri({
                      ...parametri,
                      export: {
                        ...parametri.export,
                        tipoExport: e.target.value,
                      },
                    })
                  }
                  style={{ width: 150 }}
                >
                  <MenuItem key="" value="">
                    NON ATTIVO
                  </MenuItem>
                  <MenuItem key="TBPRES01" value="TBPRES01">
                    TBPRES01
                  </MenuItem>
                </Select>
              </FormControl>

              <TextInput
                label="Codice Limiti Imposta"
                value={parametri.export.codiceLimitiImposta ?? ""}
                name="codiceLimitiImposta"
                onChange={(e) =>
                  setParametri({
                    ...parametri,
                    export: {
                      ...parametri.export,
                      codiceLimitiImposta: e.target.value,
                    },
                  })
                }
              />
            </div>

            <Button
              onClick={() => {
                setParametri({
                  ...parametri,
                  export: {
                    ...parametri.export,
                    riduzioni: [
                      ...parametri.export.riduzioni,
                      { code: "", value: "" },
                    ],
                  },
                });
              }}
            >
              Aggiungi Codice Riduzione
            </Button>
            {parametri.export.riduzioni.map((riduzione, i) => (
              <RiduzioneExport
                key={i}
                code={riduzione.code}
                value={riduzione.value}
                handleChangeRiduzioneExport={(e) => {
                  const newRidExp = [...parametri.export.riduzioni];
                  newRidExp[i][e.target.name] = e.target.value;
                  setParametri({
                    ...parametri,
                    export: { ...parametri.export, riduzioni: newRidExp },
                  });
                }}
                handleDeleteRiduzioneExport={() => {
                  const newRidExp = [...parametri.export.riduzioni];
                  newRidExp.splice(i, 1);
                  setParametri({
                    ...parametri,
                    export: { ...parametri.export, riduzioni: newRidExp },
                  });
                }}
              />
            ))}
          </CardContent>
        </Card>
        <Snackbar
          open={openSnackbar}
          autoHideDuration="6000"
          onClose={() => setOpenSnackbar(false)}
        >
          <div><Alert severity="success">Operazione effettuata</Alert></div>
        </Snackbar>
        <Snackbar
          open={openSnackbarError}
          autoHideDuration="6000"
          onClose={() => {
            setOpenSnackbarError(false);
          }}
        >
          <div><Alert severity="error">Operazione fallita</Alert></div>
        </Snackbar>
      </div>
    </div>
  );
}

const Stagione = ({
  code,
  periodo,
  importo,
  handleChangeStagione,
  handleDeleteStagione,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        borderBottom: "1px solid lightgray",
      }}
    >
      <DeleteIcon
        style={{ fontSize: "20px" }}
        button
        onClick={handleDeleteStagione}
      />
      <TextInput
        label="Nome"
        name="code"
        value={code}
        onChange={handleChangeStagione}
      />
      <FormControl style={{ margin: "1rem" }}>
        <InputLabel id="mesi-stagione-label">Mesi</InputLabel>
        <Select
          name="periodo"
          labelId="mesi-stagione-label"
          multiple
          value={periodo}
          onChange={handleChangeStagione}
          style={{ width: 150 }}
        >
          {mesi.map((mese, i) => (
            <MenuItem key={i + 1} value={i + 1}>
              {mese}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextInput
        label="Importo"
        name="importo"
        value={importo ?? ""}
        onChange={handleChangeStagione}
      />
    </div>
  );
};

const Riduzione = ({
  code,
  value,
  percentuale,
  handleChangeRiduzione,
  handleDeleteRiduzione,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        borderBottom: "1px solid lightgray",
      }}
    >
      <DeleteIcon
        style={{ fontSize: "20px" }}
        button
        onClick={handleDeleteRiduzione}
      />
      <TextInput
        label="Nome"
        value={code}
        name="code"
        onChange={handleChangeRiduzione}
      />
      <TextInput
        label="Valore"
        value={value ?? ""}
        name="value"
        onChange={handleChangeRiduzione}
      />

      <div style={{ display: "inline-block", margin: "2rem 1rem 0 1rem" }}>
        <Checkbox
          name="percentuale"
          checked={percentuale}
          onChange={handleChangeRiduzione}
        />
        Riduzione Percentuale
      </div>
    </div>
  );
};

const FasciaPrezzo = ({
  code,
  importo,
  min,
  max,
  handleChangeFascia,
  handleDeleteFascia,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        borderBottom: "1px solid lightgray",
      }}
    >
      <DeleteIcon
        style={{ fontSize: "20px" }}
        button
        onClick={handleDeleteFascia}
      />
      <TextInput
        label="Nome"
        value={code}
        name="code"
        onChange={handleChangeFascia}
      />
      <TextInput
        label="Minimo"
        value={min ?? ""}
        name="min"
        onChange={handleChangeFascia}
      />
      <TextInput
        label="Massimo"
        value={max ?? ""}
        name="max"
        onChange={handleChangeFascia}
      />
      <TextInput
        label="Importo"
        value={importo ?? ""}
        name="importo"
        onChange={handleChangeFascia}
      />
    </div>
  );
};

const RiduzioneExport = ({
  code,
  value,
  handleChangeRiduzioneExport,
  handleDeleteRiduzioneExport,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        borderBottom: "1px solid lightgray",
      }}
    >
      <DeleteIcon
        style={{ fontSize: "20px" }}
        button
        onClick={handleDeleteRiduzioneExport}
      />
      <TextInput
        label="Nome"
        value={code}
        name="code"
        onChange={handleChangeRiduzioneExport}
      />
      <TextInput
        label="Valore"
        value={value}
        name="value"
        onChange={handleChangeRiduzioneExport}
      />
    </div>
  );
};

const TextInput = (props) => {
  return <TextField style={{ margin: "1rem" }} {...props} />;
};

const Title = (props) => (
  <Typography
    style={{
      color: "#1aa3ff",
      marginTop: "1rem",
      fontSize: "1rem",
    }}
    variant="h5"
    gutterBottom
    {...props}
  />
);

export default SetupCityTax;
