import React, { useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from '@mui/material/Alert';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Card,
  CardContent,
  Button,
  Typography,
  LinearProgress,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  TextField,
  Snackbar,
  Popover,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import config from "../config";
import { AuthenticationContext } from "../contexts";

import FileDownload from "js-file-download";

import FormatXML from "xml-formatter";

import Editor from "@monaco-editor/react";

const statiEI = {
  0: "Da Inviare",
  1: "XML Generato",
  2: "XML Inviato",
  3: "Chiuso",
  4: "Errore",
  10: "Accettato da Intermediario",
  11: "Rifiutato da Intermediario",
  12: "Accettato con Warning da Intermediario",
  13: "Spedito a Sdi",
  14: "Notifica di Scarto",
  15: "Mancata Consegna",
  16: "Ricevuta di Consegna",
  17: "Esito Cedente",
  18: "Esito Cessionario",
  19: "Scarto Esito Cessionario",
  20: "Decorrenza Termini",
  21: "Trasmissione con Mancato Recapito",
  22: "Temporanea Mancata Consegna",
};

const timestampToDateTime = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.toLocaleDateString([], {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  })} - ${date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
};

function GestioneFatture() {
  const [isLoading, setIsLoading] = React.useState(false);

  // form per filtrare la query
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");
  const [recipient, setRecipient] = React.useState("");
  const [invoiceID, setInvoiceID] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  const [faStatus, setFaStatus] = React.useState("");

  const [EIs, setEIs] = React.useState([]);

  // chiave della tabella, viene cambiato per resettarla
  const [tableKey, setTableKey] = React.useState(0);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
  const [openSnackbarInfo, setOpenSnackbarInfo] = React.useState(false);
  const [dialogEditOpen, setDialogEditOpen] = React.useState(false);
  const [dialogLogAzioniOpen, setDialogLogAzioniOpen] = React.useState(false);
  const [dialogLogInvioOpen, setDialogLogInvioOpen] = React.useState(false);
  const [selectedEI, setSelectedEI] = React.useState();
  const [dialogError, setDialogError] = React.useState({
    title: "",
    open: false,
    errors: [],
  });

  const { id } = useParams();
  const { currentUser } = useContext(AuthenticationContext);

  // controlla se l'utente può modificare il file xml
  const canEdit =
    currentUser.roles.includes("CUSTOMER_ADMIN") ||
    currentUser.roles.includes("ADMIN") ||
    currentUser.roles.includes("SUPPORT");

  // colonne da visualizzare nella tabella
  const rows = EIs.map((ei) => {
    let stato = "";
    let statusCode = -1;
    if (ei.faStatus) {
      statusCode = ei.faStatus;
      stato = statiEI[statusCode];
    }

    return {
      _id: ei._id,
      statusCode,
      Stato: stato,
      "No. Documento": ei.invoiceID,
      Data: new Date(ei.invoiceDate).toLocaleDateString(),
      "No. Conto pAir": ei?.invoiceCode,
      "ID Conto pAir": ei?.protelID,
      Intestatario: ei.recipient,
      "Inviato Email": ei.sent ? "SI" : "NO",
      "Inviato SFTP": ei?.sftp?.sent ? "SI" : "NO",
      File: ei.fileName,
    };
  });

  // funzione che effettua la query delle fatture
  const handleSearch = async (refresh = false) => {
    if (
      !invoiceID &&
      !recipient &&
      !fileName &&
      !faStatus &&
      (!fromDate || !toDate)
    ) {
      setDialogError({
        open: true,
        title: "Info",
        errors: ["Inserire almeno un filtro di ricerca"],
      });
      return;
    }

    if (!refresh) {
      setTableKey((key) => key + 1);
    }
    setIsLoading(true);

    try {
      const res = await axios.post(`${config.protelExpressUri}/api/getEI`, {
        hotelID: id,
        fromDate,
        toDate,
        invoiceID,
        recipient,
        fileName,
        faStatus,
        userRoles: currentUser.roles,
      });
      setEIs(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleResend = async (_id, method) => {
    try {
      const res = await axios.post(`${config.protelExpressUri}/api/editEI`, {
        _id: _id,
        hotelID: id,
        ...(method === "MAIL" && { resendMail: true }),
        ...(method === "SFTP" && { resendSFTP: true }),
        user: currentUser.email,
      });
      if (res.status === 200) {
        setOpenSnackbarInfo(true);
        handleSearch();
      } else {
        setOpenSnackbarError(true);
      }
    } catch (error) {
      console.log(error);
      setOpenSnackbarError(true);
    }
  };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#1aa3ff",
          height: "60px",
          display: "flex",
          alignItems: "center",
          marginLeft: 250,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "white",
            fontSize: 20,
            marginLeft: 40,
          }}
          gutterBottom
        >
          Gestione Fatture Elettroniche
        </Typography>
      </div>

      <div style={{ margin: "10px 10px 0px 290px" }}>
        <Card style={{ marginBottom: 10 }}>
          <CardContent>
            <form
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <TextField
                label="Da Data"
                value={fromDate}
                type="date"
                format={"yyyy/MM/dd"}
                onChange={(e) => setFromDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="A Data"
                value={toDate}
                type="date"
                format={"yyyy/MM/dd"}
                onChange={(e) => setToDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Intestatario"
                value={recipient}
                onChange={(e) => setRecipient(e.target.value)}
              />
              <TextField
                label="No. Documento"
                value={invoiceID}
                onChange={(e) => setInvoiceID(e.target.value)}
              />
              <TextField
                label="Lotto"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
              />
              <FormControl>
                <InputLabel id="status-label">Stato</InputLabel>
                <Select
                  labelId="status-label"
                  value={faStatus}
                  name="property"
                  onChange={(e) => {
                    setFaStatus(e.target.value);
                  }}
                  style={{ width: 150 }}
                >
                  <MenuItem key="" value="">
                    TUTTI
                  </MenuItem>

                  {Object.keys(statiEI).map((key) => (
                    <MenuItem key={key} value={key}>
                      {statiEI[key]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "rgb(3, 155, 229)",
                  color: "white",
                }}
                elevation={0}
                disabled={isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
                type="submit"
              >
                CERCA
              </Button>
            </form>
          </CardContent>
          <LinearProgress
            style={{ visibility: isLoading ? "visible" : "hidden" }}
          />
        </Card>

        {EIs.length > 0 && (
          <CustomTable
            key={tableKey}
            canEdit={canEdit}
            rows={rows}
            // TODO: refactor handlers
            handleEdit={(_id) => {
              setSelectedEI(EIs.find((ei) => ei._id === _id));
              setDialogEditOpen(true);
            }}
            handleDownload={(_id) => {
              const ei = EIs.find((ei) => ei._id === _id);
              FileDownload(ei.body, ei.fileName);
            }}
            handleResend={handleResend}
            handleLogAzioni={(_id) => {
              setSelectedEI(EIs.find((ei) => ei._id === _id));
              setDialogLogAzioniOpen(true);
            }}
            handleLogInvio={(_id) => {
              setSelectedEI(EIs.find((ei) => ei._id === _id));
              setDialogLogInvioOpen(true);
            }}
          />
        )}

        <Snackbar
          open={openSnackbar}
          autoHideDuration="6000"
          onClose={() => setOpenSnackbar(false)}
        >
          <div><Alert severity="success">Operazione effettuata</Alert></div>
        </Snackbar>
        <Snackbar
          open={openSnackbarError}
          autoHideDuration="6000"
          onClose={() => {
            setOpenSnackbarError(false);
          }}
        >
          <div><Alert severity="error">Operazione fallita</Alert></div>
        </Snackbar>
        <Snackbar
          open={openSnackbarInfo}
          autoHideDuration="6000"
          onClose={() => {
            setOpenSnackbarInfo(false);
          }}
        >
          <div><Alert severity="info">Il documento sarà inviato a breve</Alert></div>
        </Snackbar>
        <Dialog
          open={dialogError.open}
          onClose={() => setDialogError({ open: false, errors: [], title: "" })}
        >
          <DialogTitle style={{ paddingBottom: 0 }}>
            {dialogError.title}
          </DialogTitle>
          <DialogContent>
            {dialogError.errors.map((error, i) => (
              <p key={i}>{error}</p>
            ))}
          </DialogContent>
        </Dialog>
        {selectedEI && (
          <>
            <EditorDialog
              ei={selectedEI}
              open={dialogEditOpen}
              onClose={() => {
                setDialogEditOpen(false);
                setSelectedEI(null);
              }}
              openSnackbar={() => setOpenSnackbar(true)}
              openSnackbarError={() => setOpenSnackbarError(true)}
              refresh={() => handleSearch(true)}
              currentUser={currentUser}
            />

            <Dialog
              open={dialogLogAzioniOpen}
              onClose={() => {
                setDialogLogAzioniOpen(false);
              }}
              maxWidth="false"
            >
              <DialogTitle>Log Azioni</DialogTitle>
              <DialogContent>
                <TableContainer style={{ maxHeight: "100%" }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Data - Ora</TableCell>
                        <TableCell>Azione</TableCell>
                        <TableCell>Utente</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedEI?.actionsLog &&
                        selectedEI.actionsLog.map((action, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {timestampToDateTime(action.timestamp)}
                            </TableCell>
                            <TableCell>{action.action}</TableCell>
                            <TableCell>{action.user}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
            </Dialog>
            <Dialog
              open={dialogLogInvioOpen}
              onClose={() => {
                setDialogLogInvioOpen(false);
              }}
              maxWidth="false"
            >
              <DialogTitle>Log Esiti SDI</DialogTitle>
              <DialogContent>
                <TableContainer style={{ maxHeight: "100%" }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Data - Ora</TableCell>
                        <TableCell>Stato</TableCell>
                        <TableCell>Info</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedEI?.faEvents &&
                        selectedEI.faEvents.map((log, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {timestampToDateTime(log.date)}
                            </TableCell>
                            <TableCell>{statiEI[log.status]}</TableCell>
                            <TableCell style={{ whiteSpace: "pre-wrap" }}>
                              {log.info && log.info.split("|").join("\n")}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
            </Dialog>
          </>
        )}
      </div>
    </div>
  );
}

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

// tabella che mostra le fatture
function CustomTable({
  rows,
  canEdit,
  handleEdit,
  handleDownload,
  handleResend,
  handleLogAzioni,
  handleLogInvio,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  /* elemento del DOM su cui agganciare il Popover per effettuare azioni.
     Viene valorizzato con il bottone della riga (fattura) selezionata */
  const [anchorEl, setAnchorEl] = React.useState(null);

  //paginazione
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //nomi delle colonne
  const head = Object.keys(rows[0]).filter(
    (column) => !["_id", "statusCode"].includes(column)
  );

  const isEIDefinitive = () => {
    if (anchorEl) {
      const ei = rows.find((row) => row._id === anchorEl.id);
      if (ei.statusCode >= 15 && ei.statusCode !== 18 && ei.statusCode !== 19) {
        return true;
      }
    }
    return false;
  };

  const highlightColor = (status) => {
    if (status >= 10 && status <= 14) {
      return "orange";
    } else if (status >= 15 && status <= 19) {
      return "green";
    } else if (status >= 20) {
      return "red";
    }
    return "lightgray";
  };

  return (
    <TableContainer component={Paper}>
      <Table style={{ minWidth: 100 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {head.map((key) => (
              <TableCell key={key} align="left">
                {key}
              </TableCell>
            ))}
            <TableCell align="left">Azioni</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //seleziona righe di un pagina
            .map((row) => (
              <TableRow
                key={row._id}
                style={{
                  borderLeft: `5px solid ${highlightColor(row.statusCode)}`,
                }}
              >
                {head.map((column) => (
                  <TableCell key={column} align="left">
                    {row[column]}
                  </TableCell>
                ))}
                <TableCell align="center" padding="checkbox">
                  <IconButton
                    id={row._id}
                    size="small"
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Righe per pagina"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} di ${count !== -1 ? count : `più di ${to}`}`
        }
      />
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {canEdit && (
          <MenuItem
            onClick={() => {
              handleEdit(anchorEl.id);
              setAnchorEl(null);
            }}
            disabled={isEIDefinitive()}
          >
            Modifica
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleDownload(anchorEl.id);
            setAnchorEl(null);
          }}
        >
          Download
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleResend(anchorEl.id, "MAIL");
            setAnchorEl(null);
          }}
          disabled={isEIDefinitive()}
        >
          Invia Mail
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleResend(anchorEl.id, "SFTP");
            setAnchorEl(null);
          }}
          disabled={isEIDefinitive()}
        >
          Invia SFTP
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLogAzioni(anchorEl.id);
            setAnchorEl(null);
          }}
        >
          Log Azioni
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLogInvio(anchorEl.id);
            setAnchorEl(null);
          }}
        >
          Log Esiti SDI
        </MenuItem>
      </Popover>
    </TableContainer>
  );
}

function EditorDialog({
  ei,
  open,
  onClose,
  openSnackbar,
  openSnackbarError,
  refresh,
  currentUser,
}) {
  // aggiunge spazi e indentazione all'xml per visualizzarlo nell'editor
  const formattedBody = FormatXML(ei.body, { collapseContent: true });
  const [code, setCode] = React.useState(formattedBody);
  const [isSaving, setIsSaving] = React.useState(false);

  // salva modifiche file xml
  const handleSave = async () => {
    setIsSaving(true);
    let unformattedBody = "";
    try {
      // rimuove formattazione xml per il salvataggio
      unformattedBody = FormatXML(code, {
        collapseContent: true,
        indentation: "",
        lineSeparator: "",
      });
    } catch (error) {
      console.log(error);
      setIsSaving(false);
      openSnackbarError();
      return;
    }
    try {
      const res = await axios.post(`${config.protelExpressUri}/api/editEI`, {
        _id: ei._id,
        hotelID: ei.hotelID,
        body: unformattedBody,
        user: currentUser.email,
      });
      if (res.status === 200) {
        openSnackbar();
        refresh();
      } else {
        openSnackbarError();
      }
      setIsSaving(false);
      onClose();
    } catch (error) {
      openSnackbarError();
      setIsSaving(false);
      onClose();
    }
  };

  return (
    <Dialog maxWidth={false} open={open} onClose={onClose}>
      <DialogTitle style={{ paddingBottom: 0 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>Modifica XML</div>
          <IconButton aria-label="close" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ height: "80vh", width: "90vw" }}>
        <Editor
          value={code}
          defaultLanguage="xml"
          onChange={(value) => setCode(value)}
          loading={<CircularProgress />}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!ei.body0}
          onClick={() => {
            setCode(FormatXML(ei.body0, { collapseContent: true }));
          }}
        >
          Ripristina Originale
        </Button>
        <Button onClick={handleSave} disabled={isSaving}>
          Salva
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GestioneFatture;
