import ReactRouterPropTypes from 'react-router-prop-types';
import { useLocation } from "react-router-dom";

import { authUrl } from '../../authentication';

const getAccessToken = (hash) => {
  if (hash) {
    const realHash = hash.substring(1);
    const result = realHash.split('&')
      .filter(el => el.match('access_token') !== null);
    if (result.length > 0) {
      const accessTokenPart = result[0];
      return accessTokenPart.split('=')[1];
    }
    return null;
  }
  return null;
};
const getState = (hash) => {
  if (hash) {
    const realHash = hash.substring(1);
    const result = realHash.split('&')
      .filter(el => el.match('state') !== null);
    if (result.length > 0) {
      const statePart = result[0];
      const stateValue = statePart.split('=')[1];
      return JSON.parse(atob(decodeURIComponent(stateValue)));
    }
    return null;
  }
  return null;
};

const LoginPage = () => {
  const location = useLocation();
  const accessToken = getAccessToken(location.hash);
  if (accessToken) {
    localStorage.setItem('token', accessToken); // eslint-disable-line
    const { returnUrl } = getState(location.hash);
    window.location.replace(returnUrl || '/');
    return null;
  }
  const { from } = location && location.state ? location.state : {};
  let state;
  if (from) {
    state = {
      returnUrl: from.pathname,
    };
  }
  else {
    state = {
      returnUrl: '/',
    };
  }
  window.location = `${authUrl}&state=${btoa(JSON.stringify(state))}`; // eslint-disable-line
  return null;
};


const propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
};

LoginPage.propTypes = propTypes;

export default LoginPage;
