import jwtDecode from 'jwt-decode';
import config from './config';

const { clientId, redirectUri, loginUri } = config.authentication;

export const authUrl = `${loginUri}?response_type=token&client_id=${clientId}&redirect_uri=${redirectUri}`;

const isAuthenticated = () => {
  const token = localStorage.getItem('token'); // eslint-disable-line
  if (!token) return false;

  const decoded = jwtDecode(token);
  const { exp } = decoded;
  const expirationDate = new Date(exp * 1000);

  if (new Date() > expirationDate) return false;

  return decoded;
};

export default isAuthenticated;
