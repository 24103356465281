import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import axios from "axios";
import { Buffer } from "buffer";
import config from "../config";
import FileDownload from "js-file-download";
import { Card, CardContent } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { encode } from "iconv-lite";

export default function BackOffice() {
  const [file, setFile] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  const { id } = useParams();

  const input = React.useRef();

  const handleInputFile = async (event) => {
    const file = event.target.files?.[0];
    setFile(file);
  };

  const handleSubmitFile = async () => {
    setIsLoading(true);
    const fileText = await file.text();
    const file64 = Buffer.from(fileText, "utf-8").toString("base64");
    axios
      .post(`${config.requestsUri}/backoffice?hotelID=${id}`, {
        data: file64,
      })
      .then((res) => {
        const { data, fileName } = res.data;
        console.log({ fileName });
        data.forEach((file, i) => {
          let name = fileName[i];
          if (!name.includes(".")) {
            name += ".txt";
          }
          const encoded = encode(file, "win1252");
          FileDownload(encoded, name);
          setIsLoading(false);
          setOpenSnackbar(true);
        });
      })
      .catch((error) => {
        setIsLoading(false);
        setOpenSnackbarError(true);
        setErrorMessage(error.response?.data || error.message);
        setOpenDialog(true);
      });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFile(event.dataTransfer.files[0]);
  };

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseSnackbarError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarError(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setErrorMessage("");
  };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F8F8F8" }}>
      <div
        style={{
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#1aa3ff",
          height: "60px",
          display: "flex",
          alignItems: "center",
          marginLeft: 250,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "white",
            fontSize: 20,
            marginLeft: 40,
          }}
          gutterBottom
        >
          Convert Back Office File
        </Typography>
      </div>

      <div style={{ margin: "10px 10px 0px 290px" }}>
        <Card>
          <CardContent>
            <Typography
              style={{ fontSize: "16px", color: "#1aa3ff" }}
              variant="h5"
              gutterBottom
            >
              Convert File
            </Typography>
            <input
              type="file"
              ref={input}
              style={{ display: "none" }}
              onChange={handleInputFile}
            />

            <div
              style={{
                border: "1px solid lightgray",
                borderRadius: 4,
                maxWidth: "80%",
                width: 400,
                height: 200,
                margin: "1rem 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
              onClick={() => input.current.click()}
            >
              <div>Click here or drop the file here</div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {file && (
                <>
                  <div style={{ fontStyle: "italic" }}>{file.name}</div>
                  <Button
                    variant="contained"
                    onClick={handleSubmitFile}
                    style={{
                      backgroundColor: "rgb(3, 155, 229)",
                      color: "white",
                      margin: "1rem",
                    }}
                    elevation={0}
                    disabled={isLoading}
                  >
                    CONVERT
                  </Button>
                </>
              )}
              {isLoading && <CircularProgress style={{ marginLeft: "1rem" }} />}
            </div>
          </CardContent>
        </Card>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration="6000"
        onClose={handleCloseSnackbar}
      >
        <div>
          <Alert severity="success">Success</Alert>
        </div>
      </Snackbar>
      <Snackbar
        open={openSnackbarError}
        autoHideDuration="6000"
        onClose={handleCloseSnackbarError}
      >
        <div>
          <Alert severity="error">Error</Alert>
        </div>
      </Snackbar>
      <Dialog onClose={handleCloseDialog} open={openDialog}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>{errorMessage}</DialogContent>
      </Dialog>
    </div>
  );
}
